import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import React from "react";
import Chart from "react-apexcharts";
import { Segment } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/segmentation";

type Proptypes = {
  sectionIndex: number;
  selectedSegment: number | null;
  segments: Segment[];
  setSelectedSegment: (index: number) => void;
};

const SegmentChart: React.FC<Proptypes> = ({ selectedSegment, sectionIndex, setSelectedSegment, segments }) => {
  const { getChartColors } = useGetChartColors([
    "#3C83F6",
    "#EC4699",
    "#0CC09E",
    "#EF4343",
    "#F59F0A",
    "#D535ED",
    "#F97415",
    "#8055F7",
    "#9CA1B0",
  ]);

  const chartOptions = {
    chart: {
      id: `segmentationChart${sectionIndex}`,
      toolbar: {
        show: false,
      },
      parentHeightOffset: 0,
      events: {
        dataPointSelection: function (event: any, chartContext: any, config: any) {
          setSelectedSegment(config.dataPointIndex);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: getChartColors(selectedSegment),
    tooltip: {
      enabled: false,
    },
  };

  const series = segments.map((segment) => {
    const minValue = segment.split.min.value || 0;
    const maxValue = segment.split.max.value || 0;
    return (minValue + maxValue) / 2;
  });

  return <Chart options={chartOptions} series={series} type="donut" height={300} width={300} />;
};

export default SegmentChart;
