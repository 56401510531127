import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { Typography, useThemeTokens, Link, Alert } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

import { suggestions, noSearchResults, rephraseYourSearch, contactManagerForCall, searchAlert } from "content/AlphaNow";
import { AlphaNowSpinner, ContentCard } from "pages/AlphaNowPage/components";
import { PageFooter, SEARCH_SUGGESTION_TYPES, CONTENT_TYPE, COMPANY_TYPE } from "@alphasights/client-portal-shared";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import RequestPrimerCard from "../../RequestPrimer/RequestPrimerCard/RequestPrimerCard";
import { REQUEST_PRIMER } from "content/AlphaNow/constants";
import { useAlphaNowQueryContext } from "pages/AlphaNowPage/components/AlphaNowQueryContext";
import { BooleanTypes } from "components/Search/consts";
import useModal from "hooks/useModal";
import { CommissionModal } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";
import { useScroll } from "pages/AlphaNowPage/components/ScrollContext";
import CardStack from "pages/AlphaNowPage/components/CardStack";
import CompanyPageCard from "pages/AlphaNowPage/components/CompanyPageCard";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ResultsCounter } from "../AlphaNowFilters/components";
import useCompanyOverview from "pages/AlphaNowPage/hooks/useCompanyOverview";
import { marketCompetitorsTitle } from "../constants";
import { LoadingSpinnerContainer, NoSearchResultsContainer, SearchResultsContainer } from "./SearchResults.styled";

// TODO: we could try to move all the logic related with
// getting results to this component
const SearchResults = ({
  isPageLimit = false,
  displayRequestPrimerCard,
  isRequestPrimer,
  getItems,
  isLoading,
  onSelect,
  hasMoreContent = false,
  bookmarksChanged = [],
  purchasedContentChanged = [],
  searchResults = [],
  selectedCompanies = [],
  numberOfResults,
  marketCompetitors = [],
  errorMessage,
  setSelectedCompanyId,
  selectedCompanyId,
}) => {
  const [noPrimerCompanies, setNoPrimerCompanies] = useState([]);
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();
  const { hasOnlyPremiumContent } = useAlphaNowContentAccessLevelContext();
  const {
    query: { selectedContentId, searchQuery, filters },
    updateQuery,
  } = useAlphaNowQueryContext();
  const {
    isVisible: isCommissionModalVisible,
    onOpen: onOpenCommissionModal,
    onClose: onCloseCommissionModal,
  } = useModal();
  const { targetRef, containerRef } = useScroll();
  const { isMobile } = useCheckScreen();
  const { companyOverviewData, hasCompanyOverview } = useCompanyOverview(selectedCompanyId);

  const hasNoSearchResults = searchResults?.length === 0;
  const defaultSearch = searchQuery.length === 0;
  const displayMoreResults = hasMoreContent && (!defaultSearch || !isPageLimit);
  const isSearchBannerEnabled = defaultSearch && isPageLimit && hasMoreContent;

  const numMarketCompetitors = marketCompetitors.length;
  const hasCompetitorMarkets = numMarketCompetitors > 0;
  const hasCompanyPageCards = hasCompetitorMarkets || hasCompanyOverview;

  const companyPageCards = useMemo(() => {
    const handleOnSelect = (companyId) => {
      updateQuery({
        selectedContentId: undefined,
      });
      setSelectedCompanyId(companyId);
    };

    const modifiedCompanyOverviewData = { ...companyOverviewData, cdsAlphaCompanyId: companyOverviewData?.id };

    const relevantCompaniesData = hasCompetitorMarkets
      ? marketCompetitors.map(({ company }) => company)
      : [modifiedCompanyOverviewData];

    if (hasCompanyPageCards)
      return (
        <CardStack title={hasCompetitorMarkets ? `${marketCompetitorsTitle} (${numMarketCompetitors})` : ""}>
          {relevantCompaniesData.map(({ name, logo, cdsAlphaCompanyId }) => {
            const isCompanyPageCardSelected = selectedCompanyId === cdsAlphaCompanyId && !selectedContentId;
            return (
              <CompanyPageCard
                key={name}
                companyName={name}
                companyLogo={logo}
                onClick={() => handleOnSelect(cdsAlphaCompanyId)}
                isSelected={isCompanyPageCardSelected}
              />
            );
          })}
        </CardStack>
      );
  }, [companyOverviewData, marketCompetitors, selectedContentId, hasCompanyPageCards, selectedCompanyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const suggestionsList = [
    <>
      <Link style={{ height: 0 }} size="small" onClick={onOpenCommissionModal} component="span">
        Commission Primers
      </Link>{" "}
      <span>on a company or market</span>
    </>,
    rephraseYourSearch,
    contactManagerForCall,
  ];

  const handlePrimerCompanies = useCallback(() => {
    //gets all the companies with primers from the search results into a simplified object
    const filteredCompaniesWithPrimers = searchResults.reduce((acc, result) => {
      if (result.contentType === CONTENT_TYPE.companyPrimer) {
        result.companies.forEach(({ companyId, companyName, companyType }) => {
          if (
            companyType === COMPANY_TYPE.anchor &&
            !acc.some((existingCompany) => existingCompany.companyId === companyId)
          ) {
            acc.push({ name: companyName, id: companyId.toString() });
          }
        });
      }
      return acc;
    }, []);

    //  checks for the ones that don't have a primer and were selected
    // in order to render them as requestPrimerCards
    const filteredCompaniesNoPrimers = selectedCompanies.filter(
      (selectedCompany) =>
        !filteredCompaniesWithPrimers.some((primerCompany) => primerCompany.id === selectedCompany.id.toString())
    );

    setNoPrimerCompanies(filteredCompaniesNoPrimers);
  }, [searchResults, selectedCompanies]);

  const isBookmarked = ({ clientBookmark, id }) => {
    const overridenBookmark = bookmarksChanged.find(({ contentId }) => contentId === id);

    return overridenBookmark ? overridenBookmark.isBookmarked : clientBookmark !== null;
  };

  const isResultAccessible = (searchResult) => {
    const { purchaseStatus, approvalStatus, id } = searchResult;

    const overridenPurchase = purchasedContentChanged.find(({ contentId }) => contentId === id);

    const isAccessible = isContentAccessible(purchaseStatus, approvalStatus);

    return overridenPurchase ? overridenPurchase.isPurchased : isAccessible;
  };

  useEffect(() => {
    if (selectedCompanies.length > 0) {
      return handlePrimerCompanies();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  if (isLoading && hasNoSearchResults) {
    return (
      <LoadingSpinnerContainer>
        <AlphaNowSpinner />
      </LoadingSpinnerContainer>
    );
  }

  if (hasNoSearchResults) {
    if (displayRequestPrimerCard) {
      return (
        <x.div pl={inner.base08} pr={inner.base06} pt={inner.base}>
          <RequestPrimerCard
            onClick={() => onSelect(REQUEST_PRIMER)}
            companies={selectedCompanies}
            isRequestPrimer={isRequestPrimer}
          />
        </x.div>
      );
    }
    return (
      <NoSearchResultsContainer>
        <x.div display="flex" flexDirection="column">
          {companyPageCards}
          <x.div mx={inner.base02} mt={inner.base06}>
            <x.div mb={inner.base03}>
              <Typography variant="body-small" component="span" color={color.text.secondary}>
                {noSearchResults}
              </Typography>
            </x.div>

            <Typography variant="body-small-em" color={color.text.secondary} mb={inner.base}>
              {suggestions}:
            </Typography>
            <x.ul ml={inner.base05}>
              {suggestionsList.map((elem, index) => (
                <Typography
                  key={`suggestion-${index}`}
                  component="li"
                  variant="body-small"
                  color={color.text.secondary}
                  listStyleType="disc"
                  listStylePosition="outside"
                >
                  {elem}
                </Typography>
              ))}
            </x.ul>
          </x.div>
        </x.div>
        {isCommissionModalVisible && <CommissionModal onClose={onCloseCommissionModal} />}
        <PageFooter />
      </NoSearchResultsContainer>
    );
  }

  return (
    <SearchResultsContainer id="searchResultsScrollableContainer" ref={containerRef}>
      {isMobile && (
        <x.div display="flex" justifyContent="right" pr="28px" pt={inner.base04}>
          <ResultsCounter numberOfResults={numberOfResults} productTypes={filters?.contentType ?? []} />
        </x.div>
      )}
      <InfiniteScroll
        dataLength={searchResults ? searchResults.length : 0}
        next={() => getItems({ contentId: selectedContentId })}
        hasMore={displayMoreResults}
        loader={
          <x.div pt={inner.base06}>
            <AlphaNowSpinner />
          </x.div>
        }
        scrollableTarget="searchResultsScrollableContainer"
      >
        <x.div
          display="flex"
          flexDirection="column"
          pl={{ xs: inner.base06, sm: inner.base08 }}
          pr={inner.base06}
          pt={{ xs: inner.base04, sm: inner.base06 }}
          gap={inner.base03}
        >
          {displayRequestPrimerCard && noPrimerCompanies?.length > 0 && (
            <RequestPrimerCard
              onClick={() => onSelect(REQUEST_PRIMER)}
              companies={noPrimerCompanies}
              isRequestPrimer={isRequestPrimer}
            />
          )}
          {errorMessage && (
            <Alert variant="warning" size="small" w="100%">
              {errorMessage}
            </Alert>
          )}
          {companyPageCards}
          {hasCompetitorMarkets && searchResults && (
            <Typography color={color.text.secondary} variant="body-em" pt={inner.base03}>
              {`Research (${numberOfResults})`}
            </Typography>
          )}
          {searchResults &&
            searchResults.map((result, index) => {
              const { id, contentType, keywordHits } = result;

              return (
                <ContentCard
                  ref={id === selectedContentId ? targetRef : null}
                  content={result}
                  searchQuery={searchQuery}
                  isBookmarked={isBookmarked(result)}
                  showPurchasedFlag={!hasOnlyPremiumContent && isResultAccessible(result)}
                  isSelected={selectedContentId === id}
                  key={`search-result-${id}`}
                  keywordHits={keywordHits}
                  onClick={() => onSelect(id, index, contentType)}
                />
              );
            })}
          {isSearchBannerEnabled && (
            <Alert variant="info" w="100%">
              <b>Tip:</b> {searchAlert}
            </Alert>
          )}
        </x.div>
      </InfiniteScroll>
      <PageFooter />
    </SearchResultsContainer>
  );
};

SearchResults.propTypes = {
  isPageLimit: PropTypes.bool,
  bookmarksChanged: PropTypes.arrayOf(
    PropTypes.shape({
      contentId: PropTypes.string.isRequired,
      isBookmarked: PropTypes.bool,
    })
  ),
  purchasedContentChanged: PropTypes.arrayOf(
    PropTypes.shape({
      contentId: PropTypes.string.isRequired,
      isPurchased: PropTypes.bool,
    })
  ),
  hasMoreContent: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  searchQuery: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.oneOf([...Object.values(SEARCH_SUGGESTION_TYPES)], ...[Object.values(BooleanTypes)]),
    })
  ),
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

export default SearchResults;
