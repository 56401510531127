import React, { useEffect, useState, useCallback } from "react";
import { differenceInHours, addMinutes, isBefore, isSameMinute, isEqual } from "date-fns";
import { FormattedDateTime, useTimezone, parseISO } from "../../providers/TimezoneProvider";
import { Option, Select, Typography } from "@alphasights/alphadesign-components";
import { GroupedAvailabilitySlots } from "pages/InteractionPage/sections/Availability/AvailabilitySelector";
import { useAvailabilitySelectorStyles } from "./AvailabilitySelector.styles";
import { x } from "@xstyled/styled-components";
import { OneHourMinimumAlert } from "components/OneHourMinimumAlert";

export const formatSlot = (timezone, { startsAt, endsAt }) => {
  const samePeriod =
    (timezone.parseDateZoned(startsAt).getHours() >= 12 && timezone.parseDateZoned(endsAt).getHours() >= 12) ||
    (timezone.parseDateZoned(startsAt).getHours() < 12 && timezone.parseDateZoned(endsAt).getHours() < 12);

  return (
    <>
      <FormattedDateTime date={startsAt} format={"h:mm" + (samePeriod ? "" : "aaa")} /> -{" "}
      <FormattedDateTime date={endsAt} format={"h:mmaaa"} />
    </>
  );
};

const splitSlots = (timespans, duration) =>
  timespans.flatMap((timespan) => {
    let smallerSlots = [];

    let startsAt = timespan.startsAt;
    let endsAt = addMinutes(startsAt, duration);

    while (isBefore(endsAt, addMinutes(timespan.endsAt, 1))) {
      smallerSlots.push({ startsAt, endsAt });

      startsAt = addMinutes(startsAt, 15);
      endsAt = addMinutes(startsAt, duration);
    }

    return smallerSlots;
  });

const Timespan = ({ startsAt, endsAt }) => {
  return (
    <span className="aui-mx-1 aui-text-grey-5 aui-leading-snug">
      <FormattedDateTime date={startsAt} format="p" /> - <FormattedDateTime date={endsAt} format="p" />
    </span>
  );
};

const SelectableTimespan = ({ selectedTimespans, onClick, startsAt, endsAt, disabled, ...props }) => {
  const isSelected = selectedTimespans.some(
    (span) => span.startsAt.getTime() === startsAt.getTime() && span.endsAt.getTime() === endsAt.getTime()
  );

  const disabledClasses = "aui-cursor-not-allowed aui-opacity-50";
  const selectedClasses = isSelected
    ? "aui-bg-grey-5 aui-text-white hover:aui-bg-grey-5"
    : "aui-text-grey-5 hover:aui-text-primary-2 hover:aui-border-primary-2";

  const classes = disabled ? disabledClasses : selectedClasses;

  return (
    <div
      onClick={!disabled ? onClick : null}
      data-tip={
        disabled ? "Please reach out to your AlphaSights project lead to schedule a call on short notice." : null
      }
      data-class="custom-react-tooltip-light"
      data-place="bottom"
      data-testid={props.dataTestId}
      className={`aui-m-1 aui-leading-tight aui-font-semibold aui-text-sm aui-py-1 aui-px-2 aui-rounded aui-border aui-border-solid aui-border-grey-5 aui-cursor-pointer
      ${classes}`}
    >
      <FormattedDateTime date={startsAt} format="p" /> - <FormattedDateTime date={endsAt} format="p" />
    </div>
  );
};

export const AvailabilitySelector = ({
  advisorAvailability,
  selectedTimespans = [],
  onSelectTimespan,
  isSelectable,
  expectedDuration,
  ...props
}) => {
  const tz = useTimezone();

  const parsedSlots = (advisorAvailability || []).map((slot) => ({
    startsAt: parseISO(slot.startsAt),
    endsAt: parseISO(slot.endsAt),
  }));

  const slots = isSelectable ? splitSlots(parsedSlots, expectedDuration) : parsedSlots;
  const dailySpans = Object.entries(tz.timespansByDay(slots));

  const currentTime = parseISO(new Date());
  const isCloseToCurrentTime = (span) => differenceInHours(span.startsAt, currentTime) < 1;

  return (
    <div className="aui-whitespace-pre-wrap aui-space-y-4">
      {dailySpans.map(([day, spans = []]) => (
        <div key={`availability-${day}`}>
          <span className="aui-block aui-text-grey-5 aui-mb-1">{day}:</span>
          <div className="aui-flex aui-flex-wrap aui--mx-1 aui--mt-1">
            {spans.map((span, idx) => (
              <React.Fragment key={`${day}-span-${idx}`}>
                {isSelectable ? (
                  <SelectableTimespan
                    {...span}
                    disabled={isCloseToCurrentTime(span)}
                    selectedTimespans={selectedTimespans}
                    onClick={() => onSelectTimespan(span)}
                    dataTestId={`timeslot-${idx}`}
                  />
                ) : (
                  <Timespan {...span} />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const AvailabilitySelectorNew = ({
  expectedDuration,
  mutualSlots,
  oneHourMinimum,
  onSelectSlot,
  selectedBulkAvailability,
  slots,
  isMobileView = false,
}) => {
  const tz = useTimezone();
  const { availabilitiesStyles } = useAvailabilitySelectorStyles();

  const shouldShowSelect = (groupedSlots) => {
    return (
      selectedBulkAvailability && groupedSlots.find((s) => isSameMinute(s.startsAt, selectedBulkAvailability.startsAt))
    );
  };

  return (
    <x.div {...availabilitiesStyles}>
      {mutualSlots.length > 0 && (
        <x.div {...availabilitiesStyles}>
          <GroupedAvailabilitySlots
            title="Mutual Availability"
            slots={mutualSlots}
            onClickTimespan={onSelectSlot}
            minExpectedDuration={expectedDuration * 60}
            showDay
          />
          {shouldShowSelect(mutualSlots) && (
            <SlotSelect
              isMobileView={isMobileView}
              expectedDuration={expectedDuration}
              onSelectSlot={onSelectSlot}
              selectedBulkAvailability={selectedBulkAvailability}
              oneHourMinimum={oneHourMinimum}
            />
          )}
        </x.div>
      )}
      {Object.entries(tz.timespansByDay(slots, "eeee d MMM")).map(([day, groupedSlots = []]) => (
        <x.div key={day} {...availabilitiesStyles}>
          <GroupedAvailabilitySlots
            title={day}
            slots={groupedSlots}
            onClickTimespan={onSelectSlot}
            minExpectedDuration={expectedDuration * 60}
          />
          {shouldShowSelect(groupedSlots) && (
            <SlotSelect
              isMobileView={isMobileView}
              expectedDuration={expectedDuration}
              onSelectSlot={onSelectSlot}
              selectedBulkAvailability={selectedBulkAvailability}
              oneHourMinimum={oneHourMinimum}
            />
          )}
        </x.div>
      ))}
    </x.div>
  );
};

const SlotSelect = ({
  expectedDuration,
  onSelectSlot,
  oneHourMinimum,
  selectedBulkAvailability,
  isMobileView = false,
}) => {
  const tz = useTimezone();
  const [value, setValue] = useState(null);
  const [slots, setSlots] = useState([]);
  const { selectLabelStyles, selectStyles } = useAvailabilitySelectorStyles();
  const { oneHourAlertStyles } = useAvailabilitySelectorStyles();

  useEffect(() => {
    const newSlots = splitSlots([selectedBulkAvailability], expectedDuration);
    setSlots(newSlots);
    setValue(newSlots[0].startsAt);
    onSelectSlot(newSlots[0], false);
  }, [onSelectSlot, selectedBulkAvailability, expectedDuration]);

  const findSlot = useCallback((startsAt) => slots.find((slot) => isEqual(slot.startsAt, startsAt)) || slots[0], [
    slots,
  ]);

  return (
    <>
      <Select
        label={<Typography {...selectLabelStyles}>Interaction start time</Typography>}
        size={isMobileView ? "small" : "medium"}
        renderSelectedValue={({ value }) => formatSlot(tz, findSlot(value))}
        onChange={(value) => {
          setValue(value);
          onSelectSlot(findSlot(value), false);
        }}
        value={value}
        {...selectStyles}
      >
        {slots.map((slot) => (
          <Option key={slot.startsAt} value={slot.startsAt}>
            {formatSlot(tz, slot)}
          </Option>
        ))}
      </Select>
      {expectedDuration < 60 && oneHourMinimum && <OneHourMinimumAlert {...oneHourAlertStyles} />}
    </>
  );
};
