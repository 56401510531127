import React, { useState } from "react";
import { ProjectSynthesisProvider } from "providers/ProjectSynthesisProvider";
import { x } from "@xstyled/styled-components";
import { SynthesisModuleHeader } from "./SynthesisModuleHeader";
import { useStyles } from "./ProjectSynthesisView.styles";
import { SynthesisSidebarHeader } from "./SynthesisSidebarHeader";
import { SynthesisModuleContent } from "./SynthesisModuleContent";
import { SynthesisSidebarContent } from "./SynthesisSidebarContent/SynthesisSidebarContent";
import _ from "lodash";
import { SynthesisAngle } from "providers/ProjectSynthesisProvider.types";

const collectAngles = (angle: any) => {
  return [
    {
      id: angle.id,
      label: angle.label,
    },
    [...angle.children.map(collectAngles).flat()],
  ];
};

export interface ProjectSynthesisViewProps {
  project: Project;
  filterOptions: any;
}

export const ProjectSynthesisView = (props: ProjectSynthesisViewProps) => {
  const angles: SynthesisAngle[] = _.flattenDeep(props.filterOptions?.groups?.map(collectAngles) || []);

  return (
    <ProjectSynthesisProvider project={props.project} angles={angles}>
      <ProjectSynthesisViewNoCtx />
    </ProjectSynthesisProvider>
  );
};

const ProjectSynthesisViewNoCtx = () => {
  const styles = useStyles();

  return (
    <x.div {...styles.viewWrapper}>
      <SynthesisSidebarWrapper />
      <SynthesisModuleWrapper />
    </x.div>
  );
};

const SynthesisSidebarWrapper = () => {
  const styles = useStyles();
  const [showSuggested, setShowSuggested] = useState(true);

  return (
    <x.div {...styles.sidebarWrapper}>
      <SynthesisSidebarHeader showSuggested={showSuggested} setShowSuggested={setShowSuggested} />
      <SynthesisSidebarContent showSuggested={showSuggested} />
    </x.div>
  );
};

const SynthesisModuleWrapper = () => {
  const styles = useStyles();

  return (
    <x.div {...styles.moduleWrapper}>
      <SynthesisModuleHeader />
      <SynthesisModuleContent />
    </x.div>
  );
};
