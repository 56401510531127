import { Button, Icon, List, ListOption, Loading, Popover, Typography } from "@alphasights/alphadesign-components";
import { MoreVert } from "@alphasights/alphadesign-icons";
import { useNotifications, useNewNavigation } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { x } from "@xstyled/styled-components";
import { CidCompaniesModal } from "components/CidCompaniesModal";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { projectSettingsTabs } from "components/ProjectSettingsModal";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { DEFAULT_ADVISOR_VIEW, usePreference } from "hooks/usePreference";
import { useUserCidEnabled } from "hooks/useUserCidEnabled";
import { sortBy } from "lodash";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { useProjectMoreOptionsContext } from "providers/ProjectMoreOptionsProvider";
import React, { useRef, useState } from "react";
import * as S from "./ProjectMoreOptions.styled";

export type ProjectMoreOptionsProps = {
  applyColumnLayoutToAllProjects?: () => void;
};

export const ProjectMoreOptions = ({ applyColumnLayoutToAllProjects }: ProjectMoreOptionsProps) => {
  const viewTypes = new Map([
    ["list-view", "List"],
    ["table-view", "Table"],
    ["customer-view", "Customer"],
    ["comparison-view", "Comparison"],
  ]);
  const { project } = useCurrentProjectContext();
  const { showSuccessBanner } = useNotifications();
  const [, { updatePreference: updateViewPreference }, isLoading] = usePreference(
    DEFAULT_ADVISOR_VIEW,
    project ? { token: project.token } : undefined
  ) as any;

  const makePreferenceDefault = applyColumnLayoutToAllProjects
    ? () => {
        applyColumnLayoutToAllProjects();
        showSuccessBanner("New table layout applied to all projects.");
      }
    : undefined;

  const makeViewDefault = !isLoading
    ? (defaultView: { defaultView: string }) => {
        updateViewPreference(defaultView);
        showSuccessBanner(`${viewTypes.get(defaultView.defaultView)} view set as default Expert view.`);
      }
    : undefined;

  return <MoreOptionsDropdown onMakePreferenceDefault={makePreferenceDefault} onMakeViewDefault={makeViewDefault} />;
};

type MoreOptionsDropdownProps = {
  onMakePreferenceDefault?: () => void;
  onMakeViewDefault?: ({ defaultView }: { defaultView: string }) => void;
};

const MoreOptionsDropdown = ({ onMakePreferenceDefault, onMakeViewDefault }: MoreOptionsDropdownProps) => {
  const newNavigationEnabled = useNewNavigation();
  const userCidEnabled = useUserCidEnabled();
  const { project } = useCurrentProjectContext();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [showingCidModal, setShowingCidModal] = useState(false);
  const {
    onOpenDownloads,
    onOpenCostOverview,
    onOpenProjectSettings,
    onChangeLayout,
    currentView,
    syncCidStatusChanges,
  } = useProjectMoreOptionsContext();

  const uploadCidAvailabilityByView = new Map([
    ["table-view", true],
    ["customer-view", true],
    ["list-view", newNavigationEnabled],
    ["comparison-view", newNavigationEnabled],
  ]);

  const onUploadCid =
    project?.active && userCidEnabled && project.cidEnabled && uploadCidAvailabilityByView.get(currentView)
      ? () => setShowingCidModal(true)
      : undefined;

  const closeAfter = (action?: Function) =>
    action &&
    ((args?: any) => {
      action(args);
      setOpen(false);
    });

  return (
    <>
      {newNavigationEnabled ? (
        <S.MoreOptionsButton
          variant="ghost"
          color="inverse"
          ref={anchorRef}
          onClick={() => setOpen(!open)}
          isSelectable
          isSelected={open}
          testId="more-options-button"
        >
          <MoreVert />
        </S.MoreOptionsButton>
      ) : (
        <Button
          variant="outline"
          padding="10px"
          ref={anchorRef}
          onClick={() => setOpen(!open)}
          data-testid="more-options-button"
        >
          <Icon>
            <MoreVert />
          </Icon>
        </Button>
      )}
      {open && (
        <Popover
          open
          anchorEl={anchorRef?.current ?? undefined}
          onClose={() => setOpen(false)}
          zIndex={29}
          placement="bottom-end"
          closeOnClickOutside={true}
        >
          <Options
            onUploadCid={closeAfter(onUploadCid)}
            onOpenDownloads={closeAfter(onOpenDownloads)}
            onOpenCostOverview={closeAfter(onOpenCostOverview)}
            onOpenProjectSettings={closeAfter(onOpenProjectSettings)}
            onMakePreferenceDefault={closeAfter(onMakePreferenceDefault)}
            onMakeViewDefault={closeAfter(onMakeViewDefault)}
            onChangeLayout={closeAfter(onChangeLayout)}
          />
        </Popover>
      )}
      {project && (
        <CidCompaniesModal
          projectToken={project.token}
          isModalOpen={showingCidModal}
          onCidCompaniesModalUpload={syncCidStatusChanges ?? (() => {})}
          onClose={() => setShowingCidModal(false)}
        />
      )}
    </>
  );
};

type OptionsProps = {
  onUploadCid?: () => void;
  onOpenDownloads?: () => void;
  onOpenCostOverview?: () => void;
  onOpenProjectSettings?: () => void;
  onMakePreferenceDefault?: () => void;
  onMakeViewDefault?: ({ defaultView }: { defaultView: string }) => void;
  onChangeLayout?: (layout: string) => void;
};

const Options = ({
  onUploadCid,
  onOpenDownloads,
  onOpenCostOverview,
  onOpenProjectSettings,
  onMakePreferenceDefault,
  onMakeViewDefault,
  onChangeLayout,
}: OptionsProps) => {
  const currentUser = useCurrentUser();
  const { showExportLinks, currentView } = useProjectMoreOptionsContext();
  const isPCCEnabledClient = currentUser?.csaSettings.peerContentContributor;
  const isPTLEnabledClient = currentUser?.csaSettings.privateTranscriptContributor;
  const {
    enableComparisonView,
    isViewAccessible: isComparisonViewAccessible,
    isViewBeingGenerated: isComparisonViewBeingGenerated,
    setReferrerOrigin,
  } = useExpertCompareContext();
  const comparisonViewTooltip = isComparisonViewBeingGenerated
    ? "Comparison view is being generated."
    : "Not enough experts to compare at this time.";
  const settingsTabs = projectSettingsTabs({
    isPCCEnabledClient,
    isPTLEnabledClient,
    isPeerContentContributor: undefined,
    isPrivateTranscriptContributor: undefined,
  });
  const newNavigationEnabled = useNewNavigation();

  const options = {
    projectSection: sortBy(
      [
        {
          label: "Settings",
          testId: "item-project-settings",
          onClick: currentUser && settingsTabs.length ? onOpenProjectSettings : undefined,
          order: 2,
        },
        {
          label: "Usage",
          testId: "item-view-usage",
          onClick: onOpenCostOverview,
          order: 3,
        },
        {
          label: "Download Excel",
          testId: "item-download-excel",
          onClick: showExportLinks ? onOpenDownloads : undefined,
          order: 4,
        },
        {
          label: newNavigationEnabled ? "Upload Bain CID" : "Upload CID",
          testId: "upload-cid",
          onClick: onUploadCid,
          order: 1,
        },
      ].filter((o) => !!o.onClick),
      // Keep current order if new navigation is not enabled
      (item) => (newNavigationEnabled ? item.order : 0)
    ),
    tableSection: [
      {
        label: "Apply Layout To All Projects",
        testId: "item-project-settings",
        onClick:
          !["list-view", "comparison-view"].includes(currentView) && currentUser ? onMakePreferenceDefault : undefined,
      },
    ].filter((o) => !!o.onClick),
  };

  return (
    <List data-testid="more-options-content">
      <ListOption type="title" label={newNavigationEnabled ? "Options" : "Project"} />
      {options.projectSection.map(({ label, testId, onClick }) => (
        <x.div key={label} onClick={onClick}>
          <ListOption data-testid={testId} key={label} label={label} type="text" />
        </x.div>
      ))}
      {!newNavigationEnabled && (
        <>
          <ListOption type="divider" />
          <ListOption type="title" label="View" />
          <x.div
            onClick={
              !["table-view", "customer-view"].includes(currentView) && onChangeLayout
                ? () => onChangeLayout("table-view")
                : undefined
            }
            data-testid="more-options-table-view"
          >
            <ListOption type="text" label="Table" selected={["table-view", "customer-view"].includes(currentView)} />
          </x.div>
          <x.div
            onClick={currentView !== "list-view" && onChangeLayout ? () => onChangeLayout("list-view") : undefined}
            data-testid="more-options-list-view"
          >
            <ListOption type="text" label="Cards" selected={currentView === "list-view"} />
          </x.div>
          {enableComparisonView && (
            <PortalTooltip title={comparisonViewTooltip} disabled={isComparisonViewAccessible} dark position="bottom">
              <DivWithLoginWall
                onClick={
                  currentView !== "comparison-view" && isComparisonViewAccessible
                    ? () => {
                        setReferrerOrigin(currentView);
                        onChangeLayout && onChangeLayout("comparison-view");
                      }
                    : undefined
                }
                data-testid="more-options-comparison-view"
              >
                <ListOption type="text" selected={currentView === "comparison-view"}>
                  <S.ComparisonViewOption>
                    <Typography color="strong" component="span">
                      Comparison
                    </Typography>
                    <S.PoweredByAiLabel>
                      <Typography variant="body-small" color="secondary" component="span">
                        Powered by AI
                      </Typography>
                      {isComparisonViewBeingGenerated && (
                        <x.span data-testid="comparison-view-loading">
                          <Loading size="sm" />
                        </x.span>
                      )}
                    </S.PoweredByAiLabel>
                  </S.ComparisonViewOption>
                </ListOption>
              </DivWithLoginWall>
            </PortalTooltip>
          )}
          {onMakeViewDefault && (
            <>
              <ListOption type="divider" />
              <x.div
                onClick={() => {
                  onMakeViewDefault({
                    defaultView: currentView,
                  });
                }}
                data-testid="more-options-set-default-view"
              >
                <ListOption type="text" label="Set as default view" />
              </x.div>
            </>
          )}
          {options.tableSection.length > 0 && (
            <>
              <ListOption type="divider" />
              <ListOption type="title" label="Table Options" />
              {options.tableSection.map(({ label, testId, onClick }) => (
                <x.div key={label} onClick={onClick} data-testid={testId}>
                  <ListOption key={label} label={label} type="text" />
                </x.div>
              ))}
            </>
          )}
        </>
      )}
    </List>
  );
};

const DivWithLoginWall = withLoginWall(x.div);
