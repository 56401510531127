/* eslint no-control-regex: 0 */

import React, { useState, useEffect, useMemo } from "react";
import { Route, Link, Redirect } from "react-router-dom";
import { PageFooter } from "@alphasights/client-portal-shared";
import { thirdPartyAccessTerms } from "./ThirdPartyAccessTerms";
import { PrimaryButtonSmall, InputField, WarningBanner, CheckboxInput } from "../alphaui";
import { fetch } from "../../hooks/useApi";

const ThirdPartyForm = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [messages, setMessages] = useState({});

  const checkName = () => {
    const notUnicode = /[^\u0000-\u00ff]/;
    const twoWords = /^[a-zA-Z]+(.|,)? [a-zA-Z]+/;
    const msg = Object.assign({}, messages);
    if (name) delete msg.name;
    if (name && name.match(notUnicode)) return setMessages(msg);
    if (name && !name.match(twoWords)) msg.name = "Name must be composed of at least two words";
    if (!name || name.match(twoWords)) delete msg.name;
    setMessages(msg);
  };

  const checkEmail = () => {
    const validEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const msg = Object.assign({}, messages);
    if (email) delete msg.email;
    if (email && !email.trim().match(validEmail)) msg.email = "Email must be valid";
    if (!email || email.trim().match(validEmail)) delete msg.email;
    setMessages(msg);
  };

  const checkCompany = () => {
    const msg = Object.assign({}, messages);
    if (company) delete msg.company;
    setMessages(msg);
  };

  const checkMandatoryFields = () => {
    const msg = Object.assign({}, messages);
    if (!name.trim()) msg.name = "Please inform your name";
    if (!email.trim()) msg.email = "Please inform your email";
    if (!company.trim()) msg.company = "Please inform your company";
    setMessages(msg);
  };

  useEffect(checkName, [name]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(checkEmail, [email]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(checkCompany, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  const pathname = `${props.match.url}/terms-of-service`;
  const hasMessages = () => Object.keys(messages).length > 0;

  return (
    <>
      <div className="aui-font-sans aui-font-normal aui-bg-grey-1">
        <main className="aui-px-8 aui-pt-16 aui-pb-24 aui-mx-auto aui-max-w-screen-lg aui-overflow-x-auto lg:aui-box-border">
          <h1 className="aui-text-center aui-leading-none aui-font-bold aui-mb-6 aui-mt-0 aui-text-4xl aui-leading-tight aui-mb-8">
            Join an AlphaSights Interaction on Project&nbsp;
            <b>{props.project?.title || ""}</b>
          </h1>
          <p className="aui-mb-12 aui-text-2xl aui-leading-tight aui-m-auto aui-text-center aui-max-w-screen-md">
            All professional connections arranged through AlphaSights take place in the context of a secure,
            legally-compliant framework, with each party adhering to the highest professional, legal and ethical
            standards at all times. For further information about AlphaSights,&nbsp;
            <a className="aui-no-underline" href="https://www.alphasights.com/about">
              click here
            </a>
            .
          </p>

          <div className="aui-shadow-md aui-flex aui-flex-col aui-px-12 aui-py-10 md:aui-w-1/2 aui-mx-auto aui-mb-3 aui-bg-white">
            <div className="aui-mb-1">
              Name <span className="aui-text-red-500">*</span>
            </div>
            <InputField value={name} error={messages.name} onChange={setName} />
            <div className="aui-mb-1 aui-mt-4">
              Email <span className="aui-text-red-500">*</span>
            </div>
            <InputField value={email} error={messages.email} onChange={setEmail} />
            <div className="aui-mb-1 aui-mt-4">
              Company <span className="aui-text-red-500">*</span>
            </div>
            <InputField value={company} error={messages.company} onChange={setCompany} />
            <div className="aui-mt-8">
              {!hasMessages() && name.trim() && email.trim() && company.trim() ? (
                <Link
                  to={{
                    pathname,
                    state: {
                      name: name.trim(),
                      email: email.trim(),
                      company: company.trim(),
                    },
                  }}
                >
                  <PrimaryButtonSmall className="aui-w-full">Proceed</PrimaryButtonSmall>
                </Link>
              ) : (
                <PrimaryButtonSmall className="aui-w-full" onClick={checkMandatoryFields}>
                  Proceed
                </PrimaryButtonSmall>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

const TermsOfService = (props) => {
  const [agreed, setAgreed] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [message, setMessage] = useState();
  useEffect(() => setMessage(undefined), [agreed]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const year = useMemo(() => new Date().getFullYear(), []);

  const { token } = props.match.params;
  const formUrl = props.match.url.replace("/terms-of-service", "");
  if (!props.location?.state) return <Redirect to={formUrl} />;
  const { name, email: emailAddress, company: companyName } = props.location.state;
  if (!name || !emailAddress || !companyName) return <Redirect to={formUrl} />;
  const url = `/api/third-party/${token}/accept`;
  const body = JSON.stringify({ name, emailAddress, companyName });
  const postConfirmation = () => {
    if (!agreed) return setMessage("Please confirm the agreement in the checkbox above");
    fetch({ url, method: "POST", body }).then(() => setConfirmed(true));
  };

  return (
    <div className="aui-bg-grey-1">
      <div className="aui-pt-12 aui-px-6 aui-max-w-screen-xl aui-font-sans aui-font-normal aui-mx-auto aui-text-dark-1">
        {confirmed ? (
          <ThankYou />
        ) : (
          <>
            <Terms />
            <div className="aui-pt-8 aui-pb-3">
              <CheckboxInput state={[agreed, setAgreed]}>
                I <strong>{name}</strong>, for myself and on behalf of <strong>{companyName}</strong>, agree to be bound
                by these Third Party Terms of Service in relation to the use of or access to any AlphaSights Services. I
                confirm that I am duly authorised to sign these Third Party Terms of Service for and on behalf of{" "}
                <strong>{companyName}</strong>.
              </CheckboxInput>
            </div>
            {message && <WarningBanner onClose={() => setMessage(undefined)}>{message}</WarningBanner>}
            <PrimaryButtonSmall onClick={postConfirmation}>Agree</PrimaryButtonSmall>
          </>
        )}
      </div>
      {!confirmed && (
        <div className="aui-bg-grey-1 aui-px-3 aui-text-center aui-pt-12 aui-pb-3">
          <i>
            © {year} AlphaSights Ltd. All rights reserved. No part of this document may be reproduced, stored, or
            transmitted in any form or by any means without the written permission of AlphaSights Ltd. Private &amp;
            Confidential.
          </i>
        </div>
      )}
    </div>
  );
};

export default ({ match: { path }, project }) => {
  return (
    <>
      <Route exact path={path} component={(props) => <ThirdPartyForm {...props} project={project} />} />
      <Route exact path={`${path}/terms-of-service`} component={TermsOfService} />
      <PageFooter />
    </>
  );
};

const Terms = () => (
  <>
    <h1 className="aui-font-bold aui-text-4xl aui-text-center">Third Party Access Terms</h1>
    <div dangerouslySetInnerHTML={{ __html: thirdPartyAccessTerms }} />
  </>
);

const ThankYou = () => (
  <div className="aui-max-w-screen-md aui-m-auto aui-pb-20">
    <h1 className="aui-font-bold aui-text-4xl aui-text-center">Many thanks!</h1>
    <p className="aui-text-xl">
      You have agreed to participate in the forthcoming Interaction in accordance with the AlphaSights Third Party Terms
      of Service.
    </p>
    <p className="aui-text-xl">
      Further scheduling and other information to follow separately. If you have any queries in relation to the
      Interaction, please contact the Client. If you have any queries in relation to the Third Party Terms of Service,
      please contact our&nbsp;
      <a className="aui-no-underline" href="mailto:legal.compliance@alphasights.com">
        Compliance team
      </a>
      .
    </p>
  </div>
);
