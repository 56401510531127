import React, { FC, createContext, useCallback, useContext, useEffect, useMemo, useState, ReactNode } from "react";
import { merge, cloneDeep, isEqual } from "lodash";
import { getQueryString, setQueryString } from "utils/query-string";
import { Query, AlphaNowQueryState } from "pages/AlphaNowPage/components/AlphaNowQueryContext/types";
import { INITIAL_QUERY_STATE, INITIAL_CONTEXT_STATE } from "pages/AlphaNowPage/components/AlphaNowQueryContext/consts";

const initialQueryState = cloneDeep(INITIAL_QUERY_STATE);
const initialContextState = cloneDeep(INITIAL_CONTEXT_STATE);

const AlphaNowQueryContext = createContext<AlphaNowQueryState>(initialContextState);

export type AlphaNowQueryProviderProps = {
  children: ReactNode;

  value?: AlphaNowQueryState;
};

const AlphaNowQueryProvider: FC<AlphaNowQueryProviderProps> = ({ children, ...props }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [queryHistory, setQueryHistory] = useState<Query[]>([merge(initialQueryState, getQueryString())]);
  const [isBackDisabled, setIsBackDisabled] = useState<boolean>(true);
  const [isForwardDisabled, setIsForwardDisabled] = useState<boolean>(true);

  const lastQueryIndex = useMemo(() => queryHistory.length - 1, [queryHistory]);

  useEffect(() => {
    setIsBackDisabled(currentIndex === 0);
    setIsForwardDisabled(currentIndex === lastQueryIndex);

    const handler = setTimeout(() => setQueryString({ ...queryHistory[currentIndex] }), 10);
    return () => clearTimeout(handler);
  }, [currentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigate = useCallback(
    (step: number) => {
      const isForwardAndNotLast = step === 1 && currentIndex !== lastQueryIndex;
      const isBackAndNotFirst = step === -1 && currentIndex !== 0;
      const canNavigate = isForwardAndNotLast || isBackAndNotFirst;
      canNavigate && setCurrentIndex(currentIndex + step);
    },
    [currentIndex, lastQueryIndex, setCurrentIndex]
  );

  const updateQuery = useCallback(
    (queryUpdate: Partial<Query>) => {
      const newQuery = { ...cloneDeep(queryHistory[currentIndex]), ...cloneDeep(queryUpdate) };

      if (isEqual(queryHistory[currentIndex], newQuery)) return;

      const newIndex = currentIndex + 1;
      const updatedHistory =
        currentIndex === lastQueryIndex ? [...queryHistory] : queryHistory.slice(0, currentIndex + 1);
      updatedHistory.push({ ...newQuery });

      setQueryHistory(cloneDeep(updatedHistory));
      setCurrentIndex(newIndex);
    },
    [queryHistory, currentIndex, lastQueryIndex, setQueryHistory, setCurrentIndex]
  );

  const memoizedContextValue = useMemo(
    () => ({
      query: queryHistory[currentIndex],
      updateQuery,
      navigate,
      isBackDisabled,
      isForwardDisabled,
    }),
    [queryHistory, currentIndex, updateQuery, navigate, isBackDisabled, isForwardDisabled]
  );

  return (
    <AlphaNowQueryContext.Provider value={memoizedContextValue} {...props}>
      {children}
    </AlphaNowQueryContext.Provider>
  );
};

const useAlphaNowQueryContext = (): AlphaNowQueryState => {
  const queryNavigationContext = useContext(AlphaNowQueryContext);

  if (queryNavigationContext === undefined) {
    throw new Error("useAlphaNowQueryContext must be used within a AlphaNowQueryProvider");
  }

  return queryNavigationContext;
};

const isInStorybookContext = () => ["viewMode=story"].some((path) => window.location.search.includes(path));

const withAlphaNowQueryProvider = (Story: FC<any>, value?: AlphaNowQueryState) => {
  if (!isInStorybookContext()) {
    throw Error("withAlphaNowQueryProvider can only be used within Storybook");
  }

  return (
    <AlphaNowQueryProvider value={value ?? cloneDeep(INITIAL_CONTEXT_STATE)}>
      <Story />
    </AlphaNowQueryProvider>
  );
};

export { AlphaNowQueryContext, AlphaNowQueryProvider, useAlphaNowQueryContext, withAlphaNowQueryProvider };
