import React, { useCallback, useMemo, useState } from "react";
import { useLockedExpert } from "../../hooks/useLockedExpert";
import {
  ClientAvailabilityButton,
  InstantScheduleButton,
  RequestButton,
  ScheduleButton,
  FollowUpButton,
  MessageButton,
} from "pages/InteractionPage/sections/Topbar/Topbar";
import { enrichInteraction } from "pages/InteractionPage";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { isCallAvailable } from "components/InteractionsPage/helpers/Interaction";
import { CallMeButton } from "components/CallMe";
import { HitOrigin } from "@alphasights/portal-api-client";
import { ENABLE_PORTAL_MESSAGES, useProjectBadgeContext } from "providers/BadgeProvider";
import { useHideExpertContext } from "providers/HideExpertProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";

export const ActionColumn = ({ interaction: data, origin }) => {
  const { locked } = useLockedExpert(data);
  const { isMobile } = useCheckScreen();

  const { unhideExpert } = useHideExpertContext();
  const {
    onLeaveAvailability,
    onRequest,
    onRequestFollowUp,
    onStartSchedule,
    onSendNewMessage: onSendNewMessageInput,
  } = useProjectInteractionsContext();
  const { hasProjectBadge } = useProjectBadgeContext();
  const currentUser = useCurrentUser();

  const [isCallMeModalOpen, setIsCallMeModalOpen] = useState(false);
  const {
    project: { active: isActiveProject, token },
  } = useCurrentProjectContext();
  const hasClientPortalMessages = hasProjectBadge(ENABLE_PORTAL_MESSAGES);

  const interaction = enrichInteraction({
    interaction: data,
    clientPccFlag: currentUser?.peerContentContributor,
    isActiveProject,
    isMobile,
    hasClientPortalMessages,
  });

  const isCallAboutToStartOrHappening = useMemo(() => isCallAvailable(interaction), [interaction]);
  const messengerEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES) && !interaction.isSurvey;
  const unhideExpertAndThen = useCallback(
    (callback) => (...props) => {
      unhideExpert(interaction, HitOrigin.tableView);
      return callback(...props);
    },
    [interaction, unhideExpert]
  );
  const onSendNewMessage = unhideExpertAndThen(onSendNewMessageInput);

  if (locked) return null;

  if (isCallAboutToStartOrHappening && !interaction.isNonCallInteraction) {
    return (
      <CallMeButton
        callMeUrl={interaction.callMeUrl}
        provider={interaction.newspeakProvider}
        isModalOpen={isCallMeModalOpen}
        setIsModalOpen={setIsCallMeModalOpen}
        projectToken={token}
        size="small"
        variant={isCallAboutToStartOrHappening ? "primary" : "outline"}
        onSendNewMessage={onSendNewMessage}
        showMessagingOptions={messengerEnabled}
        interaction={interaction}
        showPrimaryOption
      />
    );
  }

  return (
    <>
      {interaction.showInstantSchedule && (
        <InstantScheduleButton
          interaction={interaction}
          loading={interaction.runningAction === "request"}
          onClick={unhideExpertAndThen((event) => {
            event.stopPropagation();
            return onRequest(interaction.id, origin || "table-view-instant");
          })}
          showMessagingOptions={messengerEnabled}
          onSendNewMessage={onSendNewMessage}
          primaryOptionLabel="Schedule Call"
        />
      )}
      {interaction.showRequest && (
        <RequestButton
          interaction={interaction}
          loading={interaction.runningAction === "request"}
          onClick={unhideExpertAndThen((event) => {
            event.stopPropagation();
            return onRequest(interaction.id, origin || "table-view-regular");
          })}
          showMessagingOptions={messengerEnabled}
          onSendNewMessage={onSendNewMessage}
          primaryOptionLabel="Request Call"
        />
      )}
      {interaction.showSchedule && (
        <ScheduleButton
          interaction={interaction}
          loading={interaction.runningAction === "schedule"}
          onClick={unhideExpertAndThen((event) => {
            event.stopPropagation();
            onStartSchedule(interaction);
          })}
          showMessagingOptions={messengerEnabled}
          onSendNewMessage={onSendNewMessage}
          primaryOptionLabel="Schedule Call"
        />
      )}
      {interaction.state === "completed" && interaction.isActiveProject && (
        <FollowUpButton
          interaction={interaction}
          hasClientPortalMessages={hasClientPortalMessages}
          token={token}
          onRequestFollowUp={unhideExpertAndThen(() => {
            onRequestFollowUp({
              id: interaction.id,
              origin: origin ?? HitOrigin.tableView,
            });
          })}
          variant={messengerEnabled && interaction.showFollowUp ? "primary" : "outline"}
          singleButton={interaction.isSurvey}
          showMessagingOptions={messengerEnabled}
          onSendNewMessage={onSendNewMessage}
        />
      )}
      {interaction.showLeaveAvailability && !interaction.showSchedule && !messengerEnabled && (
        <ClientAvailabilityButton
          interaction={interaction}
          loading={interaction.runningAction === "schedule"}
          onClick={unhideExpertAndThen((event) => {
            unhideExpert(interaction, origin ?? HitOrigin.tableView);
            event.stopPropagation();
            onLeaveAvailability(interaction);
          })}
        />
      )}
      {interaction.showMessage && messengerEnabled && (
        <MessageButton variant="outline" interactions={[interaction]} onSendNewMessage={onSendNewMessage} />
      )}
    </>
  );
};
