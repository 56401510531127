import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Logo, LogoVariation, NavBar, Typography, Alert } from "@alphasights/alphadesign-components";
import { CallMePanel } from "components/CallMePanel";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { fetch } from "hooks/useApi";
import { Spinner } from "components/alphaui";

const LoadingData = ({ isMobile }) => {
  return (
    <div className={`aui-flex aui-flex-col ${isMobile ? "aui-my-20" : "aui-m-6"}`}>
      <div>
        <Typography className="aui-text-grey-3 aui-h-5 aui-my-px aui-text-center">Loading Dial-in Options</Typography>
      </div>
      <div className={`aui-mt-5`}>
        <Spinner size="medium" type="svg" transparent />
      </div>
    </div>
  );
};

export const CallMePage = ({ match: { path, url, params } }) => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { tab } = values;

  const { token, interactionId } = params;
  const { isMobile } = useCheckScreen();
  const [isLoading, setIsLoading] = useState(true);
  const [dialInInfo, setDialInInfo] = useState();

  useEffect(() => {
    setIsLoading(true);
    const url = `/api/internal/projects/${token}/interactions/find`;
    fetch({
      url,
      method: "POST",
      body: JSON.stringify({ filters: { ids: [interactionId] } }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data || !data.edges || data.edges.length === 0) {
          setDialInInfo(null);
        } else {
          const {
            clientAccessNumber,
            clientAccessCode,
            dialInNumbers,
            id,
            newspeakProvider,
            callMeUrl,
          } = data.edges[0].node;

          setDialInInfo({
            mainNumber: clientAccessNumber,
            accessCode: clientAccessCode,
            otherNumbers: dialInNumbers,
            projectToken: token,
            interactionId: id,
            newspeakProvider: newspeakProvider,
            isClosed: false,
            callMeUrl: callMeUrl,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [token, interactionId]);

  return (
    <>
      {isMobile ? (
        <div className="aui-rounded-md aui-shadow-md aui-flex aui-justify-center aui-py-4 aui-border-grey-2 aui-border-b">
          <Logo size="xs" variation={LogoVariation.ColorName} />
        </div>
      ) : (
        <NavBar logoProps={{ size: "xs" }} />
      )}
      <>
        {isLoading ? (
          isMobile ? (
            <div className="aui-mt-10">
              <LoadingData isMobile={isMobile} />
            </div>
          ) : (
            <div className="aui-flex aui-items-center aui-justify-center aui-bg-white-smoke aui-w-full aui-h-full">
              <div className="aui-flex-column aui-p-6">
                <LoadingData isMobile={isMobile} />
              </div>
            </div>
          )
        ) : (
          <>
            {dialInInfo ? (
              isMobile ? (
                <div className="aui-mt-10">
                  <div>
                    <CallMePanel {...dialInInfo} initialTab={tab ? parseInt(tab) || null : null} />
                  </div>
                </div>
              ) : (
                <div className="aui-flex aui-items-center aui-justify-center aui-bg-white-smoke aui-w-full aui-h-full">
                  <div className="aui-flex-column aui-p-6">
                    <div className="aui-rounded-md aui-shadow-md aui-bg-white aui-h-72 aui-px-6 aui-pb-6 aui-pt-12">
                      <CallMePanel {...dialInInfo} initialTab={tab ? parseInt(tab) || null : null} />
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="aui-m-5">
                <Alert variant="danger">
                  Failed to load dial-in options. Please reach out to your AlphaSights project lead if you continue to
                  get this error.{" "}
                  <span
                    className="aui-text-primary-1 aui-cursor-pointer"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Refresh Page
                  </span>
                </Alert>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
};
