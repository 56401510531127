import styled from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const SearchBarWrapper = styled.div(({ activeSearchBar }: { activeSearchBar: boolean }) => {
  const { spacing } = useThemeTokens();
  return {
    position: activeSearchBar ? ("static" as "static") : ("sticky" as "sticky"),
    display: "flex",
    flexWrap: "wrap" as "wrap",
    gap: spacing.inner.base,
  };
});

const SearchToolsContainer = styled.div(() => {
  const { spacing, shape, color } = useThemeTokens();

  return {
    flex: "0 0 auto",
    paddingTop: spacing.inner.base06,
    paddingInline: spacing.inner.base08,
    borderBottom: `${shape.border.width.small} solid ${color.border.neutral.default}`,
  };
});

export { SearchBarWrapper, SearchToolsContainer };
