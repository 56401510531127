import React, { ForwardedRef, forwardRef, useMemo } from "react";

import { x } from "@xstyled/styled-components";
import { Icon, XStyledAndHTMLProps, useThemeTokens } from "@alphasights/alphadesign-components";
import { Company } from "@alphasights/alphadesign-icons";

import { CONTENT_TYPE, Card, FLAGS, AlphaNowProductType } from "@alphasights/client-portal-shared";
import { CardTitle, CardSubtitle, CardHeader, Flags } from "pages/AlphaNowPage/components";

import "./index.css";
import { getContentTitle } from "content/AlphaNow/utils";

export const DataTestIds = {
  contentCard: "content-card",
};

const ContentCard = forwardRef(
  (
    {
      isBookmarked = false,
      showPurchasedFlag = false,
      keywordHits = 0,
      searchQuery = [],
      content,
      isSelected,
      onClick,
      ...props
    }: ContentCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { color, spacing } = useThemeTokens();

    const { contentType, externalTitle, speakers, companies, primer, description, productType, companyLogos } = content;

    const isAlphaView = contentType === CONTENT_TYPE.alphaview;
    const isCompanyPrimer = productType === AlphaNowProductType.companyPrimer;
    const isMarketPrimer = productType === AlphaNowProductType.marketPrimer;
    const isCustomerPrimer = productType === AlphaNowProductType.customerPrimer;
    const isPrimerContent = isCompanyPrimer || isMarketPrimer || isCustomerPrimer;

    const title = getContentTitle(productType, companies, externalTitle);
    const nonModeratorSpeakers = speakers?.filter((s) => !s.isModerator);

    const flagItems = [...(showPurchasedFlag ? [FLAGS.Purchased] : []), ...(isBookmarked ? [FLAGS.Bookmarked] : [])];

    const primerCompanyLogoSrc = primer?.logoLink?.value;

    const cardHeader = useMemo(
      () => (
        <CardHeader
          content={content as any}
          searchQuery={searchQuery}
          mentions={keywordHits}
          isPrimer={isPrimerContent}
        />
      ),
      [content, searchQuery, keywordHits, isPrimerContent]
    );

    const cardTitle = useMemo(
      () => (
        <CardTitle
          isAlphaView={isAlphaView}
          isCompanyPrimer={isCompanyPrimer}
          isMarketPrimer={isMarketPrimer}
          isCustomerPrimer={isCustomerPrimer}
          title={title}
          speakers={nonModeratorSpeakers}
        />
      ),
      [isAlphaView, isCompanyPrimer, isMarketPrimer, isCustomerPrimer, title, nonModeratorSpeakers]
    );

    const cardSubtitle = useMemo(
      () => (
        <x.div row alignItems="center" minH={spacing.inner.base06}>
          {description && <CardSubtitle subtitleString={description} />}
          <x.div row flexDirection="row-reverse">
            <Flags items={flagItems} />
          </x.div>
        </x.div>
      ),
      [description, flagItems] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const getCompanyLogos = () => {
      if (isCompanyPrimer) {
        if (!primerCompanyLogoSrc) {
          return (
            <div data-testid="placeholder-icon">
              <Icon size="large" color={color.text.secondary}>
                <Company />
              </Icon>
            </div>
          );
        }

        return (
          <x.img
            src={primerCompanyLogoSrc}
            maxW={spacing.inner.base10}
            maxH={spacing.inner.base10}
            alt={`${title ?? "company"} logo`}
          />
        );
      }

      //marketPrimer & Customer Primer
      if (Array.isArray(companyLogos) && !companyLogos.length) {
        return (
          <div data-testid="placeholder-icon">
            <Icon size="large" color={color.text.secondary}>
              <Company />
            </Icon>
          </div>
        );
      }

      return (
        <x.div
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap="2px"
          padding="2px"
          h="100%"
          gridAutoRows="1fr"
          w="100%"
          textAlign="center"
          alignItems="center"
        >
          {companyLogos?.map((logoSrc: string) => {
            return (
              <x.div
                key={logoSrc}
                backgroundImage={`url(${logoSrc})`}
                w="100%"
                h="100%"
                backgroundPosition="center"
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                title={`${title ?? "company"} logo`}
              />
            );
          })}
        </x.div>
      );
    };

    return (
      <Card
        ref={ref}
        data-testid={DataTestIds.contentCard}
        logo={isPrimerContent && getCompanyLogos()}
        headerContent={cardHeader}
        titleContent={cardTitle}
        subtitleContent={cardSubtitle}
        isSelected={isSelected}
        onClick={onClick}
        {...props}
      />
    );
  }
);

type ContentCardProps = {
  content: {
    companies: {
      companyType: string;
      companyName: string;
    }[];
    companyLogos?: string[];
    contentType: string;
    description?: string;
    externalTitle?: string;
    productType?: string;
    primer?: {
      logoLink?: {
        value: string;
      };
    };
    speakers: {
      jobTitle: string;
      company: string;
      isModerator: boolean;
    }[];
    scheduledTimeUTC?: string;
  };
  isBookmarked?: boolean;
  showPurchasedFlag?: boolean;
  isSelected: boolean;
  keywordHits?: number;
  searchQuery?: { type: number | undefined; value: string }[];
  onClick: Function;
} & XStyledAndHTMLProps<HTMLDivElement>;

export default ContentCard;
