import React from "react";
import { Icon, IconButton, Portal, TextFieldStyleVariant } from "@alphasights/alphadesign-components";
import { LeftArrow, RightArrow, Search } from "@alphasights/alphadesign-icons";
import { useHistory } from "react-router-dom";
import * as S from "./TopBar.styled";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { RealTimeCreditBalance } from "components/GlobalNav/RealTimeCreditBalance";

export const TopBar = () => (
  <S.TopBarWrapper>
    <S.LeftPanel>
      <NavigationButtons />
      <SearchBar />
    </S.LeftPanel>
    <RightPanel />
  </S.TopBarWrapper>
);

const NavigationButtons = () => {
  const history = useHistory();

  /*
   * Currently, there's no official react-router way to determine if the user can go back or forward.
   * We then use the new navigation API to determine that.
   * This API has limited availability as of now, so we need to check if it's available before using it.
   * Reference: https://developer.mozilla.org/en-US/docs/Web/API/Navigation
   */
  const navigation = (window as any)?.navigation;

  const currentIndex = navigation?.currentEntry.index;
  const previousUrl = navigation?.entries()[currentIndex - 1]?.url;

  const canGoBack = !previousUrl?.includes("/sign-in") && (navigation?.canGoBack ?? true);
  const canGoForward = navigation?.canGoForward ?? true;

  return (
    <S.NavigationButtonWrapper>
      <IconButton
        dataAttributes={{ "data-testid": "go-back" }}
        onClick={() => history.goBack()}
        disabled={!canGoBack}
        color="inverse"
        variant="basic"
      >
        <LeftArrow />
      </IconButton>
      <IconButton
        dataAttributes={{ "data-testid": "go-forward" }}
        onClick={() => history.goForward()}
        disabled={!canGoForward}
        color="inverse"
        variant="basic"
      >
        <RightArrow />
      </IconButton>
    </S.NavigationButtonWrapper>
  );
};

const SearchBar = () => {
  return (
    <S.TransparentTextField
      styleVariant={TextFieldStyleVariant.Borderless}
      placeholder="Search"
      startAdornment={
        <Icon color="inverse">
          <Search />
        </Icon>
      }
    />
  );
};

const RightPanel = () => {
  const currentUser = useCurrentUser();
  return (
    <S.RightPanel>
      {currentUser?.accessOnly && <RealTimeCreditBalance />}
      <div id="top-bar-portal-container" data-testid="top-bar-portal-container" style={{ height: "100%" }}></div>
    </S.RightPanel>
  );
};

export const TopBarPortal = ({ children }: { children: JSX.Element }) => {
  const [containerEl, setContainerEl] = React.useState<HTMLElement | undefined>(undefined);
  React.useLayoutEffect(() => {
    setContainerEl(document.querySelector("#top-bar-portal-container") as HTMLElement);
  }, []);

  const Wrapper = containerEl ? Portal : React.Fragment;
  return <Wrapper containerEl={containerEl}>{children}</Wrapper>;
};
