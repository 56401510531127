import React from "react";
import { useThemeTokens, Divider } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { SectionTitle } from "pages/AlphaNowPage/primers/components";
import { useCompanyPrimerV3Store } from "../../store/store";
import SectionWrapper from "../styled";
import OutlookSection from "./OutlookSection";

const growthHeaderItems = ["driver", "revenue impact", "commentary"];

const opportunitiesHeaderItems = ["theme", "outlook", "commentary"];

const Outlook = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { growthDrivers, opportunitiesAndRisks } = useCompanyPrimerV3Store(({ outlook }) => outlook);

  return (
    <SectionWrapper ref={ref}>
      <x.div w="100%">
        <x.div display="flex" flexDirection="column" gap={inner.base06}>
          <SectionTitle
            dataTestId="OutlookSectionTitle"
            text="Outlook"
            heading
            xtraProps={{ paddingLeft: inner.base10 }}
          />
          <Divider />
          <OutlookSection title="GROWTH DRIVERS" content={growthDrivers} headerItems={growthHeaderItems} hasDivider />
          <OutlookSection
            title="OPPORTUNITIES AND RISKS"
            content={opportunitiesAndRisks}
            headerItems={opportunitiesHeaderItems}
          />
        </x.div>
      </x.div>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Outlook));
