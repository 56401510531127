import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";

import { Toggle } from "components/alphaui";
import { Divider } from "components/Divider";
import { InfoBanner } from "components/Banner";
import { withAccessControl } from "components/AccessControl/AccessControl";

import {
  cancel,
  contributorProgram,
  info,
  interactionOptOutBeforeCompletion,
  interactionOptOutAfter72h,
  interactionOptOutWithin72h,
  interactionSureToOptOut,
  optInInfo,
  projectOptOut,
  projectSureToOptOut,
  yesOptIn,
  yesOptOut,
} from "content/ProjectsPage";
import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ResponsiveModal } from "components/ResponsiveModal";

const CHANGE_PCC_OPT_PERMISSION = "change_pcc_opt";

const PccSettingsModal = ({
  header,
  isOpen,
  isPccOptIn,
  isProject,
  onClose,
  pccOptOutWindow,
  scheduledCallTime,
  updatePccStatus,
}) => {
  const { isMobile } = useCheckScreen();
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const [isToggleOn, setIsToggleOn] = useState(isPccOptIn);
  // Todo - use real data coming from BE when the data has been fed to the portal from PTO
  // const [includeTranscript, setIncludeTranscript] = useState(true);

  useEffect(() => {
    setIsToggleOn(isPccOptIn);
  }, [isPccOptIn]);

  const areWeOptingIn = !isPccOptIn && isToggleOn;
  const areWeOptingOut = isPccOptIn && !isToggleOn;

  const shouldShowButton = areWeOptingIn || areWeOptingOut;

  const shouldShowOptInBanner = isProject && areWeOptingIn;

  const buttonMessage = areWeOptingOut ? yesOptOut : yesOptIn;

  const isInteractionEditable = () => {
    const now = new Date();
    const scheduledCallDate = new Date(scheduledCallTime);

    // It won't be pcc editable after the opt out window has elapsed
    const completionDateLimit = new Date(scheduledCallTime);
    completionDateLimit.setDate(scheduledCallDate.getDate() + pccOptOutWindow);

    return completionDateLimit > now;
  };

  const hasInteractionHappened = () => {
    if (!scheduledCallTime) return false;

    const now = new Date();
    const scheduledCallDate = new Date(scheduledCallTime);

    return scheduledCallDate < now;
  };

  const clearAndClose = () => {
    setIsToggleOn(isPccOptIn);
    onClose();
  };

  return (
    <ResponsiveModal
      variant="drawer"
      open={isOpen}
      onClose={clearAndClose}
      title={header}
      zIndex={30}
      slotWidth={isMobile ? "100vw" : "35vw"}
    >
      <x.div pt={inner.base05}>
        <x.div display="flex" flexDirection="row" alignItems="center" mb={inner.base06}>
          <Typography component="span" color={text.strong._}>
            {contributorProgram}
          </Typography>
          <x.div marginLeft="auto" display="flex" alignItems="center">
            <Toggle
              active={isToggleOn}
              disabled={!isProject && scheduledCallTime && !isInteractionEditable()}
              newToggle
              onChange={(currentStatus) => setIsToggleOn(!currentStatus)}
              size="medium"
            />
            <Typography color={isToggleOn ? text.success : text.secondary} ml={inner.base02}>
              {isToggleOn ? "On" : "Off"}
            </Typography>
          </x.div>
        </x.div>
        {shouldShowOptInBanner && (
          <InfoBanner mb={inner.base06}>
            <x.div>
              <x.span fontWeight="semibold">{info}</x.span>
              {` ${optInInfo}`}
            </x.div>
          </InfoBanner>
        )}
        {/* This has been hidden until we build the backend functionality for HD Transcript opt-out in the backend 
      { {!isToggleOn && (
        <>
          <x.div display="flex" flexDirection="row" pt="300">
            <x.div>
              <x.p>{hdTranscript}</x.p>
              <x.p fontSize="400" color="neutral700">
                {releaseTranscript}
              </x.p>
            </x.div>

            <x.div marginLeft="auto">
              <CheckboxInput
                state={[
                  includeTranscript,
                  () => setIncludeTranscript(!includeTranscript),
                ]}
              ></CheckboxInput>
            </x.div>
          </x.div>
        </>
      )} */}
        <Divider />
        <Typography variant="body-small" component="div" color={text.secondary}>
          {isProject && !isToggleOn && (
            <x.div mt={inner.base06}>
              {projectOptOut} {areWeOptingOut && projectSureToOptOut}
            </x.div>
          )}
          {!isProject && !hasInteractionHappened() && !isToggleOn && (
            <x.div mt={inner.base06}>
              {interactionOptOutBeforeCompletion} {areWeOptingOut && interactionSureToOptOut}
            </x.div>
          )}
          {!isProject &&
            hasInteractionHappened() &&
            (isInteractionEditable() ? (
              !isToggleOn && (
                <x.div mt={inner.base06}>
                  {interactionOptOutWithin72h} {areWeOptingOut && interactionSureToOptOut}
                </x.div>
              )
            ) : (
              <x.div mt={inner.base06}>{interactionOptOutAfter72h}</x.div>
            ))}
        </Typography>
      </x.div>
      <x.div display="flex" flexDirection={isMobile ? "column" : "row"} mt={inner.base06}>
        {shouldShowButton && (
          <ButtonWithAccess
            accessControl={{
              allowedPermissions: [CHANGE_PCC_OPT_PERMISSION],
            }}
            onClick={() => updatePccStatus(isToggleOn)}
            variant="secondary"
            mr={!isMobile && inner.base06}
          >
            <Typography variant="body-em" component="span">
              {buttonMessage}
            </Typography>
          </ButtonWithAccess>
        )}
        <ButtonWithAccess
          accessControl={{
            allowedPermissions: [CHANGE_PCC_OPT_PERMISSION],
          }}
          onClick={clearAndClose}
          variant="link"
        >
          <Typography variant="body-em" component="span">
            {cancel}
          </Typography>
        </ButtonWithAccess>
      </x.div>
    </ResponsiveModal>
  );
};

const ButtonWithAccess = withAccessControl(Button);

PccSettingsModal.propTypes = {
  header: PropTypes.string,
  isOpen: PropTypes.bool,
  isPccOptIn: PropTypes.bool,
  isProject: PropTypes.bool,
  onClose: PropTypes.func,
  pccOptOutWindow: PropTypes.number,
  scheduledCallTime: PropTypes.string,
};

export default PccSettingsModal;
