import React from "react";
import styled, { x } from "@xstyled/styled-components";
import { ExpertFilters } from "components/InteractionsPage/ExpertFilters";
import {
  Icon,
  IconButton,
  ListOption,
  Popover,
  RoundedButton,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { Carousel } from "./Carousel";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { ExpertCompareQuestion } from "models/ExpertCompare";
import { EditQuestionsButton } from "../EditQuestionsModal/EditQuestionsModal";
import { noop } from "lodash";
import { Info, Warning } from "@alphasights/alphadesign-icons";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { ProjectMoreOptions } from "components/ProjectMoreOptions/ProjectMoreOptions";
import { useEnv, useNewNavigation } from "@alphasights/client-portal-shared";
import { ViewToggler } from "components/AdvisorsHeaderNewUx/ViewToggler/ViewToggler";
import { Separator } from "components/InteractionsPage/HeaderPortal";
import { Env } from "@alphasights/portal-api-client";

export type ComparisonViewHeaderProps = {
  onSubmitFilters: (...props: any) => void;
  onToggleFilter: () => void;
  onResetAllFilters: () => void;
  angles: ComparisonViewAngle[];
  selectedAngle: ComparisonViewAngle;
  questions: ExpertCompareQuestion[];
};

export type ComparisonViewAngle = {
  isExpertsCapExceeded: boolean;
  children?: ComparisonViewAngle[];
} & Exclude<FilterOption, "children">;

export const ComparisonViewHeader = ({
  onSubmitFilters,
  onToggleFilter,
  onResetAllFilters,
  angles,
  selectedAngle,
  questions,
}: ComparisonViewHeaderProps) => {
  const newNavigationEnabled = useNewNavigation();
  const { wrapper, rightContent } = useComparisonViewHeaderStyles({ newNavigationEnabled });

  const expertFilters = (
    <ExpertFilters
      onSubmitFilters={onSubmitFilters}
      onToggleFilter={onToggleFilter}
      onResetAllFilters={onResetAllFilters}
      currentView="comparison-view"
    />
  );

  return (
    <>
      <x.div {...wrapper}>
        <AnglesFilters angles={angles} selectedAngle={selectedAngle} onSubmitFilters={onSubmitFilters}>
          {newNavigationEnabled && expertFilters}
        </AnglesFilters>
        <x.div {...rightContent}>
          {!newNavigationEnabled && expertFilters}
          <EditQuestionsButton selectedAngle={selectedAngle} angles={angles} questions={questions} />
          {newNavigationEnabled ? <ViewToggler /> : <ProjectMoreOptions />}
        </x.div>
      </x.div>
      {newNavigationEnabled && <Separator />}
    </>
  );
};

const AnglesFilters = ({
  angles,
  selectedAngle,
  onSubmitFilters,
  children,
}: {
  angles: ComparisonViewAngle[];
  selectedAngle: ComparisonViewAngle;
  onSubmitFilters: (...props: any) => void;
  children?: React.ReactNode;
}) => {
  const carouselChildren = [
    ...angles.map((angle: ComparisonViewAngle, key: number) => (
      <AngleFilter angle={angle} selectedAngle={selectedAngle} onSubmitFilters={onSubmitFilters} key={key} />
    )),
    children,
  ];
  return (
    <x.div display="flex" alignItems="center" overflow="hidden" position="relative">
      <Carousel children={carouselChildren} />
      <PortalTooltip title="Comparison is only available for angles with 5 or more experts." position="right" dark>
        <IconButton variant="basic" size="small" onClick={noop} testId="angle-filter-info">
          <Info />
        </IconButton>
      </PortalTooltip>
    </x.div>
  );
};

const AngleFilter = ({
  angle,
  selectedAngle,
  onSubmitFilters,
}: {
  angle: ComparisonViewAngle;
  selectedAngle: ComparisonViewAngle;
  onSubmitFilters: (...props: any) => void;
}) => {
  const selected =
    angle.id === selectedAngle.id || !!angle.children?.some((subAngle) => subAngle.id === selectedAngle.id);
  const newNavigationEnabled = useNewNavigation();
  const { angleButton, angleButtonWrapper, angleButtonContent } = useAngleFilterStyles({
    selected,
    newNavigationEnabled,
  });
  const {
    state: { appliedFilters },
  } = useProjectInteractionsContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [warningAchorEl, setWarningAchorEl] = React.useState<HTMLElement | null>();

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if ((angle.children ?? []).length > 0) {
      if (!anchorEl) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(undefined);
      }
    } else {
      applyFilter(angle);
    }
  };

  const applyFilter = (angle: FilterOption) => {
    onSubmitFilters({ ...appliedFilters, groups: [angle.id] });
  };

  return (
    <x.div {...angleButtonWrapper} ref={setWarningAchorEl}>
      <RoundedButton
        onClick={handleClick}
        variant="filter"
        {...angleButton}
        dataAttributes={{ "data-testid": `angle-filter-${angle.id}` }}
      >
        <x.div {...angleButtonContent}>
          {selected && selectedAngle.isExpertsCapExceeded && (
            <ExpertsCapExceededWarning anchorEl={warningAchorEl ?? undefined} />
          )}
          <p>
            <Typography
              variant={newNavigationEnabled ? "body-small" : "body-small-em"}
              color={newNavigationEnabled && selected ? "selected" : "secondary"}
              component="span"
            >
              {angle.label}
            </Typography>
            {angle.id !== selectedAngle.id && selected && (
              <Typography variant="body-small-em" color="info" component="span">
                {` ${selectedAngle.label}`}
              </Typography>
            )}
          </p>
        </x.div>
      </RoundedButton>
      {(angle.children ?? []).length > 0 && (
        <Popover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(undefined)} size="small">
          <ListOption
            type="text"
            label={`All`}
            size="small"
            onChange={() => applyFilter(angle)}
            dataAttributes={{ "data-testid": `angle-filter-${angle.id}` }}
            selected={angle.id === selectedAngle.id}
          />

          {angle.children?.map((subAngle: FilterOption) => (
            <ListOption
              type="text"
              label={subAngle.label}
              size="small"
              onChange={() => applyFilter(subAngle)}
              dataAttributes={{ "data-testid": `angle-filter-${subAngle.id}` }}
              selected={subAngle.id === selectedAngle.id}
              indent={1}
            />
          ))}
        </Popover>
      )}
    </x.div>
  );
};

const ExpertsCapExceededWarning = ({ anchorEl }: { anchorEl?: HTMLElement }) => {
  const newNavigationEnabled = useNewNavigation();
  const [isOpen, setIsOpen] = React.useState(false);
  const { tooltipBody } = useAngleFilterStyles();
  const { comparisonViewMaxExperts } = useEnv() as Env & { comparisonViewMaxExperts: number };

  return (
    <x.div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      data-testid="experts-cap-exceeded"
    >
      <Icon size="small" color={newNavigationEnabled ? "selected" : "secondary"}>
        <Warning />
      </Icon>
      <StyledPopover anchorEl={anchorEl} open={isOpen} onClose={() => setIsOpen(false)} size="small" closeOnMouseLeave>
        <x.div {...tooltipBody}>
          Only showing {comparisonViewMaxExperts} experts. For full expert list go to table view.
        </x.div>
      </StyledPopover>
    </x.div>
  );
};

const useComparisonViewHeaderStyles = ({ newNavigationEnabled }: { newNavigationEnabled?: boolean }) => {
  const {
    spacing: { inner },
    color: { background },
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    py: newNavigationEnabled ? inner.base04 : inner.base05,
    px: newNavigationEnabled ? inner.base06 : inner.base08,
    justifyContent: "space-between",
    alignItems: "center",
    gap: inner.base02,
    background: newNavigationEnabled ? background.surface.page.default : undefined,
  };

  const rightContent = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
  };

  return {
    wrapper,
    rightContent,
  };
};

const useAngleFilterStyles = ({
  selected,
  newNavigationEnabled,
}: {
  selected?: boolean;
  newNavigationEnabled?: boolean;
} = {}) => {
  const {
    spacing: { inner },
    color: { text, border, background },
    components: { tooltip },
  } = useThemeTokens();

  const angleButtonWrapper = {
    whiteSpace: "nowrap",
  };

  const angleButton = {
    color: text.secondary,
    borderColor: selected && border.selected,
    ...(!newNavigationEnabled && {
      h: "44px",
    }),
    bg: newNavigationEnabled && selected ? background.infoSubtle : undefined,
  };

  const angleButtonContent = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
  };

  const tooltipBody = {
    ...tooltip.body,
    ...tooltip.variant["dark"].body,
  };

  return {
    angleButtonWrapper,
    angleButton,
    angleButtonContent,
    tooltipBody,
  };
};

const StyledPopover = styled(Popover)`
  border: 0;
  .body-content {
    padding: 0;
    & > div {
      max-width: fit-content;
    }
  }
`;
