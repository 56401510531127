import React, { Dispatch, FC, SetStateAction } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import CompanyPrimerDetails from "pages/AlphaNowPage/primers/CompanyPrimer/components/header/details/CompanyPrimerDetails";
import { AlphaNowSuccessfulExpertRequestAlert } from "pages/AlphaNowPage/components";
import useContentApi from "components/BookmarkButton/useContentApi";

interface PrimerHeaderProps {
  content: CompanyPrimerContentV1 | CompanyPrimerContentV2;
  mainPrimerCompanyName: string;
  isSidebarExpanded: boolean;
  successMessage: string;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
  onBookmarkChanged: () => void;
  isExpertsView: boolean;
  setIsExpertsView: (value: boolean) => void;
  onReturnToMobileSearch: () => void;
}

const CompanyPrimerHeader: FC<PrimerHeaderProps> = ({
  content: {
    id: contentId,
    contentType,
    primer: { logoLink },
    purchaseStatus,
    lastUpdatedAt,
    paymentRequired,
    downloadFileName,
    companies,
  },
  mainPrimerCompanyName,
  isSidebarExpanded,
  successMessage,
  setSuccessMessage,
  onBookmarkChanged,
  isExpertsView,
  setIsExpertsView,
  onReturnToMobileSearch,
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { isBookmarked, onToggleBookmark } = useContentApi(contentId, contentType, onBookmarkChanged);

  return (
    <x.div
      mt={{ xs: inner.base04, sm: inner.base06 }}
      pl={{ xs: inner.base04, sm: inner.base08 }}
      pr={{ xs: inner.base04, sm: inner.base06 }}
    >
      <CompanyPrimerDetails
        {...{
          bookmarkButtonProps: { isBookmarked, onToggleBookmark },
          companies,
          contentType,
          lastUpdatedAt,
          logoLink,
          purchaseStatus,
          contentId,
          paymentRequired,
          isExpertsView,
          setIsExpertsView,
          onReturnToMobileSearch,
        }}
        mainPrimerCompanyName={mainPrimerCompanyName || ""}
        downloadFileName={downloadFileName}
      />
      {successMessage && (
        <AlphaNowSuccessfulExpertRequestAlert
          isSidebarExpanded={isSidebarExpanded}
          setSuccessMessage={setSuccessMessage}
          successMessage={successMessage}
        />
      )}
    </x.div>
  );
};

export default CompanyPrimerHeader;
