import React, { useState, useEffect } from "react";
import { x } from "@xstyled/styled-components";
import { fetch } from "../../hooks/useApi";
import PropTypes from "prop-types";
import { Flyout } from "../../components/alphaui";
import isEmpty from "lodash/isEmpty";
import { PageFooter } from "@alphasights/client-portal-shared";

import {
  contactForMoreAlphaViewsContent,
  endOfResultsContent,
  numberOfUpcomingAlphaViewsContent,
  upcomingContentContent,
} from "../../content/AlphaViews";

import { UpcomingAlphaViewCard } from "../../components/UpcomingAlphaViewCard";
import AlphaViewDetails from "../../components/AlphaViewDetails";

import { Typography } from "@alphasights/alphadesign-components";

import "./index.css";

export const UpcomingAlphaViewsPage = ({ upcomingAlphaViews }) => {
  const [alphaViewsContent, setAlphaViewsContent] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAlphaView, setSelectedAlphaView] = useState(null);
  const [upcomingAlphaViewsCount, setUpcomingAlphaViewsCount] = useState(null);

  useEffect(() => {
    // TODO - Myriam. Get rid of isEmpty in favour of !!upcomingAlphaViews
    if (!isEmpty(upcomingAlphaViews)) {
      const { content, totalElements } = upcomingAlphaViews;
      setAlphaViewsContent(content);
      setUpcomingAlphaViewsCount(totalElements);
    } else {
      fetchMyUpcomingAlphaViews();
    }
  }, [upcomingAlphaViews]);

  const fetchMyUpcomingAlphaViews = () => {
    fetch({ url: "/api/content/upcoming/" })
      .then((res) => res.json())
      .then((data) => {
        const { content, totalElements } = data;
        setAlphaViewsContent(content);
        setUpcomingAlphaViewsCount(totalElements);
      });
  };

  const fetchUpcomingAlphaViewDetails = (id) => {
    fetch({ url: `/api/content/${id}` })
      .then((res) => res.json())
      .then((alphaview) => {
        setSelectedAlphaView(alphaview);
        setDetailsOpen(true);
      });
  };

  return (
    <x.div row p={{ _: "400", md: "800" }}>
      <x.div col py="700">
        <Typography variant="h3">{upcomingContentContent}</Typography>
        <Typography>{numberOfUpcomingAlphaViewsContent(upcomingAlphaViewsCount)}</Typography>
        {alphaViewsContent &&
          alphaViewsContent.map((alphaView) => (
            <x.div key={alphaView.id}>
              <UpcomingAlphaViewCard
                alphaView={alphaView}
                handleViewDetails={fetchUpcomingAlphaViewDetails}
                selectedAlphaViewId={selectedAlphaView?.id}
              />
            </x.div>
          ))}
        <x.div row py="800" textAlign="center">
          <x.div col />
          <x.div col={{ sm: 1, md: 1 / 2 }}>
            <Typography variant="body-em">{endOfResultsContent}</Typography>
            <Typography variant="body-small" py="300">
              {contactForMoreAlphaViewsContent}
            </Typography>
          </x.div>
          <x.div col />
        </x.div>
        <PageFooter />
      </x.div>
      {selectedAlphaView && (
        <x.div col={detailsOpen ? true : 0}>
          <Flyout
            beforeClose={() => setDetailsOpen(false)}
            className="content-av-flyout"
            disableOutsideClick={modalOpen}
            onClose={() => setSelectedAlphaView(null)}
            orientation="right"
            showCancelIcon={true}
          >
            <AlphaViewDetails alphaView={selectedAlphaView} setModalOpen={setModalOpen} />
          </Flyout>
        </x.div>
      )}
    </x.div>
  );
};

UpcomingAlphaViewsPage.propTypes = {
  upcomingAlphaViews: PropTypes.shape({
    totalElements: PropTypes.number,
    numberOfElements: PropTypes.number,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        externalTitle: PropTypes.string,
        speakers: PropTypes.arrayOf(
          PropTypes.shape({
            jobTitle: PropTypes.string,
          })
        ),
        companies: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
};

UpcomingAlphaViewsPage.defaultProps = {
  upcomingAlphaViews: {},
};
