import React, { FC, useCallback, useEffect, useRef } from "react";
import { x } from "@xstyled/styled-components";
import { logTimeSpent, logScrollPercentage } from "pages/AlphaNowPage/utils/logHit";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";

import { Divider, useThemeTokens, IconButton, Typography } from "@alphasights/alphadesign-components";
import { ChevronRight } from "@alphasights/alphadesign-icons";
import ErrorMessage from "../../../ErrorMessage";
import { View } from "models/Header";
import { desktopContentContainerWidth } from "constants/AlphaNow";
import { errorLoadingQuestionView, questionUnavailableTryAgainContent, generatedByAITag } from "content/AlphaNow";
import { useAlphaNowQueryContext } from "pages/AlphaNowPage/components/AlphaNowQueryContext";

interface QuestionViewProps {
  questionsData: any[];
  contentId: string;
  isSuccess: boolean;
  setViewMode: (value: string) => void;
}

const QuestionView: FC<QuestionViewProps> = ({ questionsData = [], isSuccess, setViewMode, contentId }) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();
  const { logHit } = useTrackUserAction();
  const maxScrollPercentage = useRef(0);
  const { updateQuery } = useAlphaNowQueryContext();

  const handleClick = useCallback(
    (fragmentId: any) => {
      setViewMode(View.Transcript);
      updateQuery({ fragmentIds: [fragmentId] });

      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowViewQuestion,
        details: { contentId, fragmentPosition: fragmentId },
      });
    },
    [updateQuery, setViewMode, contentId, logHit]
  );

  useEffect(() => {
    const startTime = Date.now();
    const scrollPercentage = maxScrollPercentage.current;
    const timeSpentAction = HitAction.alphaNowTimeSpentOnQuestionView;
    const scrollAction = HitAction.alphaNowQuestionViewScrollPercentage;

    return () => {
      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowOpenQuestionView,
        details: { contentId },
      });

      logTimeSpent(startTime, { contentId, userId: "" }, logHit, timeSpentAction);

      logScrollPercentage(
        logHit,
        {
          contentId,
          scrollPercentage,
          userId: "",
        },
        scrollAction
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSuccess) {
    return <ErrorMessage header={errorLoadingQuestionView} body={questionUnavailableTryAgainContent} />;
  }

  return (
    <x.div
      display="flex"
      flexDirection="column"
      spaceY={spacing.inner.base02}
      margin="0 auto"
      maxWidth={desktopContentContainerWidth}
      pb={spacing.layout.base03}
    >
      <Typography
        display="flex"
        flexDirection="row-reverse"
        color={text.secondary}
        variant="body-small"
        pb={spacing.inner.base03}
      >
        {generatedByAITag}
      </Typography>
      <Divider />

      {questionsData.map((data) => {
        const { question, fragmentIds } = data;
        return (
          <>
            <x.div display="flex" alignItems="center" py={spacing.inner.base02}>
              <Typography
                pr={spacing.inner.base06}
                variant="body"
                w={desktopContentContainerWidth}
                color={text.strong._}
              >
                {question}
              </Typography>
              <x.div>
                <IconButton
                  onClick={() => handleClick(fragmentIds[0])}
                  disabled={false}
                  size="small"
                  variant="outline"
                  key="rounded-button"
                  testId="select-question-button"
                >
                  <ChevronRight />
                </IconButton>
              </x.div>
            </x.div>
            <Divider />
          </>
        );
      })}
    </x.div>
  );
};

export default QuestionView;
