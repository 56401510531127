import { UPCOMING_FILTER_OPTIONS } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/UpcomingFilter";
import { DATE_VALUES } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/DateFilter";
import { SORT_KEYS } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/SortByFilter";
import { HEADCOUNT_VALUES } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/HeadcountFilter";
import { REVENUE_VALUES } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/RevenueFilter";

const INITIAL_FILTERS_STATE = Object.freeze({
  bookmarkFilter: false,
  callDate: { min: 0, max: DATE_VALUES.length - 1 },
  contentType: [],
  angle: [],
  purchasedFilter: [],
  sortBy: SORT_KEYS.relevance,
  upcomingFilter: UPCOMING_FILTER_OPTIONS.all,
  headcount: { min: 0, max: HEADCOUNT_VALUES.length - 1 },
  revenue: { min: 0, max: REVENUE_VALUES.length - 1 },
  ownershipType: [],
  hqLocation: [],
});

const INITIAL_QUERY_STATE = Object.freeze({
  filters: { ...INITIAL_FILTERS_STATE },
  selectedContentId: undefined,
  searchQuery: [],
  fragmentIds: [],
  commissionPrimers: false,
  commissionSurvey: false,
});

const INITIAL_CONTEXT_STATE = Object.freeze({
  query: { ...INITIAL_QUERY_STATE },
  updateQuery: () => {},
  navigate: () => {},
  isBackDisabled: true,
  isForwardDisabled: true,
});

export { INITIAL_FILTERS_STATE, INITIAL_QUERY_STATE, INITIAL_CONTEXT_STATE };
