import React from "react";
import { x } from "@xstyled/styled-components";
import { useStyles } from "./SynthesisModuleHeader.styles";
import {
  Button,
  Icon,
  IconButton,
  Pill,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from "@alphasights/alphadesign-components";
import { Add, Command, Copy, Delete, Edit, GenerativeAi, Refresh, Reply } from "@alphasights/alphadesign-icons";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { FormattedDateRelative } from "providers/TimezoneProvider";
import styled from "styled-components";
import { SynthesisModule, SynthesisQuestion } from "@alphasights/portal-api-client";
import { SynthesisReprocessButton } from "./SynthesisReprocessButton";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import {
  DeleteEmptyModuleModal,
  DeleteModuleModal,
  DisableAutoRegenerationModal,
  SaveAndRegenerateModal,
} from "./ConfirmationModals";

export const SynthesisModuleHeader = () => {
  const styles = useStyles();
  const { selectedModule, mode, revision: question } = useProjectSynthesisContext();

  const HeaderContent = selectVariant(selectedModule, mode, question);

  return (
    <x.div>
      <HeaderContent question={question} />
      <x.div {...styles.separator} />
    </x.div>
  );
};

const selectVariant = (selectedModule: SynthesisModule | undefined, mode: Mode, question?: SynthesisQuestion) => {
  if (mode === Mode.NEW) return NewModuleVariant;
  if (!selectedModule) return EmptyVariant;
  if (question?.status === "PROCESSING") return LoadingVariant;
  if (selectedModule?.type === "EXTRACTED") return SuggestedModuleVariant;
  if (mode === Mode.EDIT) return EditVariant;

  return DefaultVariant;
};

const DefaultVariant = ({ question }: { question?: SynthesisQuestion }) => {
  const styles = useStyles();

  const { selectedProjectSynthesis } = useProjectSynthesisContext();

  if (!question) return null;

  return (
    <x.div {...styles.moduleTopBarWrapper}>
      <x.div {...styles.leftGroup}>
        <HeaderTitle title={question?.topic ?? ""} />
        <Typography component="span" color="disabled" {...styles.regeneratedAt}>
          <Icon size="small" color="disabled">
            <Refresh />
          </Icon>
          Regenerated <FormattedDateRelative date={question?.createdAt} />
        </Typography>
      </x.div>

      <x.div {...styles.rightGroup}>
        <AutoRegenerationToggle />
        <CopyBtn />
        <SynthesisReprocessButton angleTypeName={selectedProjectSynthesis?.angleType} />
        <EditBtn />
        <DeleteBtn />
      </x.div>
    </x.div>
  );
};

const EditVariant = ({ question }: { question?: SynthesisQuestion }) => {
  const styles = useStyles();
  const {
    setMode,
    saveModuleChanges,
    cancelModuleChanges,
    saveInProgress,
    isQuestionEdited,
    selectedModule,
    synthesisPreferences,
  } = useProjectSynthesisContext();

  const [saveAndRegenerateModalOpen, setSaveAndRegenerateModalOpen] = React.useState(false);
  const [disableAutoRegenModalOpen, setDisableAutoRegenModalOpen] = React.useState(false);
  const [deleteModuleModalOpen, setDeleteModuleModalOpen] = React.useState(false);

  const handleSave = () => {
    if (selectedModule?.aiRegenerationEnabled && synthesisPreferences.showSavePrompt) {
      setDeleteModuleModalOpen(false);
      setDisableAutoRegenModalOpen(true);
    } else if (
      selectedModule?.aiRegenerationEnabled &&
      !synthesisPreferences.showSavePrompt &&
      synthesisPreferences.savePromptSelectedOption === "saveAndDisable"
    ) {
      saveModuleChanges({ ...selectedModule!, aiRegenerationEnabled: false });
    } else {
      saveModuleChanges(selectedModule!);
    }
  };

  const handleSaveAndRegenerate = () => {
    if (isQuestionEdited && synthesisPreferences.showSaveAndRegeneratePrompt) {
      setSaveAndRegenerateModalOpen(true);
    } else {
      saveModuleChanges({ ...selectedModule!, aiRegenerationEnabled: true });
    }
  };

  const handleCancel = () => {
    setMode(Mode.VIEW);
    cancelModuleChanges();
  };

  const validateEmptyAnswers = () => {
    if (question?.answers?.length === 0) {
      setDeleteModuleModalOpen(true);
      return;
    }
    handleSave();
  };

  return (
    <>
      <x.div {...styles.moduleTopBarWrapper}>
        <x.div {...styles.leftGroup}>
          <HeaderTitle title={question?.topic ?? ""} />
          <Pill size="x-small">Editing</Pill>
        </x.div>

        <x.div {...styles.rightGroup}>
          <UndoBtn />
          <RedoBtn />
          <Button
            disabled={saveInProgress}
            onClick={handleCancel}
            variant="outline"
            size="small"
            dataAttributes={{ "data-testid": "cancel-synthesis-edit" }}
          >
            Cancel
          </Button>
          <Button
            disabled={saveInProgress}
            onClick={validateEmptyAnswers}
            variant={`${isQuestionEdited ? "outline" : "secondary"}`}
            size="small"
            dataAttributes={{ "data-testid": "save-synthesis" }}
          >
            Save
          </Button>
          {isQuestionEdited && (
            <Button
              startIcon={<GenerativeAi />}
              onClick={handleSaveAndRegenerate}
              variant="secondary"
              size="small"
              dataAttributes={{ "data-testid": "save-and-regenerate-synthesis" }}
            >
              Save and Regenerate
            </Button>
          )}
          <DeleteBtn />
        </x.div>
      </x.div>

      <DeleteEmptyModuleModal isOpen={deleteModuleModalOpen} setIsOpen={setDeleteModuleModalOpen} onSave={handleSave} />
      <SaveAndRegenerateModal isOpen={saveAndRegenerateModalOpen} setIsOpen={setSaveAndRegenerateModalOpen} />
      <DisableAutoRegenerationModal isOpen={disableAutoRegenModalOpen} setIsOpen={setDisableAutoRegenModalOpen} />
    </>
  );
};

const NewModuleVariant = () => {
  const styles = useStyles();

  const { setMode, newSynthesisModule } = useProjectSynthesisContext();

  const handleCancel = () => {
    setMode(Mode.VIEW);
  };

  const handleRegenerate = () => {
    newSynthesisModule.onSubmit();
  };

  return (
    <x.div {...styles.moduleTopBarWrapper}>
      <x.div {...styles.leftGroup}>
        <HeaderTitle title="New module" />
      </x.div>

      <x.div {...styles.rightGroup}>
        <Button onClick={handleCancel} variant="outline" size="small">
          Cancel
        </Button>
        <Button
          startIcon={<GenerativeAi />}
          onClick={handleRegenerate}
          variant="secondary"
          size="small"
          dataAttributes={{ "data-testid": "generate-new-insights-btn" }}
        >
          Generate New Insights
        </Button>
      </x.div>
    </x.div>
  );
};

const SuggestedModuleVariant = ({ question }: { question?: SynthesisQuestion }) => {
  const styles = useStyles();

  const { promoteModule, selectedModule } = useProjectSynthesisContext();

  return (
    <x.div {...styles.moduleTopBarWrapper}>
      <x.div {...styles.leftGroup}>
        <HeaderTitle title={question?.topic ?? ""} />
        <Pill size="x-small" variant="blue">
          Suggested
        </Pill>
      </x.div>

      <x.div {...styles.rightGroup}>
        <Button
          startIcon={<Add />}
          onClick={() => selectedModule && promoteModule(selectedModule)}
          variant="outline"
          size="small"
          dataAttributes={{ "data-testid": "add-to-synthesis" }}
        >
          Add To Synthesis
        </Button>
        <DeleteBtn />
      </x.div>
    </x.div>
  );
};

const LoadingVariant = ({ question }: { question: SynthesisQuestion | undefined }) => {
  const styles = useStyles();

  return (
    <x.div {...styles.moduleTopBarWrapper} data-testid="loading-header">
      <x.div {...styles.leftGroup}>
        {question?.topic ? <HeaderTitle title={question.topic} /> : <Skeleton variant="noMargin" width="200px" />}
      </x.div>

      <x.div {...styles.rightGroup}>
        <Skeleton variant="noMargin" width="300px" />
      </x.div>
    </x.div>
  );
};

const EmptyVariant = () => {
  const styles = useStyles();

  return (
    <x.div {...styles.moduleTopBarWrapper} data-testid="empty-module-header">
      <x.div {...styles.leftGroup}></x.div>

      <x.div {...styles.rightGroup}>
        <SynthesisReprocessButton />
      </x.div>
    </x.div>
  );
};

const AutoRegenerationToggle = () => {
  const { selectedModule, saveModuleChanges, mode } = useProjectSynthesisContext();

  const handleToggle = () => {
    saveModuleChanges({ ...selectedModule!, aiRegenerationEnabled: !selectedModule!.aiRegenerationEnabled });
  };

  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  if (mode === Mode.EDIT) return null;

  return (
    <Switch
      leftText="Auto regeneration"
      size="small"
      isSelected={selectedModule?.aiRegenerationEnabled}
      onClick={handleToggle}
      disabled={internalUserNoAccess}
      dataAttributes={{ "data-testid": "synthesis-header-auto-regen" }}
    />
  );
};

const CopyBtn = () => {
  const handleCopy = () => {
    alert("TBD");
  };

  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  return (
    <Tooltip variant="dark" title="Copy Module">
      <IconButton
        disabled={internalUserNoAccess}
        onClick={handleCopy}
        variant="outline"
        size="small"
        testId="synthesis-header-copy"
      >
        <Copy />
      </IconButton>
    </Tooltip>
  );
};

const EditBtn = () => {
  const { setMode } = useProjectSynthesisContext();
  const handleEdit = () => {
    setMode(Mode.EDIT);
  };

  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  return (
    <Tooltip variant="dark" title="Edit Module">
      <IconButton
        disabled={internalUserNoAccess}
        onClick={handleEdit}
        variant="outline"
        size="small"
        testId="synthesis-header-edit"
      >
        <Edit />
      </IconButton>
    </Tooltip>
  );
};

const UndoBtn = () => {
  const { undo } = useProjectSynthesisContext();

  return (
    <Tooltip variant="dark" title={<ShortcutTooltip action="Undo" shortcut="z" />}>
      <IconButton onClick={undo} variant="outline" size="small" testId="synthesis-header-undo">
        <Reply />
      </IconButton>
    </Tooltip>
  );
};

const RedoBtn = () => {
  const { redo } = useProjectSynthesisContext();

  return (
    <Tooltip variant="dark" title={<ShortcutTooltip action="Redo" shortcut="y" />}>
      <MirroredIconButton onClick={redo} variant="outline" size="small" testId="synthesis-header-redo">
        <Reply />
      </MirroredIconButton>
    </Tooltip>
  );
};

const ShortcutTooltip = ({ action, shortcut }: { action: string; shortcut: string }) => {
  const { keyWrapper, flexCentered } = useStyles();
  const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform;
  const isMac = platform.toLowerCase().indexOf("mac") >= 0;

  return (
    <x.div {...flexCentered}>
      <x.span mr="8px">{action}</x.span>
      <x.div {...flexCentered} gap="4px">
        <x.div {...keyWrapper}>
          {isMac ? (
            <Icon size="x-small" color="inverse">
              <Command />
            </Icon>
          ) : (
            <Typography component="span" variant="body-small" color="inverse">
              ctrl
            </Typography>
          )}
        </x.div>
        <x.div {...keyWrapper}>
          <Typography component="span" variant="body-small" color="inverse">
            {shortcut}
          </Typography>
        </x.div>
      </x.div>
    </x.div>
  );
};

const DeleteBtn = () => {
  const [deleteModuleModalOpen, setDeleteModuleModalOpen] = React.useState(false);

  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  return (
    <>
      <Tooltip variant="dark" title="Delete Module">
        <IconButton
          disabled={internalUserNoAccess}
          onClick={() => setDeleteModuleModalOpen(true)}
          variant="outline"
          size="small"
          color="danger"
          testId="synthesis-header-delete"
        >
          <Delete />
        </IconButton>
      </Tooltip>
      <DeleteModuleModal isOpen={deleteModuleModalOpen} setIsOpen={setDeleteModuleModalOpen} />
    </>
  );
};

const HeaderTitle = ({ title }: { title: string }) => {
  return (
    <Typography component="span" variant="body-large-em" color="secondary">
      {title}
    </Typography>
  );
};

const MirroredIconButton = styled(IconButton)`
  transform: scaleX(-1);
`;
