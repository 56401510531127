import React, { useState } from "react";
import { x } from "@xstyled/styled-components";
import { Divider, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import ReturnButton from "pages/AlphaNowPage/components/ReturnButton/ReturnButton";
import { useMarketPrimersStore } from "../state/store";
import {
  AlphaNowPurchasableHeader,
  AlphaNowSpinner,
  WatchlistQuickAddButton,
  Spacer,
} from "pages/AlphaNowPage/components";
import {
  AlphaNowContentType,
  AlphaNowProductType,
  AlphaNowSRMProductType,
  Market,
} from "@alphasights/client-portal-shared";
import { ICON_BUTTON_DEVICE_PROPS } from "pages/AlphaNowPage/components/AlphaNowContent/AlphaNowContentActions";
import CopyLinkButton from "components/CopyLinkButton";
import BookmarkButton from "components/BookmarkButton";
import { formatDate } from "utils/dates";
import { PrimerStatus } from "pages/AlphaNowPage/primers/CompanyPrimer/CompanyPrimerContentPageVars";
import { errorLoadingResearch, unavailableResearch } from "content/AlphaNow";
import { ErrorMessage } from "pages/AlphaNowPage/components";
import { freePrimers } from "pages/AlphaNowPage/primers/utils/constants";
import useContentApi from "components/BookmarkButton/useContentApi";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { usePrimersStore } from "../../state/store";
import MarketPill from "pages/AlphaNowPage/components/MarketPill";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";

const PrepaywallPage = ({
  id,
  contentType,
  companyIds,
  productType,
  onReturnToMobileSearch,
  onContentErrorChanged,
  onPurchasedContentChanged,
  status,
  market,
}: {
  id: string;
  contentType: AlphaNowContentType;
  companyIds: number[];
  productType: AlphaNowSRMProductType;
  onReturnToMobileSearch: () => void;
  onContentErrorChanged: () => void;
  onPurchasedContentChanged: () => void;
  status: string;
  market: Market;
}) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();
  const [primerStatus, setPrimerStatus] = useState(PrimerStatus.idle);
  const { overview } = useMarketPrimersStore();
  const { lastUpdatedAt, purchaseProperties } = usePrimersStore();
  const { hasUserBadge } = useUserBadgeContext();

  const { isMobile } = useCheckScreen();
  const { isBookmarked, onToggleBookmark } = useContentApi(id, AlphaNowProductType.marketPrimer);

  // TODO [RD1-133]: Remove Markets Badge
  const hasMarketsBadge = hasUserBadge(Badge.markets);

  // TODO [RD1-51]: Remove Watchlist Badge
  const isWatchlistQuickAddButtonVisible = !isMobile && hasUserBadge(Badge.watchlist);

  const requestPrimerInfoList = {
    title: "What is a Market Primer?",
    listItems: [
      "Market Primers are expert-led market overviews that support the first week of desktop research with trusted expert perspectives",
      "Our internal research team engage the best-in-class experts, including competitors, industry authorities and customers that operate in the market",
      "Click on a sentence to see the contributing expert(s) and request a call",
    ],
  };

  const { title, listItems } = requestPrimerInfoList;
  const iconButtonProps = isMobile ? ICON_BUTTON_DEVICE_PROPS.mobile : ICON_BUTTON_DEVICE_PROPS.other;
  const displayMarkets = !isMobile && hasMarketsBadge && !!market;

  if (primerStatus === PrimerStatus.loading) {
    return (
      <x.div mt={inner.base06}>
        <AlphaNowSpinner />
      </x.div>
    );
  }
  if (primerStatus === PrimerStatus.error) {
    return (
      <div>
        <ErrorMessage header={errorLoadingResearch} body={unavailableResearch} />
      </div>
    );
  }

  return (
    <x.div data-testid="paywall-page" h="100%" display="flex" flexDirection="column">
      <x.div p={inner.base06}>
        <x.div
          data-testid="market-insights-header-paywall-bar"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={inner.base04}
        >
          <x.div display="flex" alignItems="center" gap={inner.base02}>
            <Typography variant="body-em" style={{ textTransform: "none" }}>
              {overview.marketPrimerTitle.value
                ? `${overview.marketPrimerTitle.value} | Market Primer`
                : "Market Primer"}
            </Typography>
            {Boolean(lastUpdatedAt) && (
              <Typography color={text.secondary} variant="body">{`Last updated ${formatDate(
                lastUpdatedAt
              )} `}</Typography>
            )}
            {displayMarkets && (
              <>
                <Spacer />
                <MarketPill market={market} />
              </>
            )}
          </x.div>
          <x.div
            data-testid="market-insights-header-buttons-container"
            display="flex"
            alignItems="center"
            gap={inner.base02}
          >
            {isWatchlistQuickAddButtonVisible && (
              <WatchlistQuickAddButton cdsAlphaCompanyIds={companyIds} productType={productType} contentId={id} />
            )}
            <CopyLinkButton contentId={id} contentType={AlphaNowProductType.marketPrimer} {...iconButtonProps} />
            <BookmarkButton
              permissions={[MANAGE_CONTENT_PERMISSION]}
              isBookmarked={isBookmarked}
              onToggleBookmark={onToggleBookmark}
              {...iconButtonProps}
            />
          </x.div>
        </x.div>
        {isMobile && (
          <x.div pl={inner.base06}>
            <ReturnButton onReturn={onReturnToMobileSearch} style={{ ml: `-${inner.base03}` }} />
          </x.div>
        )}
        <x.div key={title} pb={inner.base02}>
          <Typography variant="body-em" paddingBottom={inner.base02} color={text.secondary}>
            {title}
          </Typography>
          <x.ul listStyleType="initial">
            {listItems.map((item) => {
              return (
                <x.ul listStyleType="initial" pl={inner.base06}>
                  <li key={item}>
                    <Typography>{item}</Typography>
                  </li>
                </x.ul>
              );
            })}
          </x.ul>
        </x.div>
        <Link href={freePrimers.marketPrimer} size="medium" target="_blank">
          <Typography variant="body">View a free Market Primer</Typography>
        </Link>
      </x.div>
      <Divider />
      <AlphaNowPurchasableHeader
        content={{
          id,
          contentType,
          productType: AlphaNowProductType.marketPrimer,
          status,
        }}
        contentTitle={overview.marketPrimerTitle.value ?? ""}
        price={purchaseProperties.price}
        contentApprovalStatus={purchaseProperties.approvalStatus}
        contentPurchaseStatus={purchaseProperties.purchaseStatus}
        onError={onContentErrorChanged}
        onPurchaseSuccess={onPurchasedContentChanged}
        setPrimerStatus={setPrimerStatus}
      />
      <Divider />
    </x.div>
  );
};

export default PrepaywallPage;
