import React, { useCallback } from "react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { x } from "@xstyled/styled-components";
import { AngleTypeSynthesis, SynthesisModule } from "@alphasights/portal-api-client";
import { SynthesisSidebarCard } from "./SynthesisSidebarCard";
import { useStyles } from "./SynthesisSidebarContent.styles";
import { Icon, Typography } from "@alphasights/alphadesign-components";

import _ from "lodash";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { useHideDeliverablesContent } from "views/DeliverablesView/NewDeliverablesPage/useHideDeliverablesContent";
import { Transcript } from "@alphasights/alphadesign-icons";

const DragDropContextWrapper = DragDropContext as React.ComponentType<any>;
const DroppableWrapper = Droppable as React.ComponentType<any>;
const DraggableWrapper = Draggable as React.ComponentType<any>;

export const SynthesisSidebarContent = ({ showSuggested }: { showSuggested: boolean }) => {
  const { projectSynthesis } = useProjectSynthesisContext();
  const styles = useStyles();

  const sortedProjectSynthesis = _.map(projectSynthesis, (angleTypeSynthesis) => ({
    ...angleTypeSynthesis,
    modules: _.orderBy(angleTypeSynthesis.modules, [(module) => module.type.toLowerCase()], ["asc"]),
  }));

  const countCustomModules = (angleTypeSynthesis: AngleTypeSynthesis) => {
    return _.filter(angleTypeSynthesis.modules, (module) => module.type.toLowerCase() === "custom").length;
  };

  return (
    <x.div overflow="auto" flexGrow="1" data-testid="synthesis-sidebar-content" {...styles.sidebarWrapper}>
      {projectSynthesis.length === 0 ? (
        <EmptySynthesisSidebarContent />
      ) : (
        <>
          {sortedProjectSynthesis.map((ps, index) => (
            <AngleTypeWrapper
              key={ps.angleType}
              angleTypeSynthesis={ps}
              showSuggested={showSuggested}
              indexCount={projectSynthesis[index - 1] ? countCustomModules(projectSynthesis[index - 1]) + 1 : 1}
            />
          ))}
        </>
      )}
    </x.div>
  );
};

const AngleTypeWrapper = ({
  angleTypeSynthesis,
  showSuggested,
  indexCount,
}: {
  angleTypeSynthesis: AngleTypeSynthesis;
  showSuggested: boolean;
  indexCount: number;
}) => {
  const styles = useStyles();
  const { reorderModule } = useProjectSynthesisContext();
  const isSingleExtractedModule =
    angleTypeSynthesis.modules.length === 1 && angleTypeSynthesis.modules[0].type.toLowerCase() === "extracted";

  const separateModulesByType = (angleTypeSynthesis: AngleTypeSynthesis) => {
    return _.partition(angleTypeSynthesis.modules, (module) => module.type.toLowerCase() === "custom");
  };

  const [customModules, extractedModules] = separateModulesByType(angleTypeSynthesis);

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) return;
      const {
        source: { index: originalIdx, droppableId: sourceDroppableId },
        destination: { index: targetIdx, droppableId: targetDroppableId },
      } = result;

      if (sourceDroppableId === targetDroppableId && originalIdx === targetIdx) return;

      let newCustomModules = [...customModules];
      let newExtractedModules = [...extractedModules];

      if (sourceDroppableId === "custom-module-droppable" && targetDroppableId === "custom-module-droppable") {
        const moved = customModules[originalIdx];
        newCustomModules.splice(originalIdx, 1);
        newCustomModules.splice(targetIdx, 0, moved);
      }

      if (sourceDroppableId === "extracted-module-droppable" && targetDroppableId === "extracted-module-droppable") {
        const moved = extractedModules[originalIdx];
        newExtractedModules.splice(originalIdx, 1);
        newExtractedModules.splice(targetIdx, 0, moved);
      }

      reorderModule(
        angleTypeSynthesis.angleType,
        [...newCustomModules, ...newExtractedModules].map((m) => m.id)
      );
    },
    [customModules, extractedModules, angleTypeSynthesis, reorderModule]
  );

  return (
    <x.div {...styles.angleWrapper}>
      <x.div {...styles.titleText}>
        <Typography variant="body-small-em">{angleTypeSynthesis.angleType}</Typography>
      </x.div>
      <x.div {...styles.indexedModuleWrapper}>
        <x.div {...styles.indexWrapper}>
          {angleTypeSynthesis.modules.map((m, index) => (
            <IndexSection indexCount={indexCount + index} moduleType={m.type} />
          ))}
        </x.div>
        <x.div display="flex" flexDirection="column">
          <DragDropContextWrapper onDragEnd={onDragEnd}>
            <DroppableWrapper droppableId="custom-module-droppable">
              {(provided: DroppableProvided) => (
                <x.div ref={provided.innerRef} {...provided.droppableProps}>
                  {customModules.map((m: SynthesisModule, index: number) => (
                    <ModuleSection
                      key={m.id}
                      showSuggested={showSuggested}
                      module={m}
                      isSingleExtractedModule={isSingleExtractedModule}
                      index={index}
                    />
                  ))}
                  <>{provided.placeholder}</>
                </x.div>
              )}
            </DroppableWrapper>
          </DragDropContextWrapper>
          <DragDropContextWrapper onDragEnd={onDragEnd}>
            <DroppableWrapper droppableId="extracted-module-droppable">
              {(provided: DroppableProvided) => (
                <x.div ref={provided.innerRef} {...provided.droppableProps}>
                  {extractedModules.map((m: SynthesisModule, index: number) => (
                    <ModuleSection
                      key={m.id}
                      showSuggested={showSuggested}
                      module={m}
                      isSingleExtractedModule={isSingleExtractedModule}
                      index={index}
                    />
                  ))}
                  <>{provided.placeholder}</>
                </x.div>
              )}
            </DroppableWrapper>
          </DragDropContextWrapper>
        </x.div>
      </x.div>
    </x.div>
  );
};

const IndexSection = ({ indexCount, moduleType }: { indexCount: number; moduleType: string }) => {
  const styles = useStyles();

  return (
    <x.div {...styles.numerationText}>
      {moduleType.toLowerCase() === "custom" && (
        <Typography data-testid={`index-${indexCount}`}>{indexCount}</Typography>
      )}
    </x.div>
  );
};

const ModuleSection = ({
  showSuggested,
  module,
  isSingleExtractedModule,
  index,
}: {
  showSuggested: boolean;
  module: SynthesisModule;
  isSingleExtractedModule: boolean;
  index: number;
}) => {
  const styles = useStyles();
  const { selectModule } = useProjectSynthesisContext();
  const { contentStyle } = useHideDeliverablesContent();

  return (
    <DraggableWrapper draggableId={module.id} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <x.div
          {...styles.moduleWrapper}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          key={module.id}
          {...contentStyle}
          data-testid={`module-${module.id}-${module.type}`}
        >
          <x.div onClick={() => selectModule(module)}>
            {(module.type.toLowerCase() === "custom" ||
              (showSuggested && module.type.toLowerCase() === "extracted")) && (
              <SynthesisSidebarCard
                synthesisModule={module}
                isSingleExtractedModule={isSingleExtractedModule}
                isDragging={snapshot.isDragging}
              />
            )}
          </x.div>
        </x.div>
      )}
    </DraggableWrapper>
  );
};

export const EmptySynthesisSidebarContent = () => {
  const { emptyAnswersWrapper, emptyIconWrapper } = useStyles();

  return (
    <x.div {...emptyAnswersWrapper} data-testid="empty-sidebar-content">
      <x.div {...emptyIconWrapper}>
        <Icon size="small" color="secondary">
          <Transcript />
        </Icon>
      </x.div>
      <Typography variant="body-large-em" color="secondary">
        No Synthesis Modules
      </Typography>
      <Typography color="secondary">Suggested modules will appear here when transcripts are made available</Typography>
    </x.div>
  );
};
