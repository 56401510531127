import { useState, useEffect } from "react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export default ({
  children,
  numberOfItemsDisplayed,
}: {
  children: React.ReactNode[];
  numberOfItemsDisplayed: number;
}) => {
  const [cumulativeIncrement, setCumulativeIncrement] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const { isUltraWide } = useCheckScreen();

  //display a blurred proportion of the next and/or previous item in carousel
  const totalItemsDisplayed = numberOfItemsDisplayed + 0.5;

  const numberOfCarouselSlides = Math.ceil(children.length / numberOfItemsDisplayed);

  // first slide displays 1/2 of next item and final slide displays 1/2 of prev item
  // all other slides display 1/4 prev item AND 1/4 next item
  // initialIndexIncrement handles transition to and from first and final slides
  const initialIndexIncrement = totalItemsDisplayed - 0.75;

  // standardIndexIncrement handles transition to and from slides displaying 1/4 prev and 1/4 next item
  const standardIndexIncrement = totalItemsDisplayed - 0.5;

  const numRemainingCards =
    children.length - Math.floor(children.length / numberOfItemsDisplayed) * numberOfItemsDisplayed;

  const numMissingCards = numRemainingCards === 0 ? 0 : numberOfItemsDisplayed - numRemainingCards;

  // finalIndexIncrement handles the transition to and from the final slide.
  // It applies an increment that ensures a full number of cards is always displayed on the final slide.
  // Edge case for first slide === penultimate slide (i.e. numberOfCarouselSlides > 2) as increment changes as first slide displays 1/2 prv item
  const finalIndexIncrement = initialIndexIncrement - numMissingCards - (numberOfCarouselSlides > 2 ? 0 : 0.25);

  const isFirstSlide = activeSlideIndex === 0;
  const isSecondSlide = activeSlideIndex === 1;
  const isPenultimateSlide = activeSlideIndex === numberOfCarouselSlides - 2;
  const isFinalSlide = activeSlideIndex === numberOfCarouselSlides - 1;

  const setNextIncrement = (prevIndex: number) => {
    if (isFirstSlide && !isPenultimateSlide) {
      return prevIndex + initialIndexIncrement;
    } else if (isPenultimateSlide) {
      return prevIndex + finalIndexIncrement;
    } else return prevIndex + standardIndexIncrement;
  };

  const setPreviousIncrement = (prevIndex: number) => {
    if (isSecondSlide && !isFinalSlide) {
      return prevIndex - initialIndexIncrement;
    } else if (isFinalSlide) {
      return prevIndex - finalIndexIncrement;
    } else return prevIndex - standardIndexIncrement;
  };

  const setNextSlide = () => {
    setActiveSlideIndex((prevIndex) => prevIndex + 1);
    setCumulativeIncrement((prevIndex) => setNextIncrement(prevIndex));
  };
  const setPrevSlide = () => {
    setActiveSlideIndex((prevIndex) => prevIndex - 1);
    setCumulativeIncrement((prevIndex) => setPreviousIncrement(prevIndex));
  };

  useEffect(() => {
    setCumulativeIncrement(0);
    setActiveSlideIndex(0);
  }, [isUltraWide]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    setNextSlide,
    setPrevSlide,
    cumulativeIncrement,
    isFirstSlide,
    isFinalSlide,
    activeSlideIndex,
  };
};
