import { createReducer, ReducerAction, useReducerActions } from "@alphasights/ads-community-utils";
import { useLocalStorage } from "hooks/useLocalStorage";

type LoginModalProps = {
  allowAnonymousContinue: boolean;
  message?: string;
};

type SignInConfiguration = {
  skipSignIn: boolean;
  expiration?: string;
};

export const defaultSignInConfiguration: SignInConfiguration = {
  skipSignIn: false,
};

export const defaultLoginModalProps: LoginModalProps = {
  allowAnonymousContinue: false,
};

export type ClientPortalState = {
  isReady: boolean;
  signInConfiguration: SignInConfiguration;
  modal: {
    isOpen: boolean;
    extraProps?: LoginModalProps;
  };
};

export const initialState: ClientPortalState = {
  modal: {
    isOpen: false,
    extraProps: defaultLoginModalProps,
  },
  isReady: false,
  signInConfiguration: defaultSignInConfiguration,
};

const APP_NAME = "ClientPortal";

const reducers = {
  setIsModalOpen: (state: ClientPortalState, action: ReducerAction<boolean>) => ({
    ...state,
    modal: {
      ...state.modal,
      isOpen: action.payload ?? false,
    },
  }),
  setModalExtraProps: (state: ClientPortalState, action: ReducerAction<ClientPortalState["modal"]["extraProps"]>) => ({
    ...state,
    modal: {
      ...state.modal,
      extraProps: { ...defaultLoginModalProps, ...(action.payload ?? {}) },
    },
  }),
  setIsReady: (state: ClientPortalState, action: ReducerAction<boolean>) => ({
    ...state,
    isReady: action.payload ?? false,
  }),
  setSignInConfiguration: (state: ClientPortalState, action: ReducerAction<SignInConfiguration>) => ({
    ...state,
    signInConfiguration: action.payload ?? defaultSignInConfiguration,
  }),
};

export type ClientPortalReducerActions = {
  [x in keyof typeof reducers]: <P>(payload?: P) => void;
};

export const { reducer, actions: triggers } = createReducer({
  initialState,
  name: APP_NAME,
  reducers,
});

export const useClientPortalReducer = () => {
  const [localStoredSkipSignIn, setLocalStoredSkipSignIn] = useLocalStorage("skipSignIn", null);
  const { state, actions } = useReducerActions(
    { reducer, actions: triggers },
    {
      ...initialState,
      signInConfiguration: localStoredSkipSignIn || initialState.signInConfiguration,
    }
  );
  return {
    state,
    actions: {
      ...actions,
      setSignInConfiguration: (payload: SignInConfiguration) => {
        setLocalStoredSkipSignIn(payload);
        actions.setSignInConfiguration(payload);
      },
    } as ClientPortalReducerActions,
  };
};
