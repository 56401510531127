import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMessengerThreadCardStyles = () => {
  const {
    color: { text, border, icon },
    spacing: { inner },
  } = useThemeTokens();

  const baseCard = {
    marginTop: 0,
    padding: inner.base04,
    w: 0,
    minWidth: "100%",
  };

  const activeCard = {
    ...baseCard,
    cursor: "pointer",
    borderColor: {
      _: border.neutral.default,
      hover: "transparent",
    },
    outlineWidth: {
      _: 0,
      hover: "1px",
    },
    outlineColor: text.info,
    outlineStyle: "solid",
  };

  const selectedCard = {
    ...baseCard,
    cursor: "pointer",
    borderColor: "transparent",
    outlineWidth: "1px",
    outlineColor: text.info,
    outlineStyle: "solid",
  };

  const dateLabel = {
    color: text.secondary,
  };

  const expertCompanyLabel = {
    color: text.strong._,
  };

  const expertPositionLabel = {
    color: text.strong._,
  };

  const sendersLabel = {
    color: text.strong._,
  };

  const messagePreviewLabel = {
    color: text.secondary,
    marginTop: inner.base,
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    lineClamp: "2",
    WebkitLineClamp: 2,
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical" as any,
  };

  const unreadMark = {
    display: "inline-flex",
    w: inner.base02,
    h: inner.base02,
    borderRadius: inner.base02,
    backgroundColor: icon.info,
    marginRight: inner.base02,
  };

  const topContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const contentContainer = {
    display: "flex",
    whiteSpace: "nowrap",
    marginTop: inner.base02,
    alignItems: "baseline",
  };

  const expertInfoContainer = {
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return {
    activeCard,
    selectedCard,
    dateLabel,
    expertCompanyLabel,
    expertPositionLabel,
    sendersLabel,
    messagePreviewLabel,
    unreadMark,
    topContainer,
    contentContainer,
    expertInfoContainer,
  };
};
