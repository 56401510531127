import { AngleTypeSynthesis, SynthesisModule, SynthesisQuestion } from "@alphasights/portal-api-client";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { projectSynthesis as API } from "@alphasights/portal-api-client";
import {
  EditSynthesisModuleOperations,
  useEditSynthesisModule,
} from "views/ProjectSynthesisView/hooks/useEditSynthesisModule";
import { useNewSynthesisModule } from "views/ProjectSynthesisView/hooks/useNewSynthesisModule";
import { Mode, SynthesisAngle, SynthesisPreferences } from "./ProjectSynthesisProvider.types";
import { fetch } from "hooks/useApi";
import _ from "lodash";
import { PROJECT_SYNTHESIS, usePreference } from "hooks/usePreference";

export const ProjectSynthesisContext = React.createContext<ProjectSynthesisProviderState | undefined>(undefined);

export type ProjectSynthesisProviderProps = PropsWithChildren<{
  project: Project;
  angles: SynthesisAngle[];
}>;

export interface ProjectSynthesisProviderState {
  projectSynthesis: AngleTypeSynthesis[];
  selectedModule: SynthesisModule | undefined;
  selectModule: (module: SynthesisModule) => void;
  mode: Mode;
  setMode: (mode: Mode) => void;
  editOperations: EditSynthesisModuleOperations;
  selectedRevisionIdx: number;
  revision: SynthesisQuestion | undefined;
  reprocess: (angleTypeName?: string) => void;
  selectedProjectSynthesis?: AngleTypeSynthesis;
  promoteModule: (module: SynthesisModule) => void;
  reorderModule: (angleType: string, order: string[]) => void;
  nextRevision: () => void;
  prevRevision: () => void;
  saveModuleChanges: (moduleToSave: SynthesisModule) => Promise<SynthesisModule>;
  cancelModuleChanges: () => void;
  undo: () => void;
  redo: () => void;
  saveInProgress: boolean;
  newSynthesisModule: ReturnType<typeof useNewSynthesisModule>;
  isQuestionEdited: boolean;
  deleteModule: (moduleId: string) => void;
  onPromoteAllModules: () => void;
  modulesToPromote: SynthesisModule[];
  synthesisPreferences: SynthesisPreferences;
  updateSynthesisPreferences: (updatedPreferences: Partial<SynthesisPreferences>) => void;
}

export const ProjectSynthesisProvider = ({ project, angles, children }: ProjectSynthesisProviderProps) => {
  const [projectSynthesis, setProjectSynthesis] = useState<AngleTypeSynthesis[]>([]);
  const [module, setModule] = useState<SynthesisModule | undefined>(undefined);
  const [selectedRevisionIdx, setSelectedRevisionIdx] = useState(-1);
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const { editedModule, editOperations, cancelChanges, undo, redo, clearUndoRedo } = useEditSynthesisModule(module);

  const [preference, { updatePreference }] = usePreference(PROJECT_SYNTHESIS) as any;

  const synthesisPreferences = useMemo(() => {
    const defaults = {
      showSavePrompt: true,
      showSaveAndRegeneratePrompt: true,
      disableAutoRegeneration: true,
    };
    return {
      ...defaults,
      ...preference?.attributes,
    };
  }, [preference]);

  const selectedModule = useMemo(() => {
    return mode === Mode.EDIT ? editedModule : module;
  }, [mode, editedModule, module]);

  const selectedProjectSynthesis = useMemo(() => {
    if (!module) return undefined;

    return projectSynthesis.find((ps) => ps.modules.includes(module))!!;
  }, [projectSynthesis, module]);

  const revision = useMemo(() => {
    return selectedRevisionIdx >= 0 ? selectedModule?.questionRevisions[selectedRevisionIdx] : undefined;
  }, [selectedModule, selectedRevisionIdx]);

  const isQuestionEdited = useMemo(
    () =>
      editedModule?.questionRevisions[selectedRevisionIdx]?.question !==
      module?.questionRevisions[selectedRevisionIdx]?.question,
    [editedModule, module, selectedRevisionIdx]
  );

  const selectModuleInLatestRevision = useCallback((moduleToSelect: SynthesisModule | undefined) => {
    setModule(moduleToSelect);
    setSelectedRevisionIdx((moduleToSelect?.questionRevisions.length ?? 0) - 1);
  }, []);

  const nextRevision = useCallback(() => {
    setSelectedRevisionIdx((prevIdx) => Math.min(prevIdx + 1, (selectedModule?.questionRevisions.length ?? 0) - 1));
  }, [selectedModule]);

  const prevRevision = useCallback(() => {
    setSelectedRevisionIdx((prevIdx) => Math.max(prevIdx - 1, 0));
  }, []);

  const updateSynthesis = useCallback((updatedModule: SynthesisModule) => {
    setProjectSynthesis((angles) => {
      const updatedAngles = angles.map((angle) => {
        const moduleFound = angle.modules.some((m) => {
          return m.id === updatedModule.id;
        });

        if (moduleFound) {
          return {
            ...angle,
            modules: angle.modules.map((m) => (m.id === updatedModule.id ? updatedModule : m)),
          };
        }

        return angle;
      });

      return updatedAngles;
    });
  }, []);

  const updateSynthesisWithReorder = useCallback(
    (updatedAngle: AngleTypeSynthesis) => {
      setProjectSynthesis((angles) => {
        const updatedAngles = angles.map((angle) => {
          if (angle.angleType === updatedAngle.angleType) {
            return {
              ...angle,
              modules: updatedAngle.modules,
            };
          }
          return angle;
        });
        return updatedAngles;
      });
    },
    [setProjectSynthesis]
  );

  const preemtpiveReorderUpdate = useCallback(
    (angletype: string, order: string[]) => {
      setProjectSynthesis((angles) => {
        const updatedAngles = angles.map((angle) => {
          if (angle.angleType === angletype) {
            const reorderedModules = order
              .map((id) => angle.modules.find((module) => module.id === id))
              .filter((module): module is SynthesisModule => module !== undefined);

            return {
              ...angle,
              modules: reorderedModules,
            };
          }
          return angle;
        });
        return updatedAngles;
      });
    },
    [setProjectSynthesis]
  );

  const fetchQuestions = useCallback(() => {
    return API.getProjectSynthesis(project.token).then((resp) => {
      setProjectSynthesis(resp);
      return resp;
    });
  }, [project.token]);

  const reprocess = useCallback(
    (angleTypeName?: string) => {
      API.reprocessAngleType(project.token, angleTypeName || "").then((updatedSynthesis) => {
        setProjectSynthesis(
          projectSynthesis.map((ps) => updatedSynthesis.find((updated) => ps.angleType === updated.angleType) ?? ps)
        );
      });
    },
    [projectSynthesis, project.token]
  );

  const promoteModule = useCallback(
    (moduleToPromote: SynthesisModule) => {
      return API.promoteProjectSynthesisModule(project.token, moduleToPromote.id).then((resp) => {
        const updated = resp as SynthesisModule;
        setModule(updated);
        updateSynthesis(updated);
        return resp;
      });
    },
    [project.token, updateSynthesis]
  );

  const reorderModule = useCallback(
    (angleType: string, order: string[]) => {
      preemtpiveReorderUpdate(angleType, order);
      return API.reorderProjectSynthesisAngleType(project.token, angleType, order).then((resp) => {
        const updated = resp as AngleTypeSynthesis;
        updateSynthesisWithReorder(updated);
        return resp;
      });
    },
    [project.token, preemtpiveReorderUpdate, updateSynthesisWithReorder]
  );

  const deleteModule = useCallback(
    (moduleId: string) => {
      fetch({
        method: "DELETE",
        url: `/api/projects/${project.token}/synthesis/${moduleId}`,
      })
        .then(() => {
          const relevantAngleSynthesis = projectSynthesis.find((angle) => angle.modules.some((m) => m.id === moduleId));
          const moduleToSelect =
            relevantAngleSynthesis?.modules.find((m) => m.id !== moduleId) ||
            projectSynthesis.flatMap((angle) => angle.modules).find((m) => m.id !== moduleId);
          selectModuleInLatestRevision(moduleToSelect);
          setProjectSynthesis((angles) =>
            angles
              .map((angle) => ({ ...angle, modules: angle.modules.filter((m) => m.id !== moduleId) }))
              .filter((angle) => angle.modules.length > 0)
          );
          setMode(Mode.VIEW);
        })
        .catch((error) => {
          alert("Error - will toast properly on CP2-988");
          throw error;
        });
    },
    [project.token, projectSynthesis, selectModuleInLatestRevision]
  );

  const saveModuleChanges = useCallback(
    (moduleToSave: SynthesisModule) => {
      setSaveInProgress(true);
      return API.updateProjectSynthesisModule(project.token, moduleToSave.id, moduleToSave)
        .then((updatedModule) => {
          clearUndoRedo();
          setModule(updatedModule);
          updateSynthesis(updatedModule);
          if (updatedModule.questionRevisions.length !== moduleToSave.questionRevisions.length) {
            setSelectedRevisionIdx(updatedModule.questionRevisions.length - 1);
          }
          setMode(Mode.VIEW);
          return updatedModule;
        })
        .catch((error) => {
          alert("Error - will toast properly on CP2-988");
          throw error;
        })
        .finally(() => {
          setSaveInProgress(false);
        });
    },
    [clearUndoRedo, project.token, updateSynthesis]
  );

  const onSuccessCreateModule = useCallback(
    (newModule: AngleTypeSynthesis) => {
      setProjectSynthesis((previous) =>
        previous.map((ps) => {
          if (ps.angleType === newModule.angleType) {
            return {
              ...ps,
              modules: [...ps.modules, ...newModule.modules],
            };
          }
          return ps;
        })
      );
      setMode(Mode.VIEW);
      selectModuleInLatestRevision(newModule.modules[0]);
    },
    [selectModuleInLatestRevision]
  );

  const updateSynthesisPreferences = useCallback(
    (updatedPreferences: Partial<SynthesisPreferences>) => {
      updatePreference(updatedPreferences);
    },
    [updatePreference]
  );

  const newSynthesisModule = useNewSynthesisModule({
    mode,
    project,
    angles,
    onSuccess: onSuccessCreateModule,
  });

  useEffect(
    function selectFirstModuleOnLand() {
      fetchQuestions().then((resp: AngleTypeSynthesis[]) => {
        selectModuleInLatestRevision(resp[0]?.modules[0]);
      });
    },
    [fetchQuestions, selectModuleInLatestRevision]
  );

  const modulesToPromote = useMemo(() => {
    return _.flattenDeep(projectSynthesis.map((ps) => ps.modules.filter((m) => m.type === "EXTRACTED")));
  }, [projectSynthesis]);

  const onPromoteAllModules = useCallback(() => {
    const ids = modulesToPromote.map((ps) => ps.id);

    API.promoteAllModules(project.token, { ids }).then((list) => {
      const newProjectSynthesis = projectSynthesis.map((ps) => {
        const updated: AngleTypeSynthesis | undefined = list.find((u) => u.angleType === ps.angleType);

        if (!updated) return ps;

        return {
          ...ps,
          modules: [...ps.modules.map((m) => updated.modules.find((u) => u.id === m.id) || m)],
        };
      });

      setProjectSynthesis(newProjectSynthesis);
    });
  }, [projectSynthesis, modulesToPromote, project.token]);

  const context: ProjectSynthesisProviderState = useMemo(
    () => ({
      projectSynthesis,
      selectedProjectSynthesis,
      selectedModule,
      selectModule: selectModuleInLatestRevision,
      mode,
      setMode,
      editOperations,
      selectedRevisionIdx,
      revision,
      promoteModule,
      reorderModule,
      reprocess,
      nextRevision,
      prevRevision,
      saveModuleChanges,
      cancelModuleChanges: cancelChanges,
      undo,
      redo,
      saveInProgress,
      newSynthesisModule,
      isQuestionEdited,
      deleteModule,
      onPromoteAllModules,
      modulesToPromote,
      updateSynthesisPreferences,
      synthesisPreferences,
    }),
    [
      projectSynthesis,
      selectedModule,
      mode,
      selectModuleInLatestRevision,
      editOperations,
      selectedRevisionIdx,
      revision,
      promoteModule,
      reorderModule,
      nextRevision,
      prevRevision,
      saveModuleChanges,
      cancelChanges,
      undo,
      redo,
      saveInProgress,
      reprocess,
      selectedProjectSynthesis,
      newSynthesisModule,
      isQuestionEdited,
      deleteModule,
      onPromoteAllModules,
      modulesToPromote,
      updateSynthesisPreferences,
      synthesisPreferences,
    ]
  );

  return <ProjectSynthesisContext.Provider value={context} children={children} />;
};

export const useProjectSynthesisContext = () => {
  const context = useContext(ProjectSynthesisContext);

  if (!context) throw new Error("ProjectSynthesisContext should only be used within the ProjectSynthesisProvider");

  return context;
};
