import React, { ChangeEvent, useCallback } from "react";
import styled, { x } from "@xstyled/styled-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import {
  Icon,
  IconButton,
  Pill,
  Skeleton,
  TextArea,
  Tooltip,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { ArrowRight, ChevronLeft, ChevronRight, Company, Copy, Delete, Expert } from "@alphasights/alphadesign-icons";
import { useStyles } from "./SynthesisModuleContent.styles";
import { SynthesisAnswer, SynthesisOverview, SynthesisQuestion, SynthesisQuote } from "@alphasights/portal-api-client";
import { FloatingActions } from "components/FloatingActions";
import { useHistory } from "react-router-dom";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import _ from "lodash";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { NewSynthesisModuleContent } from "./NewSynthesisModuleContent";
import { useHideDeliverablesContent } from "views/DeliverablesView/NewDeliverablesPage/useHideDeliverablesContent";

export const SynthesisModuleContent = () => {
  const { selectedModule, revision, mode } = useProjectSynthesisContext();
  const { moduleWrapper, overviewWrapper, overviewTitle, overviewContent, answersWrapper } = useStyles({
    isEditing: mode === Mode.EDIT,
  });
  const { contentStyle } = useHideDeliverablesContent();

  if (!revision || !selectedModule) return <x.div {...moduleWrapper} data-testid="empty-module-content"></x.div>;

  return (
    <x.div {...moduleWrapper} data-testid="synthesis-module-content" {...contentStyle}>
      {mode === Mode.NEW && <NewSynthesisModuleContent />}
      {mode !== Mode.NEW &&
        (revision?.status === "COMPLETED" ? (
          <x.div data-testid={`revision-${revision.revision}-content`}>
            <x.div {...overviewWrapper}>
              <x.div {...overviewTitle}>
                <Question question={revision.question} />
                {mode === Mode.VIEW && <RevisionToggler />}
              </x.div>

              <x.div {...overviewContent}>
                {revision?.overview?.map((overviewTopic) => (
                  <OverviewItem
                    key={overviewTopic.id}
                    overviewTopic={overviewTopic}
                    expertCount={revision.expertCount ?? 0}
                  />
                ))}
              </x.div>
            </x.div>

            <x.div {...answersWrapper}>
              {mode === Mode.VIEW && revision.answers?.length === 0 ? (
                <EmptyAnswers />
              ) : (
                <>
                  {revision.answers?.map((answer) => (
                    <ExpertAnswer key={answer.id} answer={answer} />
                  ))}
                </>
              )}
            </x.div>
          </x.div>
        ) : (
          <ProcessingModuleContent revision={revision} />
        ))}
    </x.div>
  );
};

const Question = ({ question }: { question: string }) => {
  const { mode, selectedRevisionIdx, editOperations } = useProjectSynthesisContext();

  const onEditQuestion = (e: ChangeEvent<HTMLTextAreaElement>) => {
    editOperations.updateQuestion(e.target.value, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT) {
    return (
      <EditableInputWrapper data-testid="question-edit">
        <EditableInput input={question} onChange={onEditQuestion} />
      </EditableInputWrapper>
    );
  }

  return <Typography variant="h3">{question}</Typography>;
};

const EditableInputWrapper = styled.div`
  width: 100%;
  textarea {
    font-size: 1.125rem;
    font-weight: 600;
  }
`;

const OverviewItem = ({ overviewTopic, expertCount }: { overviewTopic: SynthesisOverview; expertCount: number }) => {
  const { spacing } = useThemeTokens();
  const { overviewItem, borderedBox, editHeader } = useStyles();
  const { mode, editOperations, selectedRevisionIdx, saveInProgress } = useProjectSynthesisContext();

  const onEditOverview = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedOverviewTopic = { ...overviewTopic, summary: e.target.value };
    editOperations.updateOverview(updatedOverviewTopic, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT) {
    return (
      <x.div {...borderedBox} py={spacing.inner.base04} data-testid={`overview-edit-${overviewTopic.id}`}>
        <x.div {...editHeader}>
          <x.div display="flex" alignItems="center" gap={spacing.inner.base04}>
            <TopicPill title={overviewTopic.title} />
            <ExpertCounter relevantCount={overviewTopic.expertCount} totalExpertCount={expertCount} />
          </x.div>
          <IconButton
            size="small"
            variant="ghost"
            onClick={() => editOperations.deleteOverview(overviewTopic, selectedRevisionIdx)}
            testId={`delete-overview-${overviewTopic.id}`}
            disabled={saveInProgress}
          >
            <Delete />
          </IconButton>
        </x.div>
        <EditableInput input={overviewTopic.summary} onChange={onEditOverview} />
      </x.div>
    );
  }

  return (
    <x.div {...overviewItem} key={overviewTopic.id}>
      <x.div>
        <TopicPill title={overviewTopic.title} />
        <Typography component="span">{overviewTopic.summary}</Typography>
      </x.div>
      <ExpertCounter relevantCount={overviewTopic.expertCount} totalExpertCount={expertCount} />
    </x.div>
  );
};

const TopicPill = ({ title }: { title?: string }) => (
  <>
    {title && (
      <Pill size="x-small" mr="4px" isInteractive={false}>
        {title}
      </Pill>
    )}
  </>
);

const ExpertCounter = ({ relevantCount, totalExpertCount }: { relevantCount: number; totalExpertCount: number }) => (
  <Typography color="assistive" component="span" flexShrink="0">
    {(totalExpertCount ?? 0) > 0 ? `${relevantCount}/${totalExpertCount} experts` : "0 experts"}
  </Typography>
);

const RevisionToggler = () => {
  const { selectedModule, selectedRevisionIdx, prevRevision, nextRevision } = useProjectSynthesisContext();

  if (!selectedModule?.questionRevisions || selectedModule.questionRevisions.length <= 1) return null;

  return (
    <Pill
      data-testid="revision-toggler"
      leftAccessories={
        <Tooltip title="Switch to previous question">
          <IconButton size="x-small" onClick={prevRevision} variant="basic" testId="prev-revision">
            <ChevronLeft />
          </IconButton>
        </Tooltip>
      }
      rightAccessories={
        <Tooltip title="Switch to next question">
          <IconButton size="x-small" onClick={nextRevision} variant="basic" testId="next-revision">
            <ChevronRight />
          </IconButton>
        </Tooltip>
      }
      isInteractive={false}
      variant="outline"
      size="small"
    >
      <x.span w="30px" display="flex" justifyContent="center">
        <Typography component="span" variant="body-small">
          {selectedRevisionIdx + 1}/{selectedModule.questionRevisions.length}
        </Typography>
      </x.span>
    </Pill>
  );
};

const ExpertAnswer = ({ answer }: { answer: SynthesisAnswer }) => {
  const { borderedBox, companyLogoWrapper, answerTitle, answerTitleWrapper } = useStyles();
  const { mode, editOperations, selectedRevisionIdx, saveInProgress } = useProjectSynthesisContext();

  const ActionIcon = () =>
    mode === Mode.EDIT ? (
      <IconButton
        size="small"
        variant="ghost"
        onClick={() => editOperations.deleteAnswer(answer, selectedRevisionIdx)}
        testId={`delete-answer-${answer.id}`}
        disabled={saveInProgress}
      >
        <Delete />
      </IconButton>
    ) : (
      <Tooltip title="Copy insight">
        <HideableIconButton size="small" variant="ghost" onClick={() => alert("TBD in CP2-984")}>
          <Copy />
        </HideableIconButton>
      </Tooltip>
    );

  return (
    <x.div id="answer-block" {...borderedBox}>
      <x.div {...answerTitleWrapper}>
        <x.div {...answerTitle}>
          <x.div {...companyLogoWrapper}>
            {answer.expert.companyLogo ? (
              <img src={answer.expert.companyLogo} alt={answer.expert.companyName} height="12px" />
            ) : (
              <Icon>
                <Company />
              </Icon>
            )}
          </x.div>
          <Typography color="assistive" component="span" variant="body-small">
            {answer.expert.companyName} - {answer.expert.role}
          </Typography>
        </x.div>
        <ActionIcon />
      </x.div>

      <ExpertAnswerText answer={answer} />

      {answer.quotes.map((quote) => (
        <ExpertQuote key={quote.id} quote={quote} />
      ))}
    </x.div>
  );
};

const EmptyAnswers = () => {
  const { emptyAnswersWrapper, emptyIconWrapper } = useStyles();

  return (
    <x.div {...emptyAnswersWrapper} data-testid="empty-answers">
      <x.div {...emptyIconWrapper}>
        <Icon size="small" color="secondary">
          <Expert />
        </Icon>
      </x.div>
      <Typography variant="body-large-em" color="secondary">
        No Experts Insights
      </Typography>
      <Typography color="secondary">Refresh to generate expert insights for this module</Typography>
    </x.div>
  );
};

const ExpertAnswerText = ({ answer }: { answer: SynthesisAnswer }) => {
  const { mode, editOperations, selectedRevisionIdx } = useProjectSynthesisContext();

  const onEditAnswer = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedAnswer = { ...answer, text: e.target.value };
    editOperations.updateAnswer(updatedAnswer, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT) {
    return (
      <x.div data-testid={`answer-edit-${answer.id}`}>
        <EditableInput input={answer.text} onChange={onEditAnswer} />
      </x.div>
    );
  }

  return (
    <Typography component="div" variant="body-small-spaced">
      {answer.text}
    </Typography>
  );
};

const ExpertQuote = ({ quote }: { quote: SynthesisQuote }) => {
  const history = useHistory();
  const { spacing } = useThemeTokens();
  const { quoteWrapper, borderedBox, editHeader } = useStyles();
  const { mode, editOperations, selectedRevisionIdx, saveInProgress } = useProjectSynthesisContext();
  const { project } = useCurrentProjectContext();

  const navTo = useCallback(
    ({
      interactionId,
      transcriptIndex,
      tooltip,
    }: {
      interactionId: string;
      transcriptIndex: number;
      tooltip: string;
    }) => ({
      icon: <ArrowRight />,
      tooltip: tooltip,
      testid: "nav",
      onClick: () => {
        history.push(
          `/${project?.token}/experts/deliverables-view/?transcriptFocus=${transcriptIndex}&f=&selectedInteraction=${interactionId}`
        );
      },
    }),
    [history, project]
  );

  const onEditQuote = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedQuote = { ...quote, text: e.target.value };
    editOperations.updateQuote(updatedQuote, selectedRevisionIdx);
  };

  if (mode === Mode.EDIT) {
    return (
      <x.div
        {...borderedBox}
        py={spacing.inner.base03}
        px={spacing.inner.base04}
        data-testid={`quote-edit-${quote.id}`}
      >
        <x.div {...editHeader}>
          <x.div display="flex" alignItems="center" gap={spacing.inner.base02}>
            <TopicPill title={quote.topic} />
          </x.div>
          <IconButton
            size="small"
            variant="ghost"
            onClick={() => editOperations.deleteQuote(quote, selectedRevisionIdx)}
            testId={`delete-quote-${quote.id}`}
            disabled={saveInProgress}
          >
            <Delete />
          </IconButton>
        </x.div>
        <EditableInput input={quote.text} onChange={onEditQuote} />
      </x.div>
    );
  }

  return (
    <x.div {...quoteWrapper} key={quote.id}>
      <FloatingActions
        actions={_.compact([
          quote.transcriptIndex &&
            quote.interactionId &&
            navTo({
              interactionId: quote.interactionId,
              transcriptIndex: quote.transcriptIndex,
              tooltip: quote.edited ? "View original quote in transcript" : "View in transcript",
            }),
        ])}
      >
        <x.span>
          <TopicPill title={quote.topic} />
          <Typography component="span" variant="body-small-spaced" fontStyle="italic">
            {quote.text}
          </Typography>
        </x.span>
      </FloatingActions>
      {quote.edited && (
        <Typography component="span" variant="body-small-spaced" color="assistive">
          Edited
        </Typography>
      )}
    </x.div>
  );
};

const EditableInput = ({
  input,
  onChange,
}: {
  input: string;
  onChange?: ((event: ChangeEvent<HTMLTextAreaElement>) => void) | undefined;
}) => {
  const { saveInProgress } = useProjectSynthesisContext();

  return (
    <TextArea
      value={input}
      onChange={onChange}
      width="100%"
      height="unset"
      isDynamic={true}
      disabled={saveInProgress}
    />
  );
};

const ProcessingModuleContent = ({ revision }: { revision?: SynthesisQuestion }) => {
  const { borderedBox } = useStyles();

  return (
    <>
      <x.div display="flex" flexDirection="column" gap="16px" p="24px" data-testid="processing-module">
        {revision?.question ? <Question question={revision.question} /> : <Skeleton variant="noMargin" width="300px" />}
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>

      <x.div {...borderedBox}>
        <Skeleton variant="noMargin" width="300px" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>
    </>
  );
};

const HideableIconButton = styled(IconButton)`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  #answer-block:hover & {
    opacity: 1;
  }
`;
