import React, { useEffect, useState, useRef } from "react";
import { Icon, DropdownMenu } from "../alphaui";
import { getLanguageFromCode, toMinutesAndSeconds } from "../../helpers/displayHelpers";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import {
  AudioRecording,
  Close,
  Forward_30 as Forward30,
  Pause,
  PlayArrow,
  Playback,
  Replay_30 as Replay30,
  Tick,
} from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import {
  Button,
  IconOnlyButton,
  Loading,
  Popover,
  Typography,
  Icon as IconAds,
} from "@alphasights/alphadesign-components";
import { useRecordingPlayerStyles } from "./RecordingPlayer.styles";

const MinuteAndSecond = ({ duration, ...props }) => {
  return (
    <Typography component="span" variant="body-small" {...props}>
      {toMinutesAndSeconds(duration)}
    </Typography>
  );
};

export const RecordingPlayer = ({
  controlsProps,
  progressBarProps,
  playbackProps,
  disabled = false,
  isError,
  isLoading,
  compact,
  number,
  audioError,
  interactionId,
  projectToken,
  recordingId,
  origin,
  language,
  variant,
  onClose,
  setAutoScroll,
}) => {
  const { logHit } = useTrackUserAction();

  const {
    headerStyles,
    titleStyles,
    iconStyles,
    controlStyles,
    progressBarStyles,
    durationsStyles,
    wrapperStyles,
    lightGreyTextStyles,
    darkGreyTextStyles,
    descriptionStyles,
  } = useRecordingPlayerStyles(variant);

  useEffect(() => {
    if (!disabled && controlsProps.playing) {
      logHit({
        origin: origin,
        action: "TRACK_RECORDING_PLAY",
        advisorshipId: interactionId,
        projectToken: projectToken,
        references: { recordingId },
      });
      setAutoScroll && setAutoScroll(true);
    }
  }, [disabled, controlsProps.playing, origin, projectToken, recordingId]); // eslint-disable-line react-hooks/exhaustive-deps

  const languageName = getLanguageFromCode(language);
  const progressBar = (
    <ProgressBar
      progressBarProps={{
        ...progressBarProps,
        onMouseUp: (event) => {
          controlsProps.playing && setAutoScroll && setAutoScroll(true);
          progressBarProps.onMouseUp(event);
        },
      }}
      disabled={disabled}
    />
  );

  const newControls = variant === "new-flyout" || variant === "calendar-popup";

  return (
    <x.div
      className={`aui-flex aui-flex-col ${
        !newControls ? "aui-w-full aui-mx-auto aui-items-center aui-space-y-3 aui-text-grey-4" : "aui-flex-grow"
      }`}
    >
      {newControls ? (
        <x.div {...wrapperStyles}>
          <x.div {...headerStyles}>
            <x.div {...titleStyles}>
              {variant === "new-flyout" && (
                <IconAds {...iconStyles}>
                  <AudioRecording />
                </IconAds>
              )}
              {variant === "calendar-popup" && (
                <IconAds color="secondary" size="small">
                  <AudioRecording {...iconStyles} />
                </IconAds>
              )}
              <x.div {...descriptionStyles}>
                <Typography {...darkGreyTextStyles}>Audio Recording {number}</Typography>
                <Typography variant="body-small" {...lightGreyTextStyles}>
                  {isError ? (
                    audioError || "Recording file no longer available."
                  ) : (
                    <>
                      {languageName}
                      {languageName && (
                        <x.span
                          dangerouslySetInnerHTML={{
                            __html: "&nbsp&nbsp|&nbsp&nbsp",
                          }}
                        />
                      )}
                      Recording available
                    </>
                  )}
                </Typography>
              </x.div>
            </x.div>
            <x.div {...controlStyles}>
              <RecordingPlayerControls
                playing={controlsProps.playing}
                isLoading={isLoading}
                onChangeSpeed={controlsProps.onChangeSpeed}
                onClickRewind={controlsProps.onClickRewind(30)}
                onClickForward={controlsProps.onClickForward(30)}
                onClose={onClose}
                onTogglePlay={controlsProps.onTogglePlay}
                disabled={disabled}
                newControls={newControls}
                isError={isError}
                variant={variant}
              />
            </x.div>
          </x.div>
          {!isError && (
            <x.div {...progressBarStyles}>
              {progressBar}
              <x.div {...durationsStyles}>
                <MinuteAndSecond duration={playbackProps.currentTime} {...lightGreyTextStyles} />
                <MinuteAndSecond duration={playbackProps.duration} {...lightGreyTextStyles} />
              </x.div>
            </x.div>
          )}
        </x.div>
      ) : (
        <>
          {isError ? (
            audioError || "Recording file no longer available."
          ) : (
            <>
              {!compact && <span>Audio Recording {number}</span>}

              <div className="aui-flex aui-items-center aui-w-full aui-space-x-2">
                <span className="aui-font-mono aui-text-sm aui-whitespace-no-wrap">
                  <MinuteAndSecond duration={playbackProps.currentTime} />
                </span>
                {progressBar}
                <span className="aui-font-mono aui-text-sm aui-whitespace-no-wrap">
                  <MinuteAndSecond duration={playbackProps.duration} />
                </span>
              </div>

              <div>
                <div>
                  <div className="aui-space-x-8 aui-pr-8">
                    <RecordingPlayerControls
                      playing={controlsProps.playing}
                      isLoading={isLoading}
                      onChangeSpeed={controlsProps.changeSpeed}
                      onClickRewind={controlsProps.onClickRewind(15)}
                      onClickForward={controlsProps.onClickRewind(15)}
                      onTogglePlay={controlsProps.onTogglePlay}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </x.div>
  );
};

const CloseButton = ({ ...props }) => (
  <IconOnlyButton variant="ghost" {...props}>
    <Close />
  </IconOnlyButton>
);

const PlayButton = ({ newControls, ...props }) => (
  <>
    {newControls ? (
      <IconOnlyButton variant="ghost" {...props}>
        <PlayArrow />
      </IconOnlyButton>
    ) : (
      <Icon
        icon="audio-player-play aui-text-3xl"
        {...props}
        className={`${props.disabled ? "aui-cursor-not-allowed" : ""}`}
      />
    )}
  </>
);
const PauseButton = ({ newControls, ...props }) => (
  <>
    {newControls ? (
      <IconOnlyButton variant="ghost" {...props}>
        <Pause />
      </IconOnlyButton>
    ) : (
      <Icon icon="audio-player-pause aui-text-3xl" {...props} />
    )}
  </>
);
const FastForwardButton = ({ newControls, ...props }) => (
  <>
    {newControls ? (
      <IconOnlyButton variant="ghost" {...props}>
        <Forward30 />
      </IconOnlyButton>
    ) : (
      <Icon
        icon="audio-player-fast-forward-15sec aui-text-3xl"
        {...props}
        className={`${props.disabled ? "aui-cursor-not-allowed" : ""}`}
      />
    )}
  </>
);
const RewindButton = ({ newControls, ...props }) => (
  <>
    {newControls ? (
      <IconOnlyButton variant="ghost" {...props}>
        <Replay30 />
      </IconOnlyButton>
    ) : (
      <Icon
        icon="audio-player-rewind-15sec aui-text-3xl"
        {...props}
        className={`${props.disabled ? "aui-cursor-not-allowed" : ""}`}
      />
    )}
  </>
);

const PlaySpeedButton = ({ onPlaySpeedSelected }) => {
  const [icon, setIcon] = useState("audio-player-playspeed-1x");
  const formatItem = (item) => {
    if (item === "1.0") {
      return "1.0x (Normal)";
    }

    return `${item}x`;
  };

  const onItemClick = (item) => {
    const selectedSpeed = Number(item);
    onPlaySpeedSelected(selectedSpeed);
    updateIcon(item);
  };

  const updateIcon = (item) => {
    const icon = `audio-player-playspeed-${item === "1.0" ? "1" : item.replace(".", "-")}x`;
    setIcon(icon);
  };

  // Weird hack to support dropdown HOVER on desktop but CLICK on mobile.
  // If DropdownMenu is used with HOVER as mouse event, it doesn't work on
  // mobile as there's no way to hover it.
  // We believe HOVER is a better experience on desktop, so this hack was
  // born to support both desktop and mobile.
  const hoverNotSupported = window.matchMedia && window.matchMedia("(hover: none)").matches;

  return (
    <div className="aui-inline-flex">
      <DropdownMenu
        className="aui-text-grey-4"
        items={["0.5", "0.75", "1.0", "1.25", "1.5"]}
        getValue={formatItem}
        onItemClick={onItemClick}
        handle={<Icon icon={`${icon} aui-text-2xl`} />}
        dropdownPosition={DropdownMenu.POSITION.LEFT}
        mouseEvent={hoverNotSupported ? DropdownMenu.EVENT.CLICK : DropdownMenu.EVENT.HOVER}
        hideDropdownToggle
        dropdownWidth={32}
      />
    </div>
  );
};

const PlaySpeedButtonNew = ({ onPlaySpeedSelected, ...props }) => {
  const {
    popoverStyles,
    playbackSpeedStyles,
    playbackSpeedOptionsStyles,
    selectedPlaybackSpeedStyles,
  } = useRecordingPlayerStyles();
  const [speed, setSpeed] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const open = Boolean(anchorEl);
  const possibleSpeeds = [0.5, 0.75, 1.0, 1.25, 1.5];

  const handlePopoverOpen = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget.lastElementChild);
    } else {
      setAnchorEl(null);
    }
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const handleSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);
    onPlaySpeedSelected(newSpeed);
    handlePopoverClose();
  };

  return (
    <>
      <IconOnlyButton variant="ghost" onClick={handlePopoverOpen} {...props}>
        <Playback />
      </IconOnlyButton>
      <Popover
        ref={ref}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        placement="bottom"
        {...popoverStyles}
      >
        <x.div {...playbackSpeedOptionsStyles}>
          {possibleSpeeds.map((s) => (
            <Button
              key={s}
              variant="ghost"
              onClick={() => handleSpeedChange(s)}
              endIcon={
                speed === s && (
                  <IconAds color="secondary">
                    <Tick />
                  </IconAds>
                )
              }
              {...playbackSpeedStyles}
              {...(speed === s && selectedPlaybackSpeedStyles)}
            >
              <Typography variant="body">{s}x</Typography>
            </Button>
          ))}
        </x.div>
      </Popover>
    </>
  );
};

const ProgressBar = ({ progressBarProps, disabled }) => {
  const { duration, value, draggingValue, onMouseDown, onMouseUp, onMouseMove } = progressBarProps;

  const progress = duration === 0 ? 0 : ((draggingValue || value) / duration) * 100;

  return (
    <div
      className="aui-w-full aui-h-2 aui-rounded aui--my-5 aui-py-5"
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div className={`aui-w-full aui-bg-grey-2 aui-rounded ${disabled ? "aui-cursor-not-allowed" : ""}`}>
        <div className="aui-bg-grey-5 aui-h-2 aui-rounded" style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

const RecordingPlayerControls = ({
  playing,
  isLoading,
  onChangeSpeed,
  onClickRewind,
  onClickForward,
  onClose,
  onTogglePlay,
  disabled,
  newControls,
  isError,
  variant,
}) => {
  const { controlButtonStyles, playSpeedButtonStyles } = useRecordingPlayerStyles(variant);

  return (
    <>
      {isLoading ? (
        <Loading size="sm" />
      ) : (
        <>
          {!isError && (
            <>
              {newControls ? (
                <PlaySpeedButtonNew
                  onPlaySpeedSelected={onChangeSpeed}
                  disabled={disabled}
                  {...playSpeedButtonStyles}
                />
              ) : (
                <PlaySpeedButton onPlaySpeedSelected={onChangeSpeed} />
              )}
              <RewindButton
                onClick={onClickRewind}
                disabled={disabled}
                newControls={newControls}
                {...controlButtonStyles}
              />
              {playing ? (
                <PauseButton onClick={onTogglePlay} newControls={newControls} {...controlButtonStyles} />
              ) : (
                <PlayButton
                  onClick={onTogglePlay}
                  disabled={disabled}
                  newControls={newControls}
                  {...controlButtonStyles}
                />
              )}
              <FastForwardButton
                onClick={onClickForward}
                disabled={disabled}
                newControls={newControls}
                {...controlButtonStyles}
              />
            </>
          )}
          {onClose && <CloseButton onClick={onClose} {...controlButtonStyles} />}
        </>
      )}
    </>
  );
};
