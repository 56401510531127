import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const AlphaNowFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FiltersWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    paddingTop: spacing.inner.base04,
    paddingBottom: spacing.inner.base04,
    gap: spacing.inner.base,
  };
});

const FilterViewWrapper = styled.div(({ isVisible }: { isVisible: boolean }) => {
  if (isVisible) {
    return {
      opacity: 1,
      transition: "opacity 0.3s cubic-bezier(0.4, 0, 1, 1)",
    };
  }

  return {
    visibility: "hidden" as "hidden",
    opacity: 0,
    transition: "none",
  };
});

export { AlphaNowFiltersContainer, FiltersWrapper, FilterViewWrapper };
