import React from "react";
import { x } from "@xstyled/styled-components";
import { List } from "../List";
import Card from "../Card";
import PropTypes from "prop-types";
import "./index.css";

import { featuredCompaniesContent, speakersContent, viewDetailsContent } from "../../content/AlphaViews";
import { Typography, Button } from "@alphasights/alphadesign-components";

export const UpcomingAlphaViewCard = ({ alphaView, handleViewDetails, selectedAlphaViewId }) => {
  const { id, externalTitle, speakers, companies } = alphaView;

  const speakerJobTitles = speakers && speakers.map((speaker) => speaker.jobTitle);
  const companyNames = companies && [companies.map((company) => company.name).join(", ")];

  const Title = () => (
    <>
      <Typography>AlphaView</Typography>
      <Typography variant="h3" pt="300" pb="500">
        {externalTitle}
      </Typography>
    </>
  );

  return (
    <Card borderColor={alphaView.id === selectedAlphaViewId ? "neutral500" : "neutral300"}>
      <Title />
      <List icon="advisor" title={speakersContent} content={speakerJobTitles} />
      <List icon="companies" title={featuredCompaniesContent} content={companyNames} />
      <x.div className="view-details-button aui-mt-6">
        <Button variant="secondary" size="small" onClick={() => handleViewDetails(id)}>
          <Typography variant="body-em" component="span">
            {viewDetailsContent}
          </Typography>
        </Button>
      </x.div>
    </Card>
  );
};

UpcomingAlphaViewCard.propTypes = {
  alphaView: PropTypes.shape({
    externalTitle: PropTypes.string,
    speakers: PropTypes.arrayOf(
      PropTypes.shape({
        jobTitle: PropTypes.string,
      })
    ),
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
  handleViewDetails: PropTypes.func.isRequired,
  selectedAlphaViewId: PropTypes.number,
};
