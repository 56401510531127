import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { CommercialModel, Competitors, Outlook, Overview, Segmentations } from "./sections";
import { x } from "@xstyled/styled-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import { HitAction } from "@alphasights/portal-api-client";
import {
  AlphaNowSRMProductType,
  ContentApprovalStatus,
  ContentPurchaseStatus,
  useTrackUserAction,
} from "@alphasights/client-portal-shared";
import useForwardedRef from "pages/AlphaNowPage/hooks/useForwardedRef";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { CurrentScrollAreaDataArgs, handleScroll, logScrollPercentage } from "pages/AlphaNowPage/utils/logHit";
import { useCitationTrackingContext } from "components/CitationContext/CitationTrackingContext";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import { useCompanyPrimersStore } from "pages/AlphaNowPage/primers/CompanyPrimer/state/store";
import { useCompanyPrimerV3Store } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/store/store";
import PaywallPage from "./paywall/PaywallPage";
import { AlphaNowSpinner } from "pages/AlphaNowPage/components";
import { AlphaNowCompanyPrimerProps } from "pages/AlphaNowPage/primers/CompanyPrimer/CompanyPrimerContentPageVars";
import { useLinkableSection } from "pages/AlphaNowPage/primers/hooks";
import { CommissionFooter, CommissionModal } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";
import useModal from "hooks/useModal";
import useGetModalFromUrl from "pages/AlphaNowPage/primers/hooks/useGetModalFromUrl";
import SectionWrapper from "./sections/styled";
import { getAnchorCompanyIds } from "content/AlphaNow/utils";
import { PrimersHeaderActionBar } from "pages/AlphaNowPage/primers/components";
import { primersHeaderActionTabs } from "pages/AlphaNowPage/primers/utils/constants";
import { COMMISSION_URL_PARAM } from "pages/AlphaNowPage/primers/CommissionPrimer/constants";

interface CompanyPrimerContentProps extends Pick<AlphaNowCompanyPrimerProps, "content"> {
  content: CompanyPrimerContentV3;
  primerStatus: string;
  isHidden?: boolean;
  isFetchingContent?: boolean;
  price?: number;
  isDeliverables?: boolean;
  setShouldShowPreviewAlert: (value: boolean) => void;
  onContentErrorChanged: (error: string) => void;
  onPurchasedContentChanged: () => void;
}

const CompanyPrimerContent = forwardRef<HTMLDivElement, CompanyPrimerContentProps>(
  (
    {
      content,
      price = 0,
      isDeliverables = false,
      setShouldShowPreviewAlert,
      onContentErrorChanged,
      onPurchasedContentChanged,
    },
    ref
  ) => {
    const { registerScrollContainer } = useCitationTrackingContext();
    const {
      spacing: { inner },
      color,
    } = useThemeTokens();
    const currentUser = useCurrentUser();
    // @ts-ignore
    const userId = currentUser?.id;
    const { logHit } = useTrackUserAction();

    const [primer, setPrimer] = useState(content);

    const { setPrimerState: setGeneralPrimerState, isAccessible, purchaseProperties } = usePrimersStore();
    const setCompanyPrimerV3State = useCompanyPrimerV3Store(({ setCompanyPrimerState }) => setCompanyPrimerState);
    const setCompanyPrimerState = useCompanyPrimersStore(({ setCompanyPrimerState }) => setCompanyPrimerState);
    const currentMenuItem = useCompanyPrimersStore(({ currentMenuItem }) => currentMenuItem);
    const setCurrentMenuItem = useCompanyPrimersStore(({ setCurrentMenuItem }) => setCurrentMenuItem);
    const {
      isVisible: isCommissionModalVisible,
      onOpen: onOpenCommissionModal,
      onClose: onCloseCommissionModal,
    } = useModal();
    useGetModalFromUrl(onOpenCommissionModal, COMMISSION_URL_PARAM);
    //TODO Refactor V1V2V3 Header for including paywall and purchasable header content
    // const moveToMenuItem = useCompanyPrimersStore(({ moveToMenuItem }) => moveToMenuItem);
    const purchasePropertiesOnHold =
      purchaseProperties.purchaseStatus === ContentPurchaseStatus.NA ||
      purchaseProperties.approvalStatus === ContentApprovalStatus.NA;

    const maxScrollPercentage = useRef(0);

    const { ref: scrollAreaRef } = useForwardedRef(ref);

    const sectionsRefs = [
      useRef<HTMLDivElement | null>(null),
      useRef<HTMLDivElement | null>(null),
      useRef<HTMLDivElement | null>(null),
      useRef<HTMLDivElement | null>(null),
      useRef<HTMLDivElement | null>(null),
    ];
    const { setRefs: overviewRef, inView: overviewInView } = useLinkableSection({
      sectionRef: sectionsRefs[0],
      root: scrollAreaRef,
    });
    const { setRefs: segmentationsRef, inView: segmentationsInView } = useLinkableSection({
      sectionRef: sectionsRefs[1],
      root: scrollAreaRef,
    });
    const { setRefs: commercialModelRef, inView: commercialModelInView } = useLinkableSection({
      sectionRef: sectionsRefs[2],
      root: scrollAreaRef,
    });
    const { setRefs: competitorsRef, inView: competitorsInView } = useLinkableSection({
      sectionRef: sectionsRefs[3],
      root: scrollAreaRef,
    });
    const { setRefs: outlookRef, inView: outlookInView } = useLinkableSection({
      sectionRef: sectionsRefs[4],
      root: scrollAreaRef,
    });

    useEffect(() => {
      if (overviewInView && currentMenuItem !== 0) setCurrentMenuItem(0);
      else if (segmentationsInView && currentMenuItem !== 1) setCurrentMenuItem(1);
      else if (commercialModelInView && currentMenuItem !== 2) setCurrentMenuItem(2);
      else if (competitorsInView && currentMenuItem !== 3) setCurrentMenuItem(3);
      else if (outlookInView && currentMenuItem !== 4) setCurrentMenuItem(4);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overviewInView, segmentationsInView, commercialModelInView, competitorsInView, outlookInView]);

    useEffect(() => {
      registerScrollContainer(scrollAreaRef);

      const action = HitAction.alphaNowCompanyPrimerScrollPercentage;
      return () => {
        logScrollPercentage(
          logHit,
          {
            contentId: primer.id,
            scrollPercentage: maxScrollPercentage.current,
            userId: String(userId),
          },
          action
        );
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNavigationClick = (index: number) => {
      sectionsRefs[index].current?.scrollIntoView({ behavior: "smooth" });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleScrollWrapper = useCallback(() => {
      handleScroll(
        (newMaxScrollPercentage: number) => (maxScrollPercentage.current = newMaxScrollPercentage),
        maxScrollPercentage.current,
        scrollAreaRef.current as CurrentScrollAreaDataArgs
      );

      if (scrollAreaRef.current) {
        const { scrollTop, scrollHeight } = scrollAreaRef.current;
        // hide once 10% of the primer content has been scrolled
        if (scrollTop > 0.1 * scrollHeight) {
          setShouldShowPreviewAlert(false);
        } else {
          setShouldShowPreviewAlert(true);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxScrollPercentage, scrollAreaRef]);

    useEffect(() => {
      const current = scrollAreaRef.current;
      current?.addEventListener("scroll", handleScrollWrapper);

      return () => {
        current?.removeEventListener("scroll", handleScrollWrapper);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollAreaRef.current, handleScrollWrapper]);

    useEffect(() => {
      if (primer) {
        const {
          overview,
          segmentations,
          commercialModel,
          competitors,
          outlook,
          mapOfCompanyNameToLogoLinks,
          speakers,
          companies,
          approvalStatus,
          purchaseStatus,
          id: contentId,
          lastUpdatedAt,
          downloadFileName,
        } = primer;

        setCompanyPrimerV3State({
          overview,
          segmentations,
          commercialModel,
          competitors,
          outlook,
        });

        //general primer store for shared state
        setGeneralPrimerState({
          primerTitle: overview ? overview.companyName : "",
          lastUpdatedAt,
          contentId,
          isAccessible: isContentAccessible(purchaseStatus, approvalStatus),
          purchaseProperties: { price, approvalStatus, purchaseStatus },
          mapOfCompanyNameToLogoLinks,
          speakers,
          currency: overview.currency,
          companies,
          downloadFileName,
        });

        setCompanyPrimerState({
          companyName: overview.companyName,
          companyLogo: overview.logoLink,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primer]);

    useEffect(() => {
      setPrimer(content);
    }, [content, setPrimer]);

    if (purchasePropertiesOnHold)
      return (
        <x.div flex="1" mt={inner.base06} ref={scrollAreaRef}>
          <AlphaNowSpinner />
        </x.div>
      );

    return (
      <x.div
        data-testid="company-primers-v3-page"
        h="100%"
        position="relative"
        display="flex"
        flexDirection="column"
        overflowY="hidden"
      >
        {!isAccessible && (
          <x.div h="100%">
            <PaywallPage
              price={price}
              companyIds={getAnchorCompanyIds(content.companies ?? [])}
              productType={AlphaNowSRMProductType.companyPrimer}
              onReturnToMobileSearch={() => {}}
              onContentErrorChanged={onContentErrorChanged}
              onPurchasedContentChanged={onPurchasedContentChanged}
              market={content.market}
            />
          </x.div>
        )}
        <Divider />
        <PrimersHeaderActionBar
          companyIds={getAnchorCompanyIds(content.companies ?? [])}
          currentTabIndex={currentMenuItem}
          handleNavigationClick={handleNavigationClick}
          tabs={primersHeaderActionTabs.companyPrimer}
          productType={AlphaNowSRMProductType.companyPrimer}
          market={content.market}
          showNavigationOnly={isDeliverables}
        />
        <x.div
          py={inner.base08}
          px={inner.base10}
          backgroundColor={color.background.surface.recessed}
          overflow="auto"
          ref={scrollAreaRef}
          display="flex"
          flexDirection="column"
          gap={inner.base08}
        >
          <Overview ref={overviewRef} />
          <Segmentations ref={segmentationsRef} />
          <CommercialModel ref={commercialModelRef} />
          <Competitors ref={competitorsRef} />
          <Outlook ref={outlookRef} />
          <SectionWrapper px={inner.base10} py="0">
            <CommissionFooter onOpen={onOpenCommissionModal} />
          </SectionWrapper>
          {isCommissionModalVisible && (
            <CommissionModal productType={AlphaNowSRMProductType.companyPrimer} onClose={onCloseCommissionModal} />
          )}
        </x.div>
      </x.div>
    );
  }
);

export default CompanyPrimerContent;
