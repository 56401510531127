import React, { useCallback, useMemo, useState } from "react";
import {
  AccordionItem,
  Avatar,
  Button,
  Icon,
  IconButton,
  Link,
  Pill,
  Typography,
  useAlphaToast,
} from "@alphasights/alphadesign-components";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { AttachmentResponse, MessageResponse, ResponseStatusType, ThreadResponse, WorkRequestResponse } from "types";
import { messageAttachmentService } from "services/messageAttachment";
import { x } from "@xstyled/styled-components";
import { Attachment as AttachmentIcon, Download, Expert } from "@alphasights/alphadesign-icons";
import { useNotifications } from "@alphasights/client-portal-shared";
import { useMessagesStyles } from "./Messages.styles";
import { WorkRequestMaxDuration } from "components/WorkRequestMaxDuration";
import { WorkRequestDeadline } from "components/WorkRequestDeadline/WorkRequestDeadline";
import { formatTimeInHoursAndMinutes } from "helpers/interactionHelpers";
import { format, isEqual } from "date-fns";
import { messageThreadService } from "services/messageThread";
import { getRequestTypeName, sanitizeMessage } from "pages/MessengerPage/utils";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export const Messages: React.FC<{
  thread: ThreadResponse;
  projectToken: string;
}> = ({ thread, projectToken }) => {
  const { wrapper } = useMessagesStyles();

  return (
    <>
      <AccordionItem title={getRequestTypeName(thread.requestType)} open>
        <x.div {...wrapper}>
          {thread.messages.map((message: MessageResponse) => (
            <Message message={message} projectToken={projectToken} />
          ))}
        </x.div>
      </AccordionItem>
      {thread.workRequest && (
        <MessageSettings projectToken={projectToken} threadToken={thread.token} workRequest={thread.workRequest} />
      )}
    </>
  );
};

const Message: React.FC<{
  message: MessageResponse;
  projectToken: string;
}> = ({ message, projectToken }) => {
  const {
    messageContainer,
    messageContent,
    messageHeader,
    messageInfo,
    messageSender,
    messageDate,
    messageAttachmentsContainer,
    messageAttachmentIcon,
    messageAttachmentCount,
  } = useMessagesStyles();

  const { isMobile } = useCheckScreen();

  const [isExpanded, setExpanded] = useState(false);
  const { sanitizedContent, originalContent, isContentSanitized } = useMemo(() => sanitizeMessage(message), [message]);
  const messageText = isExpanded ? originalContent : sanitizedContent;

  const onToggleExpandedMode = () => setExpanded(!isExpanded);
  const expertNameWithheld = useMemo(() => {
    return message.sender.name?.toLowerCase().includes("expert name withheld");
  }, [message.sender.name]);

  return (
    <x.div {...messageContainer} data-testid={`message-container-${message.id}`}>
      <x.div {...messageHeader}>
        <x.div {...messageInfo}>
          {expertNameWithheld ? (
            <Avatar color="base02">
              <Expert />
            </Avatar>
          ) : (
            <Avatar text={message.sender.name} color="base02" />
          )}
          <div>
            <Typography variant="body-em" {...messageSender}>
              {message.sender.name}
            </Typography>
            <Typography variant="body-small" {...messageDate}>
              <FormattedDateTime date={message.sentAt} format="d LLL yyyy, hh:mm" />
            </Typography>
          </div>
        </x.div>
        {message.attachments.length > 0 && (
          <x.div {...messageAttachmentsContainer} data-testid="message-attachments-count">
            <Icon {...messageAttachmentIcon}>
              <AttachmentIcon />
            </Icon>
            <Typography {...messageAttachmentCount}>{message.attachments.length}</Typography>
          </x.div>
        )}
      </x.div>
      <Typography
        {...messageContent}
        style={{
          filter: message.obfuscated ? "blur(2.5px)" : "none",
          userSelect: message.obfuscated ? "none" : "auto",
        }}
        dangerouslySetInnerHTML={{ __html: messageText }}
      />
      {message.attachments.length > 0 && <Attachments attachments={message.attachments} projectToken={projectToken} />}

      {isContentSanitized && (
        <Link onClick={() => onToggleExpandedMode()}>
          <Typography variant={isMobile ? "body-large-em" : "body-em"}>See {isExpanded ? "Less" : "More"}</Typography>
        </Link>
      )}
    </x.div>
  );
};

const Attachments: React.FC<{
  attachments: AttachmentResponse[];
  projectToken: string;
}> = ({ attachments, projectToken }) => {
  const { attachmentsSection, attachmentsCount } = useMessagesStyles();

  return (
    <x.div {...attachmentsSection}>
      <Typography variant="body-small" {...attachmentsCount}>
        {`${attachments.length} attachment${attachments.length > 1 ? "s" : ""}`}
      </Typography>
      {attachments.map((attachment: AttachmentResponse) => (
        <Attachment attachment={attachment} projectToken={projectToken} key={`attachment-${attachment.id}`} />
      ))}
    </x.div>
  );
};

const Attachment: React.FC<{
  projectToken: string;
  attachment: AttachmentResponse;
}> = ({ projectToken, attachment }) => {
  const { attachmentContainer, attachmentFile, attachmentFileInfo } = useMessagesStyles();
  const { showSuccessBanner, showErrorBanner } = useNotifications();

  const onClickDownload = useCallback(() => {
    messageAttachmentService
      .downloadMessageAttachment(projectToken, attachment)
      .then(() => {
        showSuccessBanner("Attachment download successful");
      })
      .catch(() => {
        showErrorBanner("The attachment could not be downloaded. Please try again or contact your project lead");
      });
  }, [attachment, projectToken, showSuccessBanner, showErrorBanner]);

  return (
    <x.div {...attachmentContainer} data-testid="attachment-card">
      <x.div {...attachmentFile}>
        <Avatar color="base02">
          <AttachmentIcon />
        </Avatar>
        <div style={{ overflowWrap: "anywhere" }}>
          <Typography>{attachment.filename}</Typography>
          <Typography variant="body-small" {...attachmentFileInfo}>
            {attachment.type} • {attachment.fileSize}
          </Typography>
        </div>
      </x.div>
      <IconButtonWithAccess
        accessControl={{ allowedPermissions: ["follow-up-messages"] }}
        variant="basic"
        onClick={onClickDownload}
        color="secondary"
      >
        <Download />
      </IconButtonWithAccess>
    </x.div>
  );
};

const IconButtonWithAccess = withAccessControl(IconButton);

export const MessageSettings: React.FC<{
  projectToken: string;
  threadToken: string;
  workRequest: WorkRequestResponse;
}> = ({ projectToken, threadToken, workRequest }) => {
  const { headerSlot, messageSettingsFields, messageSettingsButton, titleContent } = useMessagesStyles();

  const { toast } = useAlphaToast();

  const [deadline, setDeadline] = useState(new Date(workRequest.deadline));
  const [maximumTimeAllowed, setMaximumTimeAllowed] = useState(workRequest.maximumTimeAllowed);
  const [selectedDeadline, setSelectedDeadline] = useState(new Date(workRequest.deadline));
  const [selectedMaximumTimeAllowed, setSelectedMaximumTimeAllowed] = useState(workRequest.maximumTimeAllowed);
  const [updateWorkRequestLoading, setUpdateWorkRequestLoading] = useState(false);

  const onUpdateWorkRequest = () => {
    setUpdateWorkRequestLoading(true);

    const content: any = {};
    const isDeadlineChanged = !isEqual(selectedDeadline, deadline);
    const isMaximumTimeAllowedChanged = selectedMaximumTimeAllowed !== maximumTimeAllowed;

    if (isDeadlineChanged) {
      content["deadline"] = selectedDeadline;
    }

    if (isMaximumTimeAllowedChanged) {
      content["maximumTimeAllowed"] = selectedMaximumTimeAllowed;
    }

    messageThreadService
      .updateWorkRequest(projectToken, threadToken, content)
      .then(() => {
        const messages: string[] = [];
        isDeadlineChanged && messages.push(`Deadine extended to ${format(selectedDeadline, "E d MMM yyyy, h:mmaaa")}`);
        isMaximumTimeAllowedChanged &&
          messages.push(`\nDuration extended to ${formatTimeInHoursAndMinutes(selectedMaximumTimeAllowed)}`);

        setDeadline(selectedDeadline);
        setMaximumTimeAllowed(selectedMaximumTimeAllowed);
        toast.success({ message: messages.join("\n") });
      })
      .finally(() => {
        setUpdateWorkRequestLoading(false);
      });
  };

  const isMessageReplied = useMemo(() => {
    return workRequest?.threadState?.description === ResponseStatusType.Replied;
  }, [workRequest]);

  return (
    <AccordionItem
      data-testid="message-settings"
      titleContent={
        <x.div {...titleContent}>
          <Typography variant="body-large-em">Message Settings</Typography>
          <x.div {...headerSlot} data-testid="header-slot">
            <Pill isInteractive={false} size="small">
              <FormattedDateTime date={deadline} format="d LLL yyyy, hh:mm" />
            </Pill>
            <Pill isInteractive={false} size="small">
              {formatTimeInHoursAndMinutes(maximumTimeAllowed)}
            </Pill>
          </x.div>
        </x.div>
      }
    >
      <x.div {...messageSettingsFields}>
        <div>
          <WorkRequestMaxDuration
            labelWithRequired
            allowOnlyIncrease
            showCallGuideOption={false}
            value={maximumTimeAllowed}
            disabled={isMessageReplied}
            onChange={setSelectedMaximumTimeAllowed}
          />
        </div>
        <div>
          <WorkRequestDeadline
            labelWithRequired
            value={deadline}
            disabled={isMessageReplied}
            onChange={setSelectedDeadline}
          />
        </div>
      </x.div>
      <Button
        data-testid="message-settings-confirm-changes-button"
        onClick={onUpdateWorkRequest}
        loading={updateWorkRequestLoading}
        disabled={isMessageReplied}
        {...messageSettingsButton}
      >
        Confirm Changes
      </Button>
    </AccordionItem>
  );
};
