import React, { useCallback, useEffect, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useMutation } from "react-query";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { PrimersHeaderActionBar } from "pages/AlphaNowPage/primers/components";
import useLinkableSection from "../hooks/useLinkableSection";
import { CitationVisibility, useCitationTrackingContext } from "components/CitationContext/CitationTrackingContext";
import { CompetitorDynamics, Outlook, Overview, Segmentation, SizeAndGrowth } from "./Sections";
import { useCitationContext } from "components/CitationContext/CitationContext";
import isEmpty from "lodash/isEmpty";
import { CurrentScrollAreaDataArgs, handleScroll, logTimeSpent } from "pages/AlphaNowPage/utils/logHit";
import OverlayAlert, { AlertContainer } from "pages/AlphaNowPage/components/OverlayAlert/OverlayAlert";
import { AlphaNowBanner, AlphaNowSpinner } from "pages/AlphaNowPage/components";
import { ArrowLeft } from "@alphasights/alphadesign-icons";
import { usePrimersStore } from "../state/store";
import { useMarketPrimersStore } from "./state/store";
import PrepayWallPage from "./prePaywall/PrepayWallPage";
import { getKeywordQuery } from "pages/AlphaNowPage/utils";
import { contentService } from "services/content";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { AlphaNowContentType, ContentPurchaseStatus, AlphaNowSRMProductType } from "@alphasights/client-portal-shared";
import { ContentApprovalStatus } from "models/AlphaShield";
import { SearchTerm } from "models/content/Search";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { mergeMentionsPrimerWithActualPrimer } from "./utils/utils";
import { getAnchorCompanyIds } from "content/AlphaNow/utils";
import useModal from "hooks/useModal";
import { CommissionFooter, CommissionModal } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";
import { primersHeaderActionTabs } from "../utils/constants";
import SectionWrapper from "../CompanyPrimer/versions/v3/sections/styled";
import useGetModalFromUrl from "../hooks/useGetModalFromUrl";
import { COMMISSION_URL_PARAM } from "../CommissionPrimer/constants";

type MarketPrimersPageProps = {
  selectedContent: any;
  price: number;
  alphaNowSearchQuery?: SearchTerm[];
  isSidebarExpanded: boolean;
  contentError: string;
  showNavigationOnlyOnHeader: boolean;
  onContentErrorChanged: () => void;
  onPurchasedContentChanged: () => void;
};

const MarketPrimersPage = ({
  selectedContent,
  price,
  alphaNowSearchQuery = [],
  isSidebarExpanded,
  contentError,
  showNavigationOnlyOnHeader = false,
  onContentErrorChanged,
  onPurchasedContentChanged,
}: MarketPrimersPageProps) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();
  const { logHit } = useTrackUserAction();
  const { contentId, setPrimerState, isAccessible, purchaseProperties } = usePrimersStore();

  const {
    sizeAndGrowth,
    setCompetitorDynamics,
    setOverview,
    setSegmentation,
    setSizeAndGrowth,
    setOutlook,
    setCompanies,
    setCompaniesV2,
  } = useMarketPrimersStore();
  const sectionsRefs = [
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
  ];
  const [primer, setPrimer] = useState(selectedContent);

  const sectionsContainerRef = useRef<HTMLDivElement | null>(null);
  const { setRefs: overviewRef, inView: overviewInView } = useLinkableSection({
    sectionRef: sectionsRefs[0],
    root: sectionsContainerRef,
  });
  const { setRefs: sizeAndGrowthRef, inView: sizeAndGrowthInView } = useLinkableSection({
    sectionRef: sectionsRefs[1],
    root: sectionsContainerRef,
  });
  const { setRefs: competitorDynamicsRef, inView: competitorDynamicsInView } = useLinkableSection({
    sectionRef: sectionsRefs[2],
    root: sectionsContainerRef,
  });
  const { setRefs: segmentationRef, inView: segmentationInView } = useLinkableSection({
    sectionRef: sectionsRefs[3],
    root: sectionsContainerRef,
  });
  const { setRefs: outlookRef, inView: oulookInView } = useLinkableSection({
    sectionRef: sectionsRefs[4],
    root: sectionsContainerRef,
  });
  const {
    isVisible: isCommissionModalVisible,
    onOpen: onOpenCommissionModal,
    onClose: onCloseCommissionModal,
  } = useModal();
  useGetModalFromUrl(onOpenCommissionModal, COMMISSION_URL_PARAM);
  const { selectedSpeakerIds } = useCitationContext();
  const currentUser = useCurrentUser();
  // @ts-ignore
  const userId = currentUser?.id;
  const [currentMenuItem, setCurrentMenuItem] = useState(0);
  const [isExpertsView] = useState(false);
  const isSpeakerSelected = !isEmpty(selectedSpeakerIds);
  const purchasePropertiesOnHold =
    purchaseProperties.purchaseStatus === ContentPurchaseStatus.NA ||
    purchaseProperties.approvalStatus === ContentApprovalStatus.NA;
  //TODO  expertView and PreviewAlert logic after MarketPrimerBeta

  const [, setShouldShowPreviewAlert] = useState(!isAccessible);
  const handleNavigationClick = (index: number): void => {
    sectionsRefs[index].current?.scrollIntoView({ behavior: "smooth" });
  };
  const maxScrollPercentage = useRef(0);

  const searchInPrimerMutation = useMutation(
    ({ contentId, keywords }: { contentId: string; keywords: string[] }) =>
      contentService.fetchMarketPrimerWithMentions(contentId, keywords),
    {
      onSuccess: (res) => {
        setPrimer(mergeMentionsPrimerWithActualPrimer(primer, res.marketPrimer));
      },
    }
  );

  const {
    isAnyCitationVisible,
    closestCitationState: { element: closestCitationElement, visibility: closestCitationVisibility },
    registerScrollContainer,
  } = useCitationTrackingContext();

  const shouldShowCitationTrackingAlert =
    !isExpertsView && !isAnyCitationVisible && isSpeakerSelected && Boolean(closestCitationElement);

  useEffect(() => {
    if (alphaNowSearchQuery && selectedContent) {
      const keywords = getKeywordQuery(alphaNowSearchQuery, selectedContent.companies).map(
        (keyword: { value: any }) => keyword.value
      );

      if (keywords.length > 0 && isAccessible) {
        searchInPrimerMutation.mutate({ contentId: selectedContent.id, keywords });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alphaNowSearchQuery, isAccessible]);

  useEffect(() => {
    if (primer) {
      const {
        companyNameIdToCDSCompanyNamesMap,
        mapOfCompanyNameToLogoLinks,
        overview,
        sizeAndGrowth,
        competitorDynamics,
        segmentation,
        speakers,
        outlook,
        id: contentId,
        lastUpdatedAt,
        approvalStatus,
        purchaseStatus,
        downloadFileName,
      } = primer;

      setOverview(overview);
      setSizeAndGrowth(sizeAndGrowth);
      setCompetitorDynamics(competitorDynamics);
      setSegmentation(segmentation);
      setOutlook(outlook);
      setCompanies(companyNameIdToCDSCompanyNamesMap ?? {});
      setCompaniesV2(mapOfCompanyNameToLogoLinks ?? {});

      setPrimerState({
        primerTitle: overview.marketPrimerTitle.value,
        lastUpdatedAt,
        contentId,
        isAccessible: isContentAccessible(purchaseStatus, approvalStatus),
        purchaseProperties: { price, approvalStatus, purchaseStatus },
        speakers,
        downloadFileName: downloadFileName ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primer]);

  useEffect(() => {
    if (overviewInView) setCurrentMenuItem(0);
    else if (sizeAndGrowthInView) setCurrentMenuItem(1);
    else if (competitorDynamicsInView) setCurrentMenuItem(2);
    else if (segmentationInView) setCurrentMenuItem(3);
    else if (oulookInView) setCurrentMenuItem(4);
    else setCurrentMenuItem(0);
  }, [overviewInView, sizeAndGrowthInView, competitorDynamicsInView, segmentationInView, oulookInView]);

  useEffect(() => {
    registerScrollContainer(sectionsContainerRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionsContainerRef]);

  const handleScrollWrapper = useCallback(() => {
    handleScroll(
      (newMaxScrollPercentage: number) => (maxScrollPercentage.current = newMaxScrollPercentage),
      maxScrollPercentage.current,
      sectionsContainerRef.current as CurrentScrollAreaDataArgs
    );

    if (sectionsContainerRef.current) {
      const { scrollTop, scrollHeight } = sectionsContainerRef.current;
      // hide once 10% of the primer content has been scrolled
      if (scrollTop > 0.1 * scrollHeight) {
        setShouldShowPreviewAlert(false);
      } else {
        setShouldShowPreviewAlert(true);
      }
    }
  }, [maxScrollPercentage, sectionsContainerRef]);

  useEffect(() => {
    const current = sectionsContainerRef.current;
    current?.addEventListener("scroll", handleScrollWrapper);

    return () => {
      current?.removeEventListener("scroll", handleScrollWrapper);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionsContainerRef.current, handleScrollWrapper]);

  useEffect(() => {
    setPrimer(selectedContent);
  }, [selectedContent]);

  useEffect(() => {
    if (contentId) {
      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowOpenCompanyPrimer,
        details: { contentId: contentId, userId },
      });

      const startTime = Date.now();
      const action = HitAction.alphaNowTimeSpentOnCompanyPrimer;
      return () => logTimeSpent(startTime, { contentId, userId: String(userId) }, logHit, action);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId]);

  if (purchasePropertiesOnHold)
    return (
      <x.div flex="1" mt={inner.base06} ref={sectionsContainerRef}>
        <AlphaNowSpinner />
      </x.div>
    );

  return (
    <x.div
      data-testid="market-primers-page"
      h="100%"
      position="relative"
      display="flex"
      flexDirection="column"
      overflowY="hidden"
    >
      {!isAccessible && (
        <x.div ref={sectionsContainerRef} h="100%">
          <PrepayWallPage
            onPurchasedContentChanged={onPurchasedContentChanged}
            onContentErrorChanged={onContentErrorChanged}
            onReturnToMobileSearch={() => {}}
            id={contentId}
            contentType={selectedContent?.contentType}
            companyIds={getAnchorCompanyIds(primer.companies ?? [])}
            productType={AlphaNowSRMProductType.marketPrimer}
            status={selectedContent?.status}
            market={primer.market}
          />
        </x.div>
      )}
      <PrimersHeaderActionBar
        companyIds={getAnchorCompanyIds(primer.companies ?? [])}
        market={primer.market}
        currentTabIndex={currentMenuItem}
        handleNavigationClick={handleNavigationClick}
        showNavigationOnly={showNavigationOnlyOnHeader}
        tabs={primersHeaderActionTabs.marketPrimer}
        productType={AlphaNowSRMProductType.marketPrimer}
      />

      <x.div
        display="flex"
        flexDirection="column"
        overflow="auto"
        ref={sectionsContainerRef}
        backgroundColor={color.background.surface.recessed}
      >
        <x.div p={inner.base06} display="flex" flexDirection="column" gap={inner.base08}>
          <Overview ref={overviewRef} />
          {sizeAndGrowth.data.length > 0 && (
            <SizeAndGrowth ref={sizeAndGrowthRef} isSidebarExpanded={isSidebarExpanded} />
          )}
          <CompetitorDynamics ref={competitorDynamicsRef} />
          <Segmentation ref={segmentationRef} />
          <Outlook ref={outlookRef} />
          <SectionWrapper px={inner.base10} py="0">
            <CommissionFooter onOpen={onOpenCommissionModal} />
          </SectionWrapper>
        </x.div>
      </x.div>
      <AlertContainer
        isVisible={shouldShowCitationTrackingAlert}
        position="absolute"
        // Pass through scroll events
        onWheel={(event) => sectionsContainerRef.current?.scrollBy({ top: event.deltaY })}
        onMouseDown={(event) => {
          event.preventDefault();

          closestCitationElement?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }}
      >
        <OverlayAlert
          // key to remount when it is becoming hidden/visible to avoid
          // seeing the rotation animation for the arrow
          keyInfo={shouldShowCitationTrackingAlert.toString()}
          isVisible={shouldShowCitationTrackingAlert}
          iconStyles={{
            transform: `rotate(${closestCitationVisibility === CitationVisibility.aboveViewport ? "90" : "270"}deg)`,
          }}
          icon={<ArrowLeft />}
          text="View expert’s insights"
        />
      </AlertContainer>
      {contentError && (
        <AlphaNowBanner
          contentType={AlphaNowContentType.srm}
          useCase={contentError}
          onClose={onContentErrorChanged}
          styles={{ position: "fixed" }}
        />
      )}
      {isCommissionModalVisible && (
        <CommissionModal productType={AlphaNowSRMProductType.marketPrimer} onClose={onCloseCommissionModal} />
      )}
    </x.div>
  );
};

export default MarketPrimersPage;
