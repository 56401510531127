import React, { useCallback, useContext, useEffect, useState } from "react";

const defaultComplianceVideo = {
  description: "Watch our video explaining interaction expectations.",
  file: null,
  thumbUrl:
    "https://embed-ssl.wistia.com/deliveries/94eb9285a1fac59299672cd6abc1d4a9ccbb1fff.jpg?image_crop_resized=320x180",
  title: "AlphaSights Compliance",
  type: "video",
  videoId: "yw3qlh3dne",
};

type ComplianceResouce = typeof defaultComplianceVideo;

export interface CurrentProjectContextState {
  complianceResources?: ComplianceResouce[];
  project?: Project;
  isLoading?: boolean;
  initialized?: boolean;
  refresh?: () => Promise<void> | undefined;
  defaultView?: string;
  updateCurrentDefaultView?: (view: string) => void;
}

export const CurrentProjectContext = React.createContext<CurrentProjectContextState>({
  complianceResources: [defaultComplianceVideo],
});

export const CurrentProjectProvider = ({
  project,
  isLoading,
  complianceResources,
  children,
  ...props
}: {
  project?: Project;
  isLoading?: boolean;
  complianceResources?: ComplianceResouce[];
  children: JSX.Element;
}) => {
  const [currentDefaultView, setCurrentDefaultView] = useState(project?.defaultView);

  useEffect(() => {
    setCurrentDefaultView(project?.defaultView);
  }, [project]);

  const updateCurrentDefaultView = useCallback((view: string) => {
    setCurrentDefaultView(view);
  }, []);

  const value = {
    project,
    isLoading,
    complianceResources: project?.complianceResources ?? [defaultComplianceVideo],
    defaultView: currentDefaultView,
    updateCurrentDefaultView,
  };

  return <CurrentProjectContext.Provider value={value} children={children} {...props} />;
};

export const useCurrentProjectContext = () => {
  const ctx = useContext(CurrentProjectContext);
  // Lazy fetch
  !ctx.initialized && ctx.refresh && ctx.refresh();
  return ctx;
};
