import { useQuery } from "react-query";
import { contentService } from "services/content";

const useContentClientSettings = () => {
  const { data: clientSettings, isLoading } = useQuery(["client-settings"], () => contentService.fetchClientSettings());

  return { clientSettings, isLoading };
};

export default useContentClientSettings;
