import {
  Expert,
  InboxResponse,
  Interaction,
  MessageResponse,
  MessageType,
  ParticipantRole,
  RequestType,
  ResponseStatusType,
  ThreadResponse,
  WorkRequestResponse,
} from "types";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { messageThreadService } from "services/messageThread";
import { interactionsToExperts } from "components/MessengerPage/helper";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { getParticipantName, setExpertsNamesThread } from "helpers/messageHelpers";
import _ from "lodash";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { ENABLE_MESSAGES_COMPLIANCE_REVIEW, useProjectBadgeContext } from "./BadgeProvider";

const setExpertsNamesInbox = (originalInboxes: InboxResponse[], experts: Expert[]) => {
  const inboxes = _.cloneDeep(originalInboxes);
  inboxes.forEach((inbox) => {
    inbox.mostRecentAdvisor.name = getParticipantName(inbox.mostRecentAdvisor, experts);
    inbox.mostRecentMessage.sender.name = getParticipantName(inbox.mostRecentMessage.sender, experts);
    inbox.threads = inbox.threads.map((thread) => setExpertsNamesThread(thread, experts));
  });
  return inboxes;
};

interface MessengerContextState {
  newMessageType?: MessageType;
  projectToken: string;
  inboxes: InboxResponse[];
  selectedInbox?: InboxResponse;
  onSelectCard: (groupId: string) => void;
  onSelectThread: (selectedThread: string) => void;
  onSendNewMessage: (messageType: MessageType | undefined, clearForm?: boolean) => void;
  experts: Expert[];
  onSubmitMessage: (params: any, attachments?: null) => Promise<void>;
  isSettingsOpened: boolean;
  setIsSettingsOpened: (isCollapsed: boolean) => void;
  adjustDeadlineExperts: string[];
  onOpenSettings: (expertIds: string[], addExpertsMode?: boolean) => void;
  onSubmitReply: (threadId: string, params: any) => Promise<MessageResponse>;
  onReplyCreated: (threadId: string, message: MessageResponse) => void;
  inboxLoading: boolean;
  maximumTaskDuration: number | undefined;
  setMaximumTaskDuration: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedExperts: Expert[];
  setSelectedExperts: React.Dispatch<React.SetStateAction<Expert[]>>;
  onSendNewMessageFromTableView: Function;
  settingsAddExpertsMode: boolean;
  onUpdateWorkRequest: (params: any) => Promise<WorkRequestResponse[]>;
  onUpdatedWorkRequestSuccess: (workRequestResponses: WorkRequestResponse[]) => void;
  onAddExperts: (workRequestId: string, params: any) => Promise<ThreadResponse[]>;
  onAddExpertsSuccess: (threadResponses: ThreadResponse[]) => void;
  isAdvisorFlyoutOpened: boolean;
  onSelectExpert: (expertId: string) => void;
  selectedExpertId?: string | null;
  isFlyoutOpened: boolean;
  onCloseAdvisorFlyout: () => void;
}

export const MessengerContext = React.createContext<undefined | MessengerContextState>(undefined);

export const MessengerProvider = ({
  projectToken,
  interactions = [],
  messageService = messageThreadService,
  newMessageType: newMessageTypeInput,
  children,
  onSendNewMessageFromTableView,
  interactionsLoading = false,
  isAdvisorFlyoutOpened = false,
  onCloseFlyout,
  messagesEnabled = true,
  ...props
}: {
  projectToken: string;
  interactions?: Interaction[];
  messageService?: typeof messageThreadService;
  newMessageType?: MessageType;
  children: JSX.Element;
  onSendNewMessageFromTableView: Function;
  interactionsLoading?: boolean;
  isAdvisorFlyoutOpened: boolean;
  onCloseFlyout: () => void;
  messagesEnabled?: boolean;
}) => {
  const user = useCurrentUser();
  const { hasProjectBadge } = useProjectBadgeContext();
  const [inboxLoading, setInboxLoading] = useState(true);
  const [inboxes, setInboxes] = useState<InboxResponse[]>([]);
  const [selectedInboxId, setSelectedInboxId] = useState<string | undefined>();
  const [newMessageType, setNewMessageType] = useState<MessageType | undefined>(newMessageTypeInput);
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);
  const [adjustDeadlineExperts, setAdjustDeadlineExperts] = useState<string[]>([]);
  const [maximumTaskDuration, setMaximumTaskDuration] = useState<number>();
  const [selectedExperts, setSelectedExperts] = useState<Expert[]>([]);
  const [settingsAddExpertsMode, setSettingsAddExpertsMode] = useState(false);
  const [selectedExpertId, setSelectedExpertId] = useState<string | null>();

  const experts = useMemo(() => interactionsToExperts(interactions, true), [interactions]);

  const needComplianceReview = hasProjectBadge(ENABLE_MESSAGES_COMPLIANCE_REVIEW);

  const { bulkLogHit } = useTrackUserAction();

  useEffect(() => {
    if (!isAdvisorFlyoutOpened) {
      setSelectedExpertId(null);
    }
  }, [isAdvisorFlyoutOpened]);

  const updateInbox = useCallback((updatedInbox: InboxResponse) => {
    setInboxes((prevInboxes) =>
      prevInboxes.map((inbox) => (inbox.groupId === updatedInbox.groupId ? updatedInbox : inbox))
    );
  }, []);

  const onOpenSettings = (expertIds: string[], addExpertsMode: boolean = false) => {
    if (expertIds && expertIds.length > 0) {
      setAdjustDeadlineExperts(expertIds);
    }
    setIsSettingsOpened(true);
    setSettingsAddExpertsMode(addExpertsMode);
    onCloseAdvisorFlyout();
  };

  const loadInboxThreads = useCallback(() => {
    setInboxLoading(true);
    return messageService
      .fetchThreadsClientInbox(projectToken)
      .then((inboxes) => {
        setInboxes(inboxes);
        return inboxes;
      })
      .catch(() => {
        return [];
      })
      .finally(() => setInboxLoading(false));
  }, [messageService, projectToken]);

  useEffect(() => {
    if (messagesEnabled && user) {
      loadInboxThreads();
    }
  }, [loadInboxThreads, messagesEnabled, user]);

  const onSelectThread = useCallback(
    (selectedThread: string) => {
      setNewMessageType(undefined);
      setSelectedInboxId(
        inboxes.find((inbox) => inbox.threads.find((thread) => thread.id === selectedThread))?.groupId
      );
    },
    [inboxes]
  );

  const onSelectCard = useCallback(
    (groupId: string) => {
      setNewMessageType(undefined);
      if (selectedInboxId) {
        const selectedInbox = inboxes.find((inbox) => inbox.groupId === selectedInboxId)!;
        selectedInbox.mostRecentMessage.isRead = true;
      }
      const newSelectedInbox = inboxes.find((inbox) => inbox.groupId === groupId);
      if (newSelectedInbox) {
        setSelectedInboxId(newSelectedInbox.groupId);
        messageService.fetchThread(projectToken, undefined, groupId).then(() => {
          const isMostRecentUnread =
            !newSelectedInbox?.mostRecentMessage.isRead && newSelectedInbox?.mostRecentMessage.sender.role !== "client";
          const isClarificationOrWorkRequest =
            newSelectedInbox?.requestType === RequestType.Clarification ||
            newSelectedInbox?.requestType === RequestType.WorkRequest;
          if (isMostRecentUnread && isClarificationOrWorkRequest) {
            const expertsWithUnreadMessages = newSelectedInbox.threads.flatMap((thread) =>
              thread.messages
                .filter((message) => message.sender.role === "advisor" && message.isRead === false)
                .map((message) => message.sender)
            );
            const readLogHits = expertsWithUnreadMessages.map((expert) => ({
              projectToken: projectToken,
              origin: HitOrigin.messagesPage,
              action:
                newSelectedInbox?.requestType === RequestType.Clarification
                  ? HitAction.readClarificationResponse
                  : HitAction.readWorkRequestResponse,
              details: {
                groupId,
                expertName: expert.name,
              },
              references: {
                expertId: expert.id,
              },
            }));
            bulkLogHit(readLogHits);
          }
        });

        newSelectedInbox?.threads.forEach((thread) => {
          thread.messages
            .filter((message) => !message.isRead)
            .forEach((message) => {
              void messageService.markMessageAsRead(projectToken, message.id);
            });
        });
      }
    },
    [inboxes, projectToken, messageService, selectedInboxId, bulkLogHit]
  );

  const onSendNewMessage = useCallback((messageType: MessageType | undefined, clearForm = false) => {
    clearForm && setSelectedExperts([]);
    clearForm && setMaximumTaskDuration(undefined);
    setSelectedInboxId(undefined);
    setNewMessageType(messageType);
  }, []);

  const onSubmitMessage = useCallback(
    (params: any, attachments = null) => {
      const onSubmitFunction =
        newMessageType === MessageType.CallGuide ? messageService.createCallGuide : messageService.createThreads;
      return onSubmitFunction(projectToken, params, attachments).then(() =>
        loadInboxThreads().then((newInboxes) => {
          setNewMessageType(undefined);
          setSelectedInboxId(newInboxes[0]?.groupId);
        })
      );
    },
    [newMessageType, messageService, projectToken, loadInboxThreads]
  );

  const onSubmitReply = useCallback(
    (threadId: string, params: any) => messageService.createMessage(projectToken, threadId, params),
    [messageService, projectToken]
  );

  const onReplyCreated = (threadId: string, message: MessageResponse) => {
    const updatedSelectedInbox = inboxes.find((inbox) => inbox.groupId === selectedInboxId);
    updatedSelectedInbox?.threads.find((thread) => thread.id === threadId)?.messages.push(message);
    updateInbox({ ...updatedSelectedInbox! });
  };

  const onUpdateWorkRequest = useCallback(
    (params: any) => messageService.batchUpdateWorkRequest(projectToken, params),
    [messageService, projectToken]
  );

  const onUpdatedWorkRequestSuccess = (workRequestResponses: WorkRequestResponse[]) => {
    const updatedSelectedInbox = inboxes.find((inbox) => inbox.groupId === selectedInboxId)!;

    workRequestResponses.forEach((workRequestResponse) => {
      const threadIndex = updatedSelectedInbox.threads.findIndex(
        (thread) => thread.workRequest?.id === workRequestResponse.id
      );

      updatedSelectedInbox.threads[threadIndex] = {
        ...updatedSelectedInbox.threads[threadIndex],
        workRequest: {
          ...(updatedSelectedInbox.threads[threadIndex].workRequest as WorkRequestResponse),
          deadline: workRequestResponse.deadline,
          maximumTimeAllowed: workRequestResponse.maximumTimeAllowed,
        },
      };
    });

    updateInbox({ ...updatedSelectedInbox! });
  };

  const onAddExpertsSuccess = (threadResponses: ThreadResponse[]) => {
    const updatedSelectedInbox = inboxes.find((inbox) => inbox.groupId === selectedInboxId)!;

    updatedSelectedInbox.threads.push(...threadResponses);

    updateInbox({ ...updatedSelectedInbox! });
  };

  const onAddExperts = useCallback(
    (threadId: string, params: any) => messageService.addExperts(projectToken, threadId, params),
    [messageService, projectToken]
  );

  const onSelectExpert = useCallback((expertId: string) => {
    setSelectedExpertId(expertId);
    setIsSettingsOpened(false);
  }, []);

  const onCloseAdvisorFlyout = useCallback(() => {
    setSelectedExpertId(null);
    onCloseFlyout();
  }, [onCloseFlyout]);

  const isFlyoutOpened = useMemo(() => isAdvisorFlyoutOpened || isSettingsOpened, [
    isAdvisorFlyoutOpened,
    isSettingsOpened,
  ]);

  const processsedInboxes = useMemo(() => setExpertsNamesInbox(filterRemovedExperts(inboxes, experts), experts), [
    experts,
    inboxes,
  ]);

  useEffect(
    function setDefaultNewMessageTypeIfNoInboxes() {
      if (processsedInboxes.length === 0 && !inboxLoading) {
        setNewMessageType(
          (prevValue) => prevValue ?? (needComplianceReview ? MessageType.Clarification : MessageType.WorkRequest)
        );
      }
    },
    [processsedInboxes.length, inboxLoading, needComplianceReview]
  );

  const context = {
    newMessageType,
    projectToken,
    inboxes: processsedInboxes,
    selectedInbox: processsedInboxes.find((inbox) => inbox.groupId === selectedInboxId),
    onSelectCard,
    onSelectThread,
    onSendNewMessage,
    onSendNewMessageFromTableView,
    experts,
    onSubmitMessage,
    isSettingsOpened,
    setIsSettingsOpened,
    adjustDeadlineExperts,
    onOpenSettings,
    onSubmitReply,
    onReplyCreated,
    inboxLoading: messagesEnabled && (inboxLoading || interactionsLoading),
    maximumTaskDuration,
    setMaximumTaskDuration,
    selectedExperts,
    setSelectedExperts,
    settingsAddExpertsMode,
    onUpdateWorkRequest,
    onUpdatedWorkRequestSuccess,
    onAddExperts,
    onAddExpertsSuccess,
    isAdvisorFlyoutOpened,
    onSelectExpert,
    selectedExpertId,
    isFlyoutOpened,
    onCloseAdvisorFlyout,
  };

  return <MessengerContext.Provider value={context} children={children} {...props} />;
};

const findMostRecentMessage = (threads: ThreadResponse[], roleFilter?: ParticipantRole) =>
  threads
    .filter((thread) => !roleFilter || thread.advisor.role === roleFilter)
    .flatMap((thread) => thread.messages)
    .sort((a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime())[0];

const filterRemovedExperts = (inboxes: InboxResponse[], experts: Expert[]): InboxResponse[] => {
  if (experts.length === 0) return inboxes;

  const expertsIds = experts.map((e) => e.id);
  const deletedExpertsIds = inboxes
    .flatMap((i) => i.threads.map((t) => t.advisor.id))
    .filter((expertId) => !expertsIds.includes(expertId));
  return inboxes
    .map((inbox) => {
      const filteredThreads = inbox.threads.filter((thread) => !deletedExpertsIds.includes(thread.advisor.id));
      return {
        ...inbox,
        threads: filteredThreads.sort((a, b) => priority.indexOf(a.status) - priority.indexOf(b.status)),
        mostRecentAdvisor:
          filteredThreads.length && deletedExpertsIds.includes(inbox.mostRecentAdvisor.id)
            ? findMostRecentMessage(filteredThreads, ParticipantRole.Advisor).sender
            : inbox.mostRecentAdvisor,
        mostRecentMessage:
          filteredThreads.length && deletedExpertsIds.includes(inbox.mostRecentMessage.sender.id)
            ? findMostRecentMessage(filteredThreads)
            : inbox.mostRecentMessage,
      };
    })
    .filter((inbox) => inbox.threads.length);
};

export const useMessengerContext = () => {
  const context = useContext(MessengerContext);

  if (!context) throw new Error("MessagesContext should only be used within the MessagesProvider");

  return context;
};

export const priority = [
  ResponseStatusType.PendingUpdate,
  ResponseStatusType.AwaitingApproval,
  ResponseStatusType.AwaitingResponse,
  ResponseStatusType.Sent,
  ResponseStatusType.Replied,
  ResponseStatusType.ExpiredAwaitingApproval,
  ResponseStatusType.Expired,
  ResponseStatusType.Declined,
  ResponseStatusType.Cancelled,
  ResponseStatusType.Closed,
];
