import React, { FC, useCallback } from "react";
import {
  Button,
  Divider,
  ListOptionProps,
  SelectItem,
  SelectMenu,
  SelectOption,
  SelectValue,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { WATCHLIST_FREQUENCY_DISPLAY_NAME } from "../consts";
import { Add } from "@alphasights/alphadesign-icons";
import { DEFAULT_WATCHLIST_NAME, INITIAL_WATCHLIST_DATA, INITIAL_WATCHLIST_PAYLOAD } from "hooks/useWatchlist/consts";
import useWatchlist, { PlaceholderWatchlistDataProps, WatchlistDataProps } from "hooks/useWatchlist";
import { getInitialWatchlistData } from "hooks/useWatchlist/utils";
import { getSelectedWatchlist } from "components/WatchlistModal/utils";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

const DataTestIds = {
  watchlistSelect: "watchlist-select",
  watchlistOption: "watchlist-option",
  createNewWatchlistOption: "create-new-watchlist-option",
};

interface WatchlistSelectProps {
  availableWatchlists: WatchlistDataProps[];
  watchlistId?: string;
  onChange: (id?: string) => void;
}

const WatchlistSelect: FC<WatchlistSelectProps> = ({ availableWatchlists, watchlistId, onChange }) => {
  const {
    spacing: { inner },
    font,
  } = useThemeTokens();
  const { createWatchlist } = useWatchlist();
  const { logHit } = useTrackUserAction();
  const hasWatchlists = availableWatchlists.length > 0;
  const defaultWatchlists = hasWatchlists
    ? availableWatchlists
    : ([INITIAL_WATCHLIST_PAYLOAD] as WatchlistDataProps[] | PlaceholderWatchlistDataProps[]);

  const renderSelectedWatchlistInput = useCallback(
    (selectedItems: SelectItem[]) => {
      const watchlistId = selectedItems[0]?.value;
      const selectedWatchlist = hasWatchlists
        ? getSelectedWatchlist(availableWatchlists, watchlistId as string)
        : INITIAL_WATCHLIST_DATA;

      return (
        <>
          <Typography component="span" variant="body-small">
            {selectedWatchlist?.name}
          </Typography>
          <Typography component="span" variant="body-small" color="secondary" pl="2px">
            • {WATCHLIST_FREQUENCY_DISPLAY_NAME[selectedWatchlist?.frequency]}
          </Typography>
        </>
      );
    },
    [availableWatchlists, hasWatchlists]
  );

  const logCreateWatchlist = (watchlistId: string) =>
    logHit({
      origin: HitOrigin.watchlist,
      action: HitAction.createWatchlist,
      details: {
        watchlistId: watchlistId,
      },
    });

  const handleCreateWatchlist = async () => {
    const newWatchlistData = getInitialWatchlistData(defaultWatchlists);
    let res;
    try {
      // on first creation, adds "New Watchlist" and "New Watchlist 1"
      res = await createWatchlist();
      logCreateWatchlist(res?.id);

      if (!hasWatchlists) {
        res = await createWatchlist(newWatchlistData);
        logCreateWatchlist(res?.id);
      }
    } catch (e) {
      console.error(`Failed to create a Watchlist: ${e}`);
    }
  };

  const handleSelectWatchlist = (value: SelectValue | SelectValue[]) => {
    hasWatchlists && onChange(value as string);
  };

  return (
    <SelectMenu
      isClearButtonEnabled={false}
      size="small"
      customSelectedItemRendererInInput={renderSelectedWatchlistInput}
      dataAttributes={{ "data-testid": DataTestIds.watchlistSelect }}
      maxHeight="300px"
      value={watchlistId ?? INITIAL_WATCHLIST_DATA.name}
      onChange={handleSelectWatchlist}
    >
      {defaultWatchlists.map(({ id, name, frequency }, index) => (
        <SelectOption
          key={index}
          label={name}
          value={id ?? INITIAL_WATCHLIST_DATA.name}
          data-testid={DataTestIds.watchlistOption}
          listOptionAccessories={
            {
              type: "text",
              secondaryText: WATCHLIST_FREQUENCY_DISPLAY_NAME[frequency],
              secondaryTextMarginLeft: "auto",
            } as ListOptionProps
          }
        >
          <Typography variant="body-small">{name}</Typography>
        </SelectOption>
      ))}
      <>
        <Divider m={inner.base} />
        <SelectOption disabled={true} size="small" style={{ height: inner.base08 }}>
          <Button
            data-testid={DataTestIds.createNewWatchlistOption}
            variant="ghost"
            size="small"
            startIcon={<Add />}
            fontWeight={font.weight.regular}
            onClick={handleCreateWatchlist}
          >
            {DEFAULT_WATCHLIST_NAME}
          </Button>
        </SelectOption>
      </>
    </SelectMenu>
  );
};

export { WatchlistSelect as default, DataTestIds };
