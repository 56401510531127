import React from "react";
import { ActionsWrapper, MessengerSuggestionWrapper } from "./MessengerSuggestion.styles";
import { Button, Typography } from "@alphasights/alphadesign-components";

export type MessengerSuggestionProps = {
  content?: string;
  onDeclineSuggestion?: () => void;
  onAcceptSuggestion?: () => void;
};

export const MessengerSuggestion = ({ content, onDeclineSuggestion, onAcceptSuggestion }: MessengerSuggestionProps) => {
  return (
    <MessengerSuggestionWrapper>
      <Typography variant="body-em">AlphaSights Suggestion</Typography>
      <Typography dangerouslySetInnerHTML={{ __html: content ?? "" }} />

      <ActionsWrapper>
        <Button onClick={() => onDeclineSuggestion?.()} size="small" variant="ghost">
          Decline Suggestion
        </Button>
        <Button onClick={() => onAcceptSuggestion?.()} size="small" variant="secondary">
          Accept Suggestion
        </Button>
      </ActionsWrapper>
    </MessengerSuggestionWrapper>
  );
};
