import React from "react";
import { x } from "@xstyled/styled-components";
import PropTypes from "prop-types";
import { Typography } from "@alphasights/alphadesign-components";

const RadioGroup = ({ disabledOptions, options, selectedOption, onChange }) => {
  return (
    <x.div
      display="flex"
      mt="500"
      spaceX="500"
      justifyContent="space-between"
      flexDirection={{ sm: "column", md: "row", lg: "row" }}
    >
      {Object.entries(options).map(([value, text]) => {
        const isDisabled = disabledOptions.some((x) => x === value);
        return (
          <x.label
            key={value}
            htmlFor={value}
            flex="1 1 0"
            py="400"
            px="500"
            borderColor={value === selectedOption ? "success500" : "neutral300"}
            borderWidth="2"
            borderRadius="200"
            color={isDisabled ? "neutral300" : ""}
          >
            <x.input
              checked={value === selectedOption}
              disabled={isDisabled}
              id={value}
              name="purchase"
              onChange={() => onChange(value)}
              type="radio"
              value={value}
            />
            <Typography variant="body-em" component="span" ml="500">
              {text}
            </Typography>
          </x.label>
        );
      })}
    </x.div>
  );
};

export default RadioGroup;

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  selectedOption: PropTypes.string,
};
