import { NotificationEvent, NotificationsWidget, useTrackUserAction } from "@alphasights/client-portal-shared";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import * as S from "./NotificationsOption.styled";
import { useUserNotificationsContext } from "components/GlobalNav/UserNotificationsContextProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";

type NotificationsOptionProps = {
  isSelected: boolean;
  setIsSelected: (value: boolean) => void;
  closeIntercom: () => void;
};

export const NotificationsOption = ({ isSelected, setIsSelected, closeIntercom }: NotificationsOptionProps) => {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const afterNotificationClick = () => closeIntercom();

  const afterNotificationWidgetClick = () => {
    if (isMounted.current) {
      setIsSelected(!isSelected);
    }
    closeIntercom();
  };

  return (
    <S.NotificationsWrapper isSelected={isSelected}>
      <PortalNotificationWidget
        afterNotificationWidgetClick={afterNotificationWidgetClick}
        afterNotificationClick={afterNotificationClick}
        open={isSelected}
        onPopoverClose={() => {
          if (isMounted.current) {
            setIsSelected(false);
          }
        }}
      />
    </S.NotificationsWrapper>
  );
};

export const PortalNotificationWidget = ({
  afterNotificationWidgetClick,
  afterNotificationClick,
  ...props
}: {
  afterNotificationWidgetClick?: () => void;
  afterNotificationClick?: () => void;
  open?: boolean;
  onPopoverClose?: () => void;
}) => {
  const currentUser = useCurrentUser();
  const { logHit } = useTrackUserAction();
  const history = useHistory();
  const { fetchNotifications, markAsRead, notificationsSummary } = useUserNotificationsContext();

  const onClickNotificationWidget = (currentEvents: NotificationEvent[]) => {
    const summary = notificationsSummary(currentEvents);
    logHit({
      origin: HitOrigin.globalNav,
      action: HitAction.notificationsBellClicked,
      details: {
        ...summary.details,
      },
      references: {
        ...summary.references,
      },
      decodeWithUserId: true,
    });
    afterNotificationWidgetClick && afterNotificationWidgetClick();
  };

  const onNotificationClick = (notification: NotificationEvent) => {
    logHit({
      origin: HitOrigin.globalNav,
      action: HitAction.notificationClicked,
      details: {
        notificationType: notification.type,
        notificationCreatedAt: notification.createdAt,
      },
    });
    markAsRead(notification.ids).finally(() => {
      const url = notification.data?.get("redirectUrl") as string;
      url && history.push(url);
      afterNotificationClick && afterNotificationClick();
    });
  };

  const onMarkAsRead = (ids: (number | string)[]) => {
    logHit({
      origin: HitOrigin.globalNav,
      action: HitAction.notificationMarkAllAsRead,
    });
    markAsRead(ids);
  };

  if (!fetchNotifications || currentUser?.accessOnly) return null;

  return (
    <NotificationsWidget
      onClickNotificationWidget={onClickNotificationWidget}
      onMarkAsRead={onMarkAsRead}
      fetchNotifications={fetchNotifications}
      onNotificationClick={onNotificationClick}
      {...props}
    />
  );
};
