import React from "react";
import { x } from "@xstyled/styled-components";
import { Icon, IconOnlyButton, Typography } from "@alphasights/alphadesign-components";
import { Close, Purchased } from "@alphasights/alphadesign-icons";

import { useNotificationStyles } from "./Notification.styles";

const VARIANT = {
  success: {
    iconColor: "success",
    icon: <Purchased />,
  },
};

const Notification = ({ onClose, children, iconColor, icon }) => {
  const { containerStyles, iconStyles, messageStyles, closeStyles } = useNotificationStyles();
  return (
    <x.div {...containerStyles}>
      <x.div {...messageStyles}>
        <x.div {...iconStyles}>
          <Icon size="medium" color={iconColor}>
            {icon}
          </Icon>
        </x.div>
        <Typography>{children}</Typography>
      </x.div>
      {onClose && (
        <IconOnlyButton variant="ghost" onClick={onClose} {...closeStyles}>
          <Close />
        </IconOnlyButton>
      )}
    </x.div>
  );
};

export const SuccessNotification = (props) => <Notification {...VARIANT.success} {...props} />;
