import React from "react";
import { useQuery } from "react-query";
import { contentService } from "services/content";
import {
  LandingPageContainer,
  StyledLoadingSpinner,
} from "pages/AlphaNowPage/components/LandingPage/LandingPage.styled";
import LandingPageContent from "pages/AlphaNowPage/components/LandingPage/LandingPageContent";
import LandingPageSidebar from "pages/AlphaNowPage/components/LandingPage/LandPageSidebar";

const DATA_TEST_IDS = {
  landingPage: "landing-page",
  landingPageLoadingSpinner: "landing-page-loading-spinner",
};

const LandingPage = () => {
  const { data: purchasedContentData, isLoading: isPurchasedContentLoading } = useQuery(["PurchasedContent"], () =>
    contentService.fetchContent({
      filters: {
        purchased_by_me: true,
        purchased_by_colleagues: true,
      },
    })
  );

  const { data: bookmarkedData, isLoading: isBookmarkedContentLoading } = useQuery(["BookmarkedContent"], () =>
    contentService.fetchContent({
      filters: {
        search_in_bookmarks: true,
      },
    })
  );

  const landingPageContent = {
    purchasedContent: purchasedContentData?.results ?? [],
    bookmarkedContent: bookmarkedData?.results ?? [],
    inTheNewsContent: [],
    recommendedInvestorLedContent: [],
  };

  const isLoading = isPurchasedContentLoading || isBookmarkedContentLoading;

  return (
    <LandingPageContainer data-testid={DATA_TEST_IDS.landingPage}>
      {isLoading ? (
        <StyledLoadingSpinner size="sm" />
      ) : (
        <>
          <LandingPageContent data={landingPageContent} />
          <LandingPageSidebar />
        </>
      )}
    </LandingPageContainer>
  );
};

export { LandingPage as default, DATA_TEST_IDS };
