import React from "react";
import { FormattedTimeRange } from "../../../providers/TimezoneProvider";
import { Icon } from "../../../components/alphaui";
import { Typography } from "@alphasights/alphadesign-components";

export const RequestWrapper = ({ event: { id }, children }) => {
  return <div data-testid={`request-wrapper-${id}`}>{children}</div>;
};

export const RequestEvent = ({ event }) => {
  return (
    <div className="aui-flex aui-flex-col aui-leading-tight aui-text-xs aui-relative">
      <div className="aui-absolute" style={{ top: 5, right: 17 }}>
        {event.onRemoveEvent && (
          <Icon icon="close" className="aui-text-xs aui-cursor-pointer aui-absolute" onClick={event.onRemoveEvent} />
        )}
      </div>
      <Typography variant="body-small-em" component="div" style={{ whiteSpace: "nowrap" }}>
        Team Availability
      </Typography>
      <Typography variant="body-small" component="div">
        <FormattedTimeRange start={event.start} end={event.end} />
      </Typography>
    </div>
  );
};

export const requestEventProps = () => ({
  className: "event-light-blue",
});
