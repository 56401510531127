export enum Mode {
  VIEW = "view",
  EDIT = "edit",
  NEW = "new",
}

export type SynthesisAngle = {
  id: string;
  label: string;
};

export interface SynthesisPreferences {
  showSaveAndRegeneratePrompt: boolean;
  showSavePrompt: boolean;
  savePromptSelectedOption: "save" | "saveAndDisable";
}
