import React, { createRef, useEffect, useState } from "react";
import { PortalMobileTopBar } from "../../components/PortalMobileTopBar";
import { x } from "@xstyled/styled-components";
import { Button, TextField, Typography, useAlphaToast, useThemeTokens } from "@alphasights/alphadesign-components";
import { useTimezone } from "../../providers/TimezoneProvider";
import { useClientPortalOperations } from "../../app/wrappers/ClientPortalWrapper";
import { useMyProfilePageStyles } from "./index.styles";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { MobilePasswordField } from "./MobilePasswordField";
import { MobileTimezoneSelect } from "./MobileTimezoneSelect";
import { TIMEZONE, usePreference } from "../../hooks/usePreference";
import { LoadingOverlay } from "@alphasights/client-portal-shared";

export const MobileProfilePage = () => {
  const { toast } = useAlphaToast();
  const currentUser = useCurrentUser();
  const timezone = useTimezone();
  const { logOutCurrentUser } = useClientPortalOperations();
  const [preference, { updatePreference }] = usePreference(TIMEZONE);

  const { color } = useThemeTokens();
  const { formLabelDisabled, formInputDisabled } = useMyProfilePageStyles();

  const [isLoading, setLoading] = useState(true);
  const [isEditing, setEditing] = useState(false);
  const [email, setEmail] = useState(null);
  const currentTimezone = `${timezone.currentTimezone.replace("_", " ")} (${timezone.format(new Date(), "OOOO")})`;

  const timezoneInputRef = createRef();

  useEffect(() => {
    setEmail(currentUser?.email);
    setLoading(currentUser?.email === undefined);
  }, [currentUser]);

  useEffect(() => {
    const preferenceTimezone = preference?.attributes?.timezone;

    preferenceTimezone && timezone.setCurrentTimezone(preferenceTimezone);
    if (timezoneInputRef.current) {
      timezoneInputRef.current.value = currentTimezone;
    }
  }, [preference, timezone, timezoneInputRef, currentTimezone]);

  const onPasswordChange = () => {
    toast.success({ message: "Password successfully updated" });
  };

  const handleEditing = (editMode) => {
    setEditing(editMode);
  };

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <x.div data-testid="mobile-preferences-page">
      <PortalMobileTopBar title="Preferences" />
      <x.div display="flex" flexDirection="column" m="16px" gap="12px">
        <Typography variant="body-small" color={color.text.secondary}>
          Update the fields below or email{" "}
          <a
            className="aui-no-underline aui-text-primary-1 aui-cursor-pointer"
            href="mailto:clientsupport@alphasights.com?subject=Help with AlphaSights Portal"
            target="_blank"
            rel="noopener noreferrer"
          >
            clientsupport@alphasights.com
          </a>{" "}
          to edit your information.
        </Typography>

        <x.div display="flex" flexDirection="column" gap="12px">
          <x.div display="flex" flexDirection="column" gap="8px">
            <Typography {...formLabelDisabled}>Email</Typography>
            <TextField
              dataTestId="email-form-field"
              size="small"
              value={email}
              disabled={true}
              {...formInputDisabled}
            />
          </x.div>

          <MobilePasswordField onPasswordChange={onPasswordChange} onEditing={handleEditing} />

          <MobileTimezoneSelect
            value={currentTimezone}
            onEditing={handleEditing}
            onTimezoneChanged={(timezone) => updatePreference({ timezone })}
          />
        </x.div>

        {!isEditing && (
          <x.div display="flex" w="96%" position="absolute" bottom={0} left={0} mx="8px" mb="8px">
            <Button variant="outline" w="100%" onClick={logOutCurrentUser}>
              Sign out
            </Button>
          </x.div>
        )}
      </x.div>
    </x.div>
  );
};
