import React, { FC } from "react";
import { useThemeTokens, IconButtonVariant, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { BookmarkButtonProps } from "components/BookmarkButton";
import BookmarkButton from "components/BookmarkButton";
import CopyLinkButton from "components/CopyLinkButton";
import { AlphaNowContentType, AlphaNowSRMProductType, AlphaNowProductType } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import ReturnButton from "pages/AlphaNowPage/components/ReturnButton/ReturnButton";
import ExpertsViewButton from "pages/AlphaNowPage/components/ExpertsViewButton/ExpertsViewButton";
import DownloadButton from "pages/AlphaNowPage/components/AlphaNowTranscript/DownloadButton";
import useModal from "hooks/useModal";
import useContentClientSettings from "hooks/useContentClientSettings";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { WatchlistQuickAddButton } from "pages/AlphaNowPage/components";
import { Badge } from "models/Badge";
import { useUserBadgeContext } from "providers/BadgeProvider";
import {
  CommissionModal,
  CommissionButton as CommissionPrimerButton,
} from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";

interface IconButtonProps {
  variant: IconButtonVariant;
}
interface IconButtonDeviceProps {
  mobile: IconButtonProps;
  other: IconButtonProps;
}

export const ICON_BUTTON_DEVICE_PROPS: IconButtonDeviceProps = {
  mobile: {
    variant: "ghost",
  },
  other: {
    variant: "basic",
  },
};

interface AlphaNowContentActionsProps {
  contentId: string;
  contentType: AlphaNowContentType | AlphaNowProductType;
  paymentRequired: boolean;
  bookmarkButtonProps: BookmarkButtonProps;
  isExpertsView?: boolean;
  downloadFileName: string;
  setIsExpertsView?: (value: boolean) => void;
  onReturnToMobileSearch?: () => void;
  companyIds: number[];
}

const AlphaNowContentActions: FC<AlphaNowContentActionsProps> = ({
  companyIds,
  contentId,
  contentType,
  paymentRequired,
  bookmarkButtonProps,
  isExpertsView = false,
  downloadFileName,
  setIsExpertsView = () => {},
  onReturnToMobileSearch = () => {},
}) => {
  const {
    spacing: { inner },
    color: {
      background: { decorative },
    },
  } = useThemeTokens();

  const { isMobile } = useCheckScreen();
  const { isVisible, onOpen, onClose } = useModal();
  const { clientSettings } = useContentClientSettings();
  // TODO [RD1-51]: Remove Watchlist Badge
  const { hasUserBadge } = useUserBadgeContext();

  const iconButtonProps = isMobile ? ICON_BUTTON_DEVICE_PROPS.mobile : ICON_BUTTON_DEVICE_PROPS.other;
  const isDownloadButtonVisible = !paymentRequired && !!clientSettings?.downloadResearch;
  const isWatchlistQuickAddButtonVisible = !isMobile && hasUserBadge(Badge.watchlist);
  const isPrimerActionButtonsVisible = isWatchlistQuickAddButtonVisible || !paymentRequired;

  return (
    <>
      <x.div
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        pr={{ sm: inner.base02 }}
        mb={{ xs: inner.base04, sm: 0 }}
      >
        {isMobile && (
          <ReturnButton onReturn={onReturnToMobileSearch} style={{ ml: `-${inner.base03}` }} {...iconButtonProps} />
        )}
        <x.div display="inline-flex" alignItems="center">
          <x.div display="inline-flex" gap={{ sm: inner.base04 }} h={{ sm: inner.base05 }}>
            <CopyLinkButton contentId={contentId} contentType={contentType} {...iconButtonProps} />
            <BookmarkButton permissions={[MANAGE_CONTENT_PERMISSION]} {...bookmarkButtonProps} {...iconButtonProps} />
            {isDownloadButtonVisible && (
              <DownloadButton
                contentId={contentId}
                contentType={contentType}
                downloadFileName={downloadFileName}
                {...iconButtonProps}
              />
            )}
            {isMobile && (
              <ExpertsViewButton
                onClick={() => setIsExpertsView(!isExpertsView)}
                isActive={isExpertsView}
                {...iconButtonProps}
              />
            )}
          </x.div>
          {isPrimerActionButtonsVisible && (
            <x.div pl={{ xs: inner.base02, sm: inner.base05 }} display="inline-flex" gap={inner.base02}>
              {isWatchlistQuickAddButtonVisible && (
                <WatchlistQuickAddButton size="medium" cdsAlphaCompanyIds={companyIds} contentId={contentId} />
              )}
              {!paymentRequired && <CommissionPrimerButton onClick={onOpen} variant="secondary" />}
            </x.div>
          )}
        </x.div>
      </x.div>
      <Typography alignItems="center" color={decorative.base02} component="div" display="flex" variant="body">
        {isVisible && <CommissionModal productType={AlphaNowSRMProductType.companyPrimer} onClose={onClose} />}
      </Typography>
    </>
  );
};

export default AlphaNowContentActions;
