import React from "react";
import { x } from "@xstyled/styled-components";
import { Typography } from "@alphasights/alphadesign-components";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";
import CitedMarkdown from "components/CitedMarkdown";

const DotLabel = ({
  dotColor,
  labelColor,
  label,
  dataTestId,
  tableEntry = false,
}: {
  dotColor: string;
  labelColor?: string;
  label?: string | CitableValue<string>;
  dataTestId?: string;
  tableEntry?: boolean;
}) => {
  return (
    <x.div cursor="pointer" display="flex" alignItems="center" data-testid={dataTestId}>
      <span
        style={{
          verticalAlign: "middle",
          display: "inline-block",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
          marginRight: "8px",
          backgroundColor: dotColor,
        }}
      ></span>
      {label && (
        <Typography component="span" color={labelColor ?? ""} variant={tableEntry ? "body-em" : "body"}>
          {typeof label === "string" ? (
            label
          ) : (
            <SensitiveDataContainer isSensitive={hasSensitiveData(label)} style={{ display: "inline-block" }}>
              <div>
                <CitedMarkdown data-testid={`${dataTestId}Description`} value={label} />
              </div>
            </SensitiveDataContainer>
          )}
        </Typography>
      )}
    </x.div>
  );
};

export default DotLabel;
