import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getFiltersFromURL, toString } from "components/InteractionsFilter/serializer";
import { flatten, mapValues, pickBy, uniq, values, zipObject } from "lodash";

export const RememberSearchParamsContext = createContext({
  withSearchParams: (path) => path,
});

const TRACKED_PATHS = ["/experts/deliverables-view", "/experts", "/calendar-view"];

const SYNC_PARAMS = {
  groups: ["/experts/deliverables-view", "/experts", "/calendar-view"],
  companies: ["/experts", "/calendar-view"],
};

export const RememberSearchParamsProvider = (props) => {
  const history = useHistory();

  const [searchParamsByPath, setSearchParamsByPath] = useState({});

  useEffect(() => {
    const match = TRACKED_PATHS.find((path) => {
      const regex = new RegExp(`\\/.*${path}/?`);

      return history.location.pathname.match(regex);
    });

    if (!match) return;

    const filters = getFiltersFromURL(history.location) ?? {};
    const paramsToSync = pickBy(SYNC_PARAMS, (paths) => paths.includes(match));
    const pathsToSync = uniq(flatten(values(paramsToSync))).filter((p) => p !== match);

    setSearchParamsByPath({
      ...searchParamsByPath,
      [match]: filters,
      ...zipObject(
        pathsToSync,
        pathsToSync.map((path) => ({
          ...(searchParamsByPath[path] ?? {}),
          ...mapValues(
            pickBy(paramsToSync, (paths) => paths.includes(path)),
            (_value, key) => filters[key]
          ),
        }))
      ),
    });
  }, [history.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const withSearchParams = (path) => {
    const search = searchParamsByPath[path];

    if (search) return path + "?f=" + encodeURIComponent(toString(search));

    return path;
  };

  const value = {
    withSearchParams,
  };

  return <RememberSearchParamsContext.Provider value={value} {...props} />;
};

export const useRememberSearchParams = () => useContext(RememberSearchParamsContext);
