import { x } from "@xstyled/styled-components";
import React, { useCallback, useEffect, useState } from "react";
import { MobileScheduleFormButton, useMobileSchedulingPanelStyles } from "./MobileSchedulingPanel.styles";
import { Button, Typography } from "@alphasights/alphadesign-components";
import { AdvisorStatus } from "../../../pages/InteractionPage/sections/InteractionHeader/InteractionHeader";
import { HighlightTextTypography } from "../../../components/AdvisorCard/HighlightText";
import { SchedulingSlots } from "../../../components/SchedulingModal/SchedulingSlots";
import { differenceInMinutes, isSameMinute } from "date-fns";
import { useCallDurationOptions } from "../../../components/SchedulingModal/CallDurationSelector";
import { localTimezone, useTimezone } from "../../../providers/TimezoneProvider";
import { useAvailabilitySlots } from "../../../pages/InteractionPage/sections/Availability/AvailabilitySelector";
import { CalendarAvailable } from "@alphasights/alphadesign-icons";
import { HitOrigin } from "@alphasights/portal-api-client";

interface SlotAvailability {
  startsAt: Date;
  endsAt: Date;
}

export interface MobileSchedulingPanelProps {
  interaction: Interaction;
  pendingRequest: any;
  requestedScheduleDate: Date;
  oneHourMinimum: boolean;
  allowNonHourAutoBook: boolean;
  allInteractions: any[];
  clientAvailability: SlotAvailability[];
  advisorAvailability: SlotAvailability[];
  previousAdvisorshipId?: number | null;
  onSubmit: (slots: any, origin: HitOrigin) => Promise<void>;
}

export const MobileSchedulingPanel = localTimezone(
  ({
    interaction,
    pendingRequest,
    requestedScheduleDate,
    oneHourMinimum = false,
    allowNonHourAutoBook = false,
    allInteractions = [],
    clientAvailability = [],
    advisorAvailability = [],
    previousAdvisorshipId = null,
    onSubmit = async (slots, origin) => {},
  }: MobileSchedulingPanelProps) => {
    const tz: any = useTimezone();
    const [selectedSlots, setSelectedSlots] = useState<any[]>([]);
    const [selectedBulkAvailability, setSelectedBulkAvailability] = useState<any>(undefined);
    const [slots, setSlots] = useState<any[]>([]);
    const [mutualSlots, setMutualSlots] = useState<any[]>([]);

    const [isRunning, setRunning] = useState(false);

    const disableSchedule = isRunning || selectedSlots.length === 0;

    const durationOptions = useCallDurationOptions({ allowNonHourAutoBook, isFollowUpCall: !!previousAdvisorshipId });
    const [expectedDuration, setExpectedDuration] = useState<number>(60);

    const { wrapper, bodyWrapper, scheduleButtonPanel, headerDescription } = useMobileSchedulingPanelStyles();

    const { availabilities, mutualAvailabilites, refetch } = useAvailabilitySlots({
      allInteractions,
      clientAvailability,
      advisorAvailability,
      interactionId: interaction.id,
      timezone: tz,
    });

    useEffect(() => {
      setSlots(availabilities);
      setMutualSlots(mutualAvailabilites);
      setSelectedSlots([]);
      setSelectedBulkAvailability(undefined);
      refetch();

      if (requestedScheduleDate) {
        const preSelectedSlot = availabilities
          .concat(mutualAvailabilites)
          .find((s) => isSameMinute(s.startsAt, requestedScheduleDate));
        onSelectSlot(preSelectedSlot);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectSlot = useCallback(
      (
        { startsAt, endsAt }: { startsAt?: Date; endsAt?: Date } = {},
        clearBulkSelected = true,
        selectedDuration = expectedDuration
      ) => {
        if (!startsAt || !endsAt) return;
        const duration = differenceInMinutes(endsAt, startsAt);
        if (duration > selectedDuration) {
          setSelectedSlots([]);
          setSelectedBulkAvailability({ startsAt, endsAt });
        } else {
          setSelectedSlots([{ startsAt, endsAt }]);
          if (clearBulkSelected) {
            setSelectedBulkAvailability(undefined);
          }
        }
      },
      [expectedDuration]
    );

    const isSlotSelected = useCallback(
      (slot: any) =>
        isSameMinute(slot?.startsAt, selectedSlots && selectedSlots[0]?.startsAt) ||
        isSameMinute(slot?.startsAt, selectedBulkAvailability?.startsAt),
      [selectedBulkAvailability, selectedSlots]
    );

    const isSlotLongEnough = useCallback(
      ({ startsAt, endsAt }: { startsAt: Date; endsAt: Date }, duration = expectedDuration) =>
        differenceInMinutes(endsAt, startsAt) >= duration,
      [expectedDuration]
    );

    const onChangeExpectedDuration = useCallback(
      (duration: number) => {
        setExpectedDuration(duration);
        const currentSelectedSlot = slots
          .concat(mutualSlots)
          .find((s) => isSlotSelected(s) && isSlotLongEnough(s, duration));
        if (currentSelectedSlot) {
          onSelectSlot(currentSelectedSlot, true, duration);
        } else {
          setSelectedSlots([]);
          setSelectedBulkAvailability(undefined);
        }
      },
      [
        isSlotSelected,
        isSlotLongEnough,
        setSelectedSlots,
        setSelectedBulkAvailability,
        onSelectSlot,
        mutualSlots,
        slots,
      ]
    );

    const onSubmitSchedule = useCallback(() => {
      setRunning(true);
      onSubmit(
        { mode: "slots", slots: selectedSlots, timezone: tz.currentTimezone },
        HitOrigin.schedulingModal
      ).then(() => setRunning(false));
    }, [onSubmit, selectedSlots, tz.currentTimezone]);

    return (
      <x.div {...wrapper} data-testid="scheduling-panel">
        <x.div {...bodyWrapper}>
          <x.div>
            <x.div>
              <AdvisorStatus pendingRequest={pendingRequest} showCalendarView={true} {...interaction} />
              <HighlightTextTypography
                highlights={interaction.doubleBlinded ? [] : interaction.highlights}
                fieldNames={[
                  "advisorships.relevant_company_name",
                  "advisorships.relevant_company_name.concat",
                  "relevantPrimaryJob.companyName",
                  "relevantPrimaryJob.companyName.concat",
                ]}
                variant="body-em"
                text={` • ${interaction.advisorCompany}`}
                {...headerDescription}
              />
              <Typography variant="body-em" {...headerDescription}>
                {" - "}
              </Typography>
              <HighlightTextTypography
                highlights={interaction.doubleBlinded ? [] : interaction.highlights}
                fieldNames={[
                  "advisorships.relevant_job_title",
                  "advisorships.relevant_job_title.concat",
                  "relevantPrimaryJob.jobTitle",
                  "relevantPrimaryJob.jobTitle.concat",
                ]}
                variant="body-em"
                text={interaction.role}
                {...headerDescription}
              />
            </x.div>
            {interaction.rolePeriod && interaction.rolePeriod.length > 0 && (
              <Typography variant="body" component="div">
                {interaction.rolePeriod}
              </Typography>
            )}
          </x.div>
          <SchedulingSlots
            slots={slots}
            mutualSlots={mutualSlots.map((s) => ({
              ...s,
              selected: isSlotSelected(s),
            }))}
            expectedDuration={expectedDuration}
            oneHourMinimum={oneHourMinimum}
            onSelectSlot={onSelectSlot}
            selectedBulkAvailability={selectedBulkAvailability}
            onChangeExpectedDuration={onChangeExpectedDuration}
            durationOptions={durationOptions}
            durationOptionValue={expectedDuration}
            isMobileView={true}
          />
        </x.div>

        <x.div {...scheduleButtonPanel}>
          <MobileScheduleFormButton>
            <FormatSlotAvailability dataRange={selectedSlots[0]} tz={tz} />
            <Button
              data-testid="schedule-button"
              variant="primary"
              disabled={disableSchedule}
              startIcon={<CalendarAvailable />}
              onClick={onSubmitSchedule}
            >
              Schedule
            </Button>
          </MobileScheduleFormButton>
        </x.div>
      </x.div>
    );
  }
);

const FormatSlotAvailability = ({ dataRange, tz }: { dataRange?: SlotAvailability; tz: any }) => {
  const { footerPeriod } = useMobileSchedulingPanelStyles();

  const topFormatted = dataRange ? tz.format(dataRange.startsAt, "EEEE d MMM,") : "";
  const bottomFormatted = dataRange
    ? tz.format(dataRange.startsAt, "HH:mm -") + tz.format(dataRange.endsAt, " HH:mm (O)")
    : "";

  return (
    <x.div {...footerPeriod}>
      <Typography variant="body">{topFormatted}</Typography>
      <Typography variant="body">{bottomFormatted}</Typography>
    </x.div>
  );
};
