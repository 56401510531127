import { Button, Divider, Icon, Portal, Typography } from "@alphasights/alphadesign-components";
import { AudioRecording, Call, MicOff, Tick } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { isIOS } from "browser";
import { accessCodeForHuman } from "components/CallMe";
import { CallAvailabilityStatus } from "components/CallMePanel/constants";
import { COUNTRY_CODES } from "constants/Country";
import { addHours, isWithinInterval } from "date-fns";
import useTwilioClient from "hooks/twilio/useTwilioClient";
import { fetch } from "hooks/useApi";
import { usePreference, TWILIO } from "hooks/usePreference";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { parseISO } from "./TimezoneProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";

export const TWILIO_CALL_METHODS = {
  DIAL_IN: {
    label: "Dial in",
    order: 0,
    enabled: () => true,
  },
  CALL_ME: {
    label: "Call me",
    order: 1,
    enabled: () => true,
  },
  WEB_CONNECT: {
    label: "WebConnect",
    order: 2,
    enabled: ({ interaction }) => ["default", "twilio"].includes(interaction.newspeakProvider),
  },
};

export const getDefaultJoinMethod = ({ interaction, joinMethod }) => {
  if (TWILIO_CALL_METHODS[joinMethod]?.enabled({ interaction })) return joinMethod;

  return "DIAL_IN";
};

const DEFAULT_PREFERENCE = {
  method: "DIAL_IN",
  countryIsoCode: "us",
  phoneNumber: "",
  ext: "",
};

export const TwilioContext = React.createContext({
  preference: DEFAULT_PREFERENCE,
  setPreference: (args) => {},
  twilioClient: null,
});

export const getUserPhone = (user) => {
  if (!user || !user.phoneNumber) return {};

  const matches = new RegExp(/(\+\d+)(-| )(.*)/).exec(user.phoneNumber);

  if (!matches) return {};
  if (matches.length !== 4) return {};

  const [match, countryCode, separator, phoneNumber] = matches; // eslint-disable-line no-unused-vars
  const countryIsoCode = COUNTRY_CODES.find(({ code }) => code === countryCode.substring(1))?.isoCode;

  if (!countryIsoCode) return {};

  return {
    countryIsoCode,
    phoneNumber,
  };
};

export const TwilioProvider = ({ children, ...props }) => {
  const user = useCurrentUser();
  const [transientPreferences, setTransientPreferences] = useState({});
  const [savedPreference, { updatePreference }] = usePreference(TWILIO);
  const twilioClient = useTwilioClient({});

  const { isMute, setIsMute, isConnected, disconnect, elapsedTime } = twilioClient;

  const preference = useMemo(() => {
    return {
      ...DEFAULT_PREFERENCE,
      ...props.twilioPreference,
      ...getUserPhone(user),
      ...(savedPreference?.attributes || {}),
      ...transientPreferences,
    };
  }, [props.twilioPreference, savedPreference, transientPreferences, user]);

  const value = useMemo(
    () => ({
      preference,
      setPreference: (preferences) => {
        if (user) {
          updatePreference(preferences);
        } else {
          setTransientPreferences(preferences);
        }
      },
      twilioClient,
    }),
    [preference, updatePreference, twilioClient, user]
  );

  useEffect(() => {
    if (!isConnected) return;

    const event = isIOS() ? "pagehide" : "beforeunload";

    const cb = (e) => {
      e.preventDefault();
      e.returnValue = "";

      const message = "Are you sure you want to disconnect?";
      e.returnValue = message;
      return message;
    };

    window.addEventListener(event, cb);

    return () => window.removeEventListener(event, cb);
  }, [isConnected]);

  return (
    <TwilioContext.Provider value={value} {...props}>
      {children}
      <WebConnectFAB
        isMute={isMute}
        setIsMute={setIsMute}
        disconnect={disconnect}
        elapsedTime={elapsedTime}
        isConnected={isConnected}
      />
    </TwilioContext.Provider>
  );
};

export const useTwilioContext = () => useContext(TwilioContext);

export const useConnection = ({ interaction, callAvailability }) => {
  return useDialIn({ interaction, dialInNumbers: [], countryIsoCode: null, callAvailability });
};

export const useDialIn = ({ interaction, dialInNumbers = [], countryIsoCode, callAvailability }) => {
  const currentNumber = useMemo(() => {
    const country = COUNTRY_CODES.find(({ isoCode }) => isoCode === countryIsoCode);
    return dialInNumbers.find((number) => number.country.includes(country?.name));
  }, [dialInNumbers, countryIsoCode]);

  return useMemo(() => {
    return {
      number: currentNumber?.number || interaction.clientAccessNumber,
      accessCode:
        accessCodeForHuman(interaction.clientAccessCode, interaction.newspeakProvider) ||
        callAvailability?.clientAccessCodeFormatted,
    };
  }, [currentNumber, interaction, callAvailability]);
};

export const useCallMe = ({ projectToken, interactionId }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const dispatch = useCallback(
    ({ phone, extension }) => {
      setError(null);
      setIsLoading(true);
      setTimeout(() => setIsLoading(false), 15000);

      const url = `/api/internal/projects/${projectToken}/calls/${interactionId}/request-call-me`;
      fetch({
        url,
        method: "POST",
        body: JSON.stringify({ phone, extension }),
        skipAlert: true,
      })
        .then(() => {})
        .catch((err) => {
          err.json().then((json) => {
            console.log(json);
            if (json.type === "INVALID_NUMBER") {
              setError({
                type: "phone",
                message: "Please check the number and try again.",
              });
            } else if (json.type === "UNSUPPORTED_COUNTRY") {
              setError({
                type: "phone",
                message: "This country does not support the 'Call Me' feature. Please use the 'Dial-in' option.",
              });
            } else {
              setError({
                type: "general",
                message:
                  "Failed to initiate the call. Please try again in a minute or reach out to your AlphaSights contact.",
              });
            }
          });
        });
    },
    [projectToken, interactionId]
  );

  return useMemo(
    () => ({
      error,
      isLoading,
      dispatch,
    }),
    [error, isLoading, dispatch]
  );
};

export const useCallAvailability = ({ scheduledCallTime, projectToken, interactionId }) => {
  const [callAvailability, setCallAvailability] = useState();

  useEffect(() => {
    if (!scheduledCallTime) return;

    const scheduled = parseISO(scheduledCallTime);
    const before = addHours(scheduled, -1);
    const after = addHours(scheduled, 2);

    if (!isWithinInterval(new Date(), { start: before, end: after })) {
      setCallAvailability({
        availability: CallAvailabilityStatus.FETCH_ERROR,
        status: "fetch_error",
      });
      return;
    }

    fetch({
      url: `/api/internal/projects/${projectToken}/calls/${interactionId}/call-availability`,
      method: "GET",
      skipAlert: true,
    })
      .then((res) => {
        if (res.status === 404) {
          setCallAvailability({
            availability: CallAvailabilityStatus.NOT_SCHEDULED,
          });
          return;
        }
        return res.json();
      })
      .then((callAvailability) => {
        setCallAvailability({
          ...callAvailability,
          clientAccessCodeFormatted: `${callAvailability.clientAccessCode}#`,
        });

        return callAvailability;
      })
      .catch(() => {
        setCallAvailability({
          availability: CallAvailabilityStatus.FETCH_ERROR,
          status: "fetch_error",
        });
      });
  }, [projectToken, scheduledCallTime, interactionId]);

  return useMemo(() => {
    return {
      callAvailability,
    };
  }, [callAvailability]);
};

const WebConnectFAB = ({ isConnected, elapsedTime, setIsMute, isMute, disconnect }) => {
  if (!isConnected) return null;

  return (
    <Portal>
      <Button
        dropdown
        splitPopoverContent={
          <x.div display="flex" flexDirection="column">
            <x.div display="flex" p="4px" pb="12px" alignItems="center">
              <Typography display="flex" color="success" gap="8px" variant="body-small" alignItems="center">
                <Icon>
                  <Tick />
                </Icon>
                Connected
              </Typography>
              <Typography color="secondary" ml="auto" variant="body-small">
                {elapsedTime}
              </Typography>
            </x.div>
            <Divider mx="-8px" />
            <x.div display="flex" p="4px" pt="12px" gap="8px">
              <Button
                variant="ghost"
                startIcon={<Icon>{isMute ? <MicOff /> : <AudioRecording />}</Icon>}
                size="small"
                p="12px 16px"
                minWidth="100px"
                onClick={() => setIsMute(!isMute)}
              >
                {isMute ? "Unmute" : "Mute"}
              </Button>
              <Button variant="outline" size="small" onClick={disconnect}>
                Disconnect
              </Button>
            </x.div>
          </x.div>
        }
        splitPopoverProps={{ placement: "top-end" }}
        variant="success"
        size="small"
        margin="30px"
        position="fixed"
        bottom={"50px"}
        right={0}
        startIcon={<Call />}
      >
        Call Active
      </Button>
    </Portal>
  );
};
