import React, { useEffect, useState } from "react";
import { Markdown } from "../alphaui";
import { parseISO, add, differenceInMinutes } from "date-fns";
import { format } from "date-fns-tz";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";

import { featuredCompaniesContent, priceInCreditsContent, topicsContent } from "../../content/AlphaViews";
import { fetch } from "../../hooks/useApi";

import { List } from "../List";

import AlphaViewDetailsModal from "./AlphaViewDetailsModal.js";
import { PURCHASE_OPTIONS } from "./constants.js";
import "./index.css";
import PurchaseOptions from "./PurchaseOptions.js";
import Speakers from "./Speakers.js";
import Title from "./Title.js";

const AlphaViewDetails = ({ alphaView, setModalOpen }) => {
  const { agenda, companies, externalTitle, price, scheduledTimeUTC, speakers } = alphaView;

  const companiesList = [companies.map((company) => company.companyName).join(", ")];

  const agendaMarkdown = () => <Markdown source={agenda} />;
  const agendaContent = [agendaMarkdown()];

  const isAfterThirtyMinutes = (eventTime) => {
    const differenceInMins = differenceInMinutes(parseISO(eventTime), new Date());
    return differenceInMins > 30;
  };

  const [isAvailableToAttend, setIsAvailableToAttend] = useState(isAfterThirtyMinutes(scheduledTimeUTC));
  const [employmentHistory, setEmploymentHistory] = useState();
  const defaultPurchaseOption = isAvailableToAttend
    ? PURCHASE_OPTIONS.CALL_AND_TRANSCRIPT
    : PURCHASE_OPTIONS.TRANSCRIPT;
  const [purchaseOption, setPurchaseOption] = useState(defaultPurchaseOption);
  const [modal, setModal] = useState({});
  const [isPurchased, setIsPurchased] = useState(false);
  // purchase request id, used to cancel the request
  const [requestId, setRequestId] = useState();

  // 2021-08-12T16:00:00.000Z --> Thursday, August 12, 2021 | 4 - 5PM GMT
  const formatDateTime = (isoTimeStr) => {
    const timeObj = parseISO(isoTimeStr);
    const formattedDate = format(timeObj, "EEEE, MMMM d, yyyy");
    const formattedTime = format(timeObj, "h - ") + format(add(timeObj, { hours: 1 }), "h b");
    return `${formattedDate} | ${formattedTime}`;
  };

  useEffect(() => {
    // modalOpen is used in UpcomingAlphaViews to disable the Flyout closeOnOutsideClick when a modal is open
    setModalOpen(!!modal.type);
  }, [modal, setModalOpen]);

  const handleModalClose = () => setModal({});

  const checkPurchaseOptionIsAvailable = (purchaseOption) => {
    // check if it is possible to attend the AV, if not we disable that option
    if (isAvailableToAttend && !isAfterThirtyMinutes(scheduledTimeUTC)) {
      setIsAvailableToAttend(false);
      setPurchaseOption(PURCHASE_OPTIONS.TRANSCRIPT);
      if (purchaseOption !== PURCHASE_OPTIONS.TRANSCRIPT) return false;
    }
    return true;
  };

  const requestAlphaView = (purchaseOption) => {
    fetch({
      body: JSON.stringify({
        type: purchaseOption,
      }),
      method: "POST",
      url: `/api/content/${alphaView.id}/orders`,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.id) {
          setRequestId(data.id);
          // TODO: does this need to bubble up to upcoming AVs?
          setIsPurchased(true);
        }
      });
  };

  const cancelRequest = () => {
    fetch({
      method: "DELETE",
      url: `/api/content/${alphaView.id}/orders/${requestId}`,
    }).then((res) => {
      if (res.ok) {
        // TODO: does this need to bubble up to upcoming AVs?
        setIsPurchased(false);
      }
    });
  };

  const onPurchase = (purchaseOption) => {
    handleModalClose();
    if (checkPurchaseOptionIsAvailable(purchaseOption)) {
      requestAlphaView(purchaseOption);
    }
  };

  const onCancel = () => {
    handleModalClose();
    cancelRequest();
  };

  return (
    <x.div pl="800">
      <Title setModal={setModal} title={externalTitle} />
      <List icon="list" title={topicsContent} content={agendaContent} />
      <List icon="companies" title={featuredCompaniesContent} content={companiesList} />
      <List icon="calendar" title={formatDateTime(scheduledTimeUTC)} />
      <List icon="credit" title={priceInCreditsContent(price)} />
      <PurchaseOptions
        alphaViewTitle={externalTitle}
        checkAvailability={checkPurchaseOptionIsAvailable}
        isAvailableToAttend={isAvailableToAttend}
        isPurchased={isPurchased}
        priceContent={priceInCreditsContent(price)}
        purchaseOption={purchaseOption}
        onCancel={onCancel}
        onClose={handleModalClose}
        onPurchase={onPurchase}
        setPurchaseOption={setPurchaseOption}
        setModal={setModal}
      />
      <Speakers setEmploymentHistory={setEmploymentHistory} setModal={setModal} speakers={speakers} />
      <AlphaViewDetailsModal
        alphaView={alphaView}
        employmentHistory={employmentHistory}
        modal={modal}
        onCancel={onCancel}
        onClose={handleModalClose}
        onPurchase={onPurchase}
        purchaseOption={purchaseOption}
      />
    </x.div>
  );
};

AlphaViewDetails.propTypes = {
  alphaView: PropTypes.shape({
    agenda: PropTypes.string,
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        companyId: PropTypes.number,
        companyName: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        id: PropTypes.number,
      })
    ),
    externalTitle: PropTypes.string,
    price: PropTypes.number,
    scheduledTimeUTC: PropTypes.string,
    speakers: PropTypes.arrayOf(
      PropTypes.shape({
        jobTitle: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  setModalOpen: PropTypes.func,
};

AlphaViewDetails.defaultProps = {
  setModalOpen: () => {},
};

export default AlphaViewDetails;
