import React, { useCallback, useEffect, useState } from "react";
import { Tabs, TabItem } from "@alphasights/alphadesign-components";
import {
  SectionWrapper,
  SectionHeader,
  SECTION_TITLES,
} from "pages/AlphaNowPage/components/AlphaNowCompanyPage/components/Section";
import { OVERVIEW } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Insights/consts";
import { InsightsSection } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Insights/types";
import useInsightsData from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Insights/useInsightsData";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import { CONTENT_TYPE } from "@alphasights/client-portal-shared";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { Citation, InsightsContent } from "@alphasights/client-portal-shared";
import { useAlphaNowQueryContext } from "../../AlphaNowQueryContext";
import { useAlphaNowContentAccessLevelContext } from "../../AlphaNowContentContext";
import { contentApi } from "@alphasights/portal-api-client";
import { useScroll } from "pages/AlphaNowPage/components/ScrollContext";

const DataTestIds = {
  Section: "insights-section",
  SectionTitle: "insights-section-title",
  ContentTypeTab: "insights-content-type-tab",
  Overview: "insights-overview",
  SectionNav: "insights-section-nav",
  SectionTab: "insights-section-tab",
  SummaryContainer: "insights-summary-container",
};

type InsightsProps = {
  companyId: number;
  companyName: string;
  onDataFetch?: (data: any) => void;
};

const Insights = ({ companyId, companyName, onDataFetch }: InsightsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [colleagues, setColleagues] = useState<{ clientContactId: number; name: string; occurrences: number }[]>([]);

  const { logHit } = useTrackUserAction();
  const {
    updateQuery,
    query: { searchQuery },
  } = useAlphaNowQueryContext();
  const { hasOnlyPremiumContent } = useAlphaNowContentAccessLevelContext();
  const { scrollToElement } = useScroll();
  // TODO [CON-3541]: Remove Private Contributor Badge
  const { hasUserBadge } = useUserBadgeContext();
  const hasRelevantColleagueBadge = hasUserBadge(Badge.privateContributor);
  const { insights, contentTypeTabs, isLoading, isDataError } = useInsightsData(companyId);
  const selectedInsights = insights?.[selectedTabIndex];
  const isPrivateLibraryTab = contentTypeTabs[selectedTabIndex]?.type === CONTENT_TYPE.privateEnterprise;
  const hasRelevantColleagueDisplayed = hasRelevantColleagueBadge && isPrivateLibraryTab;
  const isReady = !isLoading && !isDataError && selectedInsights;

  const getThemes = useCallback(() => {
    if (selectedInsights) {
      const overviewAsTheme = { ...(selectedInsights.overview ?? {}), title: OVERVIEW };
      const themes = [{ ...overviewAsTheme }, ...selectedInsights.themes];
      return themes;
    }
  }, [selectedInsights]);

  useEffect(() => {
    if (!isLoading) {
      const themes = !isDataError && selectedInsights ? getThemes() : [];
      if (onDataFetch) {
        const contentIds = themes?.[0]?.citations.map((citation: Citation) => citation.content.id) ?? [];
        const contentType = contentTypeTabs[selectedTabIndex]?.type ?? "";
        const section = themes?.[0]?.title ?? "";
        onDataFetch({ contentIds, contentType, theme: section });
      }
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    function fetchRelevantColleagues() {
      if (!companyId || !hasRelevantColleagueDisplayed) return;
      contentApi
        .getPrivateContributions(companyId)
        .then((data) => {
          setColleagues(data as any);
        })
        .catch(() => {
          setColleagues([]);
        });
    },
    [companyId, hasRelevantColleagueDisplayed]
  );

  const logSectionData = (section: InsightsSection, tabIndex?: number) => {
    const contentIds = section.citations.map((citation) => citation.content.id);
    const contentType = contentTypeTabs[tabIndex ?? selectedTabIndex].type;
    logHit({
      origin: HitOrigin.companyPage,
      action: HitAction.alphaNowViewCompanyInsights,
      details: {
        companyId,
        contentType,
        theme: section.title,
        contentIds,
      },
    });
  };

  const onContentTypeTabChange = (index: number) => {
    setSelectedTabIndex(index);
    const overviewSection = {
      ...(insights?.[index].overview ?? {}),
      title: OVERVIEW,
    };
    logSectionData(overviewSection, index);
  };

  const onCitationClick = (citationNumber: number, content: any) => {
    const citationContentId = content.id;
    updateQuery({ selectedContentId: citationContentId });
    scrollToElement();
    logHit({
      origin: HitOrigin.companyPage,
      action:
        content.contentType === CONTENT_TYPE.companyPrimer
          ? HitAction.alphaNowViewCompanyPrimer
          : HitAction.alphaNowViewTranscript,
      details: { contentId: content.id, companyId },
    });
  };

  if (!isReady) {
    return null;
  }

  return (
    <SectionWrapper data-testid={DataTestIds.Section}>
      <SectionHeader id="insights-header" title={SECTION_TITLES.Insights} companyName={companyName} />
      <Tabs onChange={onContentTypeTabChange} colorVariant="primary">
        {contentTypeTabs.map((tab, index) => (
          <TabItem
            key={index}
            dataAttributes={{ "data-testid": `${DataTestIds.ContentTypeTab}-${tab.type}` }}
            label={tab.name}
          />
        ))}
      </Tabs>
      <InsightsContent
        companyId={companyId}
        hasRelevantColleagueDisplayed={hasRelevantColleagueDisplayed}
        insight={selectedInsights}
        contentType={contentTypeTabs[selectedTabIndex]?.type ?? ""}
        hasOnlyPremiumContent={hasOnlyPremiumContent}
        onCitationClick={onCitationClick}
        onChangeSection={logSectionData}
        searchQuery={searchQuery}
        colleagues={colleagues}
      />
    </SectionWrapper>
  );
};

export { Insights as default, DataTestIds };
