import styled, { x } from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { TextField } from "@alphasights/alphadesign-components";

export const NavigationButtonWrapper = styled(x.div)`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing.inner.base04};
`;

export const TopBarWrapper = styled(x.div)`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: ${tokens.spacing.inner.base08};
  padding-right: ${tokens.spacing.inner.base02};
  padding-left: ${tokens.spacing.inner.base06};
`;

export const LeftPanel = styled(x.div)`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing.inner.base04};
  padding: ${tokens.spacing.inner.base02} 0;
  flex-grow: 1;
`;

export const RightPanel = styled(x.div)`
  display: flex;
  align-items: center;
  min-width: 756px;
  justify-content: end;
`;

export const TransparentTextField = styled(TextField)`
  max-width: 715px;
  background: rgba(255, 255, 255, 0.35);
  &:hover {
    background: rgba(255, 255, 255, 0.35);
  }
  & input::placeholder {
    color: ${tokens.color.text.inverse};
    opacity: 1;
  }
}
`;
