import React, { useCallback, useEffect, useMemo } from "react";
import useTableStyles from "./tableStyles";
import { x } from "@xstyled/styled-components";
import { AlphaTable, useAlphaTable } from "@alphasights/alphadesign-table";
import { columnDefinitions } from "./surveysColumns/ColumnDefinitions";
import { tableOptions } from "./TableOptions";
import { buildSurveyData } from "./helpers";
import _ from "lodash";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { SURVEY_TABLE_COLUMNS, usePreference } from "../../hooks/usePreference";
import { Row } from "@tanstack/react-table";
import styled from "@xstyled/styled-components";

export interface SurveyMainContentProps {
  surveyType: SurveyType;
  selectedSurveyId: string;
  selectedInteractionId: string;
  interactions: Interaction[];
  isLoading: boolean;
  onSelectCard: (interaction: Interaction) => void;
  selectedExpertIds: string[];
  setSelectedExpertIds: any;
  showCid: boolean;
}

export const SurveysMainContent = ({
  surveyType,
  selectedSurveyId,
  selectedInteractionId,
  interactions,
  isLoading,
  onSelectCard,
  selectedExpertIds,
  setSelectedExpertIds,
  showCid,
}: SurveyMainContentProps) => {
  const styles = useTableStyles();

  const { project } = useCurrentProjectContext() as any;
  const [preferences, { updatePreference }, isPrefLoading] = usePreference(SURVEY_TABLE_COLUMNS, {
    token: project.token,
  }) as any;

  const doubleBlinded = surveyType === "double_blinded";

  const surveysData = useMemo(() => {
    return buildSurveyData({
      interactions,
      selectedSurveyId,
      doubleBlinded,
    });
  }, [interactions, selectedSurveyId, doubleBlinded]);

  const columns = useMemo(() => {
    const showCallFollowUps = surveysData.some(
      (s: any) => s.followUps.length > 0 || s.interaction.state === "completed"
    );
    return columnDefinitions({ showCallFollowUps, doubleBlinded, showCid });
  }, [doubleBlinded, surveysData, showCid]);

  const onRowClicked = useCallback(
    (row: any) => {
      const {
        original: { interaction, followUps },
      } = row;
      const selected =
        followUps.length === 0
          ? interaction
          : followUps.find((it: Interaction) => it.previousAdvisorshipId && !it.followUpId);
      onSelectCard(selected);
    },
    [onSelectCard]
  );

  const rowClassRules = [
    {
      className: "selected-interaction",
      ruleFunction: (row: Row<any>) =>
        [row.original.interaction.id, ...(row.original.followUps || []).map((it: Interaction) => it.id)].indexOf(
          selectedInteractionId
        ) >= 0,
    },
  ];

  const dataIdxIdDoubleMap: { [idx: string]: any } = useMemo(
    () =>
      surveysData.reduce(
        (agg, cur, idx) => ({
          ...agg,
          [idx.toString()]: cur.interaction.advisorId,
          [cur.interaction.advisorId]: idx,
        }),
        {}
      ),
    [surveysData]
  );

  const setRowsSelected = useCallback(
    (fromTable: Map<number, boolean>) => {
      const selectedRowPositions = Object.keys(fromTable);
      const ids = selectedRowPositions.map((pos) => dataIdxIdDoubleMap[pos]);
      setSelectedExpertIds(ids);
    },
    [dataIdxIdDoubleMap, setSelectedExpertIds]
  );

  const rowsSelected = useMemo(() => {
    const selectedIdxs = selectedExpertIds.map((id) => dataIdxIdDoubleMap[id]);
    return selectedIdxs.reduce((agg, cur) => ({ ...agg, [cur]: true }), {});
  }, [dataIdxIdDoubleMap, selectedExpertIds]);

  const onColumnPinningChange = (updatedColumnPinning: any) => {
    if (
      preferences &&
      Object.keys(updatedColumnPinning).length > 0 &&
      !_.isEqual(preferences?.attributes.columnPinning, updatedColumnPinning)
    ) {
      updatePreference({ columnPinning: updatedColumnPinning });
    }
  };

  const onColumnOrderChange = (updatedColumnOrders: any) => {
    if (preferences && JSON.stringify(preferences?.attributes.columnOrder) !== JSON.stringify(updatedColumnOrders)) {
      updatePreference({ columnOrder: updatedColumnOrders });
    }
  };

  const onColumnVisibilityChange = (updatedColumnVisibilities: any) => {
    if (
      updatedColumnVisibilities &&
      Object.keys(updatedColumnVisibilities).length > 0 &&
      !_.isEqual(preferences?.attributes.columnVisibility, updatedColumnVisibilities)
    ) {
      updatePreference({ columnVisibility: updatedColumnVisibilities });
    }
  };

  const options = tableOptions({
    isLoading,
    isPrefLoading,
    onRowClicked,
    onColumnPinningChange,
    onColumnOrderChange,
    onColumnVisibilityChange,
    rowsSelected,
    setRowsSelected,
    rowClassRules,
    showSelectColumn: project.cidEnabled,
  });

  // @ts-ignore
  const table = useAlphaTable(surveysData, columns, options);

  useEffect(() => {
    if (preferences?.attributes.columnPinning) {
      table.setColumnPinning({ ...preferences.attributes.columnPinning });
    }
    if (preferences?.attributes.columnOrder) {
      table.setColumnOrder([...preferences.attributes.columnOrder]);
    }
    table.setColumnVisibility({ ...preferences?.attributes.columnVisibility });
  }, [preferences]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div data-testid="surveys-main-content" {...styles.wrapper}>
      <SelectableTableWrapper {...styles.surveysMainContent} data-testid="surveys-table">
        <AlphaTable table={table} />
      </SelectableTableWrapper>
    </x.div>
  );
};

interface SelectableTableWrapperProps {
  selectedBackgroundColor: string;
  selectedBorderColor: string;
  backgroundColor: string;
}

const SelectableTableWrapper = styled.div<SelectableTableWrapperProps>`
  display: grid;
  tbody tr {
    background-color: ${(props) => props.backgroundColor};
    &.selected-interaction {
      background-color: ${(props) => props.selectedBackgroundColor};
      border-color: ${(props) => props.selectedBorderColor};
    }
    &:hover {
      background-color: ${(props) => props.selectedBackgroundColor};
      border-color: ${(props) => props.selectedBorderColor};
    }
  }
`;
