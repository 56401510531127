import { useState, useEffect, useCallback } from "react";
import { fetch } from "./useApi";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useHistory } from "react-router-dom";
import { currentView } from "../helpers/currentView";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { HitAction } from "@alphasights/portal-api-client";

const skipAlert = true;
export const TABLE_VIEW_COLUMNS = "TABLE_VIEW_COLUMNS";
export const SURVEY_TABLE_COLUMNS = "SURVEY_TABLE_COLUMNS";
export const DEFAULT_ADVISOR_VIEW = "DEFAULT_ADVISOR_VIEW";
export const GLOBAL = "GLOBAL";
export const PROJECT = "PROJECT";
export const CALENDAR = "CALENDAR";
export const TIMEZONE = "TIMEZONE";
export const TWILIO = "TWILIO";
export const PROJECT_SYNTHESIS = "PROJECT_SYNTHESIS";

export interface Preference {
  id?: number;
  type: string;
  scope: string;
  projectToken?: string;
  attributes: { [key: string]: any };
}

const projectlize = (preference: Preference, token?: string) => {
  if (preference.scope === GLOBAL && token) {
    const { id, ...attrs } = preference;

    return {
      ...attrs,
      scope: PROJECT,
      projectToken: token,
    };
  }

  return preference;
};

export const usePreference = (type: string, { token }: { token?: string } = {}) => {
  const [preference, setPreference] = useState<Preference>();
  const isLoggedIn = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(true);
  const { logHit } = useTrackUserAction();
  const { updateCurrentDefaultView } = useCurrentProjectContext();

  const history = useHistory();
  const currentPage = currentView(history?.location?.pathname);

  useEffect(() => {
    if (!isLoggedIn) {
      setIsLoading(false);
      return;
    }
    (async () => {
      try {
        setIsLoading(true);
        const params = new URLSearchParams();
        params.append("projectToken", token || "");
        const url = `/api/auth-user/preferences/type/${type}?${params}`;
        const res = await fetch({ url, method: "GET", skipAlert });

        const loadedPreference = await res.json();
        setPreference(projectlize(loadedPreference, token));
        setIsLoading(false);
      } catch (err) {
        setPreference(
          token
            ? { type, scope: PROJECT, projectToken: token, attributes: {} }
            : { type, scope: GLOBAL, attributes: {} }
        );
        setIsLoading(false);
      }
    })();
  }, [isLoggedIn, token, type]);

  const submitPreference = useCallback(
    async (preference: Partial<Preference>) => {
      const url = "/api/auth-user/preferences";
      const body = JSON.stringify(preference);
      const res = await fetch({ url, method: "PUT", body });

      const savedPreference = await res.json();
      setPreference(projectlize(savedPreference, token));
      if (savedPreference.type === DEFAULT_ADVISOR_VIEW) {
        updateCurrentDefaultView && updateCurrentDefaultView(savedPreference.attributes.defaultView);
      }
    },
    [token, updateCurrentDefaultView]
  );

  const updatePreference = useCallback(
    (partialAttributes: { [key: string]: any }) => {
      return submitPreference({
        ...preference,
        attributes: {
          ...(preference?.attributes || {}),
          ...partialAttributes,
        },
      }).then(() => logPreferenceUpdate(preference!));
    },
    [preference, submitPreference] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const logPreferenceUpdate = (preference: Preference) => {
    const preferenceActions: { [key: string]: string } = {
      DEFAULT_ADVISOR_VIEW: "SET_DEFAULT_ADVISOR_VIEW",
      TABLE_VIEW_COLUMNS: "UPDATE_TABLE_VIEW_COLUMNS",
      CALENDAR: "CALENDAR_VIEW_PREFERENCE_UPDATE",
      TIMEZONE: "TIMEZONE_PREFERENCE_UPDATE",
    };

    const action = preferenceActions[preference.type];
    if (action) {
      logHit({
        origin: currentPage,
        action: action as HitAction,
        projectToken: token,
      });
    }
  };

  const promote = useCallback(() => {
    const { projectToken, id, ...defaults } = preference!;

    submitPreference({
      ...defaults,
      scope: GLOBAL,
    });
  }, [preference, submitPreference]);

  if (isLoggedIn) {
    return [
      preference,
      {
        submitPreference,
        updatePreference,
        promote,
      },
      isLoading,
    ];
  }
  return [
    undefined,
    {
      submitPreference: () => {},
      updatePreference: () => {},
      promote: () => {},
    },
    isLoading,
  ];
};
