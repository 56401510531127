import React from "react";
import { Icon } from "../alphaui";
import { x } from "@xstyled/styled-components";
import { Typography } from "@alphasights/alphadesign-components";
import { Copy } from "@alphasights/alphadesign-icons";
import { useComplianceResourcesStyles } from "./ComplianceResources.styles";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

const lineClamp = (lines) => ({
  display: "-webkit-box",
  WebkitLineClamp: lines,
  WebkitBoxOrient: "vertical",
});

const PlayIcon = () => (
  <svg viewBox="0 0 16.5 16.5" className="aui-fill-current aui-w-6 aui-h-6 aui-relative">
    <g>
      <path
        d="M10,1.75A8.25,8.25,0,1,0,18.25,10,8.259,8.259,0,0,0,10,1.75Zm0,15A6.75,6.75,0,1,1,16.75,10,6.758,6.758,0,0,1,10,16.75Zm2.375-6.341a.749.749,0,0,1-.219.219L9.142,12.592a.751.751,0,0,1-1.16-.628V8.036a.75.75,0,0,1,1.16-.628l3.014,1.964A.749.749,0,0,1,12.375,10.409Z"
        transform="translate(-1.75 -1.75)"
      />
    </g>
  </svg>
);

const ResourceCard = (props) => {
  switch (props.type) {
    case "video":
      return <VideoCard {...props} />;
    case "file":
      return <FileCard {...props} />;
    default:
      return <DocumentCard {...props} />;
  }
};

const Toggle = ({ expanded, onToggle }) => (
  <div
    className="aui-text-right aui-space-x-2 aui-text-grey-5 aui-cursor-pointer aui-select-none md:aui-order-last md:aui-ml-auto md:aui-pl-8"
    onClick={() => onToggle(!expanded)}
  >
    <span className="aui-font-semibold aui-whitespace-no-wrap">Compliance Resources</span>
    <Icon icon={`caret-${expanded ? "up" : "down"}`} className="aui-text-xs" />
  </div>
);

export const ComplianceResourcesOld = ({ className, resources = [], isExpanded = true, onToggleExpanded, onOpen }) => {
  const columnSize = (total) => {
    switch (total) {
      case 1:
        return "aui-w-full";
      case 2:
        return "aui-w-1/2";
      default:
        return "aui-w-11/12 md:aui-w-1/2 lg:aui-w-1/3 ";
    }
  };

  const columnSizeClass = columnSize((resources || []).length);

  return resources && resources.length > 0 ? (
    <div
      className={`aui-border aui-border-solid aui-border-grey-2 aui-bg-white aui-py-2 aui-px-8 aui-flex aui-flex-col md:aui-flex-row ${className}`}
    >
      <Toggle onToggle={onToggleExpanded} expanded={isExpanded} />

      <div
        className={`aui-overflow-x-auto aui-overflow-y-hidden ${
          isExpanded ? "aui-h-20 aui-mt-4 md:aui-mt-0" : "aui-h-0"
        } aui-transition-all aui-duration-500 aui-flex`}
      >
        {resources.map((resource) => (
          <div
            key={resource.title}
            className={`${columnSizeClass} aui-pr-4 aui-flex-shrink-0 aui-box-border aui-box-border aui-cursor-pointer hover:aui-bg-grey-1 md:aui-pr-16`}
          >
            <ResourceCard key={resource.title} {...resource} onOpen={onOpen} />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export const ComplianceResources = ({ openComplianceModal, showComplianceVideoCallback }) => {
  const { wrapper } = useComplianceResourcesStyles();
  const { complianceResources: resources } = useCurrentProjectContext();

  return resources && resources.length > 0 ? (
    <x.div {...wrapper}>
      {resources.map((resource) => (
        <x.div key={resource.title}>
          <ResourceCard
            key={resource.title}
            {...resource}
            openComplianceModal={openComplianceModal}
            showComplianceVideoCallback={showComplianceVideoCallback}
          />
        </x.div>
      ))}
    </x.div>
  ) : null;
};

const ResourceInfo = ({ title, description }) => {
  const { info, infoText } = useComplianceResourcesStyles();

  return (
    <x.div {...info}>
      <Typography variant="body-em">{title}</Typography>
      <Typography {...infoText} style={lineClamp(2)}>
        {description}
      </Typography>
    </x.div>
  );
};

const Card = (props) => {
  const { title, thumbUrl } = props;

  const { cardImageWrapper, cardImage, cardImageIcon } = useComplianceResourcesStyles();

  return (
    <>
      <x.div {...cardImageWrapper}>
        <x.img alt={title} src={thumbUrl} {...cardImage} />
        <x.div {...cardImageIcon}>{props.icon}</x.div>
      </x.div>
      <ResourceInfo {...props} />
    </>
  );
};

const FileCard = (props) => {
  const { file } = props;

  const { card } = useComplianceResourcesStyles();

  return (
    <x.a href={file} {...card} textDecoration="none">
      <Card {...props} icon={<Copy />} />
    </x.a>
  );
};

const DocumentCard = (props) => {
  const { openComplianceModal } = props;

  const handleDocumentClick = () => {
    openComplianceModal({ title: props.title, description: props.description });
  };

  const { card } = useComplianceResourcesStyles();

  return (
    <x.div {...card} onClick={handleDocumentClick}>
      <Card {...props} icon={<Copy />} />
    </x.div>
  );
};

const VideoCard = (props) => {
  const { videoId, showComplianceVideoCallback } = props;

  const { card } = useComplianceResourcesStyles();

  return (
    <x.div onClick={() => showComplianceVideoCallback({ videoId })}>
      <x.div {...card}>
        <Card {...props} icon={<PlayIcon />} />
      </x.div>
    </x.div>
  );
};
