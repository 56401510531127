import { Typography } from "@alphasights/alphadesign-components";
import styled, { th } from "@xstyled/styled-components";
import { Popover as OptionPopover } from "@alphasights/alphadesign-components";
const topNavBarHeight = 60;

export const SidebarNavWrapper = styled("div")`
  height: 100%;
  width: 70px;
  align-items: stretch;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  user-select: none;
  flex-shrink: 0;
`;

export const NavLogoContainer = styled("div")`
  display: flex;
  justify-content: center;
  padding: inner-base05 inner-base04;
`;

export const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const OptionsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: inner-base04;
`;

export const BottomContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: inner-base04;
  align-items: center;
  margin-bottom: layout-base02;
`;

export const AvatarContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding: inner-base;
  border-radius: small;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const AvatarOptionWrapper = styled("div")<{ showBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: inner-base;
  border-radius: 40px;
  background: ${({ showBackground }) => (showBackground ? "rgba(255, 255, 255, 0.2)" : "none")};
`;

export const NavOptionWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

export const NavIconWrapper = styled("div")<{ isSelected?: boolean; disabled?: boolean }>`
  padding: inner-base02;
  color: ${({ disabled }) => (disabled ? `${th.color("icon-disabled")}` : `${th.color("icon-inverse")}`)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-radius: small;
  background-color: ${({ isSelected }) => (isSelected ? "rgba(255, 255, 255, 0.2)" : "transparent")};

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? "rgba(255, 255, 255, 0.2)" : "rgba(255, 255, 255, 0.1)")};
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const LabelWrapper = styled(Typography).attrs({ variant: "body-em", component: "div", color: "inverse" })`
  font-size: 11px;
`;

export const IntercomWrapper = styled.div<{ open: boolean; intercomBooted: boolean }>`
  border-radius: small;
  padding: inner-base02;
  cursor: ${({ intercomBooted }) => (intercomBooted ? "pointer" : "not-allowed")};
  background: ${({ open, intercomBooted }) => (open && intercomBooted ? "rgba(255, 255, 255, 0.2)" : "transparent")};
  &:hover {
    background: ${({ open, intercomBooted }) =>
      open && intercomBooted ? "rgba(255, 255, 255, 0.2)" : "rgba(255, 255, 255, 0.1)"};
  }
`;

export const Popover = styled(OptionPopover)<{ isAvatarMenu?: boolean }>(
  ({ isAvatarMenu }) => `
  width: 358px;
  height: calc(100vh - ${topNavBarHeight}px);
  overflow-y: auto;
  ${isAvatarMenu ? "margin-left: 11px;" : "transform: translateX(-8px)"};
  padding: 0;
  z-index: 100;
  a {
    text-decoration: none;
  }
`
);
