import React from "react";
import { x } from "@xstyled/styled-components";
import { Typography, TextField, useThemeTokens, IconButton } from "@alphasights/alphadesign-components";
import { Delete } from "@alphasights/alphadesign-icons";
import { CommissionPrimerProperties } from "pages/AlphaNowPage/primers/CommissionPrimer/constants";

const RequestPrimerModalRow = ({
  company,
  website,
  hasDeleteButton,
  index,
  deleteRow,
  onChange,
}: {
  company: {
    value: string;
    isValid: boolean;
  };
  website: {
    value: string;
    isValid: boolean;
  };
  hasDeleteButton: boolean;
  index: number;
  deleteRow: () => void;
  onChange: (e: React.FormEvent<HTMLInputElement>, property: string, index: number) => void;
}) => {
  const {
    color: { text },
    spacing: { inner },
    font: { size },
  } = useThemeTokens();

  const textFieldStyle = {
    fontSize: size["03"],
  };

  return (
    <x.div display="flex" gap={inner.base04} w="100%" flexGrow data-testid={`modal-row-${index}`}>
      <x.div display="flex" flexDirection="column" gap={inner.base02} w="100%">
        <Typography color={text.secondary}>Company name</Typography>
        <TextField
          onChange={(e) => onChange(e, CommissionPrimerProperties.REQUESTED_COMPANY_NAME, index)}
          style={{ ...textFieldStyle }}
          placeholder="Add company name"
          value={company.value}
          error={!company.isValid}
          errorText="This field is required"
        />
      </x.div>
      <x.div w="100%" display="flex" flexDirection="column" gap={inner.base02}>
        <Typography color={text.secondary}>Company website</Typography>
        <TextField
          onChange={(e) => onChange(e, CommissionPrimerProperties.REQUESTED_COMPANY_WEBSITE, index)}
          style={{ ...textFieldStyle }}
          placeholder="Add company website"
          value={website.value}
          error={!website.isValid}
          errorText="This field is required"
        />
      </x.div>
      {hasDeleteButton && (
        <x.div display="flex" alignItems="flex-start" data-testid={`modal-row-delete-button-${index}`} marginTop="29px">
          <IconButton onClick={deleteRow} size="large" variant="outline" key="rounded-button">
            <Delete />
          </IconButton>
        </x.div>
      )}
    </x.div>
  );
};

export default RequestPrimerModalRow;
