import React, { FC } from "react";
import { Icon } from "@alphasights/alphadesign-components";
import { Purchased } from "@alphasights/alphadesign-icons";
import {
  CarouselsContainer,
  LandingPageContentContainer,
} from "pages/AlphaNowPage/components/LandingPage/LandingPageContent/LandingPageContent.styled";
import LandingPageHeader from "pages/AlphaNowPage/components/LandingPage/LandingPageHeader";
import ContentCardCarousel from "pages/AlphaNowPage/components/ContentCardCarousel";
import {
  inTheNewsTitle,
  recommendedInvestorLedTitle,
  purchasedResearchTitle,
  bookmarkedResearchTitle,
} from "pages/AlphaNowPage/components/LandingPage/constants";
import SeeAllContentCard from "pages/AlphaNowPage/components/SeeAllContentCard";
import { PURCHASE_TYPE } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/PurchasedFilter";
import { Filters, useAlphaNowQueryContext } from "pages/AlphaNowPage/components/AlphaNowQueryContext";
import {
  LandingPageContentProps,
  SingleFilterKeyProps,
} from "pages/AlphaNowPage/components/LandingPage/LandingPageContent/types";

const DATA_TEST_IDS = {
  InTheNewsSection: "in-the-news-section",
  RecommendedInvestorLedContentSection: "recommended-investor-led-content-section",
  PurchasedContentSection: "purchased-content-section",
  BookmarkedContentSection: "bookmarked-content-section",
};

const LandingPageContent: FC<LandingPageContentProps> = ({ data }) => {
  const { purchasedContent, bookmarkedContent, inTheNewsContent, recommendedInvestorLedContent } = data;
  const {
    query: { filters: queryFilters },
    updateQuery,
  } = useAlphaNowQueryContext();

  const PurchasedIcon = () => (
    <Icon size="small" color="success">
      <Purchased />
    </Icon>
  );

  const handleSeeAllClick = (updatedFilters: SingleFilterKeyProps) =>
    updateQuery({ filters: { ...queryFilters, ...updatedFilters } as Filters });

  return (
    <LandingPageContentContainer>
      <LandingPageHeader />
      <CarouselsContainer>
        {inTheNewsContent.length > 0 && (
          <ContentCardCarousel
            title={inTheNewsTitle}
            data={inTheNewsContent}
            dataTestId={DATA_TEST_IDS.InTheNewsSection}
          />
        )}
        {recommendedInvestorLedContent.length > 0 && (
          <ContentCardCarousel
            title={recommendedInvestorLedTitle}
            data={recommendedInvestorLedContent}
            rightAccessories={<PurchasedIcon />}
            dataTestId={DATA_TEST_IDS.RecommendedInvestorLedContentSection}
          />
        )}
        {purchasedContent.length > 0 && (
          <ContentCardCarousel
            title={purchasedResearchTitle}
            data={purchasedContent}
            rightAccessories={<PurchasedIcon />}
            customCard={
              <SeeAllContentCard
                onClick={() => handleSeeAllClick({ purchasedFilter: [PURCHASE_TYPE.byMe, PURCHASE_TYPE.byColleagues] })}
              />
            }
            dataTestId={DATA_TEST_IDS.PurchasedContentSection}
          />
        )}
        {bookmarkedContent.length > 0 && (
          <ContentCardCarousel
            title={bookmarkedResearchTitle}
            data={bookmarkedContent}
            customCard={<SeeAllContentCard onClick={() => handleSeeAllClick({ bookmarkFilter: true })} />}
            dataTestId={DATA_TEST_IDS.BookmarkedContentSection}
          />
        )}
      </CarouselsContainer>
    </LandingPageContentContainer>
  );
};

export { LandingPageContent as default, DATA_TEST_IDS };
