import { useMutation, useQuery, useQueryClient } from "react-query";
import { contentService } from "services/content";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import {
  CreateWatchlistPayload,
  DeleteWatchlistTriggersPayload,
  UpdateWatchlistSettingsPayload,
  FetchWatchlistPayload,
  UpdateWatchlistTriggersPayload,
} from "./types";
import { getInitialWatchlistData, getOrderedWatchlists } from "hooks/useWatchlist/utils";

const useWatchlist = () => {
  const { hasUserBadge } = useUserBadgeContext();
  const queryClient = useQueryClient();
  // TODO [RD1-51]: Remove Watchlist Badge
  const displayWatchlist = hasUserBadge(Badge.watchlist);

  const { data: watchlistData = [] } = useQuery(
    ["fetchWatchlistData", "fetchWatchlists"],
    () => contentService.fetchWatchlists(),
    {
      enabled: displayWatchlist,
      refetchOnMount: false,
    }
  );

  // TODO [RD1-92]: Remove ordering when handled
  const availableWatchlists = getOrderedWatchlists(watchlistData);

  const createWatchlist = async (createWatchlistData: CreateWatchlistPayload | {} = {}) => {
    const newWatchlist = await contentService.createWatchlist({
      ...getInitialWatchlistData(availableWatchlists),
      ...createWatchlistData,
    } as any);
    queryClient.invalidateQueries(["fetchWatchlistData", "fetchWatchlists"]);
    return newWatchlist;
  };

  const updateWatchlist = useMutation(
    ({ watchlistId, watchlistSettings }: { watchlistId: string; watchlistSettings: UpdateWatchlistSettingsPayload }) =>
      contentService.updateWatchlist(watchlistId, watchlistSettings),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchWatchlistData");
      },
    }
  );

  const useFetchWatchlist = ({ watchlistId, sort, filter }: FetchWatchlistPayload) => {
    return useQuery(
      ["fetchWatchlistData", "getWatchlist", watchlistId],
      async () => contentService.getWatchlist({ watchlistId, sort, filter }),
      {
        enabled: !!watchlistId,
      }
    );
  };

  const updateWatchlistTriggers = useMutation(
    ({ watchlistId, watchlistTriggers }: UpdateWatchlistTriggersPayload) =>
      contentService.updateWatchlistTriggers(watchlistId, watchlistTriggers),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchWatchlistData");
      },
    }
  );

  const deleteWatchlist = useMutation((watchlistId: string) => contentService.deleteWatchlist(watchlistId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchWatchlistData", "fetchWatchlists"]);
    },
  });

  const deleteWatchlistTriggers = useMutation(
    ({ watchlistId, triggerIds }: DeleteWatchlistTriggersPayload) =>
      contentService.deleteWatchlistTriggers(watchlistId, triggerIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchWatchlistData");
      },
    }
  );

  return {
    availableWatchlists: availableWatchlists ?? [],
    createWatchlist,
    updateWatchlistTriggers: updateWatchlistTriggers.mutateAsync,
    deleteWatchlistTriggers: deleteWatchlistTriggers.mutateAsync,
    updateWatchlist: updateWatchlist.mutateAsync,
    deleteWatchlist: deleteWatchlist.mutateAsync,
    useFetchWatchlist,
  };
};

export default useWatchlist;
