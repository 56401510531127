import React, { useState } from "react";
import { Icon, InputField, Toggle } from "../alphaui";
import { PrimaryButtonSmall, TernaryButtonSmall } from "../Button";
import { Modal } from "../Modal";
import { useApi } from "../../hooks/useApi";
import { Spinner } from "../Spinner";
import { FormattedDateTime } from "../../providers/TimezoneProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";

export const CalendarInvitationModalContainer = ({ token, ...props }) => {
  const [isLoading, attendees] = useApi(
    {
      url: `/api/projects/${token}/interactions/${props.id}/calendar-invitations/attendees`,
      method: "GET",
    },
    [token, props.id]
  );

  const currentUser = useCurrentUser();

  if (isLoading) {
    return <Spinner />;
  } else {
    const shouldAddCurrentUser =
      currentUser && !attendees.find((attendee) => attendee.emailAddress === currentUser.email);
    const invitationAttendees = shouldAddCurrentUser
      ? [{ emailAddress: currentUser.email, optional: false }, ...attendees]
      : attendees;
    return <CalendarInvitationModal {...props} invitationAttendees={invitationAttendees} />;
  }
};

export const CalendarInvitationModal = ({
  invitationAttendees = [],
  isOpen,
  onClose,
  onSubmit,
  id,
  advisorName,
  scheduledCallTime,
  timezoneDescription,
  ...props
}) => {
  const [attendees, setAttendees] = useState(invitationAttendees);

  const [newAttendee, setNewAttendee] = useState(null);
  const [isRunning, setRunning] = useState(false);
  const currentUser = useCurrentUser();

  const onAddAttendee = (emailAddress) => {
    setAttendees(attendees.concat({ emailAddress: emailAddress.trim(), optional: false }));
    setNewAttendee(null);
  };
  const onRemoveAttendee = (emailAddress) => {
    setAttendees(attendees.filter((attendee) => attendee.emailAddress !== emailAddress));
  };

  const onToggleOptional = (emailAddress) => {
    setAttendees(
      attendees.map((attendee) => {
        if (attendee.emailAddress === emailAddress) {
          return { emailAddress, optional: !attendee.optional };
        } else {
          return attendee;
        }
      })
    );
  };

  const onSubmitClick = () => {
    setRunning(true);
    onSubmit({ id, attendees }).finally(() => setRunning(false));
  };

  const disableAdd = !/\S+@\S+\.\S+/.test(newAttendee);

  const callTime = scheduledCallTime ? <FormattedDateTime date={scheduledCallTime} format="d LLLL" /> : "Invalid Date";

  return (
    <Modal
      isOpen={isOpen}
      showCloseIcon
      contentLabel={`Scheduling ${props.advisorName}`}
      title={
        isOpen && (
          <div className="aui-text-grey-5">
            <span className="aui-font-semibold aui-uppercase">Interaction Scheduled</span>
            <span className="aui-text-base aui-block">
              {callTime} - {timezoneDescription}
            </span>
            <span className="aui-text-base aui-block">{advisorName}</span>
          </div>
        )
      }
      onClose={onClose}
      className="aui-flex aui-flex-col md:aui-w-2/4"
    >
      {isOpen && (
        <React.Fragment>
          <article className="aui-flex aui-flex-col aui-flex-grow aui-p-6 aui-text-grey-5 aui-space-y-6">
            <div>
              <div className="aui-text-lg aui-font-semibold">Who should receive a calendar invite?</div>
              <div className="aui-flex aui-space-x-2">
                <InputField
                  type="email"
                  placeholder="Add invitee e.g. name@company.com"
                  className="aui-flex-grow"
                  value={newAttendee}
                  onChange={setNewAttendee}
                />
                <PrimaryButtonSmall onClick={() => onAddAttendee(newAttendee)} disabled={disableAdd}>
                  Add
                </PrimaryButtonSmall>
              </div>
              {!currentUser && (
                <span className="aui-text-sm aui-text-grey-3">Please remember to add your own email address</span>
              )}
            </div>
            <div className="aui-space-y-2" data-testid="attendees-div">
              {attendees.length > 0 && (
                <div className="aui-flex aui-space-x-4 aui-font-semibold">
                  <span className="aui-flex-grow">Email</span>
                  <span className="aui-w-16">Optional</span>
                  <span className="aui-w-16 aui-text-right">Remove</span>
                </div>
              )}
              {attendees.map(({ emailAddress, optional }, idx) => (
                <React.Fragment key={`atteendee-${emailAddress}`}>
                  <div className="aui-flex aui-items-center aui-space-x-4">
                    <span className="aui-flex-grow">{emailAddress}</span>
                    <div className="aui-w-16">
                      <Toggle size="small" active={optional} onChange={() => onToggleOptional(emailAddress)} />
                    </div>
                    <div className="aui-w-16 aui-text-right">
                      <Icon
                        icon="close-pill"
                        className="aui-text-grey-5 aui-cursor-pointer hover:aui-text-black aui-text-error-2"
                        onClick={() => onRemoveAttendee(emailAddress)}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </article>

          <footer className="aui-px-6 aui-py-2 aui-mt-4 aui-flex aui-flex-col md:aui-flex-row aui-border aui-border-solid aui-border-grey-2">
            <PrimaryButtonSmall
              onClick={onSubmitClick}
              isLoading={isRunning}
              disabled={isRunning || attendees.length === 0}
              className="aui-flex aui-items-center"
              data-testid="send-invitation-button"
            >
              <Icon icon="email" className="aui-mr-2 aui-text-base" />
              Send Invitation
            </PrimaryButtonSmall>
            <TernaryButtonSmall data-testid="send-invitation-close-button" children="Close" onClick={onClose} />
          </footer>
        </React.Fragment>
      )}
    </Modal>
  );
};
