import React from "react";
import { x } from "@xstyled/styled-components";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { ExpertFilters } from "components/InteractionsPage/ExpertFilters";
import { InteractionsFilter } from "components/InteractionsPage/InteractionsFilter";

export const FilterBar = ({
  toggleFilterSidebar,
  surveyType,
}: {
  toggleFilterSidebar: () => void;
  surveyType: SurveyType;
}) => {
  const {
    onFiltersChange,
    workstreamId,
    state: { project },
  } = useProjectInteractionsContext();

  return (
    <x.div>
      <ExpertFilters
        onSubmitFilters={onFiltersChange}
        onToggleFilter={toggleFilterSidebar}
        workstream={project.workstreams.find((w: any) => w.id === workstreamId)}
        isDoubleBlindedSurvey={surveyType === "double_blinded"}
        isSurveyPage
      />
    </x.div>
  );
};

export const FilterSidebar = ({ onClose }: { onClose: () => void }) => {
  const ref = React.useRef<HTMLInputElement>();
  const transcriptEnabled = false;
  const {
    onFiltersChange,
    workstreamId,
    state: { appliedFilters, filterOptions, project },
  } = useProjectInteractionsContext();

  return (
    <InteractionsFilter
      ref={ref}
      {...{
        transcriptEnabled,
        options: filterOptions,
        project,
        appliedFilters,
        onSubmit: onFiltersChange,
        onResetAll: () => onFiltersChange({}),
        onClose,
        workstream: project.workstreams.find((w: any) => w.id === workstreamId),
      }}
    />
  );
};
