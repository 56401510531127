import { debounce } from "lodash";
import { AlphaNowContentType, AlphaNowProductType } from "@alphasights/client-portal-shared";
import { Hit, HitAction, HitOrigin } from "@alphasights/portal-api-client";

const determineBookmarkAction = (productType: AlphaNowContentType | AlphaNowProductType, bookmarked: boolean) => {
  switch (productType) {
    case AlphaNowProductType.companyPrimer:
      return bookmarked ? HitAction.alphaNowUnbookmarkCompanyPrimer : HitAction.alphaNowBookmarkCompanyPrimer;
    case AlphaNowProductType.marketPrimer:
      return bookmarked ? HitAction.alphaNowUnbookmarkMarketPrimer : HitAction.alphaNowBookmarkMarketPrimer;
    case AlphaNowProductType.customerPrimer:
      return bookmarked ? HitAction.alphaNowUnbookmarkCustomerPrimer : HitAction.alphaNowBookmarkCustomerPrimer;
    default:
      return bookmarked ? HitAction.alphaNowUnbookmarkTranscript : HitAction.alphaNowBookmarkTranscript;
  }
};

export const currentOrigin = () => {
  return new URL(window.location.href).pathname.includes("deliverables-view")
    ? HitOrigin.deliverablesView
    : HitOrigin.alphaNow;
};

export const logBookmarkStatus = (
  isBookmarked: boolean,
  details: BaseContentDetails,
  logHit: (args: Hit) => void,
  productType: AlphaNowContentType | AlphaNowProductType
) => {
  logHit({
    origin: HitOrigin.alphaNow,
    action: determineBookmarkAction(productType, isBookmarked),
    details,
  });
};

export const logCopyLink = (
  details: BaseContentDetails,
  logHit: (args: Hit) => void,
  contentType: AlphaNowContentType | AlphaNowProductType
) => {
  const logHitActions = {
    [AlphaNowContentType.alphaview]: HitAction.alphaNowCopyTranscriptLink,
    [AlphaNowProductType.companyPrimer]: HitAction.alphaNowCopyCompanyPrimerLink,
    [AlphaNowProductType.marketPrimer]: HitAction.alphaNowCopyMarketPrimerLink,
    [AlphaNowProductType.customerPrimer]: HitAction.alphaNowCopyCustomerPrimerLink,
    [AlphaNowContentType.pcc]: HitAction.alphaNowCopyTranscriptLink,
  } as Record<string, HitAction>;

  logHit({
    origin: HitOrigin.alphaNow,
    //@ts-ignore
    action: logHitActions[contentType as string],
    details,
  });
};

export const logTimeSpent = (
  startTime: number,
  baseDetails: BaseContentDetails | BaseCompanyDetails,
  logHit: (args: Hit) => void,
  action: HitAction
) => {
  const millis = Date.now() - startTime;

  logHit({
    origin: HitOrigin.alphaNow,
    action: action,
    details: {
      ...baseDetails,
      durationInMilliseconds: millis,
      durationInSeconds: Math.floor(millis / 1000),
      durationInMinutes: +(millis / 60000).toFixed(1),
    },
  });
};

type ScrollPercentageDetails = {
  scrollPercentage: number;
} & (BaseContentDetails | BaseCompanyDetails);

export const logScrollPercentage = (
  logHit: (args: Hit) => void,
  details: ScrollPercentageDetails,
  action: HitAction
) => {
  const origin = currentOrigin();
  logHit({
    origin,
    action,
    details,
  });
};

export interface CurrentScrollAreaDataArgs {
  clientHeight: number;
  scrollHeight: number;
  scrollTop: number;
}

export const handleScroll = debounce(
  (
    updateMaxScroll: (scrollPercentage: number) => void,
    currentMaxScrollPercentage: number = 0,
    currentScrollAreaData?: CurrentScrollAreaDataArgs
  ) => {
    if (!currentScrollAreaData) return;

    const { clientHeight, scrollHeight, scrollTop } = currentScrollAreaData;

    // +(...) ensures that when we call .toFixed(...), we will get a # instead of a string
    const nextScrollPercentage = +((Math.round(scrollTop + clientHeight) / scrollHeight) * 100).toFixed(0);

    if (currentMaxScrollPercentage < nextScrollPercentage) {
      updateMaxScroll(nextScrollPercentage);
    }
  },
  200
);
