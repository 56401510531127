import { AiAskQuestionInput } from "components/AiAskQuestionInput";
import { useStyles } from "./NewSynthesisModuleContent.styles";
import React from "react";
import { x } from "@xstyled/styled-components";
import { Button, ListOption, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Add } from "@alphasights/alphadesign-icons";
import styled from "styled-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";

export const NewSynthesisModuleContent = () => {
  const { newSynthesisModule } = useProjectSynthesisContext();

  const styles = useStyles();
  const tokens = useThemeTokens();

  return (
    <x.div {...styles.wrapper}>
      <x.div display="flex" gap="8px">
        <Typography variant="body-em" color="secondary">
          Create a new module that synthesizes transcripts with
        </Typography>
        <Button
          variant="ghost"
          dropdown
          splitOptions={newSynthesisModule.angleOptions}
          color={newSynthesisModule.form.errors?.angle ? tokens.color.text.danger : undefined}
          dataAttributes={{
            "data-testid": "angle-handle",
          }}
        >
          {newSynthesisModule.form.angle ?? "[Select Angle]"}
        </Button>
      </x.div>

      <AiAskQuestionInput
        text={newSynthesisModule.form.question}
        onChange={(question: string) => newSynthesisModule.updateForm("question", question)}
        onSubmit={() => newSynthesisModule.onSubmit()}
        error={newSynthesisModule.form.errors?.question}
        data-testid="ai-question"
        onBlur={() => {}}
        isNewQuestion
        isLoading={false}
      />

      {newSynthesisModule.suggestions.map(({ angle, suggestions }) => {
        return (
          <x.div key={angle} {...styles.suggestionWrapper} data-testid={`suggestion-${angle}`}>
            <Typography variant="body-em" {...styles.suggestionAngle}>
              Ask {angle}
            </Typography>
            {suggestions.map((s) => {
              return (
                <WordWrapListOption
                  key={s}
                  type="text"
                  label={s}
                  onChange={() => newSynthesisModule.consumeSuggestion(angle, s)}
                  leftIcon={<Add />}
                />
              );
            })}
          </x.div>
        );
      })}
    </x.div>
  );
};

const WordWrapListOption = styled(ListOption)`
  .list-option-label {
    overflow: visible;
    white-space: unset;
    ellipsis: unset;
  }
`;
