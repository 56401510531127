import React, { FC, useRef } from "react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { AlphaNowProductType, useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { Filters as FiltersType, useAlphaNowQueryContext } from "pages/AlphaNowPage/components/AlphaNowQueryContext";

import { ResultsCounter } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";
import {
  BookmarkFilter,
  SortByFilter,
  UpcomingFilter,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters";
import { UpcomingValue } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/UpcomingFilter";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import { AlphaNowFiltersContainer, FiltersWrapper } from "./AlphaNowFilters.styled";
import Filters from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/Filters";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";

type AlphaNowFiltersProps = {
  numberOfResults: number;
  canOpenPopovers: boolean;
};

const AlphaNowFilters: FC<AlphaNowFiltersProps> = ({ numberOfResults, canOpenPopovers }) => {
  const { isMobile } = useCheckScreen();
  const {
    query: { filters: queryFilters },
    updateQuery,
  } = useAlphaNowQueryContext();
  const { hasUpcomingContent } = useAlphaNowContentAccessLevelContext();
  const { logHit } = useTrackUserAction();

  const firstUpdate = useRef(true);

  const showNumberOfResults = !isMobile;

  const onFilterApply = (value: Record<string, any>) => {
    updateQuery({ filters: { ...queryFilters!, ...value! } });
  };

  const handleSortChange = (value: FiltersType["sortBy"]) => {
    onFilterApply({ [SearchFilters.sortBy]: value });
  };

  useDeepCompareEffect(() => {
    if (!firstUpdate.current) {
      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowSearchFilters,
        details: {
          filters: queryFilters,
        },
      });
    } else {
      firstUpdate.current = false;
    }
  }, [queryFilters]);

  return (
    <AlphaNowFiltersContainer>
      <FiltersWrapper>
        <Filters isOpenable={canOpenPopovers} />
        {hasUpcomingContent && (
          <UpcomingFilter value={queryFilters?.upcomingFilter as UpcomingValue} onChange={onFilterApply} />
        )}
        <BookmarkFilter value={queryFilters?.bookmarkFilter} onChange={onFilterApply} />
        <SortByFilter value={queryFilters?.sortBy!} onChange={handleSortChange} isOpenable={canOpenPopovers} />
      </FiltersWrapper>
      {showNumberOfResults && (
        <ResultsCounter
          numberOfResults={numberOfResults}
          productTypes={(queryFilters?.contentType ?? []) as AlphaNowProductType[]}
        />
      )}
    </AlphaNowFiltersContainer>
  );
};

export default AlphaNowFilters;
