export const MODAL_TYPE = {
  CANCEL: "CANCEL",
  EMPLOYMENT_HISTORY: "EMPLOYMENT_HISTORY",
  PURCHASE: "PURCHASE",
  WHAT_IS_AN_ALPHAVIEW: "WHAT_IS_AN_ALPHAVIEW",
};

export const MONTHS = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const PURCHASE_OPTIONS = {
  CALL_AND_TRANSCRIPT: "CALL_AND_TRANSCRIPT",
  TRANSCRIPT: "TRANSCRIPT",
};

export const PURCHASE_OPTIONS_TEXT = {
  [PURCHASE_OPTIONS.CALL_AND_TRANSCRIPT]: "Attend AlphaView & Receive Transcript",
  [PURCHASE_OPTIONS.TRANSCRIPT]: "Receive Transcript Only",
};
