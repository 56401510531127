import React, { useEffect, useMemo, useRef, useState } from "react";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { SurveysMainContent } from "components/SurveysPage/SurveysMainContent";
import { FilterBar, FilterSidebar } from "components/SurveysPage/FilterBar";
import { x } from "@xstyled/styled-components";
import { Button, TextField, Typography } from "@alphasights/alphadesign-components";
import useStyles from "./styles";
import { buildSurveyData } from "components/SurveysPage/helpers";
import { Download } from "@alphasights/alphadesign-icons";
import { CidCompaniesModal } from "components/CidCompaniesModal";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useUserCidEnabled } from "hooks/useUserCidEnabled";
import { buildDownloadCompaniesCidUrl } from "helpers/urlHelpers";
import { filterSurveysWithInteractions, fromWorkstream } from "components/InteractionsPage/helpers/Workstreams";
import useQueryParams from "hooks/useQueryParams";
import { useCurrentWorkstreamContext } from "providers/CurrentWorkstreamProvider";
import { WorkstreamToggler } from "components/InteractionsPage/WorkstreamToggler";
import { AdvisorsTableViewSkeleton } from "views/AdvisorsTableView";

export const SurveyTableView = ({ selectedInteractionId }) => {
  const {
    onFiltersChange,
    state: { interactions, isInteractionsLoading, appliedFilters, searchQuery, isFlyoutOpen, isBackgroundSyncRunning },
    allInteractionsForProject,
    onSelectCard,
  } = useProjectInteractionsContext();
  const {
    project,
    selectedWorkstream: workstream,
    selectedSurveyId,
    setSelectedSurveyId,
  } = useCurrentWorkstreamContext();
  const queryParams = useQueryParams();
  const styles = useStyles();

  const [filterSidebarOpen, setFilterSidebarOpen] = useState(false);
  const [showingCidModal, setShowingCidModal] = useState(false);
  const [selectedExpertIds, setSelectedExpertIds] = useState([]);

  const validSurveys = useMemo(
    () => filterSurveysWithInteractions(allInteractionsForProject, workstream.clientSurveys),
    [allInteractionsForProject, workstream.clientSurveys]
  );

  useEffect(
    function setInitialSurveySelected() {
      if (!validSurveys.length) return;
      if (isBackgroundSyncRunning) return;

      const querySurvey = queryParams.get("currentSurvey");
      if (querySurvey) {
        setSelectedSurveyId(querySurvey);
      } else if (selectedInteractionId) {
        const selectedInteractionSurveyId = interactions.find((int) => int.id === selectedInteractionId)?.surveyResponse
          ?.clientSurveyId;

        setSelectedSurveyId((prev) => prev || selectedInteractionSurveyId || validSurveys[0].id);
      } else {
        const nonEmptySurvey = validSurveys.find((s) =>
          interactions.find((i) => i.surveyResponse?.clientSurveyId === s.id)
        );
        setSelectedSurveyId((prev) => prev || nonEmptySurvey?.id || validSurveys[0].id);
      }
    },
    [interactions, validSurveys, queryParams, setSelectedSurveyId, isBackgroundSyncRunning, selectedInteractionId]
  );

  const onFiltersSidebarToggle = () => {
    if (filterSidebarOpen) {
      setFilterSidebarOpen(false);
    } else {
      onSelectCard(null);
      setFilterSidebarOpen(true);
    }
  };

  useEffect(() => {
    if (isFlyoutOpen) {
      setFilterSidebarOpen(false);
    }
  }, [isFlyoutOpen]);

  const queryTextRef = useRef();
  const workstreamInteractions = fromWorkstream(interactions, workstream.id);

  const selectedOrFirstSurveyId = useMemo(
    () => validSurveys.find((s) => s.id === selectedSurveyId)?.id || validSurveys[0].id,
    [validSurveys, selectedSurveyId]
  );

  const surveyType = useMemo(() => {
    const clientSurvey =
      workstream.clientSurveys && workstream.clientSurveys.find((e) => e.id === selectedOrFirstSurveyId);
    return clientSurvey?.surveyType;
  }, [selectedOrFirstSurveyId, workstream.clientSurveys]);

  const showCid = !shouldHideCid({
    surveyType,
    interactions: workstreamInteractions,
    project,
    selectedSurveyId: selectedOrFirstSurveyId,
    userCidEnabled: useUserCidEnabled(),
  });

  if (isBackgroundSyncRunning) {
    return <AdvisorsTableViewSkeleton />;
  }

  return (
    <x.div {...styles.wrapper}>
      {filterSidebarOpen && <FilterSidebar onClose={() => setFilterSidebarOpen(false)} />}
      <x.div {...styles.contentWrapper}>
        <x.div {...styles.topBarWrapper}>
          <x.div {...styles.inputWrapper}>
            <WorkstreamToggler />
            <x.form
              flexGrow={1}
              data-testid="search-form"
              onSubmit={(e) => {
                e.preventDefault();
                onFiltersChange(appliedFilters, queryTextRef.current.value);
              }}
            >
              <TextField
                data-testid="keyword-search-input"
                ref={queryTextRef}
                className="aui-w-full"
                spacing="lg"
                minL={2}
                name="query"
                placeholder="Search"
                value={searchQuery}
                autoComplete="off"
                {...styles.searchInput}
              />
            </x.form>
          </x.div>
          <x.div {...styles.pillCountWrapper}>
            <FilterBar toggleFilterSidebar={onFiltersSidebarToggle} surveyType={surveyType} />
            <Count selectedSurveyId={selectedOrFirstSurveyId} surveyType={surveyType} />
          </x.div>
          {showCid && (
            <CidControls
              showingCidModal={showingCidModal}
              setShowingCidModal={setShowingCidModal}
              selectedIds={selectedExpertIds}
              surveyType={surveyType}
              interactions={workstreamInteractions}
              selectedSurveyId={selectedOrFirstSurveyId}
            />
          )}
        </x.div>
        <SurveysMainContent
          selectedExpertIds={selectedExpertIds}
          setSelectedExpertIds={setSelectedExpertIds}
          surveyType={surveyType}
          selectedInteractionId={selectedInteractionId}
          interactions={workstreamInteractions}
          isLoading={isInteractionsLoading}
          selectedSurveyId={selectedOrFirstSurveyId}
          onSelectCard={onSelectCard}
          showCid={showCid}
        />
      </x.div>
    </x.div>
  );
};

const Count = ({ selectedSurveyId, surveyType }) => {
  const {
    state: { projectWithInteractions, interactions },
  } = useProjectInteractionsContext();
  const doubleBlinded = surveyType === "double_blinded";

  const styles = useStyles();

  const total = new Set(
    buildSurveyData({
      interactions: projectWithInteractions?.interactions || [],
      selectedSurveyId: selectedSurveyId,
      doubleBlinded: doubleBlinded,
    })
  ).size;

  const current = new Set(
    buildSurveyData({
      interactions: interactions,
      selectedSurveyId: selectedSurveyId,
      doubleBlinded: doubleBlinded,
    })
  ).size;

  return (
    <Typography component={"div"} variant="body-small" {...styles.count}>
      <x.span>Showing </x.span>
      <x.span {...styles.colorRed}>{current}</x.span>
      <x.span> / </x.span>
      <x.span>{total}</x.span>
    </Typography>
  );
};

const UploadCidButton = ({ onClick, ...props }) => {
  return (
    <Button
      variant="outline"
      size="small"
      onClick={onClick}
      startIcon={<Download className="aui-rotate-180" />}
      data-testid="upload-cid"
      {...props}
    >
      <Typography variant="body-em" className="aui-inline">
        Upload CID
      </Typography>
    </Button>
  );
};

const DownloadCidButton = ({ onClick, ...props }) => {
  return (
    <Button
      variant="secondary"
      size="small"
      onClick={onClick}
      startIcon={<Download />}
      data-testid="download-cid"
      {...props}
    >
      <Typography variant="body-em" className="aui-inline">
        Download CID
      </Typography>
    </Button>
  );
};

const shouldHideCid = ({ surveyType, interactions, project, selectedSurveyId, userCidEnabled }) => {
  const hideCid = () =>
    surveyType === "double_blinded" ||
    (surveyType === "single_blinded" &&
      !interactions.find(
        (i) => i.surveyResponse?.clientSurveyId === selectedSurveyId && i.surveyResponse.state === "completed"
      )) ||
    (surveyType === "premium" && !interactions.find((i) => i.surveyResponse?.clientSurveyId === selectedSurveyId));
  return !project.active || !project.cidEnabled || !userCidEnabled || hideCid();
};

const CidControls = ({ showingCidModal, setShowingCidModal, selectedIds }) => {
  const { project } = useCurrentProjectContext();
  const { syncCidStatusChanges } = useProjectInteractionsContext();

  const onDownloadCompaniesCid = () => {
    window.open(buildDownloadCompaniesCidUrl(project.token, selectedIds));
  };

  return (
    <x.div display={"flex"}>
      <UploadCidButton onClick={() => setShowingCidModal(true)} />
      {selectedIds?.length ? <DownloadCidButton onClick={onDownloadCompaniesCid} className="aui-ml-4" /> : null}
      <CidCompaniesModal
        projectToken={project.token}
        isModalOpen={showingCidModal}
        onCidCompaniesModalUpload={syncCidStatusChanges}
        onClose={() => setShowingCidModal(false)}
      />
    </x.div>
  );
};
