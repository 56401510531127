import { spacing, typography } from "@alphasights/alphadesign-tokens/dist/js/base/tokens-docs";

enum Keys {
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  Enter = "Enter",
  Backspace = "Backspace",
  Escape = "Escape",
}

enum SearchVariant {
  Simple = "simple",
  Complex = "complex",
}

enum SearchSizeVariant {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

const STYLE_CONFIG = {
  [SearchSizeVariant.Small]: {
    height: "32px",
    typographyVariant: "body-small",
    typography: {
      fontFamily: typography.body.small["font-family"].value,
      fontSize: typography.body.small["font-size"].value,
      fontWeight: typography.body.small["font-weight"].value,
      lineHeight: typography.body.small["line-height"].value,
    },
    icon: {
      size: SearchSizeVariant.Small,
      xStyledProps: {
        pl: spacing.inner.base03.value,
      },
    },
    loadingSpinner: {
      xStyledProps: {
        w: spacing.inner.base04.value,
        h: spacing.inner.base04.value,
      },
    },
    avatar: {
      size: spacing.inner.base04.value,
    },
    pill: {
      size: "x-small",
      xStyledProps: {
        mx: 0,
      },
    },
    input: {
      height: spacing.inner.base06.value,
    },
    searchItem: {
      xStyledProps: {
        gap: spacing.inner.base.value,
      },
    },
    searchOption: {
      xStyledProps: {
        pl: spacing.inner.base03.value,
      },
    },
    footer: {
      xStyledProps: {
        mt: spacing.inner.base03.value,
        mb: spacing.inner.base02.value,
      },
      typographyVariant: "body-small",
    },
  },
  [SearchSizeVariant.Medium]: {
    height: "38px",
    typographyVariant: "body",
    typography: {
      fontFamily: typography.body.regular["font-family"].value,
      fontSize: typography.body.regular["font-size"].value,
      fontWeight: typography.body.regular["font-weight"].value,
      lineHeight: typography.body.regular["line-height"].value,
    },
    icon: {
      size: SearchSizeVariant.Medium,
      xStyledProps: {
        pl: spacing.inner.base04.value,
      },
    },
    loadingSpinner: {
      xStyledProps: {
        w: spacing.inner.base05.value,
        h: spacing.inner.base05.value,
      },
    },
    avatar: {
      size: spacing.inner.base05.value,
    },
    pill: {
      size: "medium",
      xStyledProps: {
        mx: "2px",
      },
    },
    input: {
      height: spacing.inner.base08.value,
    },
    searchItem: {
      xStyledProps: {
        gap: spacing.inner.base.value,
      },
    },
    searchOption: {
      xStyledProps: {
        pl: spacing.inner.base04.value,
      },
    },
    footer: {
      xStyledProps: {
        mt: spacing.inner.base03.value,
        mb: spacing.inner.base02.value,
      },
      typographyVariant: "body-small",
    },
  },
  [SearchSizeVariant.Large]: {
    height: "44px",
    typographyVariant: "body-large",
    typography: {
      fontFamily: typography.body.large["font-family"].value,
      fontSize: typography.body.large["font-size"],
      fontWeight: typography.body.large["font-weight"].value,
      lineHeight: typography.body.large["line-height"].value,
    },
    icon: {
      size: SearchSizeVariant.Large,
      xStyledProps: {
        pl: spacing.inner.base05.value,
      },
    },
    loadingSpinner: {
      xStyledProps: {
        w: spacing.inner.base06.value,
        h: spacing.inner.base06.value,
      },
    },
    avatar: {
      size: spacing.inner.base06.value,
    },
    pill: {
      size: "large",
      xStyledProps: {
        mx: "2px",
      },
    },
    input: {
      height: spacing.inner.base10.value,
    },
    searchItem: {
      xStyledProps: {
        gap: spacing.inner.base.value,
      },
    },
    searchOption: {
      xStyledProps: {
        pl: spacing.inner.base05.value,
      },
    },
    footer: {
      xStyledProps: {
        mt: spacing.inner.base05.value,
        mb: spacing.inner.base04.value,
      },
      typographyVariant: "body",
    },
  },
};

export { STYLE_CONFIG, Keys, SearchVariant, SearchSizeVariant };
export * from "./boolean-consts";
