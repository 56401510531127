import React from "react";
import { HideableCell } from "./HideableCell";
import { Icon } from "../../components/alphaui";
import { getLanguageFromCode } from "../../helpers/displayHelpers";
import { WrappingPopover } from "../../components/Popover";
import { HighlightTextTypography } from "../../components/AdvisorCard/HighlightText";
import { Pill, Typography, useThemeTokens, Icon as AdsIcon } from "@alphasights/alphadesign-components";
import { CustomReason } from "../../components/HighlyRecommendedPopover";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { useExpertRemarksContext } from "../../providers/ExpertRemarksProvider";
import { Quote } from "@alphasights/alphadesign-icons";

const Badge = React.forwardRef(({ iconName, text, style, classColor, classExtra, ...props }, ref) => {
  return (
    <div
      style={style}
      className={`aui-rounded aui-align-middle aui-leading-4
          ${classColor || "aui-bg-transparent aui-text-current"}
          ${classExtra || ""}
        `}
      {...props}
    >
      <span ref={ref}>
        {iconName && <Icon icon={iconName} className="aui-align-middle" />}
        {text}
      </span>
    </div>
  );
});

const HighlyRecommendedBadge = ({ data: { highlyRecommendedReason, primaryContact }, ...props }) => {
  const hasCustomReason = highlyRecommendedReason && primaryContact;

  return (
    <PortalTooltip
      position="bottom"
      title={
        hasCustomReason ? (
          <CustomReason reason={highlyRecommendedReason} primaryContact={primaryContact} />
        ) : (
          "This expert is highly recommended."
        )
      }
    >
      <Badge iconName="highly-recommended" {...props} />
    </PortalTooltip>
  );
};

const NeedsInterpreterBadge = ({ needsInterpreter, ...props }) => (
  <PortalTooltip title={`Needs Interpreter: ${getLanguageFromCode(needsInterpreter)}`} position="bottom">
    <Badge iconName="needs-translator" {...props} />
  </PortalTooltip>
);

const VerifiedBadge = ({ classExtra, ...props }) => {
  const { color } = useThemeTokens();

  return (
    <PortalTooltip
      title="This advisor has completed multiple successful interactions with your colleagues over the past 12 months"
      position="bottom"
    >
      <Badge style={{ color: color.icon.strong._ }} iconName="verified" classExtra={`${classExtra}`} {...props} />
    </PortalTooltip>
  );
};

const TotalHighlights = ({ totalHighlights, ...props }) => {
  const { spacing } = useThemeTokens();

  return (
    <Pill
      variant="blue"
      size="x-small"
      ml={spacing.inner.base02}
      isInteractive={false}
      leftAccessories={
        <AdsIcon color="info">
          <Quote />
        </AdsIcon>
      }
      {...props}
    >
      {totalHighlights}
    </Pill>
  );
};

export const AdvisorColumn = ({ row: { original: data } }) => {
  const { isExpertHidden } = useExpertRemarksContext();

  const textClass = isExpertHidden(data) ? "aui-text-grey-3" : "aui-text-primary-1";
  const totalHighlights = data.highlights?.flatMap(({ terms }) => terms).length;
  const isNameNotWithheld = data.advisorName !== "[advisor name withheld]";

  return (
    <HideableCell hidden={isExpertHidden(data)} value={data.advisorName}>
      <div
        className="aui-flex aui-items-center aui-h-full aui-overflow-hidden aui-w-full"
        data-testid="advisor-row-name"
      >
        <WrappingPopover
          text={data.advisorName}
          placement="bottom-start"
          className="aui-cursor-pointer"
          data-testid={`advisor-row-name-${data.id}`}
        >
          {isNameNotWithheld ? (
            <HighlightTextTypography
              variant="body-small"
              component="span"
              highlights={data.highlights}
              fieldNames={[
                "advisorships.advisor_name",
                "advisorships.advisor_name.concat",
                "advisor.name",
                "advisor.name.concat",
              ]}
              text={data.advisorName}
              className={textClass}
            />
          ) : (
            <Typography variant="body-small" component="span" className={textClass}>
              {data.advisorName}
            </Typography>
          )}
        </WrappingPopover>
        {data.isActiveProject && (
          <span className="aui-flex" style={{ height: 16 }}>
            {data.verified && <VerifiedBadge classExtra="aui-ml-2" data-testid={`advisor-row-verified-${data.id}`} />}
          </span>
        )}

        <span className="aui-flex aui-ml-auto" style={{ height: 16 }}>
          {data.needsInterpreter && (
            <NeedsInterpreterBadge
              classExtra="aui-ml-2"
              needsInterpreter={data.needsInterpreter}
              data-testid={`advisor-row-interpreter-${data.id}`}
            />
          )}
          {data.highlyRecommended && (
            <HighlyRecommendedBadge
              data={data}
              classExtra="aui-ml-2"
              data-testid={`advisor-row-recommended-${data.id}`}
            />
          )}
          {totalHighlights > 0 && (
            <TotalHighlights
              totalHighlights={totalHighlights}
              data-testid={`advisor-row-total-highlights-${data.id}`}
            />
          )}
        </span>
      </div>
    </HideableCell>
  );
};
