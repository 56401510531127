import { Overlay, Skeleton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { MyAlphaSightsModuleContract } from "@alphasights/client-platform-contracts";
import { useEnv, useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { usePortalStore } from "@alphasights/portal-auth-react";
import { RealTimeModuleRenderer, useListenToWindowMessageEvent } from "@alphasights/realtime-import-react";
import { createGlobalStyle, x } from "@xstyled/styled-components";
import { GlobalNav } from "components/GlobalNav";
import { extractModulePathName, myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import useModal from "hooks/useModal";
import { CommissionModal } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import "./MyAlphasightsPage.css";

const OVER_TOP_BAR = 10;

const IFrameOverOverlay = createGlobalStyle`
  #my-as-iframe {
    z-index: 11;
  }
`;

export const MyAlphasightsPage = () => {
  const env = useEnv() as any;
  const { isVisible, onOpen, onClose } = useModal();
  const { logHit } = useTrackUserAction();
  const portalStoreState = usePortalStore();
  const history = useHistory();
  const [overlay, setOverlay] = useState(false);
  const [delayedOpened, setDelayedOpened] = useState(false);
  const [moduleLoaded, setModuleLoaded] = useState(false);

  const context: MyAlphaSightsModuleContract = useMemo(() => {
    return {
      moduleBasePath: myAlphaSightsBaseUrl,
      clientPortalBaseUrl: env?.baseUrl ?? "",
      portalStoreState,
      environment: env?.environment?.toLowerCase(),
      currentUrl: extractModulePathName(history.location.pathname, myAlphaSightsBaseUrl) + history.location.search,
    };
  }, [env, portalStoreState, history.location.pathname, history.location.search]);

  const handleRequestPrimer = () => {
    logHit({
      origin: HitOrigin.myAlphasightsPage,
      action: HitAction.myAlphasightsPrimersCommission,
    });
  };

  useListenToWindowMessageEvent("open-commission-primers", onOpen);
  useListenToWindowMessageEvent("redirect", ({ payload }) => {
    const { url } = payload as Record<string, unknown>;
    if (url === history.location.pathname) return;
    history.push(url as string);
  });
  useListenToWindowMessageEvent("overlay", ({ payload }) => {
    const { open } = payload as { open: boolean };
    setOverlay(open);
    if (open) {
      // https://github.com/alphasights/client-portal/pull/6652
      setTimeout(() => setDelayedOpened(true), 0);
    } else {
      setDelayedOpened(false);
    }
  });

  const globalNavProps = {
    hideLimitationsOfUse: true,
    hideAdvisorsLink: true,
    url: "../",
  };

  const initialModuleUrl = useMemo(function memoizeToPreventIFrameUnmount() {
    return (
      `${env.myAlphasightsUrl}${extractModulePathName(history.location.pathname, myAlphaSightsBaseUrl)}` +
      history.location.search
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <GlobalNav {...(globalNavProps as any)} />
      <x.div h="100%" w="100%" position="relative">
        <MyAlphasightsPageSkeleton opacity={moduleLoaded ? 0 : 1} />
        <x.div h={moduleLoaded ? "100%" : "0px"} display="flex" flexGrow="1">
          <RealTimeModuleRenderer
            moduleUrl={initialModuleUrl}
            context={context}
            id="my-as-iframe"
            onModuleLoad={() => setModuleLoaded(true)}
          />
        </x.div>
      </x.div>

      {overlay && (
        <>
          {/* @ts-ignore */}
          <IFrameOverOverlay />
          <Overlay open={delayedOpened} zIndex={OVER_TOP_BAR}>
            <div />
          </Overlay>
        </>
      )}
      {isVisible && <CommissionModal onClose={onClose} onPrimerRequested={handleRequestPrimer} />}
    </>
  );
};

export const MyAlphasightsPageSkeleton = ({ opacity }: { opacity: number }) => {
  const { color } = useThemeTokens();

  return (
    <x.div
      display="flex"
      flexDirection="column"
      background={color.background.surface.recessed}
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      opacity={opacity}
      pointerEvents="none"
      transition="opacity 0.5s"
    >
      <x.div display="flex" h="100%">
        <x.div
          position="sticky"
          top="0"
          w="50%"
          maxWidth="358px"
          minWidth="278px"
          borderRight="solid"
          borderRightColor={tokens.color.border.neutral.default}
          borderRightWidth="1px"
          display="flex"
          flexDirection="column"
          background={color.background.surface.page.default}
        >
          <x.div flexGrow="1" overflowY="auto">
            <ProjectsMenuSkeleton />
          </x.div>
          <x.div
            display="flex"
            flexDirection="column"
            borderTop="solid"
            borderTopWidth="1px"
            borderColor={color.border.neutral.default}
            overflowY="auto"
          >
            <UpcomingCallSectionSkeleton />
          </x.div>
        </x.div>
      </x.div>
    </x.div>
  );
};

const ProjectsMenuSkeleton = () => {
  const { spacing } = useThemeTokens();

  return (
    <x.div p={spacing.inner.base06}>
      <x.div px={spacing.inner.base02}>
        <Typography variant="body-em" pb={spacing.inner.base02}>
          Projects
        </Typography>
      </x.div>
      <x.div display="flex" flexDirection="column" gap="8px">
        <Skeleton variant="noMargin" width="300px" height="36px" />
        <Skeleton variant="noMargin" width="300px" height="36px" />
        <Skeleton variant="noMargin" width="300px" height="36px" />
      </x.div>
    </x.div>
  );
};

const UpcomingCallSectionSkeleton = () => {
  const { spacing } = useThemeTokens();

  return (
    <x.div p={spacing.inner.base06}>
      <x.div mb={spacing.inner.base02}>
        <Skeleton variant="noMargin" width="100px" height="20px" />
      </x.div>
      <x.div display="flex" flexDirection="column" gap="8px">
        <Skeleton variant="noMargin" width="300px" height="16px" />
        <Skeleton variant="noMargin" width="300px" height="40px" />
      </x.div>
      <x.div mt="18px">
        <Skeleton variant="noMargin" width="300px" height="20px" />
      </x.div>
    </x.div>
  );
};
