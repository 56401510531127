import React, { useEffect, useState, useCallback } from "react";
import { NavLink as RouterNavLink, useLocation, useHistory } from "react-router-dom";
import { useCurrentProjectContext } from "../../providers/CurrentProjectProvider";
import { Logo } from "../Logo";
import { ProjectLeadCard } from "../Project/ProjectLeadCard";
import { Spinner } from "../Spinner";
import classNames from "classnames";
import "./index.css";
import { LimitationOfUsageText, useEnv } from "@alphasights/client-portal-shared";
import {
  Avatar,
  Dialog,
  Popover as PopoverAlphaUI,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
  Accordion,
  AccordionItem,
  Link,
  Modal,
  Pill,
  useThemeTokens,
  Icon,
  Popover,
  ListOption,
} from "@alphasights/alphadesign-components";
import {
  ChevronDown,
  Advisor,
  Info,
  Close,
  Menu,
  Intercom as IntercomIcon,
  ExternalLink,
} from "@alphasights/alphadesign-icons";
import styled, { th, x } from "@xstyled/styled-components";
import { useUserInitialsMenu } from "./UserInitialsMenu.styles";
import { ComplianceResources } from "components/ComplianceResources";
import { useGlobalNavStyles } from "./GlobalNav.styles";
import { Textile } from "components/Textile";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { AccessControl } from "components/AccessControl/AccessControl";
import { fetch } from "../../hooks/useApi";
import { useAccessControl } from "hooks/useAccessControl";
import { VIEW_ALPHA_GPT_PERMISSION, VIEW_CONTENT_PERMISSION } from "constants/AlphaNow";
import { Badge } from "components/Badge";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { useCurrentUser, useMyTopProjects } from "@alphasights/portal-auth-react";
import WatchlistModal from "components/WatchlistModal";
import useModal from "hooks/useModal";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge as FeatureFlag } from "models/Badge";
import useWatchlist from "hooks/useWatchlist";
import { UserNotificationsProvider } from "./UserNotificationsContextProvider";
import { useTimezone } from "providers/TimezoneProvider";
import { TIMEZONE, usePreference } from "hooks/usePreference";
import { RealTimeCreditBalance } from "./RealTimeCreditBalance";
import { useNewNavigation, PreferencesModal } from "@alphasights/client-portal-shared";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { PortalNotificationWidget } from "components/SidebarNav/NotificationsOption";

const NavLink = ({
  upperCase = true,
  bold = true,
  highlighted = false,
  className,
  hoverClass = "aui-text-primary-2",
  disabled,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = useCallback(
    (e) => {
      if (disabled) {
        e.preventDefault();
      } else if (isActive && props.to) {
        window.location.href = props.to;
      }
    },
    [isActive, disabled, props.to]
  );

  return (
    <RouterNavLink
      onClick={handleClick}
      className={(active) => {
        setIsActive(active);
        return classNames(
          "aui-block aui-no-underline aui-text-grey-5 aui-transition-color aui-transform aui-duration-200",
          {
            "aui-uppercase": upperCase,
            "aui-font-bold": bold,
            "aui-text-primary-2": highlighted,
          },
          className,
          `hover:${hoverClass}`,
          active && "aui-text-primary-2",
          disabled && "aui-cursor-not-allowed"
        );
      }}
      {...props}
    />
  );
};

const LimitationOfUsageModal = ({ isOpen, onClose }) => {
  const {
    typography: { body },
  } = useThemeTokens();

  if (!isOpen) return null;

  const bodyStyle = {
    ...body.regular,
  };

  return (
    <Modal title="AlphaSights’ Limitations of Use" open={isOpen} onClose={onClose} zIndex={1000} slotHeight="unset">
      <x.div {...bodyStyle}>
        <LimitationOfUsageText />
      </x.div>
    </Modal>
  );
};

export const MobileDialog = styled(Dialog)`
  & > div {
    width: 100%;
    height: 100%;
    > div {
      padding: 0;
    }
  }
`;

const MobileMenu = ({ env, currentView, project, ...props }) => {
  const [showLimitationOfUsage, setShowLimitationOfUsage] = React.useState(false);
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const currentUser = useCurrentUser();
  const topProjects = useMyTopProjects();
  const { logOutCurrentUser } = useClientPortalOperations();

  const onLogOut = () => {
    logOutCurrentUser();
    props.onClose();
  };

  const projectsOpen = () => {
    return props.token || window.location.pathname.includes("/my-projects");
  };

  const styles = useGlobalNavStyles();

  return (
    props.isOpen && (
      <MobileDialog
        {...styles.mobileMenuDialog}
        open={props.isOpen}
        showCloseButton={false}
        zIndex={5}
        slotHeight="100%"
        slotWidth="100%"
      >
        <x.div {...styles.mobileMenuWrapper} data-testid="mobile-menu-options">
          <x.div {...styles.mobileMenuHeaderWrapper}>
            <Close {...styles.mobileMenuCloseButton} onClick={props.onClose} />
            <x.div {...styles.mobileMenuLogo}>
              <Logo className="aui-h-6" />
            </x.div>
            <x.div {...styles.MobileMenuSpacing} />
          </x.div>
          <Divider />
          <Accordion {...styles.mobileMenuAccordion}>
            {currentUser && currentUser.contentEnabled && !currentUser.alphaNowEnabled && (
              <AccordionLink href="/content" dividerBottom={true}>
                Content
              </AccordionLink>
            )}
            {currentUser && currentUser.alphaNowEnabled && <AccordionLink href="/alphanow">AlphaNow</AccordionLink>}
            <AccordionItem title="Projects" variant={projectsOpen() ? "highlight" : "default"} open={projectsOpen()}>
              {hasProjectsPermission && (
                <x.div {...styles.mobileMenuAccordionItem}>
                  <Link variant="nav" href="/my-projects" selected={window.location.pathname.includes("/my-projects")}>
                    <Typography textTransform="none" variant="body-large-em">
                      See All
                    </Typography>
                  </Link>
                  {topProjects.length > 0 &&
                    topProjects.map((project) => (
                      <Link
                        variant="nav"
                        href={`/${project.token}/experts/${currentView}`}
                        selected={project.token === props.token}
                      >
                        <Typography textTransform="none" variant="body-large-em">
                          {project.title}
                        </Typography>
                      </Link>
                    ))}
                </x.div>
              )}
            </AccordionItem>
            <Divider />
            {!currentUser && (
              <AccordionLink href="/sign-in" dividerTop={true} dividerBottom={true}>
                Sign In
              </AccordionLink>
            )}
            {currentUser && (
              <>
                <AccordionItem title="Account">
                  <x.div {...styles.mobileMenuAccordionItem}>
                    <Link variant="nav" href="/my-profile">
                      <Typography textTransform="none" variant="body-large-em">
                        Preferences
                      </Typography>
                    </Link>
                    <Link variant="nav" onClick={() => onLogOut()}>
                      <Typography textTransform="none" variant="body-large-em">
                        Sign Out
                      </Typography>
                    </Link>
                  </x.div>
                </AccordionItem>
                <Divider />
              </>
            )}
            {props.lead && (
              <>
                <ProjectLeadCard lead={props.lead} isMobile={true} />
                <Divider />
              </>
            )}
          </Accordion>
          <x.div {...styles.mobileMenuComplianceList}>
            <List>
              <ComplianceSectionListItems
                isMobile={true}
                user={currentUser}
                setShowLimitationOfUsage={setShowLimitationOfUsage}
              />
            </List>
          </x.div>
          <LimitationOfUsageModal isOpen={showLimitationOfUsage} onClose={() => setShowLimitationOfUsage(false)} />
        </x.div>
      </MobileDialog>
    )
  );
};

const AccordionLink = ({ href, children, dividerTop = false, dividerBottom = false }) => {
  const styles = useGlobalNavStyles();

  return (
    <>
      {dividerTop && <Divider />}
      <x.div {...styles.mobileMenuAccordionLink}>
        <Link variant="nav" href={href} selected={window.location.pathname.includes(href)}>
          <Typography textTransform="none" variant="h3">
            {children}
          </Typography>
        </Link>
      </x.div>
      {dividerBottom && <Divider />}
    </>
  );
};

const getCurrentView = (pathname) => {
  var currentViewRegex = new RegExp(
    ".*(calendar-view|table-view|list-view|messages-view|deliverables-view|alphagpt-view|synthesis).*",
    "g"
  );
  var match = currentViewRegex.exec(pathname);
  return (match && match[1]) || "";
};

const BetaIcon = () => (
  <Pill
    size="small"
    isInteractive={false}
    style={{
      height: "24px",
    }}
  >
    Beta
  </Pill>
);

const NavLinkNoAccess = ({ title }) => {
  const menuTypographyVariant = "body-em";
  return (
    <NavLink to="/noaccess" hoverClass="aui-text-grey-3" disabled>
      <Typography variant={menuTypographyVariant}>{title}</Typography>
    </NavLink>
  );
};

const NavBar = ({ children, className, isLoading, project, ...props }) => {
  const [isMobileMenuOpen, toggleMobileMenu] = useState(false);
  const { lead } = project || {};
  const { pathname } = useLocation();
  const currentUser = useCurrentUser();
  const { logOutCurrentUser, openLoginModal, loginAsClient } = useClientPortalOperations();
  const currentView = getCurrentView(pathname);
  const { isMobile } = useCheckScreen();
  const env = useEnv();

  const menuItems = [];

  if (currentUser && currentUser.contentEnabled && !currentUser.alphaNowEnabled) {
    menuItems.push(
      <MenuItemWithAccessControl key="content" to="/content" title="Content" permission={VIEW_CONTENT_PERMISSION} />
    );
  }

  if (currentUser && currentUser.alphaGPTEnabled) {
    menuItems.push(
      <MenuItemWithAccessControl
        key="alphagpt"
        to="/alphagpt"
        title="AlphaGPT"
        permission={VIEW_ALPHA_GPT_PERMISSION}
        icon={<BetaIcon />}
      />
    );
  }

  if (currentUser && currentUser.alphaNowEnabled) {
    menuItems.push(
      <MenuItemWithAccessControl
        key="alphanow"
        to="/alphanow"
        title="AlphaNow"
        permission={VIEW_CONTENT_PERMISSION}
        icon={currentUser.alphaGPTEnabled === false && <BetaIcon />}
      />
    );
  }

  if (!currentUser?.accessOnly) {
    menuItems.push(
      <div data-testid="myprojects-link">
        <MenuItemWithAccessControl
          key="my-alphasights"
          to={`/${myAlphaSightsBaseUrl}`}
          title="Projects"
          permission="view_projects"
          highlighted={currentView !== "" || pathname.match(/.*\/experts/)}
        />
      </div>
    );
  }

  return (
    <>
      <MobileMenu
        isOpen={isMobileMenuOpen}
        showCloseIcon={false}
        onClose={() => toggleMobileMenu(false)}
        lead={lead}
        env={props.env}
        currentView={currentView}
        project={project}
        {...props}
      />
      <nav
        className={`aui-border-0 aui-border-b aui-border-solid aui-border-grey-2 aui-bg-white aui-z-10 ${className}
        ${isMobile ? "" : "aui-relative"}
        `}
      >
        <ul
          className={`aui-list-none aui-flex aui-flex-row aui-items-center aui-m-0 aui-py-4
          ${isMobile ? "aui-px-6" : "aui-px-8"}
         md:aui-justify-between aui-leading-5`}
        >
          <li
            data-testid="hamburger-mobile"
            className={`focus:aui-outline-none aui-cursor-pointer md:aui-hidden aui-leading-none aui-w-6 aui-flex aui-justify-center ${
              props.hideMenu ? "aui-hidden" : ""
            }`}
            onClick={() => toggleMobileMenu(true)}
          >
            <Menu height={24} width={24} />
          </li>

          <li
            className={`aui-leading-none ${props.hideMenu ? "aui-mr-12" : "aui-flex aui-flex-grow"} ${
              isMobile ? "aui-px-4" : "aui-justify-center"
            }`}
          >
            {isMobile ? <Typography variant="h3">Preferences</Typography> : <Logo className={"aui-h-6 aui-mr-8"} />}
          </li>

          {isMobile && !props.hideMenu && <li className={`aui-w-6`} />}

          <ul
            className={`aui-list-none aui-items-center aui-space-x-5 aui-p-0 aui-m-0
             md:aui-flex aui-w-full aui-hidden`}
          >
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {item}
                {index < menuItems.length - 1 && <Separator />}
              </React.Fragment>
            ))}

            <li className="md:aui-flex-grow md:aui-flex md:aui-justify-end md:aui-items-center">
              {currentUser?.accessOnly && <RealTimeCreditBalance />}
              {env.enableIntercom && currentUser && <IntercomLauncher />}
              <x.div paddingTop={"5px"} marginRight={"15px"}>
                <PortalNotificationWidget />
              </x.div>
              <ComplianceSection />
              <UserInitialsMenu
                user={currentUser}
                project={project}
                onLogOut={logOutCurrentUser}
                onLoginStart={openLoginModal}
                loginAsClient={loginAsClient}
              />
            </li>
          </ul>
        </ul>
        {isLoading && <Spinner className="aui-mx-auto aui-block aui-p-8" />}
        {children}
      </nav>
    </>
  );
};

const Separator = () => <li className="aui-border aui-border-solid aui-border-grey-2 aui-h-4" />;

const MenuItemWithAccessControl = ({ to, title, permission, icon, ...props }) => (
  <li className="aui-flex aui-flex-row aui-items-center aui-space-x-3">
    <AccessControl allowedPermissions={[permission]} renderNoAccess={() => <NavLinkNoAccess title={title} />}>
      <NavLinkWithTitle to={to} title={title} {...props} />
    </AccessControl>
    {icon}
  </li>
);

const NavLinkWithTitle = ({ to, title, ...props }) => {
  const menuTypographyVariant = "body-em";
  return (
    <NavLink to={to} {...props}>
      <Typography variant={menuTypographyVariant}>{title}</Typography>
    </NavLink>
  );
};

const IntercomLauncher = () => {
  const [open, setOpen] = useState(false);
  const [intercomBooted, setIntercomBooted] = useState(false);
  const { intercomLauncherIcon, intercomLauncherWrapper } = useGlobalNavStyles({
    intercomMessengerOpen: open,
    intercomBooted,
  });

  const Intercom = window.Intercom;

  const openIntercomPanel = () => {
    Intercom(open ? "hide" : "show");
    setOpen((open) => !open);
  };

  const checkIntercomBooted = useCallback(() => {
    const timeout = setTimeout(() => clearInterval(interval), 30000);
    const interval = setInterval(() => {
      if (Intercom.booted) {
        setIntercomBooted(true);
        clearInterval(interval);
        clearTimeout(timeout);
      }
    }, 100);
  }, [Intercom]);

  useEffect(() => {
    checkIntercomBooted();
    Intercom("onHide", () => {
      setOpen(false);
    });
  }, [Intercom, checkIntercomBooted]);

  return (
    <x.div {...intercomLauncherWrapper}>
      <Icon onClick={intercomBooted ? openIntercomPanel : undefined} {...intercomLauncherIcon}>
        <IntercomIcon />
      </Icon>
    </x.div>
  );
};

const ComplianceSection = () => {
  const ref = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showComplianceModal, setShowComplianceModal] = React.useState(false);
  const [videoPlaying, setVideoPlaying] = React.useState(null);
  const [complianceModalContent, setComplianceModalContent] = React.useState({});
  const [showLimitationOfUsage, setShowLimitationOfUsage] = React.useState(false);
  const styles = useGlobalNavStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openComplianceModal = ({ title, description }) => {
    setComplianceModalContent({ title, description });
    setShowComplianceModal(true);
    handlePopoverClose();
  };

  const showComplianceVideo = ({ videoId }) => {
    setVideoPlaying(videoId);
    handlePopoverClose();
  };

  const popoverOpen = Boolean(anchorEl);

  return (
    <>
      <x.div onMouseEnter={handlePopoverOpen} {...styles.compliancePopoverIcon}>
        <Info {...styles.compliancePopoverIconSize} />
      </x.div>

      <PopoverAlphaUI
        ref={ref}
        anchorEl={anchorEl}
        open={popoverOpen}
        onClose={handlePopoverClose}
        leaveDelay={100}
        closeOnMouseLeave={true}
        placement="bottom-end"
        zIndex="100"
        {...styles.compliancePopover}
      >
        <x.div {...styles.compliancePopoverContent}>
          <ComplianceResources
            showComplianceVideoCallback={showComplianceVideo}
            openComplianceModal={openComplianceModal}
          />
        </x.div>
        <List>
          <Divider />
          <x.div {...styles.compliancePopoverContentLinks}>
            <ComplianceSectionListItems
              isMobile={false}
              handlePopoverClose={handlePopoverClose}
              setShowLimitationOfUsage={setShowLimitationOfUsage}
            />
          </x.div>
        </List>
      </PopoverAlphaUI>
      {showComplianceModal && (
        <Dialog
          open={showComplianceModal}
          onClose={() => {
            setShowComplianceModal(false);
          }}
          zIndex={5}
          title={complianceModalContent.title}
        >
          {complianceModalContent.description && <Textile>{complianceModalContent.description}</Textile>}
        </Dialog>
      )}
      {videoPlaying && (
        <Dialog
          open={videoPlaying}
          onClose={() => {
            setVideoPlaying(null);
          }}
          zIndex={5}
          slotHeight="100%"
          slotWidth="100%"
        >
          <x.div {...styles.complianceVideoSize} className={`wistia_embed wistia_async_${videoPlaying}`} />
        </Dialog>
      )}
      <LimitationOfUsageModal isOpen={showLimitationOfUsage} onClose={() => setShowLimitationOfUsage(false)} />
    </>
  );
};

export const ComplianceSectionListItems = ({ isMobile, handlePopoverClose = undefined, setShowLimitationOfUsage }) => {
  const styles = useGlobalNavStyles({ isMobile });
  const currentUser = useCurrentUser();

  const termsUrl = currentUser?.accessOnly
    ? "https://www.alphasights.com/subscription-user-platform-terms/"
    : "https://www.alphasights.com/clientfacingdomainterms-platformterms/";

  const openExternalUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <>
        <ListItem onClick={() => openExternalUrl(termsUrl)}>
          <ListItemText {...styles.complianceListText} primary="Platform Terms" />
        </ListItem>
        <ListItem onClick={() => openExternalUrl("https://www.alphasights.com/clientfacingdomainterms-dpa/")}>
          <ListItemText {...styles.complianceListText} primary="Data Processing Agreement" />
        </ListItem>
        <ListItem onClick={() => openExternalUrl("https://www.alphasights.com/policies/")}>
          <ListItemText {...styles.complianceListText} primary="Privacy & Cookie Policy" />
        </ListItem>
        <ListItem className="cky-banner-element" onClick={() => {}}>
          <ListItemText {...styles.complianceListText} primary="Manage Cookies" />
        </ListItem>
      </>
      {isMobile && (
        <ListItem>
          <Typography variant="body-small" {...styles.complianceListText}>
            © 2020 AlphaSights Ltd. All Rights Reserved.
          </Typography>
        </ListItem>
      )}
    </>
  );
};

export const LimitationOfUsageBar = ({ onClick, bgColor = "grey-4", textAlign = "left" }) => {
  const [showLimitationOfUsage, setShowLimitationOfUsage] = useState(false);
  return (
    <>
      <div
        className={`aui-px-4 aui-flex-1 aui-py-2 aui-bg-${bgColor} aui-text-white aui-text-sm aui-text-${textAlign}`}
      >
        <span>By accessing this secure webpage, you agree to </span>
        <span
          onClick={() => setShowLimitationOfUsage(true)}
          className="aui-cursor-pointer aui-underline aui-text-sm aui-whitespace-no-wrap"
        >
          AlphaSights’ Limitations of Use
        </span>
      </div>

      <LimitationOfUsageModal isOpen={showLimitationOfUsage} onClose={() => setShowLimitationOfUsage(false)} />
    </>
  );
};

const InteractableAvatar = styled(Avatar)`
  &:hover {
    background-color: ${th.color("background-action-secondary-pressed")};
  }
`;

export const extractInitials = (name) => {
  const firstLetter = name[0];
  const lastSpacePosition = name.lastIndexOf(" ");
  const lastLetter = lastSpacePosition !== -1 ? name[lastSpacePosition + 1] : "";
  return firstLetter + lastLetter;
};

export const UserInitialsMenu = ({ user, project, onLogOut, onLoginStart, loginAsClient }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isClientProfilesLoading, setIsClientProfilesLoading] = useState(true);
  const ref = React.useRef(null);
  const [clientProfilesUsers, setClientProfilesUsers] = React.useState([]);
  const location = useLocation();
  const ptoUrl = () => "/api/auth-user/client-contact-page";
  const { isVisible, onOpen, onClose } = useModal();
  const { chargebeeSubdomain } = useEnv();
  const currentUser = useCurrentUser();

  // TODO [RD1-51]: Remove Watchlist Badge
  const { hasUserBadge } = useUserBadgeContext();
  const displayWatchlist = hasUserBadge(FeatureFlag.watchlist) && currentUser.alphaNowEnabled;
  const { availableWatchlists } = useWatchlist();

  const [isPreferenceModalOpen, setIsPreferenceModalOpen] = useState(false);
  const [, { updatePreference }] = usePreference(TIMEZONE);

  useEffect(() => {
    if (user?.internalUser && project) {
      setIsClientProfilesLoading(true);
      fetch({
        url: `/api/projects/${project.token}/client-profiles`,
        skipAlert: true,
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setClientProfilesUsers(data);
          setIsClientProfilesLoading(false);
        })
        .catch(() => {
          setClientProfilesUsers([]);
          setIsClientProfilesLoading(false);
        });
    }
  }, [project, user]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (actionCallback) => {
    actionCallback();
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);
  const active = open || location.pathname === "/my-profile";

  const { body, dropdownButton, loggedInAsWrapper } = useUserInitialsMenu({ active });

  const history = useHistory();

  const {
    color: { background, text },
    font,
  } = useThemeTokens();

  const addCreditsUrl = `https://${chargebeeSubdomain}.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=credits-${user?.servicePlan?.currency}&subscription_items[quantity][0]=1&layout=full_page`;
  const chargebeePortalUrl = `https://${chargebeeSubdomain}.chargebeeportal.com`;

  const searchParams = new URLSearchParams(location.search);
  const watchlistQueryParam = "manageWatchlist";
  const handleOpenWatchlist = () => {
    onOpen();
    searchParams.set(watchlistQueryParam, true);
    history.push({ search: searchParams.toString() });
  };

  const handleCloseWatchlist = () => {
    onClose();
    searchParams.delete(watchlistQueryParam);
    history.push({ search: searchParams.toString() });
  };

  const onUpdateUser = (field, value) => {
    if (field === "timezone") {
      timezoneCtx.setCurrentTimezone(value);
      updatePreference({ timezone: value });
    } else {
      console.info(field, value);
    }
  };

  const timezoneCtx = useTimezone();

  const isWatchlistModalVisible = displayWatchlist && (isVisible || searchParams.get(watchlistQueryParam));

  return (
    <x.div {...loggedInAsWrapper}>
      {user?.internalUser?.clientProfileId && (
        <Badge
          tooltipText={false}
          backgroundColor={background.neutral.default}
          textColor={text.strong._}
          onClick={(e) => {
            e.stopPropagation();
            window.open(ptoUrl());
          }}
          value={
            <Typography variant={"body-small"} component={"span"}>
              Viewing as:{" "}
              <Typography variant={"body-small"} component={"span"} fontWeight={font.weight.semiBold}>
                {user.name}
              </Typography>
            </Typography>
          }
        />
      )}
      <x.div {...body} onMouseEnter={handlePopoverOpen} data-testid="user-dropdown">
        <InteractableAvatar
          size="small"
          data-testid={`user-signed-${user ? "in" : "out"}-avatar`}
          text={user?.name}
          color="base02"
        >
          {!user && <Advisor />}
        </InteractableAvatar>
        <x.span {...dropdownButton} data-testid="user-dropdown-hover-icon">
          <ChevronDown></ChevronDown>
        </x.span>
        <Popover
          ref={ref}
          open={open}
          anchorEl={anchorEl}
          closeOnMouseLeave={true}
          onClose={handlePopoverClose}
          placement="bottom-end"
          leaveDelay={200}
          enterDelay={100}
          zIndex={100}
          width={user?.accessOnly ? "280px" : null}
        >
          {user ? (
            <>
              <InternalUserLoginAsClient
                user={user}
                project={project}
                clientProfilesUsers={clientProfilesUsers}
                loginAsClient={loginAsClient}
                isLoading={isClientProfilesLoading}
              />
              {displayWatchlist && (
                <ListOption
                  type="text"
                  label="Watchlists"
                  selected={false}
                  onChange={handleOpenWatchlist}
                  dataAttributes={{ "data-testid": "watchlist-button" }}
                />
              )}
              {user?.accessOnly && !user?.servicePlan?.trial && (
                <ListOption
                  type="text"
                  label="Add Credits"
                  selected={false}
                  onChange={() => window.open(addCreditsUrl, "_blank")}
                  rightIcon={<ExternalLink />}
                  dataAttributes={{ "data-testid": "add-credits-button" }}
                />
              )}
              {user?.accessOnly && (
                <ListOption
                  type="text"
                  label="Manage Subscription"
                  selected={false}
                  onChange={() => window.open(chargebeePortalUrl, "_blank")}
                  rightIcon={<ExternalLink />}
                  dataAttributes={{ "data-testid": "manage-subscription-button" }}
                />
              )}
              <ListOption
                type="text"
                label="Preferences"
                selected={false}
                onChange={() => {
                  setIsPreferenceModalOpen(true);
                  handlePopoverClose();
                }}
                dataAttributes={{ "data-testid": "my-profile-button" }}
              />
              <ListOption
                type="text"
                label="Sign Out"
                selected={false}
                onChange={() => handleMenuClick(onLogOut)}
                dataAttributes={{ "data-testid": "logout-button" }}
              />
            </>
          ) : (
            <ListOption
              type="text"
              label="Sign In"
              selected={false}
              onChange={() => handleMenuClick(onLoginStart)}
              dataAttributes={{ "data-testid": "login-button" }}
            />
          )}
        </Popover>
      </x.div>
      {isWatchlistModalVisible && (
        <WatchlistModal availableWatchlists={availableWatchlists} onClose={handleCloseWatchlist} />
      )}
      {isPreferenceModalOpen && (
        <PreferencesModal
          isOpen
          onClose={() => setIsPreferenceModalOpen(false)}
          onLogout={onLogOut}
          timezone={timezoneCtx.currentTimezone}
          onUpdateUser={onUpdateUser}
        />
      )}
    </x.div>
  );
};

export const InternalUserLoginAsClient = ({ user, project, clientProfilesUsers, loginAsClient, isLoading }) => {
  const { clientProfileUsersDivider, clientProfileUsersSpinner } = useUserInitialsMenu({});

  return user.internalUser && project ? (
    <>
      <ListOption type="title" label="Select User" size="large" />
      {isLoading ? (
        <x.div {...clientProfileUsersSpinner}>
          <Spinner />
        </x.div>
      ) : (
        clientProfilesUsers.map((clientProfile) => (
          <ListOption
            key={clientProfile.id}
            type="text"
            label={clientProfile.name}
            selected={clientProfile.id === user.internalUser.id}
            leftIcon={<Advisor />}
            onChange={() => {
              loginAsClient({
                clientProfileId: clientProfile.id,
              });
            }}
            dataAttributes={{ "data-testid": "logout-button" }}
          />
        ))
      )}
      <x.div {...clientProfileUsersDivider} />
    </>
  ) : null;
};

export const GlobalNav = React.forwardRef((props, ref) => {
  const env = useEnv();

  const { project, isLoading } = useCurrentProjectContext();

  const { isMobile } = useCheckScreen();

  const currentUser = useCurrentUser();
  const newNavigationEnabled = useNewNavigation();

  if (isMobile) {
    return null;
  }

  return newNavigationEnabled ? null : (
    <div ref={ref} data-testid="global-nav">
      <UserNotificationsProvider enabled={!!currentUser}>
        <NavBar {...{ project, isLoading, ...props }} env={env} />
      </UserNotificationsProvider>
    </div>
  );
});
