import React from "react";

import { ActionColumn } from "./ActionColumn";
import { AdvisorColumn } from "./AdvisorColumn";
import { StarColumn } from "./StarColumn";
import { HideColumn } from "./HideColumn";
import { StatusColumn } from "./StatusColumn";
import { HideableCell } from "./HideableCell";
import { LabelColumn } from "./LabelColumn";
import { HighlightTextTypography } from "../../components/AdvisorCard/HighlightText";
import { WrappingPopover } from "../../components/Popover";
import { Icon } from "../../components/alphaui";
import { Typography } from "@alphasights/alphadesign-components";
import { CommentIcon } from "components/CommentThread/CommentIcon";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { CidBadge } from "pages/InteractionPage/sections/InteractionHeader/InteractionHeader";
import { useExpertRemarksContext } from "../../providers/ExpertRemarksProvider";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { getRolePeriodDuration } from "helpers/displayHelpers";
import { MessageStatusColumn } from "./MessageStatusColumn";
import { useMessageThreadContext } from "pages/MessengerPage/context/MessageThreadContext";
import { useLabelsContext } from "providers/LabelsProvider";
import { priority } from "providers/MessengerProvider";

const statusOrder = ["available", "requested", "scheduled", "completed"];

export default ({ interactions, onSelectCard, preferencesEnabled, project, userCidEnabled, origin }) => {
  preferencesEnabled = preferencesEnabled && project.active;
  const { getWorkRequestStatus } = useMessageThreadContext();
  const { getLabelledExpert } = useLabelsContext();

  const groupOf = (interaction) => {
    const { group } = interaction;
    return group.parent ? group.parent.name : group.name;
  };

  const subGroupOf = (interaction) => {
    const { group } = interaction;
    return group.parent ? group.name : null;
  };

  const enrichInteraction = (interaction) => {
    return {
      runningActionIfIncludes: (actions) =>
        actions.includes(interaction.runningAction) ? interaction.runningAction : null,
      ...interaction,
    };
  };

  const sortTenure = (row1, row2) => {
    const period1 = getRolePeriodDuration(row1.original.rolePeriod);
    const period2 = getRolePeriodDuration(row2.original.rolePeriod);

    return period1 > period2 ? 1 : -1;
  };

  const sortLabels = (row1, row2) => {
    const expertId1 = row1.original.advisorId;
    const angle1 = row1.original.angles?.at(0);
    const angleId1 = angle1 && angle1?.id;
    const label1 = getLabelledExpert(expertId1, angleId1);

    const expertId2 = row2.original.advisorId;
    const angle2 = row2.original.angles?.at(0);
    const angleId2 = angle2 && angle2?.id;
    const label2 = getLabelledExpert(expertId2, angleId2);

    return label1?.label.text.localeCompare(label2?.label.text, undefined, { sensitivity: "accent" });
  };

  const sortStatus = (row1, row2) => {
    return statusOrder.indexOf(row1.original.state) > statusOrder.indexOf(row2.original.state) ? 1 : -1;
  };

  const sortMessageStatus = (row1, row2) => {
    const row1Status = getWorkRequestStatus({
      expertId: row1.original.advisorId,
    });
    const row2Status = getWorkRequestStatus({
      expertId: row2.original.advisorId,
    });

    return priority.indexOf(row2Status[0]?.state.description) > priority.indexOf(row1Status[0]?.state.description)
      ? 1
      : -1;
  };

  const { isExpertHidden } = useExpertRemarksContext();

  const defaultColumnConfig = {
    enablePin: preferencesEnabled,
    enableDragDrop: preferencesEnabled,
    sortAscMeta: {
      label: "Sort A-Z",
    },
    sortDescMeta: {
      label: "Sort Z-A",
    },
  };

  return {
    actionColumn: {
      id: "actionColumn",
      header: "",
      cell: ({ row: { original: interaction } }) => (
        <div className="action-column" data-testid={`action-column-${interaction.id}`}>
          <ActionColumn origin={origin} interaction={interaction} />
        </div>
      ),
      meta: {
        enableColumnActions: false,
        enableDragDrop: false,
        enableResizing: false,
      },
      size: 170,
      equals: (value, other) => {
        const runningAction = (interaction) =>
          enrichInteraction(interaction).runningActionIfIncludes(["request", "schedule"]);
        return (
          value.state === other.state &&
          value.hidden === other.hidden &&
          value.followUpId === other.followUpId &&
          runningAction(value) === runningAction(other)
        );
      },
    },

    groupColumn: {
      id: "groupColumn",
      header: "Angle",
      accessorFn: (row) => groupOf(row),
      sortingFn: "alphanumeric",
      size: 180,
      minSize: 94,
      cell: ({ row: { original: interaction } }) => (
        <HideableCell hidden={isExpertHidden({ data: interaction })}>
          <WrappingPopover text={groupOf(interaction)} data-testid={`advisor-row-group-${interaction.id}`} />
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    subGroupColumn: {
      id: "subGroupColumn",
      header: "Sub-Angle",
      size: 180,
      minSize: 125,
      accessorFn: (row) => subGroupOf(row),
      sortingFn: "alphanumeric",
      cell: ({ row: { original: interaction } }) => (
        <HideableCell hidden={isExpertHidden({ data: interaction })}>
          <WrappingPopover text={subGroupOf(interaction)} data-testid={`advisor-row-subgroup-${interaction.id}`} />
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    expertColumn: {
      id: "advisorColumn",
      header: "Expert",
      minSize: 94,
      cell: AdvisorColumn,
      accessorKey: "advisorName",
      sortingFn: "alphanumeric",
      meta: { ...defaultColumnConfig },
    },

    cidStatusColumn: {
      id: "cidColumn",
      header: "CID Status",
      accessorFn: (row) => row.externalCidStatus,
      sortingFn: "alphanumeric",
      minSize: 125,
      size: 125,
      cell: ({ row: { original: interaction } }) => {
        return (
          <HideableCell hidden={false}>
            <CidBadge
              userCidEnabled={userCidEnabled}
              externalCidStatus={interaction.externalCidStatus}
              useFullWidth
            ></CidBadge>
          </HideableCell>
        );
      },
      meta: { ...defaultColumnConfig },
    },

    companyColumn: {
      id: "companyColumn",
      header: "Relevant Company",
      accessorKey: "advisorCompany",
      sortingFn: "alphanumeric",
      minSize: 176,
      cell: ({
        row: {
          original: { id, highlights, advisorCompany, hidden, angles, advisorId },
        },
      }) => (
        <HideableCell
          hidden={isExpertHidden({
            data: { hidden, angles, advisorId },
          })}
        >
          <WrappingPopover text={advisorCompany} data-testid={`advisor-row-company-${id}`}>
            <HighlightTextTypography
              variant="body-small"
              component="span"
              highlights={highlights}
              fieldNames={[
                "advisorships.relevant_company_name",
                "advisorships.relevant_company_name.concat",
                "relevantPrimaryJob.companyName",
                "relevantPrimaryJob.companyName.concat",
              ]}
              text={advisorCompany}
            />
          </WrappingPopover>
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    relevantPositionColumn: {
      id: "positionColumn",
      header: "Relevant Position",
      accessorKey: "role",
      sortingFn: "alphanumeric",
      minSize: 184,
      cell: ({
        row: {
          original: { id, role, hidden, highlights, angles, advisorId },
        },
      }) => (
        <HideableCell
          hidden={isExpertHidden({
            data: { hidden, angles, advisorId },
          })}
        >
          <WrappingPopover text={role} data-testid={`advisor-row-role-${id}`}>
            <HighlightTextTypography
              variant="body-small"
              component="span"
              highlights={highlights}
              fieldNames={[
                "advisorships.relevant_job_title",
                "advisorships.relevant_job_title.concat",
                "relevantPrimaryJob.jobTitle",
                "relevantPrimaryJob.jobTitle.concat",
              ]}
              text={role}
            />
          </WrappingPopover>
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    tenureColumn: {
      id: "tenureColumn",
      header: "Tenure",
      accessorKey: "rolePeriod",
      sortingFn: sortTenure,
      minSize: 150,
      cell: ({ row: { original: interaction } }) => (
        <HideableCell hidden={isExpertHidden({ data: interaction })}>
          <Typography variant="body-small" component="span">
            {interaction.rolePeriod}
          </Typography>
        </HideableCell>
      ),
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort Shortest-Longest",
        },
        sortDescMeta: {
          label: "Sort Longest-Shortest",
        },
      },
    },

    geographicKnowledgeColumn: {
      id: "geographicKnowledgeColumn",
      header: "Geographic Knowledge",
      hideIf: ({ hasColumnPreferences }) => !hasColumnPreferences || !interactions.some((i) => i.geographies.length),
      minSize: 210,
      sortingFn: "alphanumeric",
      cell: ({ row: { original: interaction } }) => (
        <HideableCell hidden={isExpertHidden({ data: interaction })}>
          <WrappingPopover
            text={interaction.geographies.join(", ")}
            data-testid={`advisor-row-geographic-knowledge-${interaction.id}`}
          />
        </HideableCell>
      ),
      meta: { ...defaultColumnConfig },
    },

    statusColumn: {
      id: "statusColumn",
      header: "Call Status",
      accessorKey: "state",
      sortingFn: sortStatus,
      minSize: 220,
      cellStyle: function ({ data: interaction }) {
        return {
          padding: 0,
        };
      },
      equals: (value, other) => {
        return (
          value.followUpId === other.followUpId &&
          value.state === other.state &&
          value.hidden === other.hidden &&
          value.pendingRequest === other.pendingRequest
        );
      },
      cell: StatusColumn,
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort First to Last",
        },
        sortDescMeta: {
          label: "Sort Last to First ",
        },
      },
    },

    labelColumn: {
      id: "labelColumn",
      header: "Label",
      accessorKey: "label",
      sortingFn: sortLabels,
      minSize: 220,
      cell: LabelColumn,
      meta: {
        ...defaultColumnConfig,
        columnDescription: "Labels are visible to your colleagues and your AlphaSights team.",
        sortAscMeta: {
          label: "Sort A-Z",
        },
        sortDescMeta: {
          label: "Sort Z-A",
        },
      },
    },

    messageStatusColumn: {
      id: "messageStatusColumn",
      header: "Message Status",
      accessorKey: "state",
      sortingFn: sortMessageStatus,
      minSize: 220,
      cellStyle: function ({ data: interaction }) {
        return {
          padding: 0,
        };
      },
      equals: (value, other) => {
        return (
          value.followUpId === other.followUpId &&
          value.state === other.state &&
          value.hidden === other.hidden &&
          value.pendingRequest === other.pendingRequest
        );
      },
      cell: MessageStatusColumn,
      meta: {
        ...defaultColumnConfig,
        columnDescription: (
          <Typography variant="body-small">View Call Guide recipients in the Messages Tab.</Typography>
        ),
        columnDescriptionPopoverProps: {
          maxW: "200px",
          placement: "bottom-start",
        },
        sortAscMeta: {
          label: "Sort First to Last",
        },
        sortDescMeta: {
          label: "Sort Last to First ",
        },
      },
    },

    starColumn: {
      id: "starColumn",
      header: "",
      cell: StarColumn,
      size: 52,
      minSize: 52,
      enableResizing: false,
      equals: (value, other) => {
        const runningAction = (interaction) => enrichInteraction(interaction).runningActionIfIncludes(["toggleStar"]);
        return (
          value.starred === other.starred &&
          value.hidden === other.hidden &&
          runningAction(value) === runningAction(other)
        );
      },
      meta: {
        columnName: "Star",
        enableSorting: false,
        ...defaultColumnConfig,
      },
    },

    hideColumn: {
      id: "hideColumn",
      header: "",
      enableResizing: false,
      cell: HideColumn,
      size: 52,
      minSize: 52,
      equals: (value, other) => {
        const runningAction = (interaction) => enrichInteraction(interaction).runningActionIfIncludes(["toggleHidden"]);
        return value.hidden === other.hidden && runningAction(value) === runningAction(other);
      },
      meta: {
        columnName: "Hide",
        enableSorting: false,
        ...defaultColumnConfig,
      },
    },

    showProfileColumn: {
      id: "showProfile",
      header: "",
      size: 52,
      minSize: 52,
      enableResizing: false,
      cellClass: ["aui-items-center", "aui-shadow", "aui-justify-center"],
      cellStyle: { padding: 0, display: "inline-flex" },
      cell: ({ row: { original: data } }) => (
        <Icon icon="advisor" className="aui-text-primary-1 aui-text-xl" onClick={() => onSelectCard(data)} />
      ),
      meta: {
        columnName: "Show Profile",
        ...defaultColumnConfig,
      },
    },

    commentsColumn: {
      id: "commentsColumn",
      header: "",
      enableResizing: false,
      cell: ({ row: { original: data } }) => (
        <CommentIconWithLoginWall
          angleId={data.angles?.at(0)}
          expertId={data.advisorId}
          tooltip="Comment"
          interaction={data}
        />
      ),
      size: 52,
      minSize: 52,
      meta: {
        columnName: "Comments",
        enableSorting: false,
        ...defaultColumnConfig,
      },
    },

    proposedAtColumn: {
      id: "proposedAtColumn",
      header: "Date Added",
      accessorKey: "proposedAt",
      minSize: 130,
      cell: ({ row: { original: data } }) => {
        return <FormattedDateTime date={data.proposedAt} format="d LLL, h:mmaaa" />;
      },
      size: 45,
      meta: {
        ...defaultColumnConfig,
        sortAscMeta: {
          label: "Sort Oldest to Newest",
        },
        sortDescMeta: {
          label: "Sort Newest to Oldest ",
        },
      },
    },
  };
};

const CommentIconWithLoginWall = withLoginWall(CommentIcon);
