import React, { ForwardedRef, forwardRef } from "react";
import { x } from "@xstyled/styled-components";
import { XStyledAndHTMLProps, useThemeTokens } from "@alphasights/alphadesign-components";

const Card = forwardRef(({ children, ...props }: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    color: { background, border: borderColor },
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();

  return (
    <x.div
      ref={ref}
      p={inner.base06}
      mt={inner.base06}
      bg={background.surface.page.default}
      position="relative"
      borderRadius={border.radius.medium}
      borderWidth={border.width.large}
      borderColor={borderColor.neutral.default}
      {...props}
    >
      {children}
    </x.div>
  );
});

type CardProps = {
  children: React.ReactNode | React.ReactNode[] | string;
} & XStyledAndHTMLProps<HTMLDivElement>;

export default Card;
