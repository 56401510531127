import React, { useState, useCallback } from "react";
import { useMutation } from "react-query";
import { x } from "@xstyled/styled-components";
import {
  Modal,
  Typography,
  TextField,
  useThemeTokens,
  Button,
  Icon,
  useAlphaToast,
} from "@alphasights/alphadesign-components";
import { AddCircleOutline } from "@alphasights/alphadesign-icons";
import { contentService } from "services/content";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { unsuccessfulRequest } from "content/AlphaNow";
import RequestPrimerModalRow from "./RequestPrimerModalRow";
import "./RequestPrimer.css";
import { CommissionPrimerProperties } from "pages/AlphaNowPage/primers/CommissionPrimer/constants";

export type ModalPrimerRow = {
  [key: string]: {
    value: string;
    isValid: boolean;
  };
}[];

type RequestForm = {
  requestedPrimers: ModalPrimerRow;
  caseCode: string;
};

//add property "child" for sub-bullet points
const requestPrimerModalInformationList = {
  title: "Information",
  listItems: [
    {
      value: "We will review your commission for feasibility and compliance",
      property: "root",
    },
    {
      value: "You will receive the Primer within 10 working days",
      property: "root",
    },

    {
      value: "Payment will be taken on delivery",
      property: "root",
    },

    {
      value: "The commissioned Primer will be available for other AlphaNow clients to purchase",
      property: "root",
    },
  ],
};

const RequestPrimerModal = ({
  onClose,
  onPrimerRequested,
  logAction = true,
}: {
  onClose: () => void;
  onPrimerRequested?: () => void;
  logAction?: boolean;
}) => {
  const {
    color: { text },
    spacing: { inner },
    font: { size },
  } = useThemeTokens();
  const { toast } = useAlphaToast();
  const { logHit } = useTrackUserAction();

  const initialState = {
    requestedPrimers: [
      {
        requestedCompanyName: {
          value: "",
          isValid: true,
        },
        requestedCompanyWebsite: {
          value: "",
          isValid: true,
        },
      },
    ],
    caseCode: "",
  };

  const [requestForm, setRequestForm] = useState<RequestForm>(initialState);

  const { mutate: sendPrimerRequest } = useMutation(
    () =>
      contentService.postPrimerRequest({
        ...requestForm,
        requestedPrimers: requestForm.requestedPrimers.map((primer) => ({
          requestedCompanyName: primer.requestedCompanyName.value,
          requestedCompanyWebsite: primer.requestedCompanyWebsite.value,
        })),
      }),
    {
      onError: () => toast.error({ message: unsuccessfulRequest }),
      onSuccess: () => {
        toast.success({
          message:
            "Thank you for your request. You will be notified within 10 working days by email when this Company Primer is available",
        });
        onClose();
        onPrimerRequested && onPrimerRequested();
      },
    }
  );

  const onConfirm = () => {
    if (onConfirmValidation()) {
      sendPrimerRequest();
      logAction &&
        logHit({
          origin: HitOrigin.alphaNow,
          action: HitAction.alphaNowRequestPrimer,
        });
    }
  };

  const onConfirmValidation = () => {
    const tempForm = { ...requestForm };

    tempForm.requestedPrimers.map(
      // eslint-disable-next-line array-callback-return
      ({ requestedCompanyName, requestedCompanyWebsite }) => {
        requestedCompanyName.isValid = requestedCompanyName.value !== "";
        requestedCompanyWebsite.isValid = requestedCompanyWebsite.value !== "";
      }
    );

    setRequestForm(tempForm);

    return !tempForm.requestedPrimers.some(
      (primer) => !primer.requestedCompanyName.isValid || !primer.requestedCompanyWebsite.isValid
    );
  };

  const addRow = () => {
    setRequestForm({
      ...requestForm,
      requestedPrimers: [
        ...requestForm.requestedPrimers,
        {
          requestedCompanyName: {
            value: "",
            isValid: true,
          },

          requestedCompanyWebsite: {
            value: "",
            isValid: true,
          },
        },
      ],
    });
  };

  const deleteRow = (index: number) => {
    let updatedRows = [...requestForm.requestedPrimers];

    updatedRows = updatedRows.filter((_, i) => i !== index);

    setRequestForm({
      ...requestForm,
      requestedPrimers: updatedRows,
    });
  };

  const onChangeFormValues = useCallback(
    (e: React.FormEvent<HTMLInputElement>, property: string, index?: number) => {
      const newValue = e.currentTarget.value;
      let updatedForm = { ...requestForm };

      if (
        index !== undefined &&
        (property === CommissionPrimerProperties.REQUESTED_COMPANY_NAME ||
          CommissionPrimerProperties.REQUESTED_COMPANY_WEBSITE)
      ) {
        updatedForm.requestedPrimers[index][property].value = newValue;
        updatedForm.requestedPrimers[index][property].isValid = true;
      }
      if (property === "caseCode") {
        updatedForm[property] = newValue;
      }
      setRequestForm(updatedForm);
    },
    [requestForm]
  );

  return (
    <Modal
      title={
        <Typography variant="h3" color={text.strong._} style={{ textTransform: "none" }}>
          Commission a Primer for AlphaNow
        </Typography>
      }
      open
      onClose={onClose}
      slotWidth="555px"
      slotHeight="440px"
      variant="complex"
      zIndex={100}
      shouldShowFooter={true}
      primaryButton={
        <Button variant="secondary" onClick={onConfirm}>
          Confirm Commission
        </Button>
      }
      secondaryButton={
        <Button marginRight={inner.base04} variant="ghost" onClick={onClose}>
          Cancel
        </Button>
      }
    >
      <x.div pb={inner.base04} data-testid="container-modal-content">
        <Typography color={text.strong._}>Commission a Primer for 2 credits</Typography>
      </x.div>
      <x.div
        display="flex"
        flexDirection="column"
        gap={inner.base04}
        pb={inner.base05}
        data-testid="section-company-info-rows"
      >
        {requestForm.requestedPrimers.map((row, index) => (
          <RequestPrimerModalRow
            index={index}
            company={row.requestedCompanyName}
            website={row.requestedCompanyWebsite}
            hasDeleteButton={requestForm.requestedPrimers.length > 1}
            deleteRow={() => deleteRow(index)}
            onChange={onChangeFormValues}
          />
        ))}
      </x.div>
      <x.div display="flex" justifyContent="flex-end" data-testid="add-row-btn-container" pb={inner.base04}>
        <Button variant="outline" onClick={addRow} size="small" data-testid="add-modal-row-button">
          <x.div display="flex" gap={inner.base02}>
            <Icon size="small">
              <AddCircleOutline />
            </Icon>
            <Typography variant="body-small-em">Commission Another Primer</Typography>
          </x.div>
        </Button>
      </x.div>
      <x.div display="flex" flexDirection="column" gap={inner.base02} data-testid="section-case-code-input">
        <Typography color={text.secondary}>Case code (optional)</Typography>
        <TextField
          onChange={(e) => onChangeFormValues(e, "caseCode")}
          style={{ fontSize: size["03"] }}
          placeholder="Input text"
          value={requestForm?.caseCode}
        />
      </x.div>
      <x.div data-testid="section-information" pt={inner.base04} className="list">
        <Typography color={text.secondary} paddingBottom={inner.base02}>
          {requestPrimerModalInformationList.title}
        </Typography>
        {requestPrimerModalInformationList.listItems.map((item) => {
          return item.property === "root" ? (
            <x.ul listStyleType="initial" pl={inner.base06}>
              <x.li key={item.value}>
                <Typography color={text.secondary}>{item.value}</Typography>
              </x.li>
            </x.ul>
          ) : (
            <x.ul pl={inner.base06}>
              <x.li key={item.value}>
                <x.ul listStyleType="initial" pl={inner.base06}>
                  <x.li>
                    <Typography color={text.secondary}>{item.value}</Typography>
                  </x.li>
                </x.ul>
              </x.li>
            </x.ul>
          );
        })}
      </x.div>
    </Modal>
  );
};

export default RequestPrimerModal;
