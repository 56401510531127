import React, { Dispatch, FC, SetStateAction } from "react";
import { PrimerVersion } from "models/primers/constants";
import CompanyPrimerHeader from "pages/AlphaNowPage/primers/CompanyPrimer/components/header/CompanyPrimerHeader";

interface CompanyPrimerHeaderFactoryProps {
  content: CompanyPrimerContentV1 | CompanyPrimerContentV2 | CompanyPrimerContentV3;
  mainPrimerCompanyName: string;
  isSidebarExpanded: boolean;
  successMessage: string;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
  onBookmarkChanged: () => void;
  isExpertsView: boolean;
  setIsExpertsView: (value: boolean) => void;
  onReturnToMobileSearch: () => void;
}

const CompanyPrimerHeaderFactory: FC<CompanyPrimerHeaderFactoryProps> = ({
  content,
  mainPrimerCompanyName,
  isSidebarExpanded,
  successMessage,
  setSuccessMessage,
  onBookmarkChanged,
  isExpertsView,
  setIsExpertsView,
  onReturnToMobileSearch,
}) => {
  //TODO Refactor V1V2V3 Header for including paywall and purchasable header content
  // const currentMenuItem = useCompanyPrimersStore(({ currentMenuItem }) => currentMenuItem);
  // const setMoveToMenuItem = useCompanyPrimersStore(({ setMoveToMenuItem }) => setMoveToMenuItem);

  switch (content.srmTypeVersion) {
    case PrimerVersion.PRIMER_VERSION_1:
      return (
        <CompanyPrimerHeader
          content={content as CompanyPrimerContentV1}
          mainPrimerCompanyName={mainPrimerCompanyName}
          isSidebarExpanded={isSidebarExpanded}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          onBookmarkChanged={onBookmarkChanged}
          isExpertsView={isExpertsView}
          setIsExpertsView={setIsExpertsView}
          onReturnToMobileSearch={onReturnToMobileSearch}
        />
      );
    case PrimerVersion.PRIMER_VERSION_2:
      return (
        <CompanyPrimerHeader
          content={content as CompanyPrimerContentV2}
          mainPrimerCompanyName={mainPrimerCompanyName}
          isSidebarExpanded={isSidebarExpanded}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          onBookmarkChanged={onBookmarkChanged}
          isExpertsView={isExpertsView}
          setIsExpertsView={setIsExpertsView}
          onReturnToMobileSearch={onReturnToMobileSearch}
        />
      );
    default:
      break;
  }

  return null;
};

export default CompanyPrimerHeaderFactory;
