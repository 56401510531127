import React from "react";

import styled from "@xstyled/styled-components";
import { IconButton, useThemeTokens } from "@alphasights/alphadesign-components";
import { ChevronLeft, ChevronRight } from "@alphasights/alphadesign-icons";

import { useAlphaNowQueryContext } from "pages/AlphaNowPage/components/AlphaNowQueryContext";

const DataTestIds = {
  BackButton: "back-button",
  ForwardButton: "forward-button",
};

enum NavigationButtonVariant {
  left,
  right,
}

type NavigationButtonProps = {
  variant: NavigationButtonVariant;
  onClick: () => void;

  disabled?: boolean;
};

const Navigation = () => {
  const { navigate, isBackDisabled, isForwardDisabled } = useAlphaNowQueryContext();

  const goBack = () => navigate(-1);

  const goForward = () => navigate(1);

  const shouldHideNavigation = isBackDisabled && isForwardDisabled;

  return (
    <NavigationContainer hide={shouldHideNavigation}>
      <NavigationButton variant={NavigationButtonVariant.left} onClick={goBack} disabled={isBackDisabled} />
      <NavigationButton variant={NavigationButtonVariant.right} onClick={goForward} disabled={isForwardDisabled} />
    </NavigationContainer>
  );
};

const NavigationButton = ({ variant, disabled = false, onClick }: NavigationButtonProps) => {
  const { color, spacing } = useThemeTokens();

  return (
    <IconButton
      variant="outline"
      size="small"
      color={color.icon.strong._}
      disabled={disabled}
      buttonAttributes={{
        style: {
          width: spacing.inner.base10,
          height: spacing.inner.base10,
        },
      }}
      dataAttributes={{
        "data-testid": variant === NavigationButtonVariant.left ? DataTestIds.BackButton : DataTestIds.ForwardButton,
      }}
      onClick={onClick}
    >
      {variant === NavigationButtonVariant.left ? <ChevronLeft /> : <ChevronRight />}
    </IconButton>
  );
};

type NavigationContainerProps = {
  hide: boolean;
};

const NavigationContainer = styled.div(({ hide }: NavigationContainerProps) => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    width: hide ? 0 : "fit-content",
    opacity: hide ? 0 : 1,
    gap: spacing.inner.base,
    transition: "all 1.5s ease-in-out",
  };
});

export { Navigation as default, DataTestIds };
