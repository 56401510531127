import React, { useEffect, useState } from "react";
import {
  ComparisonViewAngle,
  ComparisonViewHeader,
  ComparisonViewHeaderProps,
} from "./ComparisonViewHeader/ComparisonViewHeader";
import { ExpertCompareQuestion, NOT_YET_ANSWERED } from "models/ExpertCompare";
import { ComparisonTable } from "./ComparisonTable/ComparisonTable";
import { Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useHistory } from "react-router-dom";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { compact, entries, groupBy, orderBy, values } from "lodash";
import { mainInteractionFor } from "components/InteractionsPage/helpers/Interaction";
import { useMemoizedValue } from "hooks/useMemoizedValue";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

type ComparisonViewProps = {
  interactions: Interaction[];
  loading: boolean;
  selectedCardId?: string;
} & ComparisonViewHeaderProps;

export const ComparisonView = ({
  interactions,
  loading: interactionsLoading,
  selectedCardId: selectedInteractionId,
  onSubmitFilters,
  onToggleFilter,
  onResetAllFilters,
}: ComparisonViewProps) => {
  const {
    allInteractionsForProject,
    onFiltersChange,
    state: { appliedFilters, filterOptions },
  } = useProjectInteractionsContext();
  const {
    questions: rawQuestions,
    loading: loadingQuestions,
    referrerOrigin,
    setReferrerOrigin,
    enableComparisonView,
  } = useExpertCompareContext();
  const history = useHistory();
  const { logHit } = useTrackUserAction();
  const [initialAngleId, setInitialAngleId] = useState<string>();
  const { project } = useCurrentProjectContext();
  const questions = useMemoizedValue(filterAndSortQuestions(rawQuestions, interactions));
  const angles = getAngles(questions, filterOptions?.groups ?? []);

  const selectedAngle = angles
    .flatMap((angle) => [angle, ...(angle.children ?? [])])
    .find((angle) => angle.id === (appliedFilters.groups ?? []).at(0));

  const origin = new URLSearchParams(history.location.search).get("origin");

  useEffect(
    function redirectToTableView() {
      const noQuestions = questions.length === 0 && !loadingQuestions;
      if (!enableComparisonView || noQuestions) {
        history.replace(`/${project!.token}/experts/table-view`);
      }
    },
    [history, loadingQuestions, project, questions.length, enableComparisonView]
  );

  useEffect(
    function readInitialAngleIdFromUrl() {
      const params = new URLSearchParams(history.location.search);
      const selectedAngleId = params.get("selectedAngleId");
      if (selectedAngleId) {
        setInitialAngleId(selectedAngleId);
        params.delete("selectedAngleId");
        history.replace({
          search: params.toString(),
        });
      }
    },
    [history]
  );

  useEffect(
    function applyInitialAngleFilter() {
      if (loadingQuestions) return;

      const appliedAngleFilter = appliedFilters.groups ?? [];
      const angleId = getInitialAngleId(allInteractionsForProject, questions, initialAngleId);
      if (angleId && appliedAngleFilter.length === 0) {
        onFiltersChange({
          ...appliedFilters,
          groups: [angleId],
        });
      }
    },
    [allInteractionsForProject, onFiltersChange, initialAngleId, appliedFilters, loadingQuestions, questions]
  );

  useEffect(
    function logHitVisit() {
      logHit({
        action: HitAction.viewComparisonView,
        projectToken: project?.token,
        origin: referrerOrigin ?? origin ?? HitOrigin.comparisonView,
      });
      setReferrerOrigin(undefined);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <x.div flexGrow={1}>
      {loadingQuestions || interactionsLoading || !selectedAngle ? (
        <ComparisonViewSkeleton />
      ) : (
        <>
          <ComparisonViewHeader
            onSubmitFilters={onSubmitFilters}
            onToggleFilter={onToggleFilter}
            onResetAllFilters={onResetAllFilters}
            angles={angles}
            selectedAngle={selectedAngle}
            questions={questions}
          />
          <ComparisonTable
            selectedAngle={selectedAngle}
            questions={questions}
            interactions={interactions}
            selectedInteractionId={selectedInteractionId}
          />
        </>
      )}
    </x.div>
  );
};

const ComparisonViewSkeleton = () => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();

  return (
    <x.div>
      <x.div px={inner.base08} py={inner.base05} display="flex">
        <x.div flexGrow={2}>
          <Skeleton height={inner.base08} />
        </x.div>
        <x.div flexGrow={1}>
          <Skeleton />
        </x.div>
      </x.div>
      <Skeleton height={layout.base08} count={6} />
    </x.div>
  );
};

const getAngles = (questions: ExpertCompareQuestion[], angles: FilterOption[]) => {
  const questionsAngles = questions.map((q) => q.angle);
  const angleIdsWithQuestions = questionsAngles.map((angle) => angle.id);
  const getIsExpertsCapExceeded = (angleId: string) =>
    questionsAngles.find((qAngle) => qAngle.id === angleId)?.isExpertsCapExceeded ?? false;

  return angles
    .filter((angle) => angleIdsWithQuestions.includes(angle.value))
    .map(
      (angle) =>
        ({
          ...angle,
          children: angle.children
            ?.filter((subAngle) => angleIdsWithQuestions.includes(subAngle.value))
            .map(
              (subAngle) =>
                ({
                  ...subAngle,
                  isExpertsCapExceeded: getIsExpertsCapExceeded(subAngle.value),
                } as ComparisonViewAngle)
            ),
          isExpertsCapExceeded: getIsExpertsCapExceeded(angle.value),
        } as ComparisonViewAngle)
    );
};

const getInitialAngleId = (
  interactions: Interaction[],
  questions: ExpertCompareQuestion[],
  initialAngleId?: string
) => {
  const mainInteractions = values(groupBy(interactions, "advisorId")).map(
    (interactions) => mainInteractionFor(interactions) as Interaction
  );

  const interactionsGroupedByAngle = groupBy(
    mainInteractions.flatMap((interaction) => {
      return interaction.angles.flatMap((angle) => {
        const angleId = angle.id;
        const parentId = angle.parent?.id;
        return compact([angleId, parentId]).map((id) => ({
          angleId: id,
          angleTitle: id === angleId ? angle.title : angle.parent?.title,
          interaction,
        }));
      });
    }),
    "angleId"
  );

  const angles = entries(interactionsGroupedByAngle).map(([angleId, interactions]) => ({
    angleId,
    angleTitle: interactions[0].angleTitle ?? "",
    numInteractions: interactions.length,
  }));

  // Sort by number of interactions and then by the angle name
  const angleTitlePriority = [
    "Suppliers",
    "Intermediaries",
    "Industry Authorities",
    "Formers",
    "Customers",
    "Competitors",
  ];
  const sortedAngles = angles.sort(
    (a, b) =>
      b.numInteractions - a.numInteractions ||
      angleTitlePriority.indexOf(b.angleTitle) - angleTitlePriority.indexOf(a.angleTitle)
  );

  return compact([initialAngleId, ...sortedAngles.map((angle) => angle.angleId)])
    .filter((id) => questions.map((q) => q.angle.id).includes(id))
    .at(0);
};

const filterAndSortQuestions = (questions: ExpertCompareQuestion[], interactions: Interaction[]) => {
  const mainInteractions = values(groupBy(interactions, "advisorId")).map(
    (interactions) => mainInteractionFor(interactions) as Interaction
  );

  // Remove responses from experts that might have been removed from the project or had its angle changed
  const questionsWithFilteredResponses = questions.map((question) => ({
    ...question,
    responses: question.responses.filter((response) => {
      const interaction = mainInteractions.find((interaction) => interaction.advisorId === response.expertId);
      const angles = interaction?.angles;
      return angles?.some((angle) => angle?.id === question.angle.id || angle?.parent?.id === question.angle.id);
    }),
  }));

  return sort(questionsWithFilteredResponses);
};

const sort = (questions: ExpertCompareQuestion[]) => {
  const getNumberOfResponses = (question: ExpertCompareQuestion) =>
    question.responses.filter((response) => response.text.length > 0 && response.text !== NOT_YET_ANSWERED).length;

  const getTotalLengthOfResponses = (question: ExpertCompareQuestion) =>
    question.responses.reduce(
      (acc, response) => acc + (response.text === NOT_YET_ANSWERED ? 0 : response.text.length),
      0
    );

  return orderBy(questions, [getNumberOfResponses, getTotalLengthOfResponses], ["desc", "desc"]);
};
