import { Button, Typography } from "@alphasights/alphadesign-components";
import React, { useEffect, useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { ChevronDown } from "@alphasights/alphadesign-icons";
import { useWorkRequestMaxiumChargeStyles } from "./WorkRequestMaximumCharge.styles";
import { Expert, MessageType } from "types";
import { calculateTotalCredit, convertCreditsToString } from "components/MessengerPage/helper";

interface WorkRequestMaximumChargeProps {
  maximumTaskDuration: number | undefined;
  selectedExperts: Expert[];
  isExpertsListOpen: boolean;
  setIsExpertsListOpen: Function;
  setExpertsCharge: Function;
  messageType: MessageType;
}

export const WorkRequestMaximumCharge: React.FC<WorkRequestMaximumChargeProps> = ({
  maximumTaskDuration: maximumTaskDurationInput,
  selectedExperts = [],
  isExpertsListOpen = false,
  setIsExpertsListOpen,
  setExpertsCharge,
  messageType,
}) => {
  const noChargeDescription = "No charge";

  const [maximumCharge, setMaximumCharge] = useState(noChargeDescription);
  const [hasCharge, setHasCharge] = useState(false);
  const maximumTaskDuration = useMemo(() => maximumTaskDurationInput ?? 0, [maximumTaskDurationInput]);

  useEffect(() => {
    if (messageType === MessageType.WorkRequest && selectedExperts.length > 0 && maximumTaskDuration > 0) {
      setMaximumCharge(convertCreditsToString(calculateTotalCredit(selectedExperts, maximumTaskDuration)));
      setExpertsCharge(
        selectedExperts.map((selectedExpert) => ({
          credits: convertCreditsToString(calculateTotalCredit([selectedExpert], maximumTaskDuration)),
          name: selectedExpert.name,
          alphaCircleMultiplier: selectedExpert.alphaCircleMultiplier,
        }))
      );

      setHasCharge(true);
    } else {
      switch (messageType) {
        case MessageType.CallGuide:
        case MessageType.Clarification: {
          setMaximumCharge("Free");
          break;
        }
        case MessageType.WorkRequest: {
          setMaximumCharge(noChargeDescription);
          break;
        }
      }
      setExpertsCharge([]);
      setHasCharge(false);
      setIsExpertsListOpen(false);
    }
  }, [maximumTaskDuration, selectedExperts, messageType, setExpertsCharge, setIsExpertsListOpen]);

  return (
    <>
      <Typography component="div" color="secondary">
        Maximum charge:{" "}
        {hasCharge ? (
          <MaximumChargeLink
            experts={selectedExperts}
            totalCharge={maximumCharge}
            opened={isExpertsListOpen}
            setOpened={setIsExpertsListOpen}
          />
        ) : (
          maximumCharge
        )}
      </Typography>
    </>
  );
};

interface MaximumChargeLinkProps {
  experts: Expert[];
  totalCharge: string;
  opened: boolean;
  setOpened: Function;
  fontColor?: string;
}

export const MaximumChargeLink: React.FC<MaximumChargeLinkProps> = ({
  experts = [],
  totalCharge,
  opened,
  setOpened,
  fontColor,
}) => {
  const { link, linkIcon } = useWorkRequestMaxiumChargeStyles({ fontColor });

  const endIcon = (
    <>
      <x.div {...linkIcon} className={opened ? "aui-rotate-180" : ""}>
        <ChevronDown />
      </x.div>
    </>
  );

  const WrapperComponent = useMemo(
    () => ({ children, ...props }: React.PropsWithChildren<any>) =>
      experts.length > 1 ? (
        <Button data-testid="experts-link" {...props}>
          {children}
        </Button>
      ) : (
        <x.span data-testid="experts-link">{children}</x.span>
      ),
    [experts.length]
  );

  return (
    <>
      <WrapperComponent variant="ghost" endIcon={endIcon} onClick={() => setOpened(!opened)} {...link}>
        <Typography variant="body" component="span">
          {totalCharge}
        </Typography>
      </WrapperComponent>
    </>
  );
};
