import React, { useCallback, useMemo, useRef } from "react";
import { Icon, IconButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { Props, x } from "@xstyled/styled-components";
import { ActionColumn } from "views/AdvisorsTableView/ActionColumn";
import { Hide, Show, StarSelected, StarUnselected } from "@alphasights/alphadesign-icons";
import { useExpertRemarksContext } from "providers/ExpertRemarksProvider";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useHideExpertContext } from "providers/HideExpertProvider";
import { HitOrigin } from "@alphasights/portal-api-client";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { useStarExpertContext } from "providers/StarExpertProvider";
import { isTablet } from "browser";
import { LimitedLinesTypography } from "@alphasights/client-portal-shared";

export const ExpertColumn = ({ interaction }: { interaction: Interaction }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const { isExpertHidden, isExpertStarred } = useExpertRemarksContext();
  const hidden = useMemo(() => isExpertHidden(interaction), [interaction, isExpertHidden]);
  const starred = useMemo(() => isExpertStarred(interaction), [interaction, isExpertStarred]);
  const isTabletDevice = useMemo(() => isTablet(), []);

  const onClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (isTabletDevice && !isHovered) {
        event.stopPropagation();
        setIsHovered(true);
      }
    },
    [isHovered, isTabletDevice]
  );

  return (
    <ExpertColumnWrapper
      onMouseEnter={() => !isTabletDevice && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid={`expert-column-${interaction.id}`}
      onClick={onClick}
      isTabletDevice={isTabletDevice}
    >
      <ExpertName interaction={interaction} hidden={hidden} starred={starred} isHovered={isHovered} />
      <x.div className="visible-on-hover" display={isHovered ? "block" : "none"}>
        <ExpertActions interaction={interaction} hidden={hidden} />
      </x.div>
      {!hidden && (
        <x.div display={isHovered ? "none" : "block"} className="hidden-on-hover">
          <LimitedLinesTypography
            tooltip={`${interaction.advisorCompany} - ${interaction.role}`}
            variant="body-small-spaced-em"
            color="strong"
          >
            {interaction.advisorCompany} - {interaction.role}
          </LimitedLinesTypography>
          <Typography variant="body-small-spaced-em" color="secondary">
            {interaction.rolePeriod}
          </Typography>
        </x.div>
      )}
    </ExpertColumnWrapper>
  );
};

const ExpertName = ({
  interaction,
  hidden,
  starred,
  isHovered,
}: {
  interaction: Interaction;
  hidden: boolean;
  starred: boolean;
  isHovered: boolean;
}) => (
  <x.div display="flex" alignItems="center" justifyContent="space-between">
    <LimitedLinesTypography
      variant="body-small-spaced-em"
      color={hidden ? "disabled" : "link"}
      tooltip={interaction.advisorName}
    >
      {interaction.advisorName}
    </LimitedLinesTypography>
    {starred && !isHovered && (
      <x.div data-testid="star-badge" className="hidden-on-hover">
        <Icon size="small" color="strong">
          <StarSelected />
        </Icon>
      </x.div>
    )}
  </x.div>
);

const ExpertActions = ({ interaction, hidden }: { interaction: Interaction; hidden: boolean }) => {
  const { actionsWrapper } = useExpertColumnStyles();

  return (
    <x.div {...actionsWrapper} data-testid="expert-actions">
      {!hidden && <StarExpertButton interaction={interaction} />}
      <HideExpertButton interaction={interaction} />
      {!hidden && (
        <ActionColumnWrapper>
          <ActionColumn interaction={interaction} origin="comparison-view" />
        </ActionColumnWrapper>
      )}
    </x.div>
  );
};

const HideExpertButton = ({ interaction }: { interaction: Interaction }) => {
  const { showReasonPopover, isLoading: isLoadingInteraction, withHideExpertReasonPopover } = useHideExpertContext()!;
  const { isExpertHidden } = useExpertRemarksContext();
  const { locked } = useLockedExpert(interaction);

  const ref = useRef(null);
  const hidden = useMemo(() => isExpertHidden(interaction), [interaction, isExpertHidden]);
  const isLoading = useMemo(() => isLoadingInteraction(interaction.id), [interaction.id, isLoadingInteraction]);
  const icon = useMemo(() => (hidden ? <Show /> : <Hide />), [hidden]);
  const tooltip = useMemo(
    () => (hidden ? "Show expert" : showReasonPopover ? "Hide expert with reason" : "Hide expert"),
    [hidden, showReasonPopover]
  );

  const IconButtonWithHideExpertReasonPopover = withHideExpertReasonPopover(IconButton);

  return (
    <PortalTooltip title={tooltip} disabled={locked} dark>
      <div>
        <IconButtonWithHideExpertReasonPopover
          ref={ref}
          disabled={locked || isLoading}
          interaction={interaction}
          context="comparison-view"
          size="small"
          variant="outline"
          testId={`hide-expert-${interaction.id}`}
          origin={HitOrigin.comparisonView}
          data-testid={`hide-expert-${interaction.id}`}
        >
          {icon}
        </IconButtonWithHideExpertReasonPopover>
      </div>
    </PortalTooltip>
  );
};

const StarExpertButton = ({ interaction }: { interaction: Interaction }) => {
  const { onToggleStar, isLoading: isLoadingInteraction } = useStarExpertContext()!;
  const { isExpertStarred } = useExpertRemarksContext();
  const { locked } = useLockedExpert(interaction);

  const starred = useMemo(() => isExpertStarred(interaction), [interaction, isExpertStarred]);
  const isLoading = useMemo(() => isLoadingInteraction(interaction.id), [interaction.id, isLoadingInteraction]);
  const icon = useMemo(() => (starred ? <StarSelected /> : <StarUnselected />), [starred]);
  const tooltip = useMemo(() => (starred ? "Unstar expert" : "Star expert"), [starred]);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    return onToggleStar(interaction, HitOrigin.comparisonView);
  };

  return (
    <PortalTooltip title={tooltip} disabled={locked} dark>
      <IconButton
        disabled={locked || isLoading}
        size="small"
        variant="outline"
        testId={`star-expert-${interaction.id}`}
        onClick={onClick}
        data-testid={`star-expert-${interaction.id}`}
      >
        {icon}
      </IconButton>
    </PortalTooltip>
  );
};

const ExpertColumnWrapper = styled(x.div)<{
  isTabletDevice: boolean;
}>`
  height: 100%;
  position: relative;
  min-height: 72px;

  &:hover {
    .visible-on-hover {
      ${(props: Props) => (props.isTabletDevice ? "" : "display: block !important")};
    }
    .hidden-on-hover {
      ${(props: Props) => (props.isTabletDevice ? "" : "display: none !important")};
    }
  }
`;

const ActionColumnWrapper = styled(x.div)`
  width: 100%;
  display: flex;
  & > div {
    width: 100%;
  }
  button:first-child {
    flex-grow: 1;
  }
`;

const useExpertColumnStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const actionsWrapper = {
    display: "flex",
    gap: inner.base02,
  };

  return {
    actionsWrapper,
  };
};
