import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { scrollStartingHour } from "../../views/ProjectCalendarView/CalendarFull";
import { ProjectsCalendar, eventHandler } from "../ProjectsCalendar";
import { isFuture, parseISO, startOfDay, compareAsc } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { BannerContainer } from "../../views/ProjectCalendarView/CalendarBanners";
import { useTimezone } from "../../providers/TimezoneProvider";
import { DispatchContext } from "../InteractionsPage/DispatchContext";
import {
  openReadAlong,
  requestChangeInteraction,
  submitInvitation,
  setFlyoutMode,
  searchProjects,
} from "../InteractionsPage/reducer";
import { SecondaryButtonSmall } from "../Button";
import { isWeekend } from "../../views/ProjectCalendarView/CalendarCustomDaysView";
import { useNotifications, useTrackUserAction } from "@alphasights/client-portal-shared";
import { useTimeoutToggle } from "hooks/useTimeoutToggle";
import { Button, Typography } from "@alphasights/alphadesign-components";
import { ReschedulingGhostEvent } from "views/ProjectCalendarView/ReschedulingGhostEvent";
import { RescheduleRequestPopup } from "views/ProjectCalendarView/RequestAvailabilityView";
import { ProjectsCalendarNew, eventHandler as eventHandlerNew } from "components/ProjectsCalendarNew";
import { TIMEZONE, usePreference } from "../../hooks/usePreference";
import { FlyoutMode } from "pages/InteractionPage/enums";
import { usePromise } from "@alphasights/ads-community-hooks";
import { apiClient } from "@alphasights/portal-api-client";
import { useProjectsIsReady } from "@alphasights/portal-auth-react";

export const AdvisorFlyoutCalendar = (props) => {
  const {
    onSelectCard,
    onCancelRequestTranscript,
    transcriptEnabled,
    onRequestTranscript,
    onRequestFollowUp,
    onCancelPendingRequest,
    clientAvailability,
    clientRequests,
    interactionChainDate,
    projectWithInteractions,
    preselected: preselectedInput,
    flyoutMode,
    availabilities,
    setAvailabilites,
    removedAvailabilities,
    setRemovedAvailabilities,
    onReschedulingDone,
    onRequestRescheduleInteractionWithoutDate,
    interactionId = props.id,
    requestedScheduleDate,
    onRequest,
    scheduledAt,
    scheduledInteractions,
  } = props;

  const { logHit } = useTrackUserAction();
  const [currentDate, setCurrentDate] = useState(startOfDay(new Date()));
  const [scrollToTime, setScrollToTime] = useState(scrollStartingHour(new Date()));
  const [showPopupOnNext, setShowPopupOnNext] = useState();
  const [savedValidAvailabilities] = useState(
    props.newFlyout
      ? []
      : (props.clientTimeslots || [])
          .map((ct) => ({
            start: parseISO(ct.startsAt),
            end: parseISO(ct.endsAt),
            source: "request",
          }))
          .filter((ct) => isFuture(ct.start))
  );
  const [requestPopupOpen, setRequestPopupOpen] = useState(false);
  const [calendarView, setCalendarView] = useState("week");
  const [calendarRange, setCalendarRange] = useState({});
  const [showWeekend, setShowWeekend] = useState(false);
  const [sundayWorkday, setSundayWorkday] = useState(false);
  const [preselected, setPreselected] = useState(null);
  const [reschedulingId, setReschedulingId] = useState(null);
  const projectsReady = useProjectsIsReady();
  const [reschedulingNewFlow, setReschedulingNewFlow] = useState(false);
  const [reschedulingRequest, setReschedulingRequest] = useState(null);

  const tz = useTimezone();
  const dispatch = useContext(DispatchContext);

  const [, { updatePreference: updateTimezonePreference }] = usePreference(TIMEZONE);

  const [view, setView] = useState("calendar");

  const { showSuccessBanner, showErrorBanner } = useNotifications();

  useEffect(() => {
    if (!projectsReady) return;

    dispatch(searchProjects({ projects: [projectWithInteractions] }));
  }, [projectsReady]); // eslint-disable-line react-hooks/exhaustive-deps

  const onExpand = useCallback(() => {
    if (props.onExpand) {
      props.onExpand();
      setCalendarView("week");
      logHit({
        origin: props.currentView,
        action: "EXTEND_FLYOUT_CALENDAR",
        advisorshipId: interactionId,
        projectToken: props.token,
      });
    }
  }, [props, interactionId, logHit]);

  useEffect(() => {
    setAvailabilites(savedValidAvailabilities);
  }, [savedValidAvailabilities, setAvailabilites]);

  const viewAsRequestAvailability = () => {
    onExpand();
    setCalendarView("week");
    setView("request-availability");
  };

  const promptForAvailability = () => {
    viewAsRequestAvailability();
  };

  const viewAsSchedule = () => {
    const startDate = requestedScheduleDate ?? getFirstAvailability();
    if (startDate) {
      setView("calendar");
      onExpand();
      setCurrentDate(startOfDay(startDate));
      setShowPopupOnNext({
        interactionId: interactionId,
        date: startOfDay(startDate).getTime(),
        selectedStart: requestedScheduleDate,
      });
    } else {
      dispatch(setFlyoutMode(FlyoutMode.RequestAvailability));
    }
  };

  const getFirstAvailability = () => {
    return props.advisorAvailability
      .map(({ startsAt, endsAt }) => ({
        startsAt: parseISO(startsAt),
        endsAt: parseISO(endsAt),
      }))
      .filter(({ startsAt }) => isFuture(startsAt))
      .sort(({ startsAt: x }, { startsAt: y }) => compareAsc(x, y))[0]?.startsAt;
  };

  useEffect(() => {
    if (interactionChainDate && flyoutMode !== "schedule") {
      const adjustedTime = utcToZonedTime(interactionChainDate, tz.currentTimezone);
      setCurrentDate(adjustedTime);
      setScrollToTime(scrollStartingHour(adjustedTime));
    }
  }, [interactionChainDate?.getTime(), tz.currentTimezone, flyoutMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (flyoutMode) {
      case FlyoutMode.Schedule:
        viewAsSchedule();
        break;

      case FlyoutMode.ProvideAvailability:
      case FlyoutMode.RequestAvailability:
        promptForAvailability();
        break;

      case FlyoutMode.Calendar:
        setView("calendar");
        break;

      case FlyoutMode.RequestReschedule:
        onRequestReschedule(interactionId);
        break;

      default:
    }
  }, [flyoutMode, requestedScheduleDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAttendeesUpdated = (params) => {
    dispatch(submitInvitation(params));
  };

  const onRequestReschedule = (interactionId) => {
    setReschedulingId(interactionId);
    setReschedulingNewFlow(true);
    setCurrentDate(startOfDay(new Date()));
  };

  const onRequestChangeInteraction = useCallback((state) => dispatch(requestChangeInteraction(state)), [dispatch]);

  const onReadAlong = (interaction, recording, transcript) => {
    dispatch(openReadAlong(interaction, recording, transcript));
  };

  const deps = {
    onSelectCard,
    onCancelRequestTranscript,
    transcriptEnabled,
    onRequest,
    onRequestTranscript,
    onRequestReschedule,
    onRequestFollowUp,
    onCancelPendingRequest,
    onAttendeesUpdated,
    onRequestChangeInteraction,
    onReadAlong,
    selectInteraction: onSelectCard,
    onOpenPopup: (event, state) => {
      setScrollToTime(scrollStartingHour(utcToZonedTime(event.start, tz.currentTimezone)));

      onSelectCard(event.interaction);
    },
  };

  const toggleSize = () => {
    if (props.onCollapse) {
      if (props.isExpanded) {
        setCalendarView("three_days");
        props.onCollapse();
      } else {
        setCalendarView("week");
        onExpand();
      }
    }
  };

  const onViewUpcomingEvent = (upcomingEvent) => {
    onExpand();

    if (isWeekend(upcomingEvent, sundayWorkday)) {
      setShowWeekend(true);
    }

    setCalendarView("week");

    const adjustedTime = utcToZonedTime(upcomingEvent, tz.currentTimezone);
    setScrollToTime(scrollStartingHour(adjustedTime));
    setCurrentDate(startOfDay(adjustedTime));
  };

  const [showProvideAvailabilityBanner, setShowProvideAvailabilityBanner] = useState(false);
  const onRequestMoreAvailability = () => {
    setShowProvideAvailabilityBanner(true);
    onExpand();
    setView("request-availability");
  };

  useEffect(() => {
    setPreselected(preselectedInput);
    preselectedInput && setCurrentDate(preselectedInput.start);
  }, [preselectedInput]);

  const { data: calls } = usePromise({
    fn: () => apiClient.get(`/api/auth-user/upcoming-calls`),
  });

  const otherProjectsScheduled = useMemo(
    () =>
      (calls || []).filter((call) => {
        return call.projectToken !== props.token;
      }),
    [calls, props.token]
  );

  const [showClientAvailability, setShowClientAvailability] = useState(true);

  const [showTeamAvailabilityBanner, toggleShowTeamAvailabilityBanner] = useTimeoutToggle();

  const hideAvailability = () => setShowClientAvailability(false);
  const onSaveClientAvailability = (args) =>
    props
      .onSaveClientAvailability(args)
      .then(() => toggleShowTeamAvailabilityBanner())
      .catch(() => showErrorBanner("Error. Availability could not be saved."));

  const getProject = () => {
    const project = projectWithInteractions;
    return {
      id: project?.id,
      angles:
        project?.angles
          ?.filter((a) => a.id === props.group.id || a.id === props.group.parent?.id)
          ?.filter((a) => !a.parent) || [],
      allInteractions: project?.interactions?.filter((a) => a.id === interactionId) || [],
      token: props.token,
      clientAvailability: clientAvailability.filter(
        ({ angleId, advisorshipId }) =>
          (!angleId || angleId === props.group.id || angleId === props.group.parent?.id) &&
          (!advisorshipId || advisorshipId === interactionId)
      ),
      anglesTaggedToClient: project?.anglesTaggedToClient,
      selectedInteractionIds: [interactionId],
      clientRequests,
      transcriptEnabled,
      interactions: [props, ...scheduledInteractions.filter(({ id }) => id !== interactionId)],
    };
  };

  return (
    <>
      {reschedulingNewFlow && (
        <ReschedulingGhostEvent
          currentDate={currentDate}
          interaction={props}
          onReschedulingDone={(reschedulingRequest) => {
            setReschedulingNewFlow(false);
            setReschedulingId(null);
            setReschedulingRequest(reschedulingRequest);
            if (onReschedulingDone) onReschedulingDone();
          }}
          onCancel={() => {}}
          advisorAvailability={props.advisorAvailability}
        />
      )}
      {requestPopupOpen && (
        <RescheduleRequestPopup
          id={interactionId}
          onClose={() => {
            setRequestPopupOpen(false);
            setView("calendar");
          }}
          onSubmit={(args) => {
            return onRequestChangeInteraction(args).then(() =>
              showSuccessBanner("Please share your preferable time to reschedule.")
            );
          }}
          interaction={{ scheduledAt }}
        />
      )}
      {(!view || view === "calendar") && (
        <>
          <div className="aui-h-full">
            <ProjectsCalendar
              projects={[getProject()]}
              secondaryProjects={[
                {
                  transcriptEnabled: false,
                  interactions: [],
                },
              ]}
              upcomingCalls={otherProjectsScheduled || []}
              eventHandler={eventHandler({
                deps,
                onAttendeesUpdated,
                onRequestChangeInteraction,
                setPreselected,
                projectToken: props.token,
                tz,
                selectedInteractions: [props],
                onSaveClientAvailability: onSaveClientAvailability,
              })}
              preselect={preselected}
              isFlyout={true}
              toolbarShowViewOptions={props.isExpanded}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              view={calendarView}
              setView={setCalendarView}
              onRangeChange={setCalendarRange}
              scrollToTime={scrollToTime}
              setScrollToTime={setScrollToTime}
              showWeekend={showWeekend}
              setShowWeekend={setShowWeekend}
              sundayWorkday={sundayWorkday}
              setSundayWorkday={setSundayWorkday}
              openEventPopupState={[showPopupOnNext, setShowPopupOnNext]}
              onTimezoneChanged={(timezone) => updateTimezonePreference({ timezone })}
              toolbarChildren={
                <>
                  {props.onExpand && props.onCollapse && (
                    <SecondaryButtonSmall
                      onClick={toggleSize}
                      className="aui-mx-3"
                      style={{ paddingTop: 4, paddingBottom: 4 }}
                      data-testid="flyout-expand-collapse"
                    >
                      <Typography variant="body-em" component="span">
                        {props.isExpanded ? "Collapse" : "Expand"}
                      </Typography>
                    </SecondaryButtonSmall>
                  )}
                  {reschedulingNewFlow && (
                    <Button
                      variant="outline"
                      size="small"
                      onClick={() => {
                        onRequestRescheduleInteractionWithoutDate(interactionId);
                      }}
                    >
                      <Typography variant="body-small-em">Propose New Time Later</Typography>
                    </Button>
                  )}
                </>
              }
              showClientAvailability={showClientAvailability}
              setShowClientAvailability={setShowClientAvailability}
              newFlyout={true}
              reschedulingId={reschedulingId}
            />
          </div>
          <BannerContainer
            interactions={[props]}
            onViewUpcoming={onViewUpcomingEvent}
            start={calendarRange.start}
            end={calendarRange.end}
            showWeekend={showWeekend}
            isExpanded={props.isExpanded}
            sundayWorkday={sundayWorkday}
            onRequestMoreAvailability={onRequestMoreAvailability}
            showTeamAvailabilityBanner={showTeamAvailabilityBanner}
            onHideTeamAvailability={hideAvailability}
            showReschedulingSelectBanner={reschedulingNewFlow}
            reschedulingRequest={reschedulingRequest}
          />
        </>
      )}
      {view === "request-availability" && (
        <div className="aui-h-full">
          <ProjectsCalendarNew
            project={getProject()}
            eventHandler={eventHandlerNew({
              deps,
              setPreselected,
            })}
            preselect={preselected}
            isFlyout={true}
            toolbarShowViewOptions={props.isExpanded}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            view={calendarView}
            setView={setCalendarView}
            onRangeChange={setCalendarRange}
            scrollToTime={scrollToTime}
            setScrollToTime={setScrollToTime}
            showWeekend={showWeekend}
            setShowWeekend={setShowWeekend}
            sundayWorkday={sundayWorkday}
            setSundayWorkday={setSundayWorkday}
            openEventPopupState={[showPopupOnNext, setShowPopupOnNext]}
            onTimezoneChanged={(timezone) => updateTimezonePreference({ timezone })}
            availabilities={availabilities}
            setAvailabilites={setAvailabilites}
            removedAvailabilities={removedAvailabilities}
            setRemovedAvailabilities={setRemovedAvailabilities}
            setPreselected={setPreselected}
            isProvideAvailability={true}
          />
          <BannerContainer
            interactions={[props]}
            onViewUpcoming={onViewUpcomingEvent}
            start={calendarRange.start}
            end={calendarRange.end}
            showWeekend={showWeekend}
            isExpanded={props.isExpanded}
            sundayWorkday={sundayWorkday}
            onRequestMoreAvailability={onRequestMoreAvailability}
            showTeamAvailabilityBanner={showTeamAvailabilityBanner}
            onHideTeamAvailability={hideAvailability}
            showReschedulingSelectBanner={reschedulingNewFlow}
            reschedulingRequest={reschedulingRequest}
            showProvideAvailabilityBanner={showProvideAvailabilityBanner}
            onHideProvideAvailabilityBanner={() => setShowProvideAvailabilityBanner(false)}
          />
        </div>
      )}
    </>
  );
};
