import React, { useRef } from "react";
import { Icon, TextField, TextFieldProps } from "@alphasights/alphadesign-components";
import { Close, Search } from "@alphasights/alphadesign-icons";
import { debounce } from "lodash";
import * as S from "./CollapsibleSearch.styled";

type CollapsibleSearchProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onValueChange?: (value: string) => void;
  onClear: (event: React.MouseEvent<HTMLDivElement>) => void;
  value: string;
} & Omit<TextFieldProps, "onChange" | "value" | "onSubmit">;

export const CollapsibleSearch = ({ onSubmit, onValueChange, onClear, value, ...props }: CollapsibleSearchProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const debouncedOnValueChange = onValueChange && debounce((e) => onValueChange(e), 500);
  return (
    <S.Form data-testid="search-form" onSubmit={onSubmit} onClick={() => ref.current?.focus()}>
      <TextField
        ref={ref}
        onChange={debouncedOnValueChange ? (e) => debouncedOnValueChange(e.target.value) : undefined}
        value={value}
        size="small"
        startAdornment={
          <Icon size="small" color="secondary">
            <Search />
          </Icon>
        }
        endAdornment={
          <Icon size="small" onClick={onClear} color="secondary" dataAttributes={{ "data-testid": "clear-search" }}>
            <Close />
          </Icon>
        }
        placeholder="Search"
        {...props}
      />
    </S.Form>
  );
};
