import React, { useRef } from "react";
import PropTypes from "prop-types";
import { intersection } from "lodash";
import { x } from "@xstyled/styled-components";
import { formatDate } from "utils/dates";
import { DATE_FORMAT } from "content/AlphaNow/constants";
import { FLAGS, Companies } from "@alphasights/client-portal-shared";
import {
  getCompaniesFromSearchQuery,
  getOrderedAnchorCompanies,
  browsersLocalTime,
  SortOrder,
} from "content/AlphaNow/utils";
import { Flags } from "pages/AlphaNowPage/components";

const PILL_END_BUFFER = 10;

const CardHeader = ({ content = { companies: [] }, searchQuery = [], mentions, isPrimer }) => {
  const ref = useRef();

  const { scheduledTimeUTC, createdAt, status, lastUpdatedAt, companies } = content;

  const formattedDate = formatDate(
    isPrimer ? browsersLocalTime(lastUpdatedAt ?? 0) : scheduledTimeUTC ?? browsersLocalTime(createdAt),
    DATE_FORMAT
  );

  const searchedCompanies = getCompaniesFromSearchQuery(searchQuery);
  const searchCompanyIds = searchedCompanies.map(({ id }) => id);
  const searchCompanyNames = searchedCompanies.map(({ value }) => value);

  const anchorCompanies = getOrderedAnchorCompanies(companies, searchCompanyIds, SortOrder.Length).map(
    ({ companyName }) => companyName
  );

  const searchInAnchorCompanies = intersection(searchCompanyNames, anchorCompanies).length > 0;

  const flags = [...[FLAGS.ContentDate], ...(mentions && !searchInAnchorCompanies ? [FLAGS.Mentions] : [])];

  return (
    <x.div flex="1 0 auto" row flexWrap="nowrap" ref={ref}>
      <Companies
        companies={anchorCompanies}
        searchCompanies={searchCompanyNames}
        containerRef={ref}
        customBuffer={PILL_END_BUFFER}
      />
      <Flags items={flags} mentions={mentions} contentDate={formattedDate} status={status} />
    </x.div>
  );
};

CardHeader.propTypes = {
  content: PropTypes.shape({
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        companyName: PropTypes.string,
      })
    ).isRequired,
    scheduledTimeUTC: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  searchQuery: PropTypes.arrayOf(PropTypes.object),
  mentions: PropTypes.number,
  isPrimer: PropTypes.bool,
};

export default CardHeader;
