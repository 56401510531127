import React from "react";
import { Icon } from "../alphaui";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";

import { Modal } from "../Modal";

import { MODAL_TYPE } from "./constants.js";
import "./index.css";

const Button = ({ children, isTernary, onClick, ...props }) => {
  return isTernary ? (
    <x.span
      onClick={onClick}
      color={{ _: "inherit", hover: "secondary500" }}
      whiteSpace="nowrap"
      cursor="pointer"
      {...props}
    >
      {children}
    </x.span>
  ) : (
    <x.button
      onClick={onClick}
      py="400"
      px="700"
      borderRadius="200"
      backgroundImage="gradient-to-l"
      gradientFrom="primary800"
      gradientVia="primary700"
      gradientTo="primary500"
      color="white"
      fontSize="450"
      lineHeight="450"
      cursor="pointer"
      {...props}
    >
      {children}
    </x.button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  isTernary: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  isTernary: false,
  onClick: () => {},
};

const AlphaViewDetailsModal = ({ modal, onClose }) => {
  const { body, buttons, title } = modal;
  return (
    <Modal className="content-modal md:aui-w-1/2 md:aui-rounded" isOpen={!!modal.type} onClose={onClose}>
      <x.div p="800" color="neutral900" fontSize="450" lineHeight="450">
        <x.div
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="300"
          fontSize="600"
          fontWeight="bold"
          lineHeight="600"
        >
          {title}
          <x.div fontSize="450" cursor="pointer" onClick={onClose}>
            <Icon icon="close" />
          </x.div>
        </x.div>
        <x.div overflow="scroll">{body}</x.div>
        {buttons?.length && (
          <x.div display="flex" alignItems="center" mt="900">
            {buttons.map(({ text, ...props }, index) => {
              return (
                <Button key={index} mr="700" {...props}>
                  {text}
                </Button>
              );
            })}
          </x.div>
        )}
      </x.div>
    </Modal>
  );
};

AlphaViewDetailsModal.propTypes = {
  modal: PropTypes.shape({
    body: PropTypes.node,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        isTernary: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
    title: PropTypes.string,
    type: PropTypes.oneOf(Object.values(MODAL_TYPE)),
  }),
  onClose: PropTypes.func.isRequired,
};

export default AlphaViewDetailsModal;
