import React from "react";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";

import { employmentHistoryContent, viewEmploymentHistory } from "../../content/AlphaViews";
import { MODAL_TYPE } from "./constants";
import EmploymentHistory from "./EmploymentHistory.js";
import { Typography } from "@alphasights/alphadesign-components";

// TODO: uncomment when jobDuration is available on the DB
// const jobDuration = ([from, to]) =>
//   `${format(parseISO(from), "MMM yy'")} - ${format(parseISO(to), "MMM yy'")}`;

const employmentHistoryModal = (employmentHistory) => {
  return {
    body: employmentHistory.map((employment) => <EmploymentHistory key={employment.jobId} {...employment} />),
    title: employmentHistoryContent,
    type: MODAL_TYPE.EMPLOYMENT_HISTORY,
  };
};

const Speakers = ({ setEmploymentHistory, setModal, speakers }) => (
  <x.div mt="900">
    <Typography variant="h3">Speakers</Typography>
    {speakers &&
      speakers.map(({ bio, careerHistory, jobTitle, name }) => (
        <x.div mt="base" mb="800" key={name}>
          <Typography>{name}</Typography>
          <Typography variant="body-large-em">{jobTitle}</Typography>
          {/* TODO: uncomment when jobDuration is available on the DB */
          /* <x.div fontSize="450" lineHeight="450" fontWeight="bold">
            {jobDuration(speaker.jobDuration)}
          </x.div> */}
          <Typography mt="300">{bio}</Typography>
          {careerHistory?.length && (
            <x.div
              onClick={() => {
                setEmploymentHistory(careerHistory);
                setModal(employmentHistoryModal(careerHistory));
              }}
              color={{ _: "secondary500", hover: "secondary700" }}
              whiteSpace="nowrap"
              cursor="pointer"
            >
              <Typography variant="body-em" component="span">
                {viewEmploymentHistory}
              </Typography>
            </x.div>
          )}
        </x.div>
      ))}
  </x.div>
);

Speakers.propTypes = {
  setEmploymentHistory: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default Speakers;
