import React, { ForwardedRef, useCallback, useMemo } from "react";
import useQueryParams from "hooks/useQueryParams";
import { useCurrentWorkstreamContext } from "providers/CurrentWorkstreamProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useHistory } from "react-router-dom";
import { filterSurveysWithInteractions } from "./helpers/Workstreams";
import { Option, Select, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const WorkstreamToggler = React.forwardRef((params, ref: ForwardedRef<HTMLDivElement>) => {
  const styles = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();
  const { allInteractionsForProject } = useProjectInteractionsContext();
  const { project, selectedSurveyId, selectedWorkstream } = useCurrentWorkstreamContext();

  const buildSelectId = (workstream: Workstream, surveyId?: string) =>
    surveyId
      ? `${workstream.workstreamType}_${workstream.id}_${surveyId}`
      : `${workstream.workstreamType}_${workstream.id}`;

  const options = useMemo(
    () =>
      project.workstreams.flatMap((w) => {
        return w.workstreamType === "survey" && w.clientSurveys?.length > 0
          ? filterSurveysWithInteractions(allInteractionsForProject, w.clientSurveys).map((s: ClientSurvey) => ({
              id: buildSelectId(w, s.id),
              value: s.description,
            }))
          : { id: buildSelectId(w), value: "Call" };
      }),
    [allInteractionsForProject, project.workstreams]
  );

  const onSelectChange = useCallback(
    (id: string) => {
      const [workstreamType, workstreamId, surveyId] = id.split("_");
      if (workstreamId === selectedWorkstream?.id && workstreamType === "survey") {
        queryParams.set("currentSurvey", surveyId);
      } else if (workstreamType === "survey") {
        history.push(`/${project.token}/workstream/${workstreamId}/experts?currentSurvey=${surveyId}`);
      } else {
        history.push(`/${project.token}/workstream/${workstreamId}/experts`);
      }
    },
    [history, project.token, queryParams, selectedWorkstream]
  );

  const dropdownWidth = useMemo(() => {
    const longestDescription = Math.max(...options.map((opt) => opt.value).map((el) => el.length));
    const charCountLimited = Math.min(longestDescription, 24);
    const charSize = 11;
    return charCountLimited * charSize;
  }, [options]);

  if (!selectedWorkstream || options.length < 2) {
    return null;
  }

  return (
    <x.div ref={ref}>
      <Select
        value={buildSelectId(selectedWorkstream, selectedSurveyId)}
        // @ts-ignore
        onChange={onSelectChange}
        data-testid="workstreams-select"
        {...styles.selectInput}
        w={dropdownWidth}
      >
        {options.map((opt) => (
          <div key={`workstreams-select-${opt.id}`} data-testid={`workstreams-select-${opt.id}`}>
            <Option value={opt.id}>{opt.value}</Option>
          </div>
        ))}
      </Select>
    </x.div>
  );
});

export const useStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    selectInput: {
      py: inner.base03,
      px: inner.base05,
      fontSize: "14px",
      lineHeight: "20px",
      minWidth: "180px",
      gap: "4px",
    },
  };
};
