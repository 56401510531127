import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const LoadingSpinnerContainer = styled.div(() => {
  const {
    spacing: { layout },
  } = useThemeTokens();
  return {
    paddingBlock: layout.base08,
    gap: layout.base03,
    display: "flex",
    flexDirection: "column" as "column",
  };
});

const NoSearchResultsContainer = styled.div(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    paddingLeft: inner.base08,
    paddingRight: inner.base06,
    paddingTop: inner.base06,
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
  };
});

const SearchResultsContainer = styled.div(() => {
  return {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  };
});

export { LoadingSpinnerContainer, NoSearchResultsContainer, SearchResultsContainer };
