import React, { ReactNode } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

const OverviewDetailsRow = ({
  label,
  value,
  children,
}: {
  label: string;
  value?: string;
  children?: ReactNode | string;
}) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();

  return (
    <x.div display="flex" pb={inner.base02} lineHeight={inner.base06}>
      <Typography component="div" color={color.text.secondary} flex={"0 0 150px"} mr={inner.base06} variant={"body"}>
        {label}
      </Typography>
      {children ?? (
        <Typography variant="body" color={color.text.strong._}>
          {value}
        </Typography>
      )}
    </x.div>
  );
};

export default OverviewDetailsRow;
