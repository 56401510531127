import { useThemeTokens } from "@alphasights/alphadesign-components";

const useStyles = ({ contentHidden } = {}) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  return {
    wrapper: {
      display: "flex",
    },
    pageWrapper: {
      display: "flex",
      flexDirection: "row",
      minHeight: "0px",
      maxWidth: "100vw",
      flexGrow: 1,
    },
    surveyTableWrapper: {
      minWidth: 0,
      flexGrow: 1,
      display: contentHidden ? "none" : null,
    },
    topBarWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItens: "stretch",
      rowGap: inner.base05,
      maxWidth: "910px",
      mx: "auto",
      my: inner.base08,
      px: inner.base04,
    },
    inputWrapper: {
      display: "flex",
      alignItems: "center",
      columnGap: inner.base02,
      zIndex: 5,
    },
    pillCountWrapper: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-between",
      overflowX: "auto",
      overflowY: "hidden",
    },
    count: {
      color: text.secondary,
    },
    colorRed: {
      color: text.danger,
    },
    contentWrapper: {
      minWidth: 0,
      flexGrow: 1,
    },
    searchInput: {
      py: inner.base03,
      px: inner.base05,
      fontSize: "14px",
      h: "44px !important",
    },
  };
};

export default useStyles;
