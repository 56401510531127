import { Mode, SynthesisAngle } from "providers/ProjectSynthesisProvider.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AngleTypeSynthesis, projectSynthesis as API } from "@alphasights/portal-api-client";
import _ from "lodash";

const SUGGESTIONS = [
  {
    angle: "Formers",
    suggestions: [
      "What are the current trends and growth drivers in the US cabinetry market, and how are these expected to evolve in the coming years?",
      "How are supply chain challenges, particularly in lumber and other raw materials, impacting cabinetry manufacturers, and what are the expected long-term effects?",
      "Who are the key players in the cabinetry market, and what differentiates them in terms of pricing, quality, and distribution?",
    ],
  },
  {
    angle: "Competitors",
    suggestions: [
      "What impact do imported cabinetry products (especially from countries like China) have on pricing, quality expectations, and margins in the US cabinetry market? What impact do imported cabinetry products (especially from countries like China) have on pricing, quality expectations, and margins in the US cabinetry market?",
      "What are the primary sales channels for cabinetry products (e.g., direct-to-consumer, home improvement stores, contractors), and what channel shifts are anticipated?",
    ],
  },
  {
    angle: "Customers",
    suggestions: [
      "What typical EBITDA margins can be expected in the cabinetry market, and what factors most significantly impact profitability?",
      "What role does technology and automation play in the cabinetry manufacturing process, and what level of investment is typical or necessary to stay competitive?",
    ],
  },
];

export type SynthesisForm = {
  angle: string | null;
  question: string;
  errors?: {
    angle?: boolean;
    question?: boolean;
  };
};

export const useNewSynthesisModule = ({
  mode,
  project,
  angles,
  onSuccess,
}: {
  mode: Mode;
  project: Project;
  angles: SynthesisAngle[];
  onSuccess: (newModule: AngleTypeSynthesis) => void;
}) => {
  const [form, setForm] = useState<SynthesisForm>({ angle: null, question: "" });

  const updateForm = useCallback((field: string, value: string) => {
    setForm((form) => ({
      ...form,
      [field]: value,
      errors: {
        ...form.errors,
        [field]: undefined,
      },
    }));
  }, []);

  const suggestions = useMemo(() => {
    const value = SUGGESTIONS.filter((s) => s.angle === form.angle);

    if (value.length > 0) return value;

    return SUGGESTIONS;
  }, [form.angle]);

  const angleOptions = useMemo(() => {
    return angles.map((a) => ({
      id: a.id,
      label: a.label,
      value: a.label,
      onClick: () => updateForm("angle", a.label),
    }));
  }, [updateForm, angles]);

  const consumeSuggestion = useCallback(
    (angle: string, question: string) => {
      updateForm("angle", angle);
      updateForm("question", question);
    },
    [updateForm]
  );

  useEffect(() => {
    if (mode !== Mode.NEW) return;

    setForm({ angle: null, question: "" });
  }, [mode]);

  const onSubmit = useCallback(() => {
    let errors = {
      angle: !form.angle,
      question: !form.question || form.question.length < 25,
    };

    setForm((form) => ({
      ...form,
      errors,
    }));

    if (Object.values(errors).some(_.identity)) return null;

    return API.createProjectSynthesisModule(project.token, {
      angleTypeName: form.angle!,
      question: form.question,
    }).then((newModule: AngleTypeSynthesis) => {
      onSuccess(newModule);
      return newModule;
    });
  }, [form, onSuccess, project.token]);

  return useMemo(
    () => ({
      angleOptions,
      suggestions,
      form,
      consumeSuggestion,
      updateForm,
      onSubmit,
    }),
    [angleOptions, form, consumeSuggestion, suggestions, updateForm, onSubmit]
  );
};
