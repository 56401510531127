import React from "react";
import { x } from "@xstyled/styled-components";
import { SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { Icon, useThemeTokens, Typography } from "@alphasights/alphadesign-components";
import { Company, Dot, Quote } from "@alphasights/alphadesign-icons";
import { TRIGGER_TYPE, WatchlistTriggerProps } from "hooks/useWatchlist";
import {
  TriggerContainer,
  TriggerTitleContainer,
  StyledTypography,
  NotificationContainer,
} from "pages/AlphaNowPage/components/LandingPage/WatchlistItem/WatchlistItem.styled";
import { NEW_WATCHLIST_LABEL } from "pages/AlphaNowPage/components/LandingPage/WatchlistItem/constants";
import { Filters, useAlphaNowQueryContext } from "pages/AlphaNowPage/components/AlphaNowQueryContext";
import { buildSearchQuery } from "content/AlphaNow/utils";
import { SORT_KEYS } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/SortByFilter";

const DATA_TEST_IDS = {
  WatchlistItem: "watchlist-item",
};

const TriggerIcon = ({ logo, type }: { logo?: string; type: TRIGGER_TYPE }) => {
  const { spacing } = useThemeTokens();
  if (logo) {
    return <x.img src={logo} w={spacing.inner.base05} h={spacing.inner.base05} />;
  }
  const icon = type === TRIGGER_TYPE.company ? <Company /> : <Quote />;
  return (
    <Icon size="medium" color="secondary">
      {icon}
    </Icon>
  );
};

const WatchlistItem = ({ trigger }: { trigger: WatchlistTriggerProps }) => {
  const { color } = useThemeTokens();
  const {
    updateQuery,
    query: { filters: queryFilters },
  } = useAlphaNowQueryContext();

  const { type, companyInfo, token, unread, id } = trigger;
  const value = type === TRIGGER_TYPE.company ? companyInfo?.name : token;
  const numUnreadTriggers = unread.length;
  const hasUnreadTrigger = numUnreadTriggers > 0;

  const handleClickTrigger = () => {
    const newSearchQuery = buildSearchQuery({
      type: type === TRIGGER_TYPE.company ? SEARCH_SUGGESTION_TYPES.Company : SEARCH_SUGGESTION_TYPES.Keyword,
      value,
      id: companyInfo?.cdsAlphaCompanyId,
    });
    updateQuery({
      searchQuery: newSearchQuery,
      filters: { ...queryFilters, sortBy: SORT_KEYS.callDateDesc } as Filters,
    });
  };

  return (
    <TriggerContainer key={id} onClick={handleClickTrigger} data-testid={DATA_TEST_IDS.WatchlistItem}>
      <TriggerTitleContainer hasUnreadTrigger={hasUnreadTrigger}>
        <TriggerIcon type={type} logo={companyInfo?.logo} />
        <StyledTypography>{value}</StyledTypography>
      </TriggerTitleContainer>
      {hasUnreadTrigger && (
        <NotificationContainer>
          <Typography
            variant="body-small"
            color={color.text.info}
          >{`${numUnreadTriggers} ${NEW_WATCHLIST_LABEL}`}</Typography>
          <Icon color="info">
            <Dot />
          </Icon>
        </NotificationContainer>
      )}
    </TriggerContainer>
  );
};

export { WatchlistItem as default, DATA_TEST_IDS };
