import { Company as CompanyIcon, Expert, Quote, Settings, Industry } from "@alphasights/alphadesign-icons";
import { SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { BooleanTypes } from "components/Search/consts";

export const SEARCH_SUGGESTION_TYPE_ICONS: Record<number, Function> = {
  [SEARCH_SUGGESTION_TYPES.Company]: CompanyIcon,
  [SEARCH_SUGGESTION_TYPES.Keyword]: Quote,
  [SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch]: Quote,
  [SEARCH_SUGGESTION_TYPES.Colleague]: Expert,
  [SEARCH_SUGGESTION_TYPES.Market]: Industry,
  [BooleanTypes.AND]: Settings,
  [BooleanTypes.NOT]: Settings,
  [BooleanTypes.OR]: Settings,
  [BooleanTypes.LEFT_BRACKET]: Settings,
  [BooleanTypes.RIGHT_BRACKET]: Settings,
};

export const SEARCH_SUGGESTION_TYPE_NAMES: Record<number, string> = {
  [SEARCH_SUGGESTION_TYPES.Company]: "Company",
  [SEARCH_SUGGESTION_TYPES.Keyword]: "Keyword",
  [SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch]: "Keyword",
  [SEARCH_SUGGESTION_TYPES.Colleague]: "Colleague",
  [SEARCH_SUGGESTION_TYPES.Market]: "Market",
  [BooleanTypes.AND]: "Boolean",
  [BooleanTypes.NOT]: "Boolean",
  [BooleanTypes.OR]: "Boolean",
  [BooleanTypes.LEFT_BRACKET]: "Boolean",
  [BooleanTypes.RIGHT_BRACKET]: "Boolean",
};
