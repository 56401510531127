import React, { useEffect, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCustomerPrimersStore, useMentionedExpertsStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import { labelToMultiline } from "pages/AlphaNowPage/primers/utils/charts";
import { ChartLegend, ChartTooltip } from "pages/AlphaNowPage/primers/components";
import { SIDEBAR_ANIMATION_SPEED } from "constants/AlphaNow";
import XAxis from "./chart/XAxis";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

const getNumberWithOrdinal = (number: number): string => {
  const s = ["th", "st", "nd", "rd"],
    v = number % 100;

  return s[(v - 20) % 10] || s[v] || s[0];
};

const RankedKPCsChart = () => {
  const sectionName = "RANKED_KPCS_CHART";
  const [selectedSerie, setSelectedSerie] = useState<number | null>(null);
  const [chartKey, setChartKey] = useState<number>(0);
  const {
    spacing,
    color,
    typography,
    font: { family },
  } = useThemeTokens();

  const isSidebarExpanded = useCustomerPrimersStore(({ isSidebarExpanded }) => isSidebarExpanded);
  const keyPurchasingCriteria = useCustomerPrimersStore(({ keyPurchasingCriteria }) => keyPurchasingCriteria);
  const { ranked } = keyPurchasingCriteria;
  const expertCount = usePrimersStore(({ expertCount }) => expertCount);
  const expertsMentionedSection = useMentionedExpertsStore(({ expertsMentionedSection }) => expertsMentionedSection);
  const resetExpertsMentioned = useMentionedExpertsStore(({ resetExpertsMentioned }) => resetExpertsMentioned);
  const setExpertsMentionedSectionAndExpertsIds = useMentionedExpertsStore(
    ({ setExpertsMentionedSectionAndExpertsIds }) => setExpertsMentionedSectionAndExpertsIds
  );

  // TODO: need to check in what version of ADS this colors are
  /*const kpcsColors = [
    color.chart.sequential.base,
    color.chart.sequential.base02,
    color.chart.sequential.base03,
    color.chart.sequential.base04,
    color.chart.sequential.base05,
    color.chart.sequential.base06,
    color.chart.sequential.base07,
  ];*/

  const values: { name: string; data: number[] }[] = useMemo(
    () =>
      [...Array(ranked.length).keys()].map((i) => ({
        name: "",
        data: [],
      })),
    [ranked]
  );

  const kpcsPossibleColors = ["#1E3FAE", "#1D4FD7", "#2463EB", "#3C83F6", "#61A6FA", "#91C3FD", "#BEDBFE"];
  const kpcsColors = kpcsPossibleColors.slice(0, values.length);
  const { getChartColors } = useGetChartColors(kpcsColors, true, values.length);

  // in this type of chart, to have the data being shown correctly we need to group the kpcs differently, like
  // [kpc1.1,kpc2.1,kpc3.1]
  // [kpc1.2,kpc2.2,kpc3.2]
  // [kpc1.3,kpc2.3,kpc3.3]
  const getSeries = useMemo(() => {
    // Initialize an empty array for each KPC
    const series: { data: number[] }[] = values.map(() => ({ data: [] }));

    // Group the ranks by their value and count how many ranks have each value for each KPC
    ranked.forEach(({ ranks }) => {
      const counts = new Array(values.length).fill(0);
      ranks.forEach((rank) => {
        if (rank.value !== undefined && rank.value >= 1 && rank.value <= values.length) {
          counts[rank.value - 1]++;
        }
      });
      counts.forEach((count, i) => {
        series[i].data.push(count);
      });
    });

    return series;
  }, [ranked, values]);

  const getKPCsNamesAndScores = useMemo(
    () => () =>
      ranked.map(({ kpc }, index) => ({
        name: kpc,
        multilinedName: labelToMultiline(kpc, 15),
        score: `${index + 1}`,
      })),
    [ranked]
  );

  useEffect(() => {
    // we need this because, due to component memoization, the chart was
    // not resizing when the search bar collapsed at first load.
    //
    // to solve this we get the expanded status of the search bar and
    // when it changes we enforce a new key to the chart. We use a setTimeout
    // to defer this change to when the animation finishes + half a second,
    // to ensure the size is correctly calculated
    let timer = setTimeout(
      () => setChartKey((currentState) => currentState + 1),
      parseFloat(SIDEBAR_ANIMATION_SPEED.substring(0, SIDEBAR_ANIMATION_SPEED.length - 1)) * 1000 + 500
    );

    return () => {
      clearTimeout(timer);
    };
  }, [isSidebarExpanded]);

  useEffect(() => {
    if (expertsMentionedSection !== sectionName && selectedSerie !== null) {
      setSelectedSerie(null);
    }
  }, [expertsMentionedSection, sectionName, selectedSerie]);

  const options = {
    chart: {
      fontFamily: family.text.regular,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        dataPointSelection: function (event: any, chartContext: any, config: any) {
          const newSelectedSerie = values.length * config.dataPointIndex + config.seriesIndex;

          if (newSelectedSerie === selectedSerie) {
            resetExpertsMentioned();
            setSelectedSerie(null);
          } else {
            const kpc = ranked[config.dataPointIndex];
            const speakersIds = kpc.ranks
              .filter((rank) => rank.value === config.seriesIndex + 1)
              .map((rank) => rank.citedBy)
              .flat();

            setExpertsMentionedSectionAndExpertsIds(sectionName, speakersIds);
            setSelectedSerie(newSelectedSerie);
          }
        },
      },
      parentHeightOffset: 0,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          enabled: false,
        },
        columnWidth: "48px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: color.text.strong._,
          fontSize: typography.body.small.fontSize,
          fontWeight: typography.body.small.fontWeight,
        },
      },
      max: expertCount,
      tickAmount: expertCount / 2,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        const rankValue = series[seriesIndex][dataPointIndex];
        const plural = rankValue === 1 ? "" : "s";
        const rank = seriesIndex + 1;

        return ReactDOMServer.renderToString(
          <ChartTooltip>{`Ranked ${rank}${getNumberWithOrdinal(rank)} - ${rankValue} expert${plural}`}</ChartTooltip>
        );
      },
    },
    colors: [
      function ({ seriesIndex, dataPointIndex }: { seriesIndex: any; dataPointIndex: any }) {
        // because the chart is stacked, we replicate the colors per lines so that we can have only one selected
        return getChartColors(selectedSerie)[values.length * dataPointIndex + seriesIndex];
      },
    ],
    fill: {
      opacity: 1,
    },
    title: {
      text: undefined,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    // this avoids the color to be darker when clicking on an entry
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
  } as ApexOptions;

  return (
    <>
      <x.div {...typography.body.small} color={color.text.strong._} mt={spacing.inner.base04}>
        Customers
      </x.div>
      <x.div paddingLeft="20px">
        <Chart key={chartKey} options={options} series={getSeries} type="bar" height={350} />
      </x.div>
      <XAxis kpcs={getKPCsNamesAndScores()} />
      <ChartLegend
        elements={Array.from({ length: values.length }, (_, i) => `Rank ${i + 1}`).map((entry, index) => ({
          color: kpcsPossibleColors[index],
          label: entry,
        }))}
      />
    </>
  );
};

export default RankedKPCsChart;
