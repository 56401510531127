import React, { useContext, useState } from "react";
import { ClientContact } from "models/ClientContact";
import { projectLauncherService } from "services/projectLauncherService";
import { usePortalStoreOperations } from "@alphasights/portal-auth-react";

export interface ProjectLauncherContextState {
  projectName?: string;
  projectBrief: string;
  numberOfCalls?: number;
  clientContacts: ClientContact[];
  onProjectNameChange: (value: string) => void;
  onProjectBriefChange: (value: string) => void;
  onNumberOfCallsChange: (value: number) => void;
  onClientContactsChange: (value: ClientContact[]) => void;
  onLaunchProject: () => Promise<string>;
}

export const ProjectLauncherContext = React.createContext<undefined | ProjectLauncherContextState>(undefined);

export const ProjectLauncherProvider = ({
  service = projectLauncherService,
  children,
}: {
  service?: typeof projectLauncherService;
  children: JSX.Element;
}) => {
  const [projectName, setProjectName] = useState<string>();
  const [projectBrief, setProjectBrief] = useState("");
  const [clientContacts, setClientContacts] = useState<ClientContact[]>([]);
  const [numberOfCalls, setNumberOfCalls] = useState<number>();
  const {
    projects: { operations: projectOperations },
  } = usePortalStoreOperations();

  const onLaunchProject = () => {
    return service
      .launchProject({
        title: projectName ?? "[Project Name Pending]",
        brief: projectBrief,
        expectedInteractionsCount: numberOfCalls ?? 0,
        clientContactIds: clientContacts.map((contact) => contact.id),
        companies: [],
      })
      .then(({ token }) => {
        projectOperations.loadMyProjects();
        return token;
      });
  };

  const context = {
    projectName,
    onProjectNameChange: setProjectName,
    projectBrief,
    onProjectBriefChange: setProjectBrief,
    numberOfCalls,
    onNumberOfCallsChange: setNumberOfCalls,
    clientContacts,
    onClientContactsChange: setClientContacts,
    onLaunchProject,
  };

  return <ProjectLauncherContext.Provider value={context} children={children} />;
};

export const useProjectLauncherContext = () => {
  const context = useContext(ProjectLauncherContext);

  if (!context) throw new Error("ProjectLauncherContext should only be used within ProjectLauncherProvider");

  return context;
};
