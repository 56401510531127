import React, { useEffect, useReducer, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { isEmpty, omit } from "lodash";
import { x } from "@xstyled/styled-components";
import {
  AlphaNowContentType,
  useTrackUserAction,
  SEARCH_SUGGESTION_TYPES,
  PromiseStatus,
} from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useThemeTokens, Alert } from "@alphasights/alphadesign-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { ACTION_TYPES, SEARCH_SUGGESTION_TYPE_NAMES, SEARCH_SUGGESTION_TYPE_ICONS } from "constants/AlphaNow";
import {
  searchForExpertInsights,
  noSearchMatches,
  invalidCharacterNumber,
  invalidBooleanSearch,
} from "content/AlphaNow";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { contentService } from "services/content";
import { MatchedOnField, MatchedOnType } from "models/companies/search/constants";
import { Badge } from "models/Badge";
import { useUserBadgeContext } from "providers/BadgeProvider";

import Search, { SearchSizeVariant, SearchVariant } from "components/Search";
import { getDefaultComponents } from "components/Search/components";
import AlphaNowFilters from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters";
import SearchResults from "pages/AlphaNowPage/components/AlphaNowSearch/SearchResults";

import { REQUEST_PRIMER } from "content/AlphaNow/constants";
import { BooleanExpressionError, isBooleanSearch } from "components/Search/utils";
import { processSearchQuery } from "pages/AlphaNowPage/components/AlphaNowSearch/boolean-expression-utils";
import { Navigation } from "pages/AlphaNowPage/components";
import { useAlphaNowQueryContext } from "pages/AlphaNowPage/components/AlphaNowQueryContext";
import useContentClientSettings from "hooks/useContentClientSettings";
import {
  SearchBarWrapper,
  SearchToolsContainer,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowSearch.styled";
import {
  hasSearchFailure,
  getMarketValues,
  getMarketCompetitorCompanyIds,
  getContentFiltersPayload,
  extractSearchItemsFromQuery,
} from "pages/AlphaNowPage/components/AlphaNowSearch/utils";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import { hasAnyPrimer } from "content/AlphaNow/utils";
import {
  resultsPerPage,
  pageLimit,
  COMPANY_SEARCH_ID,
  COLLEAGUE_SEARCH_ID,
  MARKET_SEARCH_ID,
  noContentErrorMessage,
  noRelevantMarketsErrorMessage,
} from "./constants";

import "./index.css";

const WarningBanner = ({ text }) => {
  const { spacing } = useThemeTokens();
  return (
    <x.div mt={spacing.inner.base02}>
      <Alert variant="warning" zIndex="100">
        {text}
      </Alert>
    </x.div>
  );
};

const enhanceOption = (option) => ({
  ...option,
  ...(option.logo ? { avatar: { src: option.logo } } : { StartIcon: SEARCH_SUGGESTION_TYPE_ICONS[option.type] }),
  secondaryLabel: SEARCH_SUGGESTION_TYPE_NAMES[option.type],
});

const SearchItem = ({ data, ...props }) => {
  const enhancedData = enhanceOption(data);
  const { SearchItem: BaseSearchItem } = getDefaultComponents();
  return <BaseSearchItem data={enhancedData} {...props} />;
};

const Option = ({ data, ...props }) => {
  const { Option: BaseOption } = getDefaultComponents();
  const enhancedOption = enhanceOption(data);
  return <BaseOption data={enhancedOption} {...props} />;
};

const initialPageState = {
  hasMoreSearchResults: false,
  numberOfResults: undefined,
  searchIsLoading: true,
  searchResults: [],
  searchResultsPage: 1,
};

const pageReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.updateSearchResults:
      return { ...state, ...action.payload };
    case ACTION_TYPES.updateSearchIsLoading:
      return { ...state, searchIsLoading: action.payload };
    default:
      throw new Error(
        `Action { type: ${action.type}, payload: ${action.payload} } must be one of the defined ACTION_TYPES.`
      );
  }
};

const cleanUpQuery = (query) =>
  query?.map((item) => omit(item, ["secondaryLabel", "StartIcon", "inputValue", "logo"])) ?? [];

const AlphaNowSearch = ({
  isRequestPrimer = false,
  isSidebarExpanded,
  purchasedContentChanged = [],
  bookmarksChanged = [],
  accessDenied,
  setAccessDenied,
  activeSearchBar,
  setActiveSearchBar,
  selectedCompanyId,
  setSelectedCompanyId,
  setServicePlanExpired,
}) => {
  const currentUser = useCurrentUser();
  const userId = currentUser?.id;
  const { hasUserBadge } = useUserBadgeContext();
  const { logHit } = useTrackUserAction();
  const { isMobile } = useCheckScreen();

  const firstUpdate = useRef(true);
  const queryClient = useQueryClient();

  const [state, pageDispatch] = useReducer(pageReducer, initialPageState);

  const { query: queryState, updateQuery } = useAlphaNowQueryContext();

  const [searchSelectedCompanies, setSearchSelectedCompanies] = useState([]);
  const [searchQueryError, setSearchQueryError] = useState(false);
  const [invalidCharacterNumberSearch, setInvalidCharacterNumberSearch] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [pageNumber, setPageNumber] = useState();
  const [marketCompetitors, setMarketCompetitors] = useState([]);
  const [searchErrorMessage, setSearchErrorMessage] = useState();

  const { hasMoreSearchResults, numberOfResults, searchIsLoading, searchResults, searchResultsPage } = state;

  const { filters, searchQuery, selectedContentId } = queryState;
  const { clientSettings } = useContentClientSettings();
  const { contentTypeMapping } = useAlphaNowContentAccessLevelContext();

  const displayRequestPrimerCard =
    !selectedCompanyId && searchSelectedCompanies?.length > 0 && hasAnyPrimer(contentTypeMapping);

  // TODO [CON-3541]: Remove Private Contributor Badge
  const hasRelevantColleaguesBadge = hasUserBadge(Badge.privateContributor);

  // TODO [RD1-133]: Remove Markets Badge
  const hasMarketsEnabled = hasUserBadge(Badge.markets) && !isMobile;

  // TODO [CON-3152]: Remove Company Page Badge
  const hasCompanyPageBadge = hasUserBadge(Badge.companyPage);
  // TODO [RD1-134]: Remove Company Metadata Badge
  const hasCompanyMetadataBadge = hasUserBadge(Badge.companyMetadata);

  const hasRelevantColleaguesEnabled = hasRelevantColleaguesBadge && !!clientSettings?.showRelevantColleagues;

  const hasCompanyPageEnabled = hasCompanyPageBadge && !isMobile;

  const handleSearchLoading = (isLoading) => {
    pageDispatch({
      type: ACTION_TYPES.updateSearchIsLoading,
      payload: isLoading,
    });
  };

  const fetchSearchSuggestions = (inputValue) => {
    const fetchPromises = [
      {
        id: COMPANY_SEARCH_ID,
        promise: queryClient.fetchQuery(["search-companies", inputValue], () =>
          contentService.searchCompanies(inputValue)
        ),
      },
    ];

    if (hasRelevantColleaguesEnabled) {
      fetchPromises.push({
        id: COLLEAGUE_SEARCH_ID,
        promise: queryClient.fetchQuery(["search-colleagues", inputValue], () =>
          contentService.searchColleagues(inputValue)
        ),
      });
    }

    if (hasMarketsEnabled) {
      fetchPromises.push({
        id: MARKET_SEARCH_ID,
        promise: queryClient.fetchQuery(["search-markets", inputValue], () => contentService.searchMarkets(inputValue)),
      });
    }

    return fetchPromises;
  };

  const processSearchResults = (results, fetches) => {
    let companies = [];
    let colleagues = [];
    let markets = [];

    results.forEach((result, index) => {
      if (result.status === PromiseStatus.Fulfilled) {
        switch (fetches[index].id) {
          case COMPANY_SEARCH_ID:
            companies = result.value;
            break;
          case COLLEAGUE_SEARCH_ID:
            colleagues = result.value;
            break;
          case MARKET_SEARCH_ID:
            markets = result.value;
            break;
          default:
            break;
        }
      }
    });

    return { companies, colleagues, markets };
  };

  const handleSearchFailure = () => {
    setSearchError(true);
    return [];
  };

  const loadSearchOptions = async (inputValue) => {
    setSearchError(false);

    if (inputValue.length <= 1) return [];

    const searchFetches = fetchSearchSuggestions(inputValue);
    const searchResults = searchFetches.map((fetchObj) => fetchObj.promise);

    const searchOptions = Promise.allSettled(searchResults)
      .then((results) => {
        const { companies, colleagues, markets } = processSearchResults(results, searchFetches);

        if (hasSearchFailure(results)) {
          handleSearchFailure();
        }

        const primaryNameExactMatches = companies.filter(
          ({ matchedOn: { field, type } }) => field === MatchedOnField.PrimaryName && type === MatchedOnType.Exact
        );

        const options = [];
        if (isEmpty(primaryNameExactMatches)) {
          options.push({
            label: inputValue[0].toUpperCase() + inputValue.substring(1),
            value: inputValue,
            type: SEARCH_SUGGESTION_TYPES.Keyword,
          });
        } else {
          primaryNameExactMatches.forEach(({ cdsAlphaCompanyId, primaryName }) => {
            options.push({
              label: inputValue[0].toUpperCase() + inputValue.substring(1),
              value: primaryName,
              id: cdsAlphaCompanyId,
              type: SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch,
            });
          });
        }

        colleagues.forEach(({ id, name }) => {
          options.push({
            label: name,
            value: name,
            inputValue,
            id,
            type: SEARCH_SUGGESTION_TYPES.Colleague,
          });
        });

        markets.forEach(({ id, name }) => {
          options.push({
            label: name,
            value: name,
            inputValue,
            id,
            type: SEARCH_SUGGESTION_TYPES.Market,
          });
        });

        companies.forEach(({ cdsAlphaCompanyId, primaryName, logo }) => {
          options.push({
            label: primaryName,
            value: primaryName,
            inputValue,
            id: cdsAlphaCompanyId,
            type: SEARCH_SUGGESTION_TYPES.Company,
            logo,
          });
        });

        return options;
      })
      .catch(() => handleSearchFailure());
    return searchOptions;
  };

  const validateQuery = (query) => {
    if (isBooleanSearch(query)) {
      try {
        const booleanExpressionTree = processSearchQuery(query);
        setSearchQueryError(false);
        return booleanExpressionTree;
      } catch (e) {
        if (e instanceof BooleanExpressionError) {
          setSearchQueryError(true);
          handleSearchLoading(false);
          return;
        } else {
          throw e;
        }
      }
    }
    setSearchQueryError(false);
    return query;
  };

  const onSearchChange = (searchQuery) => {
    const query = cleanUpQuery(searchQuery ?? []);

    if (!validateQuery(query)) return;

    setActiveSearchBar(false);
    updateQuery({
      searchQuery: query,
      selectedContentId: undefined,
    });
    // prevents the useEffect from clearing the search query until the user dismisses the warning banner
    if (accessDenied) setAccessDenied(false);

    if (searchErrorMessage) setSearchErrorMessage();
    if (marketCompetitors) setMarketCompetitors([]);
    if (selectedCompanyId) setSelectedCompanyId();
  };

  const fetchSearchResults = (searchPayload) => {
    const fetchPromises = [contentService.fetchContent(searchPayload)];
    const hasMarketCompetitorsEnabled = hasCompanyPageEnabled && searchPayload.markets.length > 0;
    if (hasMarketCompetitorsEnabled) {
      fetchPromises.push(contentService.fetchRelevantCompanies(searchPayload));
    }

    return fetchPromises;
  };

  const handleResetSearchResults = () => {
    pageDispatch({
      type: ACTION_TYPES.updateSearchResults,
      payload: {
        searchResults: [],
        hasMoreSearchResults: false,
        numberOfResults: 0,
        searchResultsPage: 0,
      },
    });
  };

  const rejectedContentSearchPromise = async (contentSearchResults) => {
    pageDispatch({
      type: ACTION_TYPES.updateSearchResults,
      payload: {
        searchResults: null,
        searchIsLoading: false,
      },
    });
    setSearchErrorMessage(noContentErrorMessage);

    const error = contentSearchResults.reason;
    if (error?.status === 404) {
      const response = await error.json();

      setServicePlanExpired(response?.type === "PricingDetailException");
    }
  };

  const selectDefaultCompanyPage = async (marketCompetitorResults, selectedCompanies) => {
    let marketCompetitorCompanyId;

    if (marketCompetitorResults?.status === PromiseStatus.Fulfilled) {
      const marketCompetitors = marketCompetitorResults.value;
      if (!!marketCompetitors) {
        marketCompetitorCompanyId = marketCompetitors[0]?.company?.cdsAlphaCompanyId;
        setSelectedCompanyId(marketCompetitorCompanyId);
        setMarketCompetitors(marketCompetitors);
      }
    } else if (marketCompetitorResults?.status === PromiseStatus.Rejected) {
      setSearchErrorMessage(noRelevantMarketsErrorMessage);
    }

    if (!marketCompetitorCompanyId && selectedCompanies.length > 0) {
      const [{ id, name }] = selectedCompanies;
      try {
        const companyPageSettings = await contentService.fetchCompanyPageSettings(id, name);
        companyPageSettings.hasCompanyPage && setSelectedCompanyId(id);
      } catch (err) {
        console.error(err);
      }
    }
  };

  // newSearch is true when getItems is run on first load or with new parameters, it is false in infinite scroll
  const getItems = async ({ newSearch = false, initialSearch = false, contentId }) => {
    const isBooleanQuery = isBooleanSearch(searchQuery);
    let booleanSearchConditions = [];

    if (isBooleanQuery) {
      const validatedQuery = validateQuery(searchQuery);
      if (!validatedQuery) return;
      booleanSearchConditions.push(validatedQuery);
    }

    handleSearchLoading(true);

    if (newSearch) handleResetSearchResults();

    const {
      keywords: selectedKeywords,
      companies: selectedCompanies,
      colleagues: selectedColleagues,
      markets: selectedMarkets,
    } = extractSearchItemsFromQuery(searchQuery, isBooleanQuery);

    const newPage = newSearch ? 1 : searchResultsPage + 1;

    Promise.allSettled(
      fetchSearchResults({
        companies: selectedCompanies,
        keywords: selectedKeywords,
        colleagues: selectedColleagues,
        markets: selectedMarkets,
        page: newPage,
        // TODO [RD1-134]: Remove Company Metadata Badge
        filters: getContentFiltersPayload(filters, hasCompanyMetadataBadge),
        booleanSearchConditions,
        pageSize: resultsPerPage,
      })
    ).then(async (results) => {
      const [contentSearchResults, marketCompetitorResults] = results;
      if (contentSearchResults.status === PromiseStatus.Rejected) {
        await rejectedContentSearchPromise(contentSearchResults);
      } else {
        if (hasCompanyPageEnabled) await selectDefaultCompanyPage(marketCompetitorResults, selectedCompanies);

        const { requestId, results: contentResults, total, hasNextPage } = contentSearchResults.value;

        setPageNumber(newPage);
        setSearchSelectedCompanies(selectedCompanies);

        if (searchQuery.length > 0 && !initialSearch) {
          logHit({
            origin: HitOrigin.alphaNow,
            action: HitAction.alphaNowSearch,
            details: {
              companies: selectedCompanies,
              keywords: selectedKeywords,
              markets: getMarketValues(searchQuery, isBooleanQuery),
              marketCompetitorCompanyIds: getMarketCompetitorCompanyIds(marketCompetitorResults?.value),
              advanced_search_conditions: booleanSearchConditions,
              requestId,
            },
          });
        }

        if (contentResults) {
          let newResults = newSearch ? contentResults : [...searchResults, ...contentResults];
          if (contentId) {
            try {
              // temporary workaround until AlphaNow vs Transcript search relationship is reevaluated
              // and transcript search can support boolean search
              const allKeywords = selectedKeywords ?? [
                ...new Set(
                  searchQuery
                    .filter(({ type }) => parseInt(type) === SEARCH_SUGGESTION_TYPES.Keyword)
                    .map(({ value }) => value.replace(/["]+/g, ""))
                ),
              ];
              const sharedContent = await contentService.fetchContentById(contentId);
              if (allKeywords.length) {
                const sharedContentMentions = await contentService.fetchTranscriptMentions(contentId, allKeywords);
                sharedContent.keywordHits = sharedContentMentions?.data["transcript_search"].total;
              }
              newResults = [sharedContent, ...newResults.filter((x) => x.id !== contentId)];
              setAccessDenied(false);
            } catch (err) {
              setAccessDenied(true);
            }
          }
          pageDispatch({
            type: ACTION_TYPES.updateSearchResults,
            payload: {
              searchResults: newResults,
              hasMoreSearchResults: hasNextPage,
              numberOfResults: total,
              searchResultsPage: newPage,
              searchIsLoading: false,
            },
          });
        }
      }
    });
  };

  const selectContent = (id, index = null, contentType = null) => {
    const logHitAction =
      contentType === AlphaNowContentType.srm ? HitAction.alphaNowViewCompanyPrimer : HitAction.alphaNowViewTranscript;

    if (id) {
      const newQuery = {};
      newQuery.selectedContentId = id;

      if (id !== REQUEST_PRIMER) {
        logHit({
          origin: HitOrigin.alphaNow,
          action: logHitAction,
          details: { contentId: id, rank: index + 1, userId },
        });
        newQuery.fragmentIds = [];
      }
      updateQuery(newQuery);
    }
  };

  const activateSearchBar = () => {
    setActiveSearchBar(true);
  };

  const deactivateSearchBar = () => {
    setActiveSearchBar(false);
  };

  useDeepCompareEffect(() => {
    // reset selected content and search when filters / searchQuery change
    if (firstUpdate.current) {
      // on first update, get shared contentId from state and get items with shared content
      if (selectedContentId) {
        updateQuery({ selectedContentId });
        getItems({ newSearch: true, contentId: selectedContentId });
        firstUpdate.current = false;
        return;
      }
      // fail-safe to prevent boolean search from being performed if the user is unbadged
      // i.e. the user accesses a shared link with a boolean search query
      if (isBooleanSearch(searchQuery)) {
        updateQuery({ searchQuery: [] });
        firstUpdate.current = false;
        return;
      }
    }
    getItems({ newSearch: true, initialSearch: firstUpdate.current });
    // reset selected content when a new search is performed
    selectContent(undefined);
    firstUpdate.current = false;
  }, [filters, searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //handles selecting content in the company page which does not exist within the search results
    const isNewSearchCompanyPage =
      selectedCompanyId && selectedContentId && !searchResults.map(({ id }) => id).includes(selectedContentId);

    isNewSearchCompanyPage && getItems({ newSearch: true, contentId: selectedContentId });
  }, [selectedContentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accessDenied && searchQuery.length) {
      updateQuery({ searchQuery: [] });
      handleSearchLoading(false);
    }
  }, [accessDenied, pageDispatch, updateQuery, searchQuery]);

  return (
    <>
      <x.div display="flex" flex="1 1 auto" flexDirection="column" minH="0">
        <SearchToolsContainer>
          <SearchBarWrapper activeSearchBar={activeSearchBar} id="alphanow-search-container">
            {!isMobile && <Navigation />}
            <Search
              variant={SearchVariant.Complex}
              size={isMobile ? SearchSizeVariant.Large : SearchSizeVariant.Medium}
              query={searchQuery}
              placeholder={searchForExpertInsights}
              debounceSearch={true}
              loadOptions={loadSearchOptions}
              onChange={onSearchChange}
              onFocus={activateSearchBar}
              onBlur={deactivateSearchBar}
              setInvalidCharacterNumberSearch={setInvalidCharacterNumberSearch}
              components={{
                SearchItem,
                Option,
              }}
              optionProps={{
                inputEmphasis: ({ type }) =>
                  [SEARCH_SUGGESTION_TYPES.Company, SEARCH_SUGGESTION_TYPES.Colleague].includes(type),
                anywhereEmphasis: ({ type }) => type === SEARCH_SUGGESTION_TYPES.Colleague,
              }}
              allowSingleCharSearch={false}
              allowBooleanOperators
              autoHideSearchIcon={isMobile}
              style={{ flex: 1 }}
            />
          </SearchBarWrapper>
          {invalidCharacterNumberSearch && <WarningBanner text={invalidCharacterNumber} />}
          {searchError && <WarningBanner text={noSearchMatches} />}
          {searchQueryError && <WarningBanner text={invalidBooleanSearch} />}
          <AlphaNowFilters numberOfResults={numberOfResults} canOpenPopovers={isSidebarExpanded} />
        </SearchToolsContainer>
        <SearchResults
          isPageLimit={pageNumber === pageLimit}
          key={searchQuery}
          isRequestPrimer={isRequestPrimer}
          displayRequestPrimerCard={displayRequestPrimerCard}
          isLoading={searchIsLoading}
          hasMoreContent={hasMoreSearchResults}
          onSelect={selectContent}
          searchResults={searchResults}
          bookmarksChanged={bookmarksChanged}
          purchasedContentChanged={purchasedContentChanged}
          getItems={getItems}
          selectedCompanies={searchSelectedCompanies}
          marketCompetitors={marketCompetitors}
          setSelectedCompanyId={setSelectedCompanyId}
          selectedCompanyId={selectedCompanyId}
          errorMessage={searchErrorMessage}
          numberOfResults={numberOfResults}
        />
      </x.div>
    </>
  );
};

export default AlphaNowSearch;
