import React from "react";
import { x } from "@xstyled/styled-components";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { TopBar } from "./TopBar/TopBar";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { SidebarNav } from "components/SidebarNav/SidebarNav";

export const withNavigationContainer = <P extends object>(WrappedComponent: React.ComponentType<P>) => ({
  ...props
}: P) => {
  const newNavigationEnabled = useNewNavigation();
  const { isMobile } = useCheckScreen();
  const Wrapper = newNavigationEnabled && !isMobile ? NavigationContainer : React.Fragment;

  return (
    <Wrapper>
      <WrappedComponent {...(props as P)} />
    </Wrapper>
  );
};

const NavigationContainer = ({ children }: { children?: React.ReactElement }) => {
  const { wrapperStyles, contentStyles, mainContentStyles, viewPortStyles } = useNavigationContainerStyles();

  return (
    <x.div {...wrapperStyles}>
      <SidebarNav />
      <x.div {...contentStyles}>
        <TopBar />
        <x.div {...mainContentStyles}>
          <x.div {...viewPortStyles} data-testid="navigation-viewport">
            {children}
          </x.div>
        </x.div>
      </x.div>
    </x.div>
  );
};

const useNavigationContainerStyles = () => {
  const {
    spacing: { inner },
    color: { background },
  } = useThemeTokens();

  const wrapperStyles = {
    display: "flex",
    flexGrow: 1,
    background: "linear-gradient(131.42deg, #23497E 5.52%, #011D3A 54.61%, #23497E 98.27%)",
    h: "100dvh",
  };

  const contentStyles = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minW: 0,
  };

  const mainContentStyles = {
    borderRadius: inner.base02,
    overflow: "hidden",
    marginRight: inner.base,
    marginBottom: inner.base,
    flexGrow: 1,
  };

  const viewPortStyles = {
    overflow: "auto",
    background: background.surface.recessed,
    w: "100%",
    h: "100%",
    display: "flex",
    flexDirection: "column",
  };

  return {
    wrapperStyles,
    contentStyles,
    mainContentStyles,
    viewPortStyles,
  };
};
