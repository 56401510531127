import { PrimerVersion } from "@alphasights/client-portal-shared";
import { fetch } from "hooks/useApi";

const CONTENT_RECOMMENDATION_HEADER = "X-Content-Recommendation-Id";

export const projectService = {
  async fetchPrivateTranscriptContributorImpactedInteractions(projectToken, privateTranscriptContributor) {
    return fetch({
      url: `/api/projects/${projectToken}/impacted-interactions/private-transcript-contributor?private-transcript-contributor=${privateTranscriptContributor}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async fetchPeerContentContributorImpactedInteractions(projectToken, peerContentContributor) {
    return fetch({
      url: `/api/projects/${projectToken}/impacted-interactions/peer-content-contributor?peer-content-contributor=${peerContentContributor}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async fetchCanUpdatePeerContentContributor(projectToken, interactionId, peerContentContributor) {
    return fetch({
      url: `/api/projects/${projectToken}/interactions/${interactionId}/should-update-peer-content-contributor?peer-content-contributor=${peerContentContributor}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async fetchCanUpdatePrivateTranscriptContributor(projectToken, interactionId, privateTranscriptContributor) {
    return fetch({
      url: `/api/projects/${projectToken}/interactions/${interactionId}/should-update-private-transcript-contributor?private-transcript-contributor=${privateTranscriptContributor}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
};

export const contentService = {
  async fetchContentTypes() {
    return fetch({
      url: "/api/content/content-types",
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async fetchContentTypesToAccessLevelsMap() {
    return fetch({
      url: "/api/content/content-types-to-access-levels",
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async fetchContent({
    companies = [],
    keywords = [],
    colleagues = [],
    markets = [],
    page = 0,
    filters = {},
    booleanSearchConditions = [],
    pageSize = 20,
    companyType = null,
  }) {
    return await fetch({
      url: "/api/content/search",
      method: "PUT",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        ...filters,
        companies,
        keywords,
        colleague_ids: colleagues,
        market_ids: markets,
        advanced_search_conditions: booleanSearchConditions,
        page_size: pageSize,
        content_types: filters.content_types ? filters.content_types : [],
        page,
        company_type: companyType,
      }),
    }).then((res) => res.json());
  },
  async fetchContentById(contentId, trackingId, projectToken) {
    const searchParams = new URLSearchParams();
    projectToken && searchParams.set("projectToken", projectToken);

    return await fetch({
      ...(Boolean(trackingId) && {
        customHeaders: { [CONTENT_RECOMMENDATION_HEADER]: trackingId },
      }),
      url: `/api/content/alpha-now/${contentId}?` + searchParams.toString(),
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  async fetchContentByIds(contentIds, projectToken) {
    return await fetch({
      url: `/api/content/alpha-now`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({
        projectToken,
        contentIds,
      }),
    }).then((res) => res.json());
  },
  async searchCompanies(query) {
    return await fetch({
      url: `/api/content/companies/search?query=${encodeURIComponent(query)}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async fetchContentPurchasePrice(contentId) {
    return await fetch({
      url: `api/content/${contentId}/purchase/price`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  async purchaseTranscript({ caseCode, contentId, projectToken }, trackingId) {
    return await fetch({
      ...(Boolean(trackingId) && {
        customHeaders: { [CONTENT_RECOMMENDATION_HEADER]: trackingId },
      }),
      url: `/api/content/${contentId}/purchase`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({
        caseCode,
        projectToken,
      }),
    }).then((res) => res.json());
  },
  async purchaseUpcomingAlphaView({ caseCode, contentId, requestType, projectToken }, trackingId) {
    return await fetch({
      ...(Boolean(trackingId) && {
        customHeaders: { [CONTENT_RECOMMENDATION_HEADER]: trackingId },
      }),
      url: `/api/content/${contentId}/purchase/upcoming`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({
        caseCode,
        requestType,
        projectToken,
      }),
    }).then((res) => res.json());
  },
  async requestAlphaShieldApproval({ caseCode, contentId, contentTitle, projectToken }) {
    return await fetch({
      url: `/api/content/alpha-now/${contentId}/request-approval?title=${encodeURIComponent(contentTitle)}`,
      handleForbidden: true,
      skipAlert: true,
      method: "PATCH",
      body: JSON.stringify({
        caseCode,
        projectToken,
      }),
    }).then((res) => res);
  },
  async fetchConversation(contentId, projectToken) {
    const searchParams = new URLSearchParams();
    projectToken && searchParams.set("projectToken", projectToken);

    const conversation = await fetch({
      url: `/api/content/${contentId}/transcript/conversation?` + searchParams.toString(),
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
    return conversation;
  },
  async searchConversation(contentId, fullConversation, keywords, transcriptId, projectToken) {
    const data = await fetch({
      url: "/api/content/search/conversation",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        contentId,
        keywords,
        fullConversation,
        projectToken,
      }),
    }).then((res) => res.json());

    // Transcript View
    if (fullConversation && data.total === 0) {
      // If no mentions are found on the full conversation Transcript View search, fetch the conversation without mentions
      return contentService.fetchConversation(contentId, projectToken);
    }
    return data;
  },
  async searchTranscript(contentId, fullTranscript, keywords, transcriptId, projectToken) {
    const { data } = await fetch({
      url: "/api/content/search/transcript",
      method: "PUT",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        contentId,
        keywords,
        fullTranscript,
        projectToken,
      }),
    }).then((res) => res.json());

    // If no mentions are found on the full transcript view search, fetch the transcript without mentions
    if (fullTranscript && data.transcript_search.total === 0) {
      return contentService.fetchTranscript(transcriptId, projectToken);
    }
    // Mentions View
    return data.transcript_search;
  },
  async fetchTranscript(contentId, projectToken) {
    const searchParams = new URLSearchParams();
    projectToken && searchParams.set("projectToken", projectToken);

    return fetch({
      url: `/api/content/${contentId}/transcript?` + searchParams.toString(),
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async fetchContentBookmark(contentIds) {
    const bookmarks = await fetch({
      url: `api/client-bookmarks?contentIds=${contentIds}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
    return bookmarks[0];
  },
  async postContentBookmark(payload) {
    return fetch({
      url: "api/client-bookmarks",
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify(payload),
    }).then((res) => res.json());
  },
  async deleteContentBookmark(id) {
    return fetch({
      url: `api/client-bookmarks/${id}`,
      handleForbidden: true,
      skipAlert: true,
      method: "DELETE",
    });
  },
  async fetchExpertRequests(contentId) {
    return fetch({
      url: `/api/content/client-expert-inquiry?contentId=${contentId}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  async postExpertRequest({ contentId, note, speakerIds }) {
    return fetch({
      url: "/api/content/client-expert-inquiry",
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({
        contentId,
        note,
        speakerIds,
      }),
    }).then((res) => res.json());
  },
  async deleteExpertRequest(clientExpertInquiryId) {
    return fetch({
      url: `/api/content/client-expert-inquiry/${clientExpertInquiryId}`,
      method: "DELETE",
      handleForbidden: true,
      skipAlert: true,
    });
  },
  async updateExpertRequest(clientExpertInquiryId, { note, speakerIds, contentId = undefined }) {
    return fetch({
      url: `/api/content/client-expert-inquiry/${clientExpertInquiryId}`,
      method: "PUT",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        contentId,
        note,
        speakerIds,
      }),
    }).then((res) => res.json());
  },
  async downloadTranscript(contentId, projectToken) {
    const searchParams = new URLSearchParams();
    projectToken && searchParams.set("projectToken", projectToken);
    return fetch({
      url: `/api/content/${contentId}/download?` + searchParams.toString(),
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.blob());
  },
  async fetchTranscriptMentions(contentId, keywords) {
    return fetch({
      url: "/api/content/search/transcript/mentions",
      method: "PUT",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        contentId,
        keywords,
      }),
    }).then((res) => res.json());
  },

  async fetchContentAudio(contentId) {
    return fetch({
      url: `/api/content/${contentId}/audio`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.blob());
  },

  async fetchContentVtt(contentId) {
    return fetch({
      url: `/api/content/${contentId}/audio/vtt`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.blob());
  },

  async fetchAudioMetadata(contentId) {
    return fetch({
      url: `/api/content/${contentId}/audio/check`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  async fetchCompanyPrimerWithMentions(contentId, keywords, srmTypeVersion) {
    return fetch({
      url: `/api/content/search/company-primer${srmTypeVersion === PrimerVersion.PRIMER_VERSION_3 ? "-v3" : ""}`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({
        contentId,
        keywords,
      }),
    }).then((res) => res.json());
  },
  async fetchMarketPrimerWithMentions(contentId, keywords) {
    return fetch({
      url: `/api/content/search/market-primer`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({
        contentId,
        keywords,
      }),
    }).then((res) => res.json());
  },
  async fetchCustomerPrimerWithMentions(contentId, keywords) {
    return fetch({
      url: `/api/content/search/customer-primer`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({
        contentId,
        keywords,
      }),
    }).then((res) => res.json());
  },
  async fetchContentQuestionView(contentId) {
    return fetch({
      url: `/api/content/${contentId}/questions`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },

  async fetchComprehensiveSummary(contentId, projectToken) {
    return fetch({
      url: `/api/content/${contentId}/summary${projectToken ? `?projectToken=${projectToken}` : ""}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  async searchComprehensiveSummary(contentId, projectToken, keywords) {
    const data = await fetch({
      url: "/api/content/search/summary",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        contentId,
        projectToken,
        keywords,
      }),
    }).then((res) => res.json());

    // Comprehensive Summary View
    if (data.total === 0) {
      // If no keywords are found on the comprehensive summary view search, fetch the summary without keywords
      return contentService.fetchComprehensiveSummary(contentId, projectToken);
    }
    return data;
  },
  async getNewsletterPreferences() {
    return fetch({
      url: "/api/content/newsletter/preferences",
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  async updateNewsletterPreferences(subscribed) {
    return fetch({
      url: "/api/content/newsletter/preferences",
      handleForbidden: true,
      skipAlert: true,
      method: "PUT",
      body: JSON.stringify({
        subscribed,
        frequencyInDays: null,
      }),
    }).then((res) => res.json());
  },
  // for external clients without an account
  async getExternalNewsletterPreferences(trackingId) {
    return fetch({
      url: `/api/content/newsletter/${trackingId}/preferences`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  // for external clients without an account
  async updateExternalNewsletterPreferences(subscribed, trackingId) {
    return fetch({
      url: `/api/content/newsletter/${trackingId}/preferences`,
      handleForbidden: true,
      skipAlert: true,
      method: "PUT",
      body: JSON.stringify({
        subscribed,
        frequencyInDays: null,
      }),
    }).then((res) => res.json());
  },
  async postPrimerRequest(payload) {
    return fetch({
      url: "api/content/client-primer-inquiry",
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify(payload),
    }).then((res) => res.json());
  },
  async postSurveyRequest(payload) {
    return fetch({
      url: "api/content/client-survey-inquiry",
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify(payload),
    }).then((res) => res.json());
  },
  async searchAgenda(contentId, keywords) {
    const data = await fetch({
      url: "/api/content/search/agenda",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        contentId,
        keywords,
      }),
    }).then((res) => res.json());
    return data;
  },
  async fetchAlphaNowCompanyOverview(alphaCompanyId) {
    return fetch({
      url: `/api/content/companies/${alphaCompanyId}/overview`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  async fetchCompanyPageSettings(alphaCompanyId, companyName) {
    return fetch({
      url: `/api/content/company-page-settings?cdsAlphaCompanyId=${alphaCompanyId}&companyName=${companyName}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },

  async fetchExpertsByCompany(alphaCompanyId, sorting = {}, page, angleType = "", pageSize = 5) {
    let { sortBy = "", sortDirection = "" } = sorting;
    sortDirection = sortDirection.toUpperCase();
    const url = `/api/content/companies/${alphaCompanyId}/speaker-contributions?angleType=${angleType}&page=${page}&size=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    const res = await fetch({
      url,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    });
    const data = await res.json();
    return data;
  },

  async fetchAlphaNowAggregatedCompaniesContent(alphaCompanyId, angleType) {
    return fetch({
      url: `/api/content/companies/${alphaCompanyId}/aggregate-companies-content?angleType=${encodeURIComponent(
        angleType
      )}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },

  async fetchCompanyInsights(alphaCompanyId, contentType) {
    return fetch({
      url: `/api/content/companies/${alphaCompanyId}/insights?contentType=${contentType}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  async fetchPrivateContributions(alphaCompanyId) {
    return fetch({
      url: `/api/content/companies/${alphaCompanyId}/private-contributions`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  async fetchRelevantColleagues(contentId) {
    return fetch({
      url: `/api/content/${contentId}/relevant-colleagues`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },

  async fetchClientSettings() {
    return fetch({
      url: `/api/content/client-settings`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },

  async fetchWatchlists() {
    return fetch({
      url: `/api/content/watchlists?showTriggerUnread=true`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },

  async createWatchlist({
    name,
    triggers,
    frequency,
    deliveryDayOfWeek = null,
    deliveryTime = null,
    deliveryTimeZone = null,
  }) {
    return await fetch({
      url: "/api/content/create-watchlist",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        name,
        triggers,
        frequency,
        deliveryDayOfWeek,
        deliveryTime,
        deliveryTimeZone,
      }),
    }).then((res) => res.json());
  },

  async updateWatchlist(watchlistId, watchlistSettings) {
    return fetch({
      url: `/api/content/watchlists/${watchlistId}/update-watchlist`,
      method: "PUT",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify(watchlistSettings),
    }).then((res) => res.json());
  },

  async deleteWatchlist(watchlistId) {
    return fetch({
      url: `/api/content/watchlists/${watchlistId}/delete-watchlist`,
      method: "DELETE",
      handleForbidden: true,
      skipAlert: true,
    });
  },

  async getWatchlist({ watchlistId, sort, filter }) {
    return fetch({
      url: `/api/content/watchlists/${watchlistId}?sorting=${encodeURIComponent(sort)}&filter=${encodeURIComponent(
        filter
      )}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async updateWatchlistTriggers(watchlistId, triggers) {
    return fetch({
      url: `/api/content/watchlists/${watchlistId}/triggers/update-triggers`,
      method: "PUT",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        triggers,
      }),
    }).then((res) => res.json());
  },

  async deleteWatchlistTriggers(watchlistId, triggerIds) {
    return fetch({
      url: `/api/content/watchlists/${watchlistId}/triggers/delete-triggers?triggerIds=${encodeURIComponent(
        triggerIds
      )}`,
      method: "DELETE",
      handleForbidden: true,
      skipAlert: true,
    });
  },

  async searchColleagues(name) {
    return fetch({
      url: `/api/content/private-library/colleagues/search?name=${name}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },

  async searchWatchlistCompanies(query) {
    return await fetch({
      url: `/api/watchlist/companies/global-search?query=${encodeURIComponent(query)}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async bulkSearchWatchlistCompanies(query) {
    return await fetch({
      url: `/api/watchlist/companies/bulk-search?query=${encodeURIComponent(query)}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async searchMarkets(query, limit = 2) {
    return await fetch({
      url: `/api/content/markets/search?query=${query}&limit=${limit}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },

  async updateLibraryRecord(request) {
    return await fetch({
      url: `/api/content/library`,
      method: "PATCH",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify(request),
    }).then((res) => res.json());
  },

  async fetchRelevantCompanies({
    companies = [],
    keywords = [],
    colleagues = [],
    markets = [],
    filters = {},
    booleanSearchConditions = [],
    companyType = null,
  }) {
    return await fetch({
      url: "/api/content/search/relevant-companies",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        ...filters,
        companies,
        keywords,
        colleague_ids: colleagues,
        market_ids: markets,
        advanced_search_conditions: booleanSearchConditions,
        content_types: filters.content_types ? filters.content_types : [],
        company_type: companyType,
      }),
    }).then((res) => res.json());
  },

  async fetchSearchSummary({
    companies = [],
    keywords = [],
    colleagues = [],
    markets = [],
    booleanSearchConditions = [],
    filters = {},
  }) {
    return await fetch({
      url: "/api/content/search/content-summary",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify({
        ...filters,
        companies,
        keywords,
        colleague_ids: colleagues,
        market_ids: markets,
        advanced_search_conditions: booleanSearchConditions,
      }),
    }).then((res) => res.json());
  },
};
