import React, { useState } from "react";
import { PrimaryButtonSmall } from "../Button";
import { Icon } from "../alphaui";
import { fetch, useApi } from "../../hooks/useApi";
import { Spinner } from "../Spinner";
import { PageFooter } from "@alphasights/client-portal-shared";
import { pendingRequestLabels } from "components/InteractionsPage/helpers/Interaction";

export const DismissClientRequest = ({
  location: { pathname, hash, search },
  match: {
    path,
    url,
    params: { id, token, ...others },
  },
}) => {
  const [handled, setHandled] = useState(false);

  const dismissRequest = () => {
    const url = `/api/projects/${token}/interaction-requests/${id}`;
    return fetch({
      url: url,
      method: "DELETE",
    }).then(() => {
      setHandled(true);
    });
  };

  const [isLoading, clientRequest] = useApi(
    {
      url: `/api/projects/${token}/interaction-requests/${id}`,
      method: "GET",
    },
    [token, id]
  );

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="aui-h-screen aui-flex aui-flex-col aui-justify-between">
      <div className="aui-max-w-screen-lg lg:aui-mx-auto lg:aui-my-8 aui-text-grey-4 aui-m-8">
        <div className="aui-text-2xl aui-font-semibold aui-text-grey-5">
          {clientRequest.userName || "Client"} wants to {pendingRequestLabels[clientRequest.type]?.toLowerCase()} this
          interaction.
        </div>
        <div className="aui-text-2xl">
          {clientRequest.reason ? (
            <>
              <div>Reason given:</div>
              <div className="aui-leading-loose">{clientRequest.reason}</div>
            </>
          ) : (
            <div>Client did not provide a justification</div>
          )}
        </div>
        {clientRequest.processedAt && <div>This request was addressed at {clientRequest.processedAt}</div>}
        {!clientRequest.processedAt && (
          <PrimaryButtonSmall
            onClick={dismissRequest}
            className="aui-inline-flex aui-items-center aui-justify-center"
            disabled={handled}
          >
            <Icon icon="close-pill" className="aui-text-lg aui-mr-2" />
            <span>{handled ? "Done!" : "Dismiss this request"}</span>
          </PrimaryButtonSmall>
        )}
      </div>
      <PageFooter />
    </div>
  );
};
