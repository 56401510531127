import React, { useCallback, useMemo, useRef } from "react";
import { Divider } from "@alphasights/alphadesign-components";
import { WorkstreamToggler } from "components/InteractionsPage/WorkstreamToggler";
import { CUSTOMER_KNOWLEDGE, PORTAL_WORKSTREAM_BADGE, useProjectBadgeContext } from "providers/BadgeProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { hasSufficientCustomerKnowledge } from "pages/CustomerExpertPage/helpers";
import { ProjectMoreOptions, ProjectMoreOptionsProps } from "components/ProjectMoreOptions/ProjectMoreOptions";
import { ViewToggler } from "./ViewToggler/ViewToggler";
import { CustomerKnowledgeViewToggler } from "./CustomerKnowledgeViewToggler/CustomerKnowledgeViewToggler";
import { each } from "lodash";
import * as S from "./AdvisorsHeaderNewUx.styled";
import { useNewNavigation } from "@alphasights/client-portal-shared";

export type AdvisorsHeaderNewUxProps = {
  experts?: { angleTypeName: string }[];
  onChangeLayout: (layout: string) => void;
  searchComponent: React.ReactNode;
  filterComponent: (isCompact: boolean) => React.ReactNode;
  counter: React.ReactNode;
  currentView: string;
} & ProjectMoreOptionsProps;

export const AdvisorsHeaderNewUx = ({
  experts,
  onChangeLayout,
  searchComponent,
  filterComponent: filterComponentFn,
  counter,
  currentView,
  ...props
}: AdvisorsHeaderNewUxProps) => {
  const workstreamTogglerRef = useRef(null);
  const { portalCustomerKnowledgeEnabled } = useCurrentUser() ?? {};
  const newNavigationEnabled = useNewNavigation();
  const { hasProjectBadge } = useProjectBadgeContext();
  const { elementRef, isOverflow } = useObserveOverflow();
  const filterComponent = useMemo(() => {
    return filterComponentFn(isOverflow);
  }, [filterComponentFn, isOverflow]);

  const hasPortalWorkstreams = hasProjectBadge(PORTAL_WORKSTREAM_BADGE);
  const customerViewEnabled = portalCustomerKnowledgeEnabled || hasProjectBadge(CUSTOMER_KNOWLEDGE);
  const showCustomerViewToggler =
    (!isOverflow || newNavigationEnabled) &&
    customerViewEnabled &&
    hasSufficientCustomerKnowledge(experts) &&
    ["table-view", "customer-view"].includes(currentView);

  const LeftGroup = newNavigationEnabled ? S.Group : React.Fragment;
  const RightGroup = newNavigationEnabled ? S.GroupWithGap : React.Fragment;

  return (
    <S.Wrapper newNavigationEnabled={newNavigationEnabled} ref={elementRef}>
      <LeftGroup>
        {hasPortalWorkstreams && <WorkstreamToggler ref={workstreamTogglerRef} />}
        {workstreamTogglerRef.current && <Divider vertical h="30px" mx="10px" />}
        {showCustomerViewToggler && (
          <>
            <CustomerKnowledgeViewToggler onChangeLayout={onChangeLayout} currentView={currentView} />
            <Divider vertical h="30px" mx="10px" my={newNavigationEnabled ? undefined : "10px"} />
          </>
        )}
        {searchComponent}
        {newNavigationEnabled ? <S.Spacing /> : <Divider vertical h="30px" m="10px" />}
        {filterComponent}
      </LeftGroup>
      <RightGroup>
        {!newNavigationEnabled && (
          <>
            {counter}
            <Divider vertical h="0px" m="10px" />
          </>
        )}
        {!newNavigationEnabled ? <ProjectMoreOptions {...props} /> : <ViewToggler />}
      </RightGroup>
    </S.Wrapper>
  );
};

const useObserveOverflow = () => {
  const [isOverflow, setIsOverflow] = React.useState(true);

  const observeChildrenVisibility = useCallback((node: Element) => {
    const observer = new IntersectionObserver(
      (intersections) => {
        const someChildrenPartiallyVisible = intersections.some(
          ({ isIntersecting, intersectionRatio }) => !isIntersecting && intersectionRatio > 0 && intersectionRatio < 1
        );
        setIsOverflow(someChildrenPartiallyVisible);
        someChildrenPartiallyVisible && observer.disconnect();
      },
      { root: node, threshold: 1 }
    );

    each(node.children, (child) => observer.observe(child));
  }, []);

  const elementRef = useCallback(
    (node: HTMLElement | null) => {
      if (!node) return;
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) =>
        entries.forEach(({ target }) => observeChildrenVisibility(target))
      );
      resizeObserver.observe(node);
    },
    [observeChildrenVisibility]
  );

  return {
    elementRef,
    isOverflow,
  };
};
