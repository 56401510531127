import React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { landingPageDescription } from "pages/AlphaNowPage/components/LandingPage/constants";
import {
  HeaderContainer,
  StyledHeader,
  StyledTypography,
} from "pages/AlphaNowPage/components/LandingPage/LandingPageHeader/LandingPageHeader.styled";

const DATA_TEST_IDS = {
  landingPageTitle: "landing-page-title",
};

const LandingPageHeader = () => {
  const { color, font } = useThemeTokens();
  return (
    <HeaderContainer>
      <StyledHeader data-testid={DATA_TEST_IDS.landingPageTitle}>
        <Typography fontSize={font.size["07"]}>Welcome to the</Typography>
        <StyledTypography>AlphaSights Platform</StyledTypography>
      </StyledHeader>
      <Typography color={color.text.secondary}>{landingPageDescription}</Typography>
    </HeaderContainer>
  );
};

export { LandingPageHeader as default, DATA_TEST_IDS };
