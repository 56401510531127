import React, { useEffect, useRef, useState } from "react";
import { Popover, Button, Icon, ListOption, IconButton } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

import { getActualAppliedFilters } from "components/InteractionsPage/ExpertFilters";
import { FilterItem } from "components/InteractionsPage/FilterItem";
import { FilterChip } from "components/InteractionsPage/FilterChip";
import { useExpertFiltersStyles } from "components/InteractionsPage/ExpertFilters.styles";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ButtonWithMargin } from "components/Button";
import { ChevronLeft, ChevronRight, Close } from "@alphasights/alphadesign-icons";
import { useIsOverflow, useNewNavigation } from "@alphasights/client-portal-shared";

export const CalendarFilters = ({
  appliedFilters,
  options,
  project,
  selectedTabState,
  onSubmitFilters,
  onToggleFilter,
  onResetAllFilters,
  searchQuery,
}) => {
  const { isMobile } = useCheckScreen();
  const { containerStyles, containerStylesCalendar, listStyles } = useExpertFiltersStyles();
  const filterOptions = filterDefinitions({
    options,
    appliedFilters,
    project,
  });

  const onSubmitFiltersCallback = (newFilters) =>
    onSubmitFilters({
      ...appliedFilters,
      ...newFilters,
    });

  const [selected, select] = selectedTabState;
  const [showAvailaibilityFilter, setShowAvailaibilityFilter] = useState(true);
  const numFiltersApplied = Object.values(appliedFilters).reduce((acc, filter) => acc + (filter?.length ?? 0), 0);
  const hasAppliedFilter = numFiltersApplied > 0 || (selected && selected !== "all") || searchQuery;
  const filtersRef = useRef();
  const isFiltersOverflow = useIsOverflow(filtersRef, [selected, numFiltersApplied]);
  useEffect(() => {
    if (!isFiltersOverflow) {
      setShowAvailaibilityFilter(true);
    }
  }, [isFiltersOverflow]);

  return (
    <x.div ref={filtersRef} {...containerStyles} {...containerStylesCalendar}>
      {hasAppliedFilter && isFiltersOverflow && !showAvailaibilityFilter && (
        <Icon onClick={() => setShowAvailaibilityFilter(true)} color="secondary" data-testid="filters-chevron-left">
          <ChevronLeft />
        </Icon>
      )}
      <x.ul {...listStyles}>
        {!isMobile ? (
          <>
            {showAvailaibilityFilter && <AvailabilityFilterItem state={selectedTabState} />}
            {filterOptions.map((option, index) => (
              <FilterItem
                key={`item-${index}`}
                filterDefinition={option}
                onApplyFilter={(value) => onSubmitFiltersCallback({ [option.name]: value })}
              />
            ))}
          </>
        ) : (
          <FilterChip
            title="Filters"
            onClick={onToggleFilter}
            appliedFilters={getActualAppliedFilters({ appliedFilters, includeAllFilters: true })}
          />
        )}
        {hasAppliedFilter && (!isFiltersOverflow || !showAvailaibilityFilter) && (
          <x.li display="flex" alignItems="center">
            <ClearAllButton
              onClick={() => {
                onResetAllFilters();
                select && select("all");
              }}
            />
          </x.li>
        )}
      </x.ul>
      {hasAppliedFilter && isFiltersOverflow && showAvailaibilityFilter && (
        <Icon onClick={() => setShowAvailaibilityFilter(false)} color="secondary" data-testid="filters-chevron-right">
          <ChevronRight />
        </Icon>
      )}
    </x.div>
  );
};

const ClearAllButton = ({ onClick }) => {
  const newNavigationEnabled = useNewNavigation();
  return newNavigationEnabled ? (
    <IconButton variant="ghost" color="strong" onClick={onClick} size="small" testId="clear-all-button">
      <Close />
    </IconButton>
  ) : (
    <Button variant="ghost" onClick={onClick} whiteSpace="nowrap" h="30px">
      Clear All
    </Button>
  );
};

const AvailabilityFilterItem = ({ state: [selected, select] }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState();
  const { buttonsWrapper, itemsWrapper } = useExpertFiltersStyles();
  const openPopover = (event) => setAnchorEl(event.currentTarget);
  const closePopover = () => setAnchorEl(null);
  const applyFilter = () => {
    closePopover();
    select(checked ?? "all");
  };
  const clearFilter = () => {
    closePopover();
    setChecked("all");
    select("all");
  };
  const isChecked = (option) => (checked ? checked === option : selected === option);
  const open = Boolean(anchorEl);

  const FilterOption = ({ option, label }) => (
    <ListOption
      key={`checkbox-${option}`}
      type="text"
      inputType="checkbox"
      dataAttributes={{ "data-testid": `checkbox-${option}` }}
      label={label}
      selected={isChecked(option)}
      onChange={() => (isChecked(option) ? setChecked("all") : setChecked(option))}
    />
  );

  return (
    <>
      <FilterChip
        title="Availability"
        onClick={openPopover}
        appliedFilters={selected && selected !== "all" ? [selected] : []}
        data-testid="toggle-filter-Availability"
        open={open}
      />
      <NoPaddingPopover animationFrame={true} anchorEl={anchorEl} open={open} onClose={closePopover}>
        <x.div data-testid="filter-dropdown-content">
          <x.div {...itemsWrapper}>
            <FilterOption option="today" label="Today" />
            <FilterOption option="this week" label="This Week" />
            <FilterOption option="available" label="Available" />
            <FilterOption option="all" label="All" />
          </x.div>
          <x.div {...buttonsWrapper}>
            <ButtonWithMargin variant="ghost" onClick={clearFilter}>
              Clear
            </ButtonWithMargin>
            <Button variant="secondary" onClick={applyFilter}>
              Save
            </Button>
          </x.div>
        </x.div>
      </NoPaddingPopover>
    </>
  );
};

const NoPaddingPopover = styled(Popover)`
  .body-content {
    padding: 0;
  }
`;

const filterDefinitions = ({ options = {}, appliedFilters = {}, project }) => {
  const filterOptions = [];

  filterOptions.push({
    title: "Angle",
    type: "checkbox",
    name: "groups",
    value: appliedFilters.groups || [],
    options: options.groups,
    initialValue: [],
  });

  if (project.active) {
    filterOptions.push({
      title: "Status",
      type: "checkbox",
      name: "status",
      options: [
        { value: "proposed", label: "Available" },
        { value: "requested", label: "Requested" },
        { value: "scheduled", label: "Scheduled" },
        { value: "completed", label: "Completed" },
      ],
      value: appliedFilters.status || [],
      initialValue: [],
    });
  }

  if (options.companies) {
    filterOptions.push({
      title: "Company",
      type: "checkbox",
      name: "companies",
      value: appliedFilters.companies || [],
      options: options.companies,
      initialValue: [],
    });
  }

  return filterOptions;
};
