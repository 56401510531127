import { filter, uniq } from "lodash/fp";
import React, { FC, ReactElement } from "react";
import ReactHtmlParser from "react-html-parser";

import { formatCurrency } from "./formatCurrency";

export type CitationRenderer<T> = FC<CitationRendererProps<T>>;
export type Range<T> = { from: T; to: T | null };

export const TestIds = {
  lineBreak: "line-break",
};

export interface CitationRendererProps<T> {
  value: T;
}

/**
 * Returns a {@link CitationRenderer} with the provided {@link date}
 **/
export const DateRenderWithParens: CitationRenderer<number> = ({ value }) => <span>{`(${value})`}</span>;

/**
 * Returns a {@link CitationRenderer} with the provided {@link currencySymbol}
 **/
export const currencyRendererWithSymbol: (currencySymbol: string) => CitationRenderer<number> = (currencySymbol) => ({
  value,
}) => (
  <span>
    {currencySymbol}
    {formatCurrency(value)}
  </span>
);

export const PercentRenderer: CitationRenderer<number> = ({ value }) => <span>{value}%</span>;

export const NumberRenderer: CitationRenderer<number> = ({ value }) => <span>{value.toLocaleString()}</span>;

export const ToStringRenderer: CitationRenderer<any> = ({ value }) => {
  const generateLines = (value: any): ReactElement[] => {
    const elements = value
      ? value
          .toString()
          .split(/\r|\n|\r\n/)
          .filter((line: string) => line !== "")
          .flatMap((line: string, index: number) => [
            // @ts-ignore
            <span key={`line-${index}-${value}`}>{ReactHtmlParser(line)}</span>,
            <div key={`space-${index}-${value}`} data-testid={TestIds.lineBreak} style={{ height: "8px" }}></div>,
          ])
      : [""];
    elements.pop(); // last element will always be a line break

    return elements;
  };
  return <span className="mentions-container">{generateLines(value)}</span>;
};

interface RenderRangeParams<T> {
  renderer?: CitationRenderer<T>;

  /** Optional {@link React.ReactNode} to render after the range, but still within the highlighting */
  after?: React.ReactNode;

  /** Optional {@link React.ReactNode} to render before the range, but still within the highlighting */
  before?: React.ReactNode;

  /** Optional {@link boolean} to render {@link Range.to} even if {@link Range.to} matches {@link Range.from} (so no change) */
  shouldRenderFullRangeWhenNoChange?: boolean;

  /** Optional info to show between brackets */
  desc?: number;
}

export function renderRange<T>({
  before,
  renderer,
  after,
  shouldRenderFullRangeWhenNoChange = true,
  desc,
}: RenderRangeParams<T> = {}): CitationRenderer<Range<T>> {
  const render = renderer ?? ToStringRenderer;
  const description = desc ? ` (${desc})` : "";

  return ({ value: { from, to } }) => {
    const toValue = [null, undefined].includes(to as any) ? "+" : <> to {render({ value: to! })}</>;
    const shouldRenderToValue = to !== from || shouldRenderFullRangeWhenNoChange;

    return (
      <span>
        {before}
        {render({ value: from })}
        {shouldRenderToValue && toValue}
        {after}
        {description}
      </span>
    );
  };
}

const mergeSpeakerIds = (citation1: CitableValue<any>, citation2: CitableValue<any>) =>
  uniq([...citation1.citedBy, ...citation2.citedBy]);

/** Combines {@link start} and {@link end} into a single {@link CitableValue<Range<T>>} */
export function rangeOf<T>(start: CitableValue<T>, end: CitableValue<T>): CitableValue<Range<T>> {
  return {
    value: { from: start.value!, to: end.value! },
    citedBy: mergeSpeakerIds(start, end),
  };
}

export const dateOf = (quarter: CitableValue<number>, year: CitableValue<number>) => ({
  value: filter(Boolean)([quarter.value && `Q${quarter.value}`, year.value]).join(" "),
  citedBy: mergeSpeakerIds(quarter, year),
});
