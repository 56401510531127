export const alphaViewContent = "AlphaView";
export const cancelAttendanceAndTranscriptPurchaseContent = (title) =>
  `Are you sure you want to cancel your purchase to attend and recieve the transcript for '${title}'? You will no longer be able to attend or receive a transcript of this event.`;
export const cancelPurchaseContent = "Cancel Purchase";
export const cancelTranscriptPurchaseContent = (title) =>
  `Are you sure you want to cancel your purchase of the ‘${title}’ transcript? You will no longer receive a transcript of this event.`;
export const confirmPurchaseContent = "Confirm purchase";
export const confirmPurchaseAttendAndTranscriptContent = (price, title) =>
  `Do you want to attend and purchase the transcript for '${title}' for ${price}?`;
export const confirmPurchaseTranscriptContent = (price, title) =>
  `Do you want to purchase the '${title}' transcript for ${price}?`;
export const contactForMoreAlphaViewsContent =
  "This page only displays upcoming AlphaViews. For a complete list of our historic AlphaViews, please reach out to your account manager.";
export const employmentHistoryContent = "Employment History";
export const endOfResultsContent = "End of results";
export const featuredCompaniesContent = "Featured Companies";
export const notAvailableContent = "Not available";
export const noCancelContent = "No, cancel";
export const goBackContent = "No, go back";
export const numberOfUpcomingAlphaViewsContent = (number) => `${number} AlphaViews upcoming in the next 2 weeks`;
export const priceInCreditsContent = (price) => `${price} ${price === 1 ? "credit" : "credits"}`;
export const purchaseContent = "Purchase";
export const purchasedContent = "Purchased";
export const selectPurchaseOptionContent = "Select Purchase Option";
export const speakersContent = "Speakers";
export const tooCloseToStartTimeContent = "Too close to event start time";
export const topicsContent = "Topics";
export const upcomingContentContent = "Upcoming Content";
export const viewDetailsContent = "View Details";
export const viewEmploymentHistory = " View Employment History";
export const whatIsAnAlphaViewContent = "What is an AlphaView?";
export const whatIsAnAlphaViewBodyContent =
  "Get a high-level overview of market themes and industry trends by dialing into an AlphaView, a conference call featuring a panel of experts and an experienced moderator discussing a focused, relevant topic. You will receive a transcript after the call at no extra cost.";
export const willReceiveTranscriptContent =
  "A member of our team will be in contact with a transcript of the AlphaView within 48 hours of the event";
export const willReceiveInviteAndTranscriptContent =
  "A member of our team will be in contact with a calendar invitation, followed by a transcript of the AlphaView within 48 hours of the event";
export const yesCancelContent = "Yes, cancel";
export const yesPurchaseContent = "Yes, purchase";
