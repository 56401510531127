import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { AccordionItem, Typography, useThemeTokens, Alert } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Toggle } from "components/alphaui";
import { Badge } from "components/Badge";
import { useQuery } from "react-query";
import { projectService } from "services/content";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { updateInteractionPCCFlag, updateInteractionPTLFlag, loadUpgrades } from "components/InteractionsPage/reducer";
import { NoXMarginSkeleton } from "components/NoXMarginSkeleton";

export const ContributorProgram = ({
  interactionId,
  isPCCOptedIn,
  setIsPCCOptedIn,
  isPTLEnabledClient,
  canUpdatePeerContentContributor = { result: false },
  projectToken,
}) => {
  const {
    color,
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();
  const dispatch = useContext(DispatchContext);

  const { result } = canUpdatePeerContentContributor;
  const isToggleDisabled = !result;

  const updateState = useCallback(
    () =>
      dispatch(updateInteractionPCCFlag(interactionId, !isPCCOptedIn)).then(() => {
        dispatch(loadUpgrades({ projectToken, interactionId }));
      }),
    [dispatch, interactionId, isPCCOptedIn, projectToken]
  );

  const handleOnToggleClick = () => {
    updateState();
    setIsPCCOptedIn((prevState) => !prevState);
  };

  return (
    <x.div
      display="flex"
      justifyContent="center"
      flexDirection="column"
      paddingBottom={isPTLEnabledClient ? inner.base06 : "initial"}
      borderBottom={isPTLEnabledClient && `${border.width.small} solid ${color.border.neutral.default}`}
      data-testid="contributor-program-option"
    >
      <Typography variant="body-em" component="div" color={color.text.strong._} marginBottom={inner.base04}>
        Contributor Program
      </Typography>
      <x.div display="flex" alignItems="center" justifyContent="space-between">
        <Typography color={color.text.strong._} component="div">
          Contribute transcript and receive discount
        </Typography>
        <x.div display="flex" alignItems="center">
          <Typography color={color.text.warning} component="span" marginRight={inner.base02}>
            {isPCCOptedIn ? "Opted In" : "Opted Out"}
          </Typography>
          <Toggle
            disabled={isToggleDisabled}
            active={isPCCOptedIn}
            newToggle={!isToggleDisabled}
            key={`${interactionId}-${isPCCOptedIn}`}
            onChange={handleOnToggleClick}
            size="small"
          />
        </x.div>
      </x.div>
      <Typography
        color={color.text.disabled}
        variant="body-small"
        component="div"
        marginBottom={isToggleDisabled && inner.base02}
      >
        {isPCCOptedIn ? "Discount applied to this call" : "Discount not applied to this call"}
      </Typography>
      {isToggleDisabled && (
        <Alert data-testid="contributor-program-option-alert" variant="warning" w="100%">
          The window to change your contribution status has lapsed.
        </Alert>
      )}
    </x.div>
  );
};

export const PrivateTranscriptLibrary = ({
  isPCCEnabledClient,
  interactionId,
  isPrivateContributorOptedIn,
  setIsPrivateContributorOptedIn,
  canUpdatePrivateTranscriptContributor = { result: false },
  projectToken,
}) => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();
  const dispatch = useContext(DispatchContext);

  const { result } = canUpdatePrivateTranscriptContributor;
  const isToggleDisabled = !result;

  const updateState = useCallback(
    () =>
      dispatch(updateInteractionPTLFlag(interactionId, !isPrivateContributorOptedIn)).then(() => {
        dispatch(loadUpgrades({ projectToken, interactionId }));
      }),
    [dispatch, interactionId, isPrivateContributorOptedIn, projectToken]
  );

  const handleOnToggleClick = () => {
    updateState();
    setIsPrivateContributorOptedIn((prevState) => !prevState);
  };

  return (
    <x.div
      display="flex"
      justifyContent="center"
      flexDirection="column"
      paddingTop={isPCCEnabledClient ? inner.base06 : "initial"}
      data-testid="private-transcript-contributor-option"
    >
      <Typography
        variant="body-em"
        component="div"
        color={color.background.action.secondary.hover}
        marginBottom={inner.base04}
      >
        Private Transcript Library
      </Typography>
      <x.div display="flex" alignItems="center" justifyContent="space-between">
        <Typography color={color.background.action.secondary.hover} component="div">
          Share with my enterprise
        </Typography>
        <x.div display="flex" alignItems="center">
          <Typography color={color.text.warning} component="span" marginRight={inner.base02}>
            {isPrivateContributorOptedIn ? "Opted In" : "Opted Out"}
          </Typography>
          <Toggle
            disabled={isToggleDisabled}
            active={isPrivateContributorOptedIn}
            newToggle={!isToggleDisabled}
            key={`${interactionId}-${isPrivateContributorOptedIn}`}
            onChange={handleOnToggleClick}
            size="small"
          />
        </x.div>
      </x.div>
    </x.div>
  );
};

export const InteractionSettings = ({
  projectToken,
  interaction,
  upgrades = [],
  isPTLEnabledClient = false,
  isPCCEnabledClient = false,
}) => {
  const { privateTranscriptContributor, peerContentContributor, id: interactionId } = interaction;
  const [isPCCOptedIn, setIsPCCOptedIn] = useState(peerContentContributor);
  const [isPrivateContributorOptedIn, setIsPrivateContributorOptedIn] = useState(privateTranscriptContributor);

  const hasTranscriptUpgrade = useMemo(() => {
    return upgrades.map((u) => u.value).includes("transcript");
  }, [upgrades, interaction]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: canUpdatePeerContentContributor, isFetching: isFetchingPcc } = useQuery(
    ["canUpdatePeerContentContributor", interactionId, peerContentContributor, hasTranscriptUpgrade],
    () => projectService.fetchCanUpdatePeerContentContributor(projectToken, interactionId, !peerContentContributor),
    {
      enabled: Boolean(interactionId),
    }
  );

  const { data: canUpdatePrivateTranscriptContributor, isFetching: isFetchingPtl } = useQuery(
    ["canUpdatePrivateTranscriptContributor", interactionId, privateTranscriptContributor, hasTranscriptUpgrade],
    () =>
      projectService.fetchCanUpdatePrivateTranscriptContributor(
        projectToken,
        interactionId,
        !privateTranscriptContributor
      ),
    {
      enabled: Boolean(interactionId),
    }
  );

  useEffect(() => {
    setIsPrivateContributorOptedIn(privateTranscriptContributor);
    setIsPCCOptedIn(peerContentContributor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionId, peerContentContributor, privateTranscriptContributor]);

  const Title = () => {
    const {
      color,
      spacing: { inner },
    } = useThemeTokens();
    const showPCCBadge = isPCCEnabledClient && isPCCOptedIn;
    const showPTLBadge = isPTLEnabledClient && isPrivateContributorOptedIn;

    return (
      <x.span display="flex" gap={inner.base02} alignItems="center">
        <Typography color={color.background.action.secondary.hover} variant="body-large-em" component="span">
          Interaction Settings
        </Typography>
        {showPCCBadge && (
          <Badge value="Contributor" data-testid="interaction-settings-contributor-pill" borderRadius={inner.base02} />
        )}
        {showPTLBadge && (
          <Badge value="Enterprise" data-testid="interaction-settings-enterprise-pill" borderRadius={inner.base02} />
        )}
      </x.span>
    );
  };

  return (
    <AccordionItem data-testid="interaction-settings-section" title={<Title />}>
      {isFetchingPcc || isFetchingPtl ? (
        <x.div display="flex" justifyContent="center">
          <NoXMarginSkeleton count={5} />
        </x.div>
      ) : (
        <>
          {isPCCEnabledClient && (
            <ContributorProgram
              interactionId={interactionId}
              isPCCOptedIn={isPCCOptedIn}
              setIsPCCOptedIn={setIsPCCOptedIn}
              isPTLEnabledClient={isPTLEnabledClient}
              canUpdatePeerContentContributor={canUpdatePeerContentContributor}
              projectToken={projectToken}
            />
          )}
          {isPTLEnabledClient && (
            <PrivateTranscriptLibrary
              isPCCEnabledClient={isPCCEnabledClient}
              interactionId={interactionId}
              isPrivateContributorOptedIn={isPrivateContributorOptedIn}
              setIsPrivateContributorOptedIn={setIsPrivateContributorOptedIn}
              canUpdatePrivateTranscriptContributor={canUpdatePrivateTranscriptContributor}
              projectToken={projectToken}
            />
          )}
        </>
      )}
    </AccordionItem>
  );
};
