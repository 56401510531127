import styled from "@xstyled/styled-components";
import { Button, useThemeTokens } from "@alphasights/alphadesign-components";

const ButtonWrapper = styled.div(({ isNextButton }: { isNextButton: boolean }) => {
  const {
    color: { background },
    spacing: { layout },
  } = useThemeTokens();
  return `
  position: absolute;
  display: flex;
  align-items: center;
    top: 0;
    width: ${layout.base15};
    height: 100%;
    background: linear-gradient(${isNextButton ? "90deg" : "270deg"}, #ffff0000 0%, ${background.inverse} 87.93%);
    right: ${isNextButton ? 0 : "auto"};
    `;
});

const CarouselItemWrapper = styled.div(
  ({ percentageCarouselItemDisplayed }: { percentageCarouselItemDisplayed: number }) => {
    const {
      spacing: { inner },
    } = useThemeTokens();
    return `
    flex: 0 0 ${percentageCarouselItemDisplayed}%;
    box-sizing: border-box;
    padding-right: ${inner.base03};
    `;
  }
);
const StyledButton = styled(Button)(({ isNextButton }: { isNextButton: boolean }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return `
  position: absolute;
  height: ${inner.base08};
  width: ${inner.base08};
  padding: 0px;
  right: ${isNextButton ? inner.base03 : undefined};
  left:${isNextButton ? undefined : inner.base03};
    `;
});

export { ButtonWrapper, CarouselItemWrapper, StyledButton };
