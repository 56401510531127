import React from "react";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";
import { MONTHS } from "./constants";

const getDateFromMonthAndYear = (month, year) => year && `${MONTHS[month] || ""} ${year}`;

const EmploymentHistory = ({ company, endMonth, endYear, startMonth, startYear, title }) => {
  return (
    <x.div mt="500" fontSize="500" lineHeight="500">
      <x.div fontWeight="bold">{title}</x.div>
      <x.div color="neutral700">{company}</x.div>
      <x.div color="neutral700">
        {getDateFromMonthAndYear(startMonth, startYear)} - {getDateFromMonthAndYear(endMonth, endYear)}
      </x.div>
    </x.div>
  );
};

EmploymentHistory.propTypes = {
  company: PropTypes.string.isRequired,
  endMonth: PropTypes.number,
  endYear: PropTypes.number,
  startMonth: PropTypes.number,
  startYear: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export default EmploymentHistory;
