import React from "react";
import { TernaryButton } from "../alphaui";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";

import { alphaViewContent, whatIsAnAlphaViewBodyContent, whatIsAnAlphaViewContent } from "../../content/AlphaViews";
import { MODAL_TYPE } from "./constants";
import { Typography } from "@alphasights/alphadesign-components";

const whatIsAnAlphaViewModal = {
  body: whatIsAnAlphaViewBodyContent,
  title: whatIsAnAlphaViewContent,
  type: MODAL_TYPE.WHAT_IS_AN_ALPHAVIEW,
};

const Title = ({ setModal, title }) => (
  <>
    <x.div className="what-is-av" display="flex" alignItems="center">
      <Typography>{alphaViewContent}</Typography>
      <TernaryButton icon="more-info" className="aui-gray-400" onClick={() => setModal(whatIsAnAlphaViewModal)} />
    </x.div>
    <Typography variant="h2">{title}</Typography>
  </>
);

Title.propTypes = {
  setModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Title;
