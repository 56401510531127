const landingPageDescription =
  "Each week, we add hundreds of new expert insights to our library. Browse our research below, or search for a company or topic of interest.";
const inTheNewsTitle = "In the News";
const recommendedInvestorLedTitle = "Recommended Investor-led Calls";
const purchasedResearchTitle = "Purchased by You and Your Colleagues";
const bookmarkedResearchTitle = "Bookmarked";

export {
  landingPageDescription,
  inTheNewsTitle,
  recommendedInvestorLedTitle,
  purchasedResearchTitle,
  bookmarkedResearchTitle,
};
