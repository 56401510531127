import { useMemo } from "react";

export default (colors?: string[], isStacked: boolean = false, numberOfBars: number = 1) => {
  const defaultColors = [
    "#3C83F6",
    "#EC4699",
    "#0CC09E",
    "#EF4343",
    "#F59F0A",
    "#D535ED",
    "#F97415",
    "#8055F7",
    "#9CA1B0",
  ];

  const hexToRgba = (hex: string, alphaPercentage: number): string => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const clearHex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(clearHex);

    return result
      ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}, ${
          alphaPercentage / 100
        })`
      : "";
  };

  const isIndexSelected = (index: number, selectedIndex: number | number[] | null) => {
    if (selectedIndex === null) return false;

    if (Array.isArray(selectedIndex)) {
      return selectedIndex.includes(index);
    } else {
      return selectedIndex === index;
    }
  };

  return {
    getChartColors: useMemo(
      () => (selectedIndex: number | number[] | null): string[] => {
        if (isStacked) {
          const baseColors = colors && colors.length > 0 ? colors : defaultColors;
          const stackedColors = [];

          for (let i = 0; i < numberOfBars; i += 1) {
            stackedColors.push(...baseColors);
          }

          return stackedColors.map((color, index) =>
            selectedIndex === null || isIndexSelected(index, selectedIndex) ? color : hexToRgba(color, 20)
          );
        } else {
          return (colors && colors.length > 0 ? colors : defaultColors).map((color, index) => {
            return selectedIndex === null || isIndexSelected(index, selectedIndex) ? color : hexToRgba(color, 20);
          });
        }
      },
      [colors, defaultColors, isStacked, numberOfBars]
    ),
  };
};
