import qs from "qs";

// This is inspired from https://github.com/xpepermint/query-types
// which is an Express utility for parsing query strings

const isObject = (val: any) => {
  return val && typeof val === "object" && val.constructor === Object;
};

const isNumber = (val: any) => {
  return !isNaN(parseFloat(val)) && isFinite(val);
};

const isBoolean = (val: any) => {
  return val === "false" || val === "true" || typeof val === "boolean";
};

const isArray = (val: any) => {
  return Array.isArray(val);
};

const parseValue = (val: any): any => {
  if (typeof val === "undefined" || val === "") {
    return null;
  } else if (isBoolean(val)) {
    return parseBoolean(val);
  } else if (isArray(val)) {
    return parseArray(val);
  } else if (isObject(val)) {
    return parseObject(val);
  } else if (isNumber(val)) {
    return parseNumber(val);
  } else {
    return val;
  }
};

const parseObject = (obj: any) => {
  const result = {} as any;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const val = parseValue(obj[key]);
      if (val !== null) result[key] = val;
    }
  }
  return result;
};

const parseArray = (arr: any[]) => {
  return arr.map((item) => parseValue(item)) as any[];
};

const parseNumber = (val: string) => {
  return Number(val);
};

const parseBoolean = (val: string) => {
  return val === "true";
};

// ***************************************************************

const createURLFromParams = (params: any, pathname?: string) => {
  const queryParams = qs.stringify(params);
  const {
    location: { protocol, host, pathname: currentPathname },
  } = window;
  let url = `${protocol}//${host}${pathname ?? currentPathname}`;
  if (queryParams) {
    url += `?${queryParams}`;
  }
  return url;
};

const getQueryString = () => parseObject(qs.parse(window.location.search, { ignoreQueryPrefix: true }));

const setQueryString = (params: any) => {
  const url = createURLFromParams(params);
  window.history.pushState({ path: url }, "", url);
};

export {
  isObject,
  isNumber,
  isBoolean,
  isArray,
  parseValue,
  parseObject,
  parseArray,
  parseNumber,
  parseBoolean,
  createURLFromParams,
  getQueryString,
  setQueryString,
};
