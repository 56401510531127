import React, { useEffect } from "react";
import { x } from "@xstyled/styled-components";
import { MessengerBody, MessengerBodySkeleton } from "../MessengerBody/MessengerBody";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { MessengerHeader, MessengerHeaderSkeleton } from "../MessengerHeader/MessengerHeader";
import { NewMessageForm } from "../NewMessageForm/NewMessageForm";
import { useMessengerContext } from "providers/MessengerProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

export const MessengerContent = () => {
  const { selectedInbox: inbox, projectToken, newMessageType, inboxLoading } = useMessengerContext();
  const { container } = useMessengerContentStyles();
  const { logHit } = useTrackUserAction();
  useEffect(() => {
    const advisorId = inbox?.threads[0]?.advisor?.id;
    if (advisorId) {
      logHit({
        origin: HitOrigin.messagesPage,
        action: HitAction.messageOpen,
        projectToken,
        references: { expertId: advisorId },
      });
    }
  }, [inbox, projectToken, logHit]);

  return (
    <x.div data-testid="messenger-inbox-content" {...container}>
      {inboxLoading ? (
        <>
          <MessengerHeaderSkeleton />
          <MessengerBodySkeleton />
        </>
      ) : (
        <>
          {newMessageType && (
            <x.div data-testid="new-message-form" display="flex" flexGrow="1" minH="0">
              <NewMessageForm />
            </x.div>
          )}
          {inbox && !newMessageType && (
            <>
              <MessengerHeader />
              <MessengerBody inbox={inbox} projectToken={projectToken} />
            </>
          )}
        </>
      )}
    </x.div>
  );
};

export const useMessengerContentStyles = () => {
  const {
    color: { background },
  } = useThemeTokens();

  const container = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    background: background.surface.page.default,
  };

  return {
    container,
  };
};
