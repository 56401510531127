import React from "react";
import { x } from "@xstyled/styled-components";
import { Icon } from "../alphaui";
import PropTypes from "prop-types";
import { Typography } from "@alphasights/alphadesign-components";

export const List = ({ icon, title, content }) => {
  return (
    <>
      <x.div row py="200" justifyContent="flex-start">
        <x.div
          display="flex"
          alignItems="center"
          justifyContent="center"
          col={{
            _: 20,
          }}
        >
          <Icon icon={icon}></Icon>
        </x.div>
        <x.div col={{ _: 8 }}></x.div>
        <x.div col>
          <Typography variant="body-em">{title}</Typography>
        </x.div>
      </x.div>
      {content &&
        content.map((rowContent, index) => (
          <x.div key={index} mb="400" row>
            <x.div col={{ _: 20 }}></x.div>
            <x.div col={{ _: 8 }}></x.div>
            <x.div col>
              <Typography>{rowContent}</Typography>
            </x.div>
          </x.div>
        ))}
    </>
  );
};

List.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
};
