import React from "react";
import { Typography } from "@alphasights/alphadesign-components";

export const HighlightText = ({ className = "", ...props }) => (
  <span className={className} dangerouslySetInnerHTML={{ __html: getHighlightedText(props) }} />
);

export const HighlightTextTypography = ({ variant = "body", component, className = "", color, ...props }) => (
  <Typography
    variant={variant}
    component={component}
    className={className}
    color={color}
    dangerouslySetInnerHTML={{ __html: getHighlightedText(props) }}
  />
);

export const getHighlightedText = ({ highlights = [], fieldNames, text }) => {
  const highlight = highlights.find((highlight) => fieldNames.includes(highlight.fieldName));
  return highlight && highlight.terms.length ? highlight.terms[0] : text;
};
