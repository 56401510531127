const resultsPerPage = 20;
const maxNumResults = 200;
const pageLimit = maxNumResults / resultsPerPage;

const CUSTOMER_PRIMER_RESULTS_THRESHOLD = 20;
const MAX_RESULTS_DISPLAY = 99;

const COMPANY_SEARCH_ID = "searchCompanies";
const COLLEAGUE_SEARCH_ID = "searchColleagues";
const MARKET_SEARCH_ID = "searchMarkets";

const marketCompetitorsTitle = "Competitors";
const noContentErrorMessage = "Unable to display research at the moment.";
const noRelevantMarketsErrorMessage = "Unable to display competitors for this market at the moment.";

export {
  resultsPerPage,
  pageLimit,
  MAX_RESULTS_DISPLAY,
  CUSTOMER_PRIMER_RESULTS_THRESHOLD,
  COMPANY_SEARCH_ID,
  COLLEAGUE_SEARCH_ID,
  MARKET_SEARCH_ID,
  noContentErrorMessage,
  noRelevantMarketsErrorMessage,
  marketCompetitorsTitle,
};
