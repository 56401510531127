import React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import { MarketSplit } from "../../types/commercialModel";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";

const MarketSplits = ({ splits, isGoToMarket = false }: { splits: MarketSplit[]; isGoToMarket: boolean }) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  return (
    <x.div display="grid" gap={inner.base06}>
      {splits.map((split, index) => {
        return (
          <x.div
            display="grid"
            gridTemplateColumns="20% 80%"
            columnGap={inner.base04}
            alignItems="baseline"
            key={index}
          >
            <SensitiveDataContainer isSensitive={split.title.isSensitive}>
              <Typography variant="body-em" color={text.strong._}>
                {isGoToMarket || split.model === "Customised split heading" ? (
                  <CitationTextWrapper value={split.title} />
                ) : (
                  split.model
                )}
              </Typography>
            </SensitiveDataContainer>
            <CitationTextWrapper value={split.commentary.content} />
          </x.div>
        );
      })}
    </x.div>
  );
};

export default MarketSplits;
