import React from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../customTypes";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

const ExpertCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;
  const tokens = useThemeTokens();

  return (
    <x.div data-testid={`survey-expert-column-${interaction.id}`} color={tokens.color.text.info}>
      {props.getValue()}
    </x.div>
  );
};

export const ExpertColumn = {
  id: "ExpertColumn",
  accessorKey: "interaction.advisorName",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <ExpertCell props={props} />,
  header: "Expert",
  minSize: 50,
};
