import React from "react";
import { SecondaryButtonSmall } from "components/Button";
import { Icon } from "../../../components/alphaui";
import { pendingRequestLabels } from "components/InteractionsPage/helpers/Interaction";
import { InteractionRequestReschedulingSection } from "./InteractionRequestReschedulingSection";
import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { PopupDataAreaLeftPad } from "../PopupRenderers/common";

export const InteractionRequestSection = ({ interaction, requestPending, onCancelPendingRequest, variant }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  if (!requestPending) return null;

  if (requestPending.type === "RESCHEDULE_REQUEST" && requestPending.payload.requestedTime)
    return (
      <InteractionRequestReschedulingSection
        interaction={interaction}
        requestPending={requestPending}
        onCancelPendingRequest={onCancelPendingRequest}
        variant={variant}
      />
    );

  if (variant === "calendar-popup-v1") {
    return (
      <PopupDataAreaLeftPad marginTop="base04">
        <Typography variant="body-small" color="secondary">
          Your AlphaSights project lead has been notified of your request to{" "}
          {requestPending.type === "CANCEL_REQUEST"
            ? "cancel"
            : pendingRequestLabels[requestPending.type]?.toLowerCase()}{" "}
          this interaction.
        </Typography>
        <Button
          variant="outline"
          loading={interaction.runningAction === "requestChangeInteraction"}
          onClick={() => onCancelPendingRequest(requestPending)}
          data-testid="cancel-pending-request-button"
          w="100%"
          mt={inner.base04}
        >
          Retract {requestPending.type === "SCHEDULE_REQUEST" ? "Schedule" : "Cancellation"} Request
        </Button>
      </PopupDataAreaLeftPad>
    );
  }

  return (
    <div className="aui-flex aui-mt-4">
      <div className="aui-w-10">
        <Icon icon="calendar-availability" className="aui-text-grey-4 aui-leading-normal" />
      </div>
      <div data-testid="pending-request" className="aui-font-semibold aui-text-grey-4 aui-flex-1">
        <div>
          Your AlphaSights project lead has been notified of your request to{" "}
          {requestPending.type === "CANCEL_REQUEST"
            ? "cancel"
            : pendingRequestLabels[requestPending.type]?.toLowerCase()}{" "}
          this interaction.
        </div>
        <SecondaryButtonSmall
          data-testid="cancel-pending-request-button"
          isLoading={interaction.runningAction === "requestChangeInteraction"}
          onClick={() => onCancelPendingRequest(requestPending)}
          className="aui-inline-flex aui-items-center aui-justify-center aui-mt-2"
        >
          Cancel Request
        </SecondaryButtonSmall>
      </div>
    </div>
  );
};
