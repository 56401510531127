import React from "react";
import { Icon } from "../alphaui";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";

import {
  cancelAttendanceAndTranscriptPurchaseContent,
  cancelPurchaseContent,
  cancelTranscriptPurchaseContent,
  confirmPurchaseAttendAndTranscriptContent,
  confirmPurchaseContent,
  confirmPurchaseTranscriptContent,
  goBackContent,
  noCancelContent,
  notAvailableContent,
  purchaseContent,
  purchasedContent,
  selectPurchaseOptionContent,
  tooCloseToStartTimeContent,
  willReceiveInviteAndTranscriptContent,
  willReceiveTranscriptContent,
  yesCancelContent,
  yesPurchaseContent,
} from "../../content/AlphaViews";

import RadioGroup from "../RadioGroup";
import { WarningBanner } from "../Banner";
import { AccessControl } from "../AccessControl/AccessControl";

import { MODAL_TYPE, PURCHASE_OPTIONS, PURCHASE_OPTIONS_TEXT } from "./constants.js";
import { Typography } from "@alphasights/alphadesign-components";

const cancelButtons = (onCancel, onClose) => [
  { onClick: onCancel, text: yesCancelContent },
  { isTernary: true, onClick: onClose, text: goBackContent },
];

const cancelBody = (purchaseOption, title) => {
  return purchaseOption === PURCHASE_OPTIONS.TRANSCRIPT
    ? cancelTranscriptPurchaseContent(title)
    : cancelAttendanceAndTranscriptPurchaseContent(title);
};

const purchaseButtons = (onClose, onPurchase) => [
  { onClick: onPurchase, text: yesPurchaseContent },
  { isTernary: true, onClick: onClose, text: noCancelContent },
];

const purchaseBody = (price, purchaseOption, title) => {
  return purchaseOption === PURCHASE_OPTIONS.TRANSCRIPT
    ? confirmPurchaseTranscriptContent(price, title)
    : confirmPurchaseAttendAndTranscriptContent(price, title);
};

const UnPurchased = ({
  alphaViewTitle,
  checkAvailability,
  isAvailableToAttend,
  onPurchase,
  onClose,
  priceContent,
  purchaseOption,
  setPurchaseOption,
  setModal,
}) => {
  const purchaseModal = {
    body: purchaseBody(priceContent, purchaseOption, alphaViewTitle),
    buttons: purchaseButtons(onClose, () => onPurchase(purchaseOption)),
    title: confirmPurchaseContent,
    type: MODAL_TYPE.PURCHASE,
  };

  return (
    <x.div mt="800">
      <Typography variant="body-large-em">{selectPurchaseOptionContent}</Typography>
      <RadioGroup
        disabledOptions={isAvailableToAttend ? [] : [PURCHASE_OPTIONS.CALL_AND_TRANSCRIPT]}
        options={PURCHASE_OPTIONS_TEXT}
        selectedOption={purchaseOption}
        onChange={setPurchaseOption}
      />
      {!isAvailableToAttend && (
        <WarningBanner mt="300">
          <Typography variant="body-em" component="span">
            {notAvailableContent}
          </Typography>{" "}
          <Typography component="span">{tooCloseToStartTimeContent}</Typography>
        </WarningBanner>
      )}
      <x.div display="flex" mt="500">
        <x.div flexGrow={1}>
          <AccessControl allowedPermissions={["alphaviews", "peer_contributed_content"]}>
            <x.button
              w={1}
              py="400"
              px="700"
              borderRadius="200"
              backgroundImage="gradient-to-l"
              gradientFrom="primary800"
              gradientVia="primary700"
              gradientTo="primary500"
              color="white"
              cursor="pointer"
              onClick={() => {
                if (checkAvailability(purchaseOption)) {
                  setModal(purchaseModal);
                }
              }}
            >
              <Typography component="span" variant="body-em">
                {purchaseContent}
              </Typography>
            </x.button>
          </AccessControl>
        </x.div>
      </x.div>
    </x.div>
  );
};

UnPurchased.propTypes = {
  alphaViewTitle: PropTypes.string.isRequired,
  checkAvailability: PropTypes.func.isRequired,
  isAvailableToAttend: PropTypes.bool,
  onPurchase: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  priceContent: PropTypes.string.isRequired,
  purchaseOption: PropTypes.string.isRequired,
  setPurchaseOption: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
};

const Purchased = ({ alphaViewTitle, onCancel, onClose, purchaseOption, setModal }) => {
  const cancelModal = {
    body: cancelBody(purchaseOption, alphaViewTitle),
    buttons: cancelButtons(onCancel, onClose),
    title: cancelPurchaseContent,
    type: MODAL_TYPE.CANCEL,
  };

  return (
    <x.div mt="800" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <x.div
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="1"
        mb="300"
        py="400"
        px="500"
        borderColor="success600"
        borderWidth="2"
        borderRadius="200"
        color="success600"
        fontSize="450"
        lineHeight="450"
        fontWeight="bold"
      >
        <Icon icon="check" className="aui-mx-2" />
        {purchasedContent}
      </x.div>
      <x.p my="300" color="neutral900" fontSize="450" textAlign="center">
        {purchaseOption === PURCHASE_OPTIONS.TRANSCRIPT
          ? willReceiveTranscriptContent
          : willReceiveInviteAndTranscriptContent}
      </x.p>
      <x.span
        onClick={() => setModal(cancelModal)}
        mt="700"
        color={{ _: "neutral700", hover: "secondary500" }}
        fontWeight="bold"
        whiteSpace="nowrap"
        cursor="pointer"
      >
        {cancelPurchaseContent}
      </x.span>
    </x.div>
  );
};

Purchased.propTypes = {
  alphaViewTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  purchaseOption: PropTypes.string.isRequired,
  setModal: PropTypes.func.isRequired,
};

const PurchaseOptions = ({ isPurchased, ...props }) => {
  return isPurchased ? <Purchased {...props} /> : <UnPurchased {...props} />;
};

PurchaseOptions.propTypes = {
  isPurchased: PropTypes.bool,
};

export default PurchaseOptions;
