import React, { ReactNode } from "react";
import {
  Divider,
  Pill,
  PillVariants,
  Typography,
  useThemeTokens,
  Icon as RenderIcon,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Flag } from "@alphasights/alphadesign-icons";
import { OutlookProperties } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/outlook";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import { CompetitorGrowth } from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import DirectionalArrow from "components/DirectionalArrow";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";

const OutlookContent = ({
  content,
  headerItems,
  isGrowthDrivers,
}: {
  content: OutlookProperties[];
  headerItems: string[];
  isGrowthDrivers: boolean;
}) => {
  const {
    color: { text },
    spacing: { inner },
    typography,
  } = useThemeTokens();
  const { isAccessible } = usePrimersStore();

  const impactOrder: {
    [key: string]: {
      order: number;
      value: CompetitorGrowth.Increasing | CompetitorGrowth.Decreasing;
      color: string;
      variant: PillVariants;
    };
  } = {
    HIGHLY_POSITIVE: {
      order: 4,
      value: CompetitorGrowth.Increasing,
      color: text.success,
      variant: "green",
    },
    POSITIVE: {
      order: 3,
      value: CompetitorGrowth.Increasing,
      color: text.success,
      variant: "green",
    },
    NEGATIVE: {
      order: 2,
      value: CompetitorGrowth.Decreasing,
      color: text.danger,
      variant: "red",
    },
    HIGHLY_NEGATIVE: {
      order: 1,
      value: CompetitorGrowth.Decreasing,
      color: text.danger,
      variant: "red",
    },
  };

  const renderIcons = (impact: string, parentIndex: number) => {
    let Icon: ReactNode;
    const IconsGroup: ReactNode[] = [];

    isGrowthDrivers
      ? (Icon = (
          <DirectionalArrow
            key={`${parentIndex}_1`}
            isUp={impactOrder[impact]?.value === CompetitorGrowth.Increasing}
          />
        ))
      : (Icon = (
          <RenderIcon key={`${parentIndex}_1`} size="small" color={impactOrder[impact].color}>
            <Flag />
          </RenderIcon>
        ));

    IconsGroup.push(Icon);

    if (impact === "HIGHLY_POSITIVE" || impact === "HIGHLY_NEGATIVE") {
      const newIcon = {
        ...Icon,
        key: `${parentIndex}_2`,
      };
      IconsGroup.push(newIcon);
    }

    return IconsGroup;
  };

  const sortByImpact = (a: OutlookProperties, b: OutlookProperties) => {
    const aImpact = a.impact?.value || "";
    const bImpact = b.impact?.value || "";
    return impactOrder[bImpact]?.order - impactOrder[aImpact]?.order;
  };

  const sortedContent = [...content].sort(sortByImpact);

  return (
    <>
      <x.div display="grid" columnGap="10px" w="100%" gridTemplateColumns="175px 155px 1fr" color={text.assistive}>
        {headerItems.map((item) => {
          return (
            <Typography key={item} variant="navigation">
              {item}
            </Typography>
          );
        })}
      </x.div>
      <Divider />
      {sortedContent.map((item, index) => {
        const impact = item.impact.value || "";

        return (
          <SensitiveDataContainer isSensitive={!isAccessible}>
            <x.div
              alignItems="start"
              display="grid"
              columnGap="10px"
              gridTemplateColumns="175px 155px 1fr"
              w="100%"
              key={index}
            >
              <x.div color={text.strong._} {...typography.body.em}>
                <CitationTextWrapper value={item.driver} />
              </x.div>
              <x.div pt={inner.base}>
                <Pill size="small" variant={impactOrder[impact]?.variant as PillVariants}>
                  <x.span
                    display="flex"
                    alignItems="center"
                    color={impactOrder[impact]?.color}
                    backgroundColor="inherit"
                  >
                    {renderIcons(impact, index)}
                  </x.span>
                </Pill>
              </x.div>
              <x.div color={text.strong._} {...typography.body.regular}>
                {<CitationTextWrapper value={item.commentary.content} />}
              </x.div>
            </x.div>
          </SensitiveDataContainer>
        );
      })}
    </>
  );
};

export default OutlookContent;
