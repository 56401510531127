import { useCurrentUser } from "@alphasights/portal-auth-react";

const CID_BLACKLISTED_EMAILS = ["nick.smith+alphademo@alphasights.com"];

export const useUserCidEnabled = () => {
  const currentUser = useCurrentUser();
  return (
    currentUser &&
    !CID_BLACKLISTED_EMAILS.includes(currentUser.email) &&
    (currentUser.internalUser || currentUser.cidEnabled)
  );
};
