import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useAdvisorCardStyles = ({ selected, isExpertHidden, isMobileLayout, locked, ctasCount } = {}) => {
  const {
    color: { border, background, base, text },
    shape,
    spacing,
  } = useThemeTokens();

  const cardContainer = {
    borderStyle: "solid",
    borderWidth: shape.border.width.sm,
    borderColor: locked
      ? border.neutral.default
      : {
          _: selected ? border.selected : border.neutral.default,
          hover: border.selected,
        },
    borderRadius: shape.border.radius.medium,
    position: "relative",
    background: isExpertHidden ? background.disabled : base.white,
    cursor: "pointer",
  };

  const cardFooter = {
    borderTopWidth: "1px",
    borderTopColor: border.neutral.default,
    p: spacing.inner.base06,
    pt: spacing.inner.base04,
    display: ctasCount === 0 ? "none" : "flex",
    alignItems: isMobileLayout ? "stretch" : "center",
    justifyContent: locked ? "flex-start" : "flex-end",
    gap: spacing.inner.base04,
    flexDirection: isMobileLayout ? "column" : "row",
  };

  const availabilitiesText = {
    color: text.secondary,
    textAlign: isMobileLayout ? "center" : "left",
  };

  const cardList = {
    display: "flex",
    flexDirection: "column",
    gap: spacing.layout.base03,
  };

  const cardGroupList = {
    display: "flex",
    flexDirection: "column",
    gap: spacing.layout.base06,
  };

  const textColorStrong = {
    color: text.strong._,
  };

  return {
    textColorStrong,
    cardList,
    cardGroupList,
    cardContainer,
    cardFooter,
    availabilitiesText,
  };
};
