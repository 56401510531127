import React from "react";
import classNames from "classnames";
import { Icon } from "../alphaui";
import { RecordingPlayer } from "../RecordingPlayer";
import { useAudioPlayer } from "hooks/useAudioPlayer";

export const AudioPlayer = ({ recording, onClose, number, interactionId, projectToken, origin, variant, language }) => {
  const containerClassNames = classNames(
    "aui-flex",
    "aui-w-full",
    "aui-border-0",
    "aui-border-t",
    "aui-border-b",
    "aui-border-solid",
    "aui-border-grey-2",
    "aui-py-5",
    "aui-px-3",
    "aui-my-3"
  );

  const { controlsProps, progressBarProps, playbackProps, renderElements } = useAudioPlayer({
    audioUrl: recording.url,
    disabled: recording.obfuscated,
  });

  return (
    <div
      className={"new-flyout" === variant || "calendar-popup" === variant ? "aui-flex-grow" : containerClassNames}
      data-testid={`audio-player-${number}`}
    >
      {recording.url ? (
        <>
          {renderElements}
          <RecordingPlayer
            controlsProps={controlsProps}
            progressBarProps={progressBarProps}
            playbackProps={playbackProps}
            number={number}
            disabled={recording.obfuscated}
            interactionId={interactionId}
            projectToken={projectToken}
            recordingId={recording.id}
            origin={origin}
            variant={variant}
            onClose={onClose}
            language={language}
          />
        </>
      ) : (
        <span>Invalid Audio</span>
      )}
      {variant !== "new-flyout" && variant !== "calendar-popup" && (
        <div className="aui-relative" style={{ top: 0, right: 10 }}>
          <Icon icon="close" className="aui-text-xs aui-cursor-pointer aui-text-grey-5" onClick={onClose} />
        </div>
      )}
    </div>
  );
};
