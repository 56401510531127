import React, { useRef } from "react";
import { Container, ListOption, Typography } from "@alphasights/alphadesign-components";
import * as S from "components/SidebarNav/SidebarNav.styled";
import useOnClickOutside from "hooks/useOnClickHooks";

type AvatarMenuProps = {
  onWatchlistClick: () => void;
  onPreferencesClick: () => void;
  onClick: (() => void) | undefined;
  userName?: string;
  anchorElement: Element | undefined;
  closeMenu: () => void;
};

export const AvatarMenu = ({
  onWatchlistClick,
  onPreferencesClick,
  onClick,
  userName,
  anchorElement,
  closeMenu,
}: AvatarMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, (event) => {
    closeMenu();
  });

  return (
    <S.Popover
      open
      placement="right-start"
      onClick={onClick}
      closeOnClickOutside={true}
      anchorEl={anchorElement}
      ref={ref}
      isAvatarMenu
    >
      <Container>
        <ListOption type="text" isInteractive={false} indent={0}>
          <Typography variant="body-large-em">{userName}</Typography>
        </ListOption>
        <ListOption
          type="text"
          size="regular"
          label="Watchlist"
          onChange={() => onWatchlistClick()}
          dataAttributes={{ "data-testid": `watchlist-link` }}
        />
        <ListOption
          type="text"
          size="regular"
          label="Preferences"
          onChange={() => onPreferencesClick()}
          dataAttributes={{ "data-testid": `preferences-link` }}
        />
      </Container>
    </S.Popover>
  );
};
