import React from "react";
import { x } from "@xstyled/styled-components";
import { Button, IconButton, Switch } from "@alphasights/alphadesign-components";
import { Download } from "@alphasights/alphadesign-icons";
import { useStyles } from "./SynthesisSidebarHeader.styles";
import { SynthesisReprocessButton } from "../SynthesisModuleHeader/SynthesisReprocessButton";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import _ from "lodash";

export const SynthesisSidebarHeader = ({
  showSuggested = true,
  setShowSuggested,
}: {
  showSuggested: boolean;
  setShowSuggested: (showSuggested: boolean) => void;
}) => {
  const styles = useStyles();

  const { projectSynthesis, setMode, onPromoteAllModules, modulesToPromote } = useProjectSynthesisContext();

  const onNewModuleClick = () => {
    setMode(Mode.NEW);
  };

  const handleSuggestedToggle = () => {
    setShowSuggested(!showSuggested);
  };

  const handleDownload = () => {
    alert("TBD in CP2-987");
  };

  const newModuleOptions = _.compact([
    {
      label: "Create new module",
      onClick: onNewModuleClick,
    },
    modulesToPromote.length > 0 && {
      label: "Add all suggested modules",
      onClick: onPromoteAllModules,
    },
  ]);

  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  return (
    <x.div data-testid="synthesis-sidebar-header">
      {projectSynthesis.length === 0 ? (
        <EmptySynthesisSidebarHeader />
      ) : (
        <x.div {...styles.headerWrapper}>
          <x.div {...styles.leftGroup}>
            <Button
              variant="primary"
              splitOptions={newModuleOptions.length > 1 ? newModuleOptions : undefined}
              size="small"
              onClick={onNewModuleClick}
              dataAttributes={{ "data-testid": "new-module-btn" }}
              disabled={internalUserNoAccess}
            >
              New module
            </Button>
            <SynthesisReprocessButton />
            <IconButton
              onClick={handleDownload}
              disabled={internalUserNoAccess}
              variant="outline"
              size="small"
              testId="download-synthesis"
            >
              <Download />
            </IconButton>
          </x.div>

          <x.div {...styles.rightGroup}>
            <Switch leftText="Suggested" size="small" isSelected={showSuggested} onClick={handleSuggestedToggle} />
          </x.div>
        </x.div>
      )}
      <x.div {...styles.separator} />
    </x.div>
  );
};

export const EmptySynthesisSidebarHeader = () => {
  const styles = useStyles();

  return (
    <x.div {...styles.headerWrapper} data-testid="empty-sidebar-header">
      <x.div {...styles.leftGroup}>
        <Button variant="primary" size="small" disabled>
          Create New Module
        </Button>
      </x.div>

      <x.div {...styles.rightGroup}></x.div>
    </x.div>
  );
};
