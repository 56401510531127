import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useComplianceResourcesStyles = () => {
  const {
    color: { text, base },
    spacing: { inner },
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
  };

  const card = {
    display: "flex",
    gap: inner.base04,
    color: "inherit",
  };

  const cardImageWrapper = {
    display: "flex",
    position: "relative",
    maxHeight: "60px",
    maxWidth: "29%",
    cursor: "pointer",
  };

  const cardImage = {
    width: 105,
    height: 60,
  };

  const cardImageIcon = {
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    color: base.white,
    backgroundColor: base.grey[1000],
    opacity: 0.7,
    pointerEvents: "none",
  };

  const info = {
    display: "flex",
    flexDirection: "column",
    maxHeight: "60px",
    maxWidth: "70%",
  };

  const infoText = {
    color: text.secondary,
    overflow: "hidden",
  };

  return {
    wrapper,
    card,
    cardImageWrapper,
    cardImage,
    cardImageIcon,
    info,
    infoText,
  };
};
