import React from "react";
import { x } from "@xstyled/styled-components";
import { SynthesisModule } from "@alphasights/portal-api-client";
import { useStyles } from "./SynthesisSidebarContent.styles";
import { Add, DragIndicator } from "@alphasights/alphadesign-icons";
import { IconButton, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { Mode } from "providers/ProjectSynthesisProvider.types";

export const SynthesisSidebarCard = ({
  synthesisModule,
  isSingleExtractedModule,
  isDragging,
}: {
  synthesisModule: SynthesisModule;
  isSingleExtractedModule: boolean;
  isDragging: boolean;
}) => {
  const styles = useStyles();
  const { selectedModule, promoteModule, mode } = useProjectSynthesisContext();

  const isSelected = mode !== Mode.NEW && synthesisModule.id === selectedModule?.id;

  const lastRevision = synthesisModule.questionRevisions[synthesisModule.questionRevisions.length - 1];

  if (!lastRevision) {
    return null;
  }

  return (
    <x.div
      {...styles.cardWrapper}
      {...(synthesisModule.type.toLowerCase() === "custom" || isSingleExtractedModule
        ? styles.isCustom
        : styles.isExtracted)}
      {...(isSelected ? styles.isActive : styles.isInactive)}
      aria-selected={isSelected}
      data-testid={`synthesis-sidebar-card-${lastRevision.topic ?? "processing"}`}
    >
      <Typography variant="body" color={"black"} lineHeight="32px">
        {lastRevision.topic ? <>{lastRevision.topic}</> : <Skeleton variant="noMargin" width="250px" height="20px" />}
      </Typography>
      {synthesisModule.type.toLowerCase() === "extracted" && !isDragging && (
        <IconButton
          variant={"ghost"}
          size={"small"}
          onClick={() => {
            promoteModule(synthesisModule);
          }}
        >
          <Add data-testid="promote-button" />
        </IconButton>
      )}
      {isDragging && <DragIndicator />}
    </x.div>
  );
};
