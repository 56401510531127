import React, { useCallback, useEffect } from "react";
import styled, { x } from "@xstyled/styled-components";
import { Button, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { useAlphaGPTContentRecommendationStyles } from "./AlphaGPTContentRecommendation.styles";
import { Reply, Transcript } from "@alphasights/alphadesign-icons";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { contentService } from "services/content";
import { ContentCard } from "pages/AlphaNowPage/components";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import RequestPrimerModal from "pages/AlphaNowPage/components/RequestPrimer/RequestPrimerModal/RequestPrimerModal";
import useModal from "hooks/useModal";
import { AlphaGPTMessageContent } from "../AlphaGPTMessageContent/AlphaGPTMessageContent";
import { AlphaGPTMessageProvider, useAlphaGPTMessageContext } from "providers/AlphaGPTMessageProvider";
import { AlphaGptCollapsibleList } from "../AlphaGPTCollapsibleList/AlphaGPTCollapsibleList";
import { AlphaNowContentType } from "@alphasights/client-portal-shared";
import { Citation, CitationContentType } from "models/AlphaGpt";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { createURLFromParams } from "utils/query-string";

interface AlphaGPTContentRecommendationProps {
  collapse?: boolean;
}

export const AlphaGPTContentRecommendation = React.forwardRef<HTMLDivElement, AlphaGPTContentRecommendationProps>(
  ({ collapse = false }, ref) => {
    const { projectToken, searchContent, onCitationClick, conversation, selectedContent } = useAlphaGPTContext();
    const { query, id: messageId } = useAlphaGPTMessageContext();
    const { contentRecomendation } = useAlphaGPTContentRecommendationStyles();
    const [searchResults, setSearchResults] = React.useState<ContentResults[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const { hasOnlyPremiumContent } = useAlphaNowContentAccessLevelContext();
    const { logHit } = useTrackUserAction();
    const openContentInNewTab = (contentId: string) => {
      window.open(`/alphanow?selectedContentId=${contentId}`, "_blank");
    };

    const onClick = (id: string, contentType: AlphaNowContentType) => {
      if (onCitationClick) {
        return onCitationClick({ id } as Citation, contentType as CitationContentType);
      } else {
        return openContentInNewTab(id);
      }
    };

    const trackSuggestedContentResponse = useCallback(
      (results: ContentResults[]) => {
        logHit({
          origin: projectToken ? HitOrigin.projectAlphaGptPage : HitOrigin.alphaGptPage,
          action: HitAction.alphaGptContentRecommended,
          projectToken,
          details: {
            conversationId: conversation?.id,
            messageId: messageId,
            returnedResults: results.length > 0,
            recommendedContentIds: results.map((result) => result.id),
          },
        });
      },
      [conversation, messageId, projectToken, logHit]
    );

    useEffect(
      function loadSuggestedContent() {
        if (query) {
          setIsLoading(true);
          const contentTypes = projectToken ? [AlphaNowContentType.alphaview, AlphaNowContentType.pcc] : undefined;
          searchContent([query], 10, contentTypes)
            .then((response) => {
              setSearchResults(response);
              trackSuggestedContentResponse(response);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          trackSuggestedContentResponse([]);
        }
      },
      [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(
      function updateSelectedContentFields() {
        if (selectedContent) {
          setSearchResults((prev) =>
            prev.map((result) =>
              result.id === selectedContent.id
                ? {
                    ...result,
                    purchaseStatus: selectedContent.purchaseStatus,
                    paymentRequired: selectedContent.paymentRequired,
                  }
                : result
            )
          );
        }
      },
      [selectedContent]
    );

    const isResultAccessible = (searchResult: any) => {
      const { purchaseStatus, approvalStatus } = searchResult;
      return isContentAccessible(purchaseStatus, approvalStatus);
    };

    return (
      <x.div data-testid="alpha-gpt-recommended-content">
        {isLoading && <Skeleton variant="noMargin" height="110px" data-testid="loading" />}
        {!isLoading &&
          (searchResults.length > 0 ? (
            <x.div ref={ref}>
              <AlphaGPTMessageProvider
                message={{ id: "", sender: "GPT", text: "Here is some content related to your search:" }}
              >
                <AlphaGPTMessageContent />
              </AlphaGPTMessageProvider>
              <x.div {...contentRecomendation} data-testid="content-recomendations">
                <AlphaGptCollapsibleList
                  collapse={collapse}
                  items={searchResults.map((result) => {
                    const { id, keywordHits, clientBookmark, contentType } = result;
                    return (
                      <ContentCard
                        content={result}
                        searchQuery={[{ type: undefined, value: query! }]}
                        isBookmarked={clientBookmark !== null}
                        showPurchasedFlag={!hasOnlyPremiumContent && isResultAccessible(result)}
                        isSelected={false}
                        key={`search-result-${id}`}
                        keywordHits={keywordHits}
                        onClick={() => onClick(id, contentType!)}
                        data-testid={`content-card-${id}`}
                      />
                    );
                  })}
                />
              </x.div>
              {!projectToken && <ViewAllContentButton query={query!} />}
            </x.div>
          ) : (
            <NoRecommendedContent onContentClick={openContentInNewTab}></NoRecommendedContent>
          ))}
      </x.div>
    );
  }
);

const NoRecommendedContent = ({ onContentClick }: { onContentClick: (contentId: string) => void }) => {
  const { companyPrimersEnabled } = useCurrentUser()!;
  const { contentRecomendation, requestPrimerSection, freePrimerSkeleton } = useAlphaGPTContentRecommendationStyles();
  const { freePrimerIds } = useAlphaGPTContext();
  const [freePrimers, setFreePrimers] = React.useState<any[]>([]);
  const [isPrimerRequested, setIsPrimerRequested] = React.useState(false);
  useEffect(() => {
    if (freePrimerIds.length && companyPrimersEnabled) {
      contentService.fetchContentByIds(freePrimerIds).then((responses: any[]) => {
        setFreePrimers(responses);
      });
    }
  }, [companyPrimersEnabled, freePrimerIds]);

  const onPrimerRequested = () => {
    setIsPrimerRequested(true);
  };

  const message = companyPrimersEnabled
    ? "I was unable to find any relevant content relating to your search.\n\n" +
      "Would you like to request one of our Primers? AlphaSights' specialized Research team engages " +
      "top industry experts and synthesizes their insights into an interactive deliverable on a company or market. " +
      "You can request these to be made privately for you.\n\n" +
      "Here's an example of each Primer type:"
    : `I was unable to find any relevant content relating to your search.`;

  return (
    <x.div data-testid="alpha-gpt-no-recommended-content">
      <AlphaGPTMessageProvider message={{ id: "", sender: "GPT", text: message }}>
        <AlphaGPTMessageContent />
      </AlphaGPTMessageProvider>
      {companyPrimersEnabled && (
        <>
          <x.div {...contentRecomendation}>
            {freePrimers.length ? (
              <>
                {freePrimers.map((primer: any) => (
                  <x.div data-testid={`content-recomendation-free-${primer.productType}`}>
                    <ContentCard
                      content={primer}
                      searchQuery={[{ type: undefined, value: "" }]}
                      isBookmarked={primer.clientBookmark !== null}
                      showPurchasedFlag={false}
                      isSelected={false}
                      onClick={() => onContentClick(primer.id)}
                      data-testid={"content-card-free-primer"}
                    />
                  </x.div>
                ))}
              </>
            ) : (
              <x.div {...freePrimerSkeleton}>
                <Skeleton variant="noMargin" height="110px" />
              </x.div>
            )}
          </x.div>
          <x.div {...requestPrimerSection}>
            {isPrimerRequested ? (
              <Typography color="strong" variant="body-large">
                Your request for a Primer has been sent to our team who will review your request for feasibility and
                compliance.
              </Typography>
            ) : (
              <RequestPrimerButton onPrimerRequested={onPrimerRequested} />
            )}
          </x.div>
        </>
      )}
    </x.div>
  );
};

const RequestPrimerButton = ({ onPrimerRequested }: { onPrimerRequested: () => void }) => {
  const { requestPrimerButton } = useAlphaGPTContentRecommendationStyles();
  const { isVisible, onOpen, onClose } = useModal();

  return (
    <>
      <Button
        variant="outline"
        size="small"
        startIcon={<AlphaGPTReplyIcon />}
        onClick={onOpen}
        data-testid="request-primer-button"
        {...requestPrimerButton}
      >
        Yes, Request A Primer
      </Button>
      {isVisible && <RequestPrimerModal onClose={onClose} onPrimerRequested={onPrimerRequested} />}
    </>
  );
};

const ViewAllContentButton = ({ query }: { query: string }) => {
  const { viewAllContentButton } = useAlphaGPTContentRecommendationStyles();

  const viewAllContent = () => {
    const url = createURLFromParams({ searchQuery: [{ label: query, value: query, type: 3 }] }, "/alphanow");
    window.open(url, "_blank");
  };

  return (
    <Button
      variant="outline"
      size="small"
      endIcon={<Transcript />}
      onClick={viewAllContent}
      data-testid="view-all-content-button"
      {...viewAllContentButton}
    >
      View All Content
    </Button>
  );
};

export const AlphaGPTReplyIcon = styled(Reply)`
  transform: rotate(180deg);
`;
