import React, { useState } from "react";
import {
  AccordionItem,
  Typography,
  Tooltip,
  TooltipContent,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { AvailabilitySelector } from "./AvailabilitySelector";
import { x } from "@xstyled/styled-components";
import { Badge } from "components/Badge";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { CurrentTimezone } from "providers/TimezoneProvider";

export const AdvisorAvailability = React.forwardRef(({ isVisible = true, interaction, ...props }, ref) => {
  const [numSlots, setNumSlots] = useState();
  const { isExpandedAvailability } = useFlyoutContext();

  if (!isVisible) return null;

  return (
    <AccordionItem
      key={`interaction-${interaction.id}`}
      title={<Title numSlots={numSlots} interaction={interaction} />}
      subtitle={
        <Typography component="span" variant="body-small" color="secondary">
          <CurrentTimezone format="O" formatOnly />
        </Typography>
      }
      open={isExpandedAvailability}
      ref={ref}
    >
      <div data-testid="advisor-flyout-availability">
        <div>
          <AvailabilitySelector
            advisorAvailability={interaction.advisorAvailability}
            interaction={interaction}
            setNumSlots={setNumSlots}
            {...props}
          />
        </div>
      </div>
    </AccordionItem>
  );
});

const Title = ({ numSlots, interaction }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const titleStyles = {
    display: "flex",
    gap: inner.base02,
    alignItems: "center",
  };

  return (
    <x.span {...titleStyles}>
      <Typography component="span" variant="body-large-em">
        Availability & Scheduling
      </Typography>
      {numSlots > 0 && interaction.state === "completed" && <Badge value={`${numSlots} Slots`}></Badge>}
      <OneHourMinimum interaction={interaction} />
    </x.span>
  );
};

const OneHourMinimum = ({ interaction }) => {
  const {
    components: {
      popover: { body },
    },
  } = useThemeTokens();

  const style = {
    ...body,
    width: 240,
  };

  return (
    (interaction.oneHourMinimum && interaction.state !== "scheduled" && (
      <Tooltip position="bottom" closeOnScroll={true} {...style}>
        <TooltipContent>
          <Typography variant="body-small">
            You may schedule a 30 minute call with this expert, but you will be billed for the full hour.
          </Typography>
        </TooltipContent>
        <div data-testid="one-hour-min-badge">
          <Badge value="One hour min" />
        </div>
      </Tooltip>
    )) ||
    null
  );
};
