import { IconButton, Tooltip } from "@alphasights/alphadesign-components";
import { Refresh } from "@alphasights/alphadesign-icons";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import React from "react";

export const SynthesisReprocessButton = ({ angleTypeName }: { angleTypeName?: string }) => {
  const permissions = useCurrentUser()?.permissions || [];
  const { reprocess } = useProjectSynthesisContext();

  const handleRefresh = () => {
    reprocess(angleTypeName);
  };

  if (!permissions.includes("access_transcripts_and_recordings")) return null;

  return (
    <Tooltip variant="dark" title="Refresh Module">
      <IconButton onClick={handleRefresh} variant="outline" size="small" testId="synthesis-header-refresh">
        <Refresh />
      </IconButton>
    </Tooltip>
  );
};
