import React, { useState } from "react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Divider, IconButtonVariant, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import { PrimerStatus } from "../../../CompanyPrimerContentPageVars";
import {
  AlphaNowPurchasableHeader,
  AlphaNowSpinner,
  ErrorMessage,
  WatchlistQuickAddButton,
  Spacer,
} from "pages/AlphaNowPage/components";
import { errorLoadingResearch, unavailableResearch } from "content/AlphaNow";
import ReturnButton from "pages/AlphaNowPage/components/ReturnButton/ReturnButton";
import CopyLinkButton from "components/CopyLinkButton";
import BookmarkButton from "components/BookmarkButton";
import { formatDate } from "utils/dates";
import {
  AlphaNowContentType,
  AlphaNowProductType,
  AlphaNowSRMProductType,
  Market,
} from "@alphasights/client-portal-shared";
import { freePrimers } from "pages/AlphaNowPage/primers/utils/constants";
import useContentApi from "components/BookmarkButton/useContentApi";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import MarketPill from "pages/AlphaNowPage/components/MarketPill";

interface IconButtonProps {
  variant: IconButtonVariant;
}
interface IconButtonDeviceProps {
  mobile: IconButtonProps;
  other: IconButtonProps;
}

export const ICON_BUTTON_DEVICE_PROPS: IconButtonDeviceProps = {
  mobile: {
    variant: "ghost",
  },
  other: {
    variant: "basic",
  },
};

interface CompanyPrimerPrePaywallProps {
  onReturnToMobileSearch: () => void;
  onContentErrorChanged: (error: string) => void;
  onPurchasedContentChanged: () => void;
  market: Market;
  price: number;
  companyIds: number[];
  productType: AlphaNowSRMProductType;
}

const requestCompanyPrimerInfoList = {
  title: "What is a Company Primer?",
  listItems: [
    "Company Primers are expert-led company overviews that support the first week of desktop research with trusted expert perspectives",
    "Our internal research team engage the best-in-class experts, including former executives, competitors and customers of the company",
    "Click on a sentence to see the contributing expert(s) and request a call",
  ],
};

const PaywallPage = ({
  onReturnToMobileSearch,
  onContentErrorChanged,
  onPurchasedContentChanged,
  price,
  market,
  companyIds,
  productType,
}: CompanyPrimerPrePaywallProps) => {
  const [primerStatus, setPrimerStatus] = useState(PrimerStatus.idle);

  const { color, spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  const { hasUserBadge } = useUserBadgeContext();

  const primerTitle = usePrimersStore(({ primerTitle }) => primerTitle);
  const id = usePrimersStore(({ contentId }) => contentId);
  const lastUpdatedAt = usePrimersStore(({ lastUpdatedAt }) => lastUpdatedAt);
  const purchaseProperties = usePrimersStore(({ purchaseProperties }) => purchaseProperties);

  const { isBookmarked, onToggleBookmark } = useContentApi(id, AlphaNowProductType.companyPrimer);

  const { title, listItems } = requestCompanyPrimerInfoList;
  const iconButtonProps = isMobile ? ICON_BUTTON_DEVICE_PROPS.mobile : ICON_BUTTON_DEVICE_PROPS.other;

  // TODO [RD1-133]: Remove Markets Badge
  const hasMarketsBadge = hasUserBadge(Badge.markets);
  const displayMarkets = !isMobile && hasMarketsBadge && !!market;
  // TODO [RD1-51]: Remove Watchlist Badge
  const isWatchlistQuickAddButtonVisible = !isMobile && hasUserBadge(Badge.watchlist);

  if (primerStatus === PrimerStatus.loading) {
    return (
      <x.div mt={spacing.inner.base06}>
        <AlphaNowSpinner />
      </x.div>
    );
  }

  if (primerStatus === PrimerStatus.error) {
    return (
      <div>
        <ErrorMessage header={errorLoadingResearch} body={unavailableResearch} />
      </div>
    );
  }

  return (
    <x.div data-testid="company-primer-prepaywall-page" h="100%" display="flex" flexDirection="column">
      <x.div p={spacing.inner.base06}>
        <x.div
          data-testid="company-primer-header-prepaywall-bar"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={spacing.inner.base04}
        >
          <x.div display="flex" alignItems="center" gap={spacing.inner.base02}>
            <Typography variant="body-em" style={{ textTransform: "none" }}>
              {primerTitle ? `${primerTitle} | Company Primer` : "Company Primer"}
            </Typography>
            {Boolean(lastUpdatedAt) && (
              <Typography color={color.text.secondary} variant="body">{`Last updated ${formatDate(
                lastUpdatedAt,
                "d MMM yyyy"
              )} `}</Typography>
            )}
            {displayMarkets && (
              <>
                <Spacer />
                <MarketPill market={market} />
              </>
            )}
          </x.div>
          <x.div
            data-testid="company-primer-header-buttons-container"
            display="flex"
            alignItems="center"
            gap={spacing.inner.base02}
          >
            {isWatchlistQuickAddButtonVisible && (
              <WatchlistQuickAddButton cdsAlphaCompanyIds={companyIds} productType={productType} contentId={id} />
            )}
            <CopyLinkButton contentId={id} contentType={AlphaNowProductType.companyPrimer} {...iconButtonProps} />
            <BookmarkButton
              permissions={[MANAGE_CONTENT_PERMISSION]}
              isBookmarked={isBookmarked}
              onToggleBookmark={onToggleBookmark}
              {...iconButtonProps}
            />
          </x.div>
        </x.div>
        {isMobile && (
          <x.div pl={spacing.inner.base06}>
            <ReturnButton onReturn={onReturnToMobileSearch} style={{ ml: `-${spacing.inner.base03}` }} />
          </x.div>
        )}
        <x.div key={title} pb={spacing.inner.base02}>
          <Typography variant="body-em" paddingBottom={spacing.inner.base02} color={color.text.secondary}>
            {title}
          </Typography>
          <x.ul listStyleType="initial">
            {listItems.map((item) => {
              return (
                <x.ul listStyleType="initial" pl={spacing.inner.base05}>
                  <li key={item}>
                    <Typography>{item}</Typography>
                  </li>
                </x.ul>
              );
            })}
          </x.ul>
        </x.div>
        <Link href={freePrimers.companyPrimer} size="medium" target="_blank">
          <Typography variant="body">View a free Company Primer</Typography>
        </Link>
      </x.div>
      <Divider />
      <AlphaNowPurchasableHeader
        content={{
          id,
          contentType: AlphaNowContentType.srm,
          productType: AlphaNowProductType.companyPrimer,
          status: primerStatus,
        }}
        contentTitle={primerTitle ?? ""}
        price={price}
        contentApprovalStatus={purchaseProperties?.approvalStatus}
        contentPurchaseStatus={purchaseProperties?.purchaseStatus}
        onError={onContentErrorChanged}
        onPurchaseSuccess={onPurchasedContentChanged}
        setPrimerStatus={setPrimerStatus}
      />
    </x.div>
  );
};

export default PaywallPage;
