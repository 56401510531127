import { Avatar } from "@alphasights/alphadesign-components";
import { PreferencesModal } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { useUserInitialsMenu } from "components/GlobalNav/UserInitialsMenu.styles";
import { useTimezone } from "providers/TimezoneProvider";
import React, { useState } from "react";
import { AvatarContainer, AvatarOptionWrapper } from "components/SidebarNav/SidebarNav.styled";
import { CustomBadge } from "components/CustomBadge";
import { Expert } from "@alphasights/alphadesign-icons";
import { AvatarMenu } from "./AvatarMenu";
import WatchlistModal from "components/WatchlistModal";
import useWatchlist from "hooks/useWatchlist";

type AvatarOptionProps = {
  closeIntercom?: () => void;
};

export const AvatarOption = ({ closeIntercom }: AvatarOptionProps) => {
  const user = useCurrentUser() as
    | (User & {
        internalUser?: { clientProfileId: number };
      })
    | undefined;
  const [isPreferenceModalOpen, setIsPreferenceModalOpen] = useState(false);
  const [isWatchListModalOpen, setIsWatchlistModalOpen] = useState(false);
  const { logOutCurrentUser, openLoginModal } = useClientPortalOperations();
  const { loggedInAsWrapper } = useUserInitialsMenu();
  const isInternalUser = !!user?.internalUser?.clientProfileId;
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const { availableWatchlists } = useWatchlist();

  const handleAvatarClick = (event: React.MouseEvent) => {
    if (user) {
      closeIntercom && closeIntercom();
      setAnchorEl(event.currentTarget);
    } else {
      openLoginModal();
    }
  };

  const handlePreferencesClick = () => {
    setIsPreferenceModalOpen(true);
    setAnchorEl(undefined);
  };

  const handleWatchlistClick = () => {
    setIsWatchlistModalOpen(true);
    setAnchorEl(undefined);
  };

  const closeMenu = () => {
    setAnchorEl(undefined);
  };

  const timezoneCtx = useTimezone() as { currentTimezone: string };

  return (
    <>
      <AvatarOptionWrapper showBackground={isInternalUser}>
        {isInternalUser && <CustomBadge tooltipText={`Viewing as ${user?.name}`} {...loggedInAsWrapper} />}
        <AvatarContainer>
          <Avatar
            size="medium"
            text={user?.name}
            onClick={handleAvatarClick}
            children={user?.name ? undefined : <Expert />}
            data-testid="avatar"
          />
        </AvatarContainer>
      </AvatarOptionWrapper>
      {anchorEl && (
        <AvatarMenu
          onPreferencesClick={handlePreferencesClick}
          onWatchlistClick={handleWatchlistClick}
          onClick={closeIntercom}
          userName={user?.name}
          anchorElement={anchorEl}
          closeMenu={closeMenu}
          data-testid="avatar-menu"
        />
      )}
      {isPreferenceModalOpen && (
        <PreferencesModal
          isOpen
          onClose={() => setIsPreferenceModalOpen(false)}
          onLogout={logOutCurrentUser}
          timezone={timezoneCtx.currentTimezone}
          onUpdateUser={(_) => Promise.resolve()}
        />
      )}
      {isWatchListModalOpen && availableWatchlists && (
        <WatchlistModal onClose={() => setIsWatchlistModalOpen(false)} availableWatchlists={availableWatchlists} />
      )}
    </>
  );
};
