import { Icon } from "../../components/alphaui";
import React from "react";
import { Pill, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useExpertRemarksContext } from "../../providers/ExpertRemarksProvider";
import { StatusChip } from "components/SurveysPage/surveysColumns/FollowUpCallStatusColumn";
import { TooltipStateDetail } from "components/InteractionsPage/TooltipStateDetail";
import { FormattedDateTime } from "../../providers/TimezoneProvider";

const statusColumnPrefix = "status-column-";
const statusChipPrefix = "status-chip-";
const statusCountChipPrefix = "status-count-chip-";
const statusCircleChipPrefix = "status-circle-chip-";

export const StatusColumn = ({ row }) => {
  const value = row.original;
  if (
    (value.interactionType === "Work Product" || value.interactionType === "Work Request") &&
    Object.keys(value.othersCounts).length === 0
  ) {
    value.state = "proposed";
  }

  const { isExpertHidden } = useExpertRemarksContext();

  const adjustedState = isExpertHidden(value) ? "hidden" : value.state.replace(/proposed/, "available");

  const totalOthers = Object.values(value.othersCounts).reduce((acc, it) => acc + it, 0);

  const showCredits = value.state === "proposed" && Object.keys(value.othersCounts || {}).length === 0;

  const showAlphaCircleMultipler = showCredits && value.alphaCircleMultiplier > 1.0;

  return (
    <div
      className="aui-capitalize aui-flex aui-space-x-2 aui-items-center aui-w-full aui-h-full"
      data-testid={`${statusColumnPrefix}${value.id}`}
    >
      <TooltipStateDetail interaction={value} pendingRequest={value.pendingRequest}>
        <StatusChip
          data-testid={`${statusChipPrefix}${value.id}`}
          followUpState={adjustedState}
          chipContent={adjustedState}
          hidden={isExpertHidden(value)}
          pendingRequest={value.pendingRequest}
        />
      </TooltipStateDetail>

      {!!totalOthers && (
        <StatusChip
          data-testid={`${statusCountChipPrefix}${value.id}`}
          hidden={isExpertHidden(value)}
          chipContent={`${totalOthers}`}
          newChipCount={true}
          hoverBorder={true}
          tooltipText={<OthersTooltip states={value.othersStates} />}
          followUpState={Object.keys(value.othersCounts).reduce((state, it) => (it === state ? state : undefined))}
        />
      )}
      {showAlphaCircleMultipler && (
        <AlphaCircleMultiplierChip
          data-testid={`${statusCircleChipPrefix}${value.id}`}
          hidden={isExpertHidden(value)}
          content={`${value.alphaCircleMultiplier}`}
          icon={<Icon icon="alphacircle" size="small" />}
        />
      )}
    </div>
  );
};

const OthersTooltip = ({ states }) => {
  const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const validStates = states || [];
  const validSize = validStates.length <= 3;
  const top = validSize ? validStates : validStates.slice(0, 2);
  const othersCount = validSize ? 0 : validStates.length - 2;

  return (
    <ul>
      {top.map((state) => (
        <li>
          {capitalize(state.description)}
          {" • "}
          <FormattedDateTime date={state.period} format={"E d MMM, h:mmaaa"} />
        </li>
      ))}
      {othersCount > 0 && <li>+ {othersCount} more that can be viewed in the expert flyout</li>}
    </ul>
  );
};

const AlphaCircleMultiplierChip = ({ "data-testid": dataTestid, icon, hidden, content }) => {
  const {
    color: { background, text },
    spacing: { inner },
  } = useThemeTokens();

  const chipColor = {
    backgroundColor: `${background.neutral.default} !important`,
    color: text.strong._,
    border: "none !important",
  };

  const chipStyle = {
    padding: `${inner.base} ${inner.base02}`,
    borderRadius: inner.base02,
  };

  return (
    <Pill
      id={dataTestid}
      data-testid={dataTestid}
      size="small"
      leftAccessories={icon}
      style={chipStyle}
      {...chipColor}
      isInteractive={false}
    >
      <Typography variant="body-small">{content}</Typography>
    </Pill>
  );
};
