import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { color, spacing, shape } = useThemeTokens();
  return {
    sidebarWrapper: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "504px",
      gap: spacing.layout.base03,
      p: spacing.layout.base03,
      bg: color.background.surface.recessed,
    },
    angleWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base03,
    },
    indexedModuleWrapper: {
      display: "flex",
    },
    moduleWrapper: {
      display: "flex",
      justifyContent: "space-between",
      gap: spacing.inner.base03,
      marginBottom: spacing.inner.base03,
    },
    cardWrapper: {
      w: "100%",
      minWidth: "424px",
      h: "52px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      py: "10px",
      px: spacing.inner.base04,
      gap: spacing.inner.base03,
      borderRadius: spacing.inner.base02,
    },
    isCustom: {
      border: "1px solid",
      bg: color.background.surface.page.default,
    },
    isExtracted: {
      border: "1px dashed",
      bg: color.background.surface.page.hover,
    },
    isActive: {
      borderColor: color.border.selected,
    },
    isInactive: {
      borderColor: color.border.neutral.default,
    },
    titleText: {
      color: color.text.secondary,
      paddingLeft: spacing.inner.base08,
      paddingRight: spacing.inner.base08,
    },
    indexWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base03,
    },
    numerationText: {
      h: "52px",
      w: spacing.inner.base08,
      gap: spacing.inner.base03,
      minWidth: spacing.inner.base04,
      color: color.text.assistive,
      paddingLeft: spacing.inner.base,
      paddingRight: spacing.inner.base,
      textAlign: "center",
    },
    emptyAnswersWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      gap: spacing.inner.base04,
      border: `1px dashed ${color.border.divider}`,
      borderRadius: shape.border.radius.small,
      px: "50px",
      flex: "1",
    },
    emptyIconWrapper: {
      background: color.background.neutral.default,
      borderRadius: shape.border.radius.small,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      h: spacing.inner.base08,
      w: spacing.inner.base08,
    },
  };
};
