import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { flatExpertsWithRequests } from "../../components/InteractionsPage/helpers/Interaction";
import buildColumnDefinitions from "./TableColumnDefinitions";
import { ContentLoader } from "./ContentLoader";
import { TABLE_VIEW_COLUMNS, usePreference } from "../../hooks/usePreference";
import "./index.css";
import "../../components/Popover/popover.css";
import { ExpertFilters } from "components/InteractionsPage/ExpertFilters";
import { Button, Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";
import { ENABLE_PORTAL_COMMENTS, ENABLE_PORTAL_MESSAGES, useProjectBadgeContext } from "providers/BadgeProvider";
import { useExpertRemarksContext } from "../../providers/ExpertRemarksProvider";
import { AlphaTable, useAlphaTable } from "@alphasights/alphadesign-table";
import styled, { x } from "@xstyled/styled-components";
import _, { isEqual } from "lodash";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { selectExperts } from "components/InteractionsPage/reducer";
import { NewMessageOrigin } from "pages/InteractionPage/enums";
import { ActionBar } from "./ActionBar/ActionBar";
import {
  buildColumnsOptions,
  columnIds,
  columnPreferenceExists,
  hasOrderChanged,
  hasPinningChange,
  hasVisibilityChanged,
} from "../../helpers/portalTablePreferencesHelper";
import { useCurrentUser, useMyProjects } from "@alphasights/portal-auth-react";
import { useLabelsContext } from "providers/LabelsProvider";
import { useMessageThreadContext } from "pages/MessengerPage/context/MessageThreadContext";
import { AdvisorsPageHeader } from "views/AdvisorsPage/AdvisorsPageHeader";
import pluralize from "pluralize";
import { useMemoizedValue } from "hooks/useMemoizedValue";
import { useNewNavigation } from "@alphasights/client-portal-shared";

export { ContentLoader };

export const buildColumnDefs = ({
  columns,
  project,
  showComments,
  isProjectCidEnabled,
  isMobile,
  isCustomerKnowledgeEnabled,
  threadHeaders,
  interactions,
}) => {
  const columnDefs = [];

  if (project.active) {
    columnDefs.push(columns.actionColumn);
  }

  if (project.enableLabels) {
    columnDefs.push(columns.labelColumn);
  }

  if (showComments) columnDefs.push(columns.commentsColumn);

  if (isCustomerKnowledgeEnabled) {
    columnDefs.push(columns.currentVendorColumn);
    columnDefs.push(columns.otherVendorsColumn);
    columnDefs.push(columns.roleColumn);
  }

  columnDefs.push(columns.groupColumn);

  if (interactions.some((i) => i.hasSubGroup)) {
    columnDefs.push(columns.subGroupColumn);
  }

  columnDefs.push(columns.expertColumn);

  if (project.active && isProjectCidEnabled) columnDefs.push(columns.cidStatusColumn);

  columnDefs.push(columns.companyColumn);
  columnDefs.push(columns.relevantPositionColumn);
  columnDefs.push(columns.tenureColumn);

  columnDefs.push(columns.statusColumn);

  columnDefs.push(columns.proposedAtColumn);

  if (project.enablePortalMessages && threadHeaders.length > 0) {
    columnDefs.push(columns.messageStatusColumn);
  }

  if (project.active) {
    columnDefs.push(columns.geographicKnowledgeColumn);
  }

  columnDefs.push(columns.starColumn);
  columnDefs.push(columns.hideColumn);

  if (isMobile) columnDefs.push(columns.showProfileColumn);

  return columnDefs;
};

export const AdvisorsTableViewSkeleton = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div>
      <x.div maxW="1024px" marginLeft="auto" marginRight="auto" marginTop={inner.base08} marginBottom={inner.base14}>
        <Skeleton height={inner.base12} />
        <x.div display="flex">
          <x.div flexGrow={2}>
            <Skeleton />
          </x.div>
          <x.div flexGrow={1}>
            <Skeleton />
          </x.div>
        </x.div>
      </x.div>
      <AdvisorsTableSkeleton />
    </x.div>
  );
};

const AdvisorsTableSkeleton = ({ ...props }) => (
  <x.div {...props}>
    <Skeleton count={6} loaderClassName="messenger-loader" />
  </x.div>
);

const AdvisorsTableViewComponent = React.memo(({ loading, isFilterOptionsLoading, ...props }) => {
  const newNavigationEnabled = useNewNavigation();

  if (!props.headerHeight && !newNavigationEnabled) {
    return <AdvisorsTableViewSkeleton />;
  }
  return <TableViewWithPreferences {...props} loading={loading || isFilterOptionsLoading} />;
});

export const AdvisorsTableView = React.memo(AdvisorsTableViewComponent);

const TableViewWithPreferencesComponent = ({ token, ...props }) => {
  const columnPreferences = usePreference(TABLE_VIEW_COLUMNS, { token });
  const [
    columnPreference,
    { updatePreference: updateColumnPreference, promote: applyColumnLayoutToAllProjects },
    columnPreferenceLoading,
  ] = columnPreferences;
  const isLoading = columnPreferenceLoading;

  return (
    <>
      {isLoading ? (
        <AdvisorsTableViewSkeleton />
      ) : (
        <TableView
          columnPreference={columnPreference}
          updateColumnPreference={updateColumnPreference}
          applyColumnLayoutToAllProjects={applyColumnLayoutToAllProjects}
          token={token}
          {...props}
        />
      )}
    </>
  );
};

const TableViewWithPreferences = React.memo(TableViewWithPreferencesComponent);

const TableViewComponent = (props) => {
  const {
    clientRequests,
    columnPreference,
    applyColumnLayoutToAllProjects,
    updateColumnPreference,
    experts = [],
    isMobile,
    isProjectCidEnabled,
    onDownloadCompaniesCid,
    onSelectCard,
    pastProjectsEnabled,
    preferencesEnabled,
    scrollToCardId,
    selectedCardId,
    token,
    userCidEnabled,
    project,
    onSearchQueryChange,
    searchQuery,
    onSearch,
    onSubmitFilters,
    onToggleFilter,
    loading,
    showing,
    currentView,
    isPCCEnabledClient,
    onResetAllFilters,
    hasClientPortalMessages,
    selectedExpertsIds,
    newMessageOrigin,
    interactions,
    isActiveProject,
    appliedFilters,
  } = props;
  const [showHiddenExperts, setShowHiddenExperts] = useState(false);
  const { isExpertHidden } = useExpertRemarksContext();
  const newNavigationEnabled = useNewNavigation();

  const isExpertHiddenRef = useRef(isExpertHidden);
  useEffect(() => {
    isExpertHiddenRef.current = isExpertHidden;
  }, [isExpertHidden]);

  useEffect(
    function changeHiddenExpertsVisibilityOnFilterChanged() {
      setShowHiddenExperts(appliedFilters.profile_activity && appliedFilters.profile_activity.includes("Hidden"));
    },
    [appliedFilters]
  );

  const rowData = useMemoizedValue(
    _.sortBy(flatExpertsWithRequests(experts, clientRequests), [(o) => isExpertHiddenRef.current(o)])
  );
  const hiddenExperts = useMemo(() => rowData.filter((expert) => isExpertHiddenRef.current(expert)), [rowData]);

  return (
    <div
      className="aui-flex aui-flex-col aui-flex-grow"
      style={{
        marginRight: "0px",
      }}
    >
      {!newNavigationEnabled && (
        <div className="aui-flex aui-flex-col">
          <div>
            <AdvisorsPageHeader
              className="aui-mt-6 aui-mb-4"
              onSearch={onSearch}
              onSearchQueryChange={onSearchQueryChange}
              pastProjectsEnabled={pastProjectsEnabled}
              preferencesEnabled={preferencesEnabled}
              project={project}
              searchQuery={searchQuery}
              showing={showing}
              token={token}
              isActiveProject={project.active}
              applyColumnLayoutToAllProjects={applyColumnLayoutToAllProjects}
              filterComponent={(isCompact) => (
                <ExpertFilters
                  onSubmitFilters={onSubmitFilters}
                  onToggleFilter={onToggleFilter}
                  onResetAllFilters={onResetAllFilters}
                  searchQuery={searchQuery}
                  isCompact={isCompact}
                />
              )}
              currentView={currentView}
              {...props}
            />
          </div>
        </div>
      )}
      <AdvisorsTableSkeleton style={{ display: loading ? "" : "none" }} />
      <div className="AdvisorsTableView aui-relative aui-flex-grow" style={{ display: loading ? "none" : "" }}>
        <Table
          columnPreference={columnPreference}
          updateColumnPreference={updateColumnPreference}
          isMobile={isMobile}
          isProjectCidEnabled={isProjectCidEnabled}
          onSelectCard={onSelectCard}
          scrollToCardId={scrollToCardId}
          selectedCardId={selectedCardId}
          project={project}
          selectedExpertsIds={useMemoizedValue(selectedExpertsIds ?? [])}
          newMessageOrigin={newMessageOrigin}
          preferencesEnabled={preferencesEnabled}
          hasClientPortalMessages={hasClientPortalMessages}
          userCidEnabled={userCidEnabled}
          showHiddenExperts={showHiddenExperts}
          interactions={useMemoizedValue(interactions ?? [])}
          isActiveProject={isActiveProject}
          onDownloadCompaniesCid={onDownloadCompaniesCid}
          isPCCEnabledClient={isPCCEnabledClient}
          rowData={rowData}
        />
        {hiddenExperts.length > 0 && (
          <x.div display="flex" justifyContent="center" className="aui-mt-6 aui-mb-12 ">
            <Button variant="outline" size="small" onClick={() => setShowHiddenExperts(!showHiddenExperts)}>
              {showHiddenExperts ? "Remove" : "Show"} Hidden {pluralize("Expert", hiddenExperts.length)}
            </Button>
          </x.div>
        )}
      </div>
    </div>
  );
};

const TableView = React.memo(TableViewComponent);

const Table = (props) => {
  const {
    columnPreference,
    updateColumnPreference,
    isMobile,
    isProjectCidEnabled,
    onSelectCard,
    scrollToCardId,
    selectedCardId,
    project,
    selectedExpertsIds,
    newMessageOrigin,
    interactions,
    preferencesEnabled,
    hasClientPortalMessages,
    userCidEnabled,
    onDownloadCompaniesCid,
    showHiddenExperts,
    isPCCEnabledClient,
    rowData,
  } = props;
  const { color } = useThemeTokens();
  const [hideHeader, setHideHeader] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const currentUser = useCurrentUser();
  const allProjects = useMyProjects();
  const { hasProjectBadge } = useProjectBadgeContext();
  const { isExpertHidden } = useExpertRemarksContext();
  const { enableLabels } = useLabelsContext();
  const poralMessagesEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES);
  const hasActionButtons = poralMessagesEnabled || isProjectCidEnabled || enableLabels;
  const { threadHeaders } = useMessageThreadContext();

  const isExpertHiddenRef = useRef(isExpertHidden);
  useEffect(() => {
    isExpertHiddenRef.current = isExpertHidden;
  }, [isExpertHidden]);

  const showComments =
    (!currentUser || allProjects.find(({ token }) => token === project.token)) &&
    hasProjectBadge(ENABLE_PORTAL_COMMENTS) &&
    project.active;

  useEffect(() => {
    const element = document.getElementById(`advisor-row-${scrollToCardId}`);
    if (element) {
      element.scrollIntoView();
    }
  }, [scrollToCardId]);

  const initialColumns = buildColumnDefinitions({
    onSelectCard,
    project,
    interactions,
    preferencesEnabled,
    userCidEnabled,
  });

  const saveColumnPreferences = () => {
    const attributes = buildColumnPreferences(columnIds(columns));
    updateColumnPreference(attributes);
  };

  useEffect(() => {
    if (!columnPreferenceExists(columnPreference)) {
      saveColumnPreferences();
    }
  }, [columnPreference?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = buildColumnDefs({
    columns: initialColumns,
    project,
    showComments,
    isProjectCidEnabled,
    isMobile,
    threadHeaders,
    interactions: rowData,
  });

  const dispatch = useContext(DispatchContext);

  const onRowSelectionChange = (selection) => {
    const selectedRows = rowData.filter((item, index) => index in selection && selection[index]);
    setSelectedRows(selectedRows);
    setHideHeader(hasActionButtons && Object.keys(selectedRows).length > 0);
    const expertsIds = selectedRows.map((interaction) => interaction.advisorId);
    if (newMessageOrigin === NewMessageOrigin.TableView && !isEqual(expertsIds, selectedExpertsIds) && !firstLoad) {
      dispatch(selectExperts(expertsIds));
    }
  };

  const onRowClicked = useCallback(
    (row) => {
      const { original: interaction } = row;

      if (!isExpertHidden(interaction)) {
        onSelectCard({
          ...interaction,
          showChainList: false,
          scrollToCardId: interaction.id,
        });
      }
    },
    [onSelectCard, isExpertHidden]
  );

  const rowClassRules = [
    {
      className: "row-selected",
      ruleFunction: (row) => row.original.id === selectedCardId,
    },
    {
      className: "row-hidden",
      ruleFunction: (row) => isExpertHiddenRef.current(row.original),
    },
  ];

  const options = buildColumnsOptions({
    columnPreference,
    columns,
    hideSelectColumn: !currentUser || !hasActionButtons,
    onRowClicked,
    rowClassRules,
    hideHeader: hideHeader,
    enableRowSelection: ({ original: interaction }) => !isExpertHidden(interaction),
    onRowSelectionChange,
    onColumnOrderChange: (updatedColumnOrders) => {
      if (hasOrderChanged(updatedColumnOrders, columnPreference)) {
        saveColumnPreferences();
      }
    },
    onColumnVisibilityChange: (updatedColumnVisibilities) => {
      if (hasVisibilityChanged(updatedColumnVisibilities, columnPreference, columns)) {
        saveColumnPreferences();
      }
    },
    onColumnPinningChange: (updatedColumnPinning) => {
      if (hasPinningChange(updatedColumnPinning, columnPreference)) {
        saveColumnPreferences();
      }
    },
  });

  const table = useAlphaTable(
    rowData.filter((expert) => showHiddenExperts || !isExpertHiddenRef.current(expert)),
    columns,
    options
  );

  useEffect(
    function updateColumnOrderOnNewColumnsAdded() {
      table.setColumnOrder(options.state.columnOrder);
    },
    [columns.length] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (selectedExpertsIds && newMessageOrigin === NewMessageOrigin.TableView) {
      const selectedRows = Object.assign(
        {},
        rowData.map((row) => selectedExpertsIds.includes(row.advisorId))
      );
      table.setRowSelection(selectedRows);
    }
  }, [selectedExpertsIds, table, rowData, newMessageOrigin]);

  const downloadCidCompaniesCsv = () => {
    onDownloadCompaniesCid(selectedRows);
    table.resetRowSelection(true);
  };

  const buildColumnPreferences = useCallback(
    (columns) => {
      const columnIds = table.getState().columnOrder.length > 0 ? table.getState().columnOrder : columns;
      return columnIds.reduce((obj, column, index) => {
        const isColumnVisible = _.get(table.getState(), `columnVisibility.${column}`, true);
        const isColumnHiddenInPreferences = _.get(columnPreference, `attributes.${column}.hide`, false);
        return {
          ...obj,
          [column]: {
            position: index,
            hide: columns.includes(column) ? !isColumnVisible : isColumnHiddenInPreferences,
            pinned: table.getState().columnPinning.left.includes(column),
          },
        };
      }, {});
    },
    [table, columnPreference]
  );

  const onSelectAll = () => {
    table.toggleAllRowsSelected(true);
  };

  const onUnselectAll = () => {
    table.resetRowSelection(true);
  };

  useEffect(() => setFirstLoad(false), []);

  return (
    <AlphaTableWrapper
      background={color.background.surface.page.default}
      selectedRowColor={color.background.selected.subtle}
      hiddenRowColor={color.background.neutral.subtle}
      hoverRowColor={color.background.neutral.hover}
    >
      {hideHeader && (
        <ActionBar
          interactions={rowData}
          selectedInteractions={selectedRows}
          onDownloadCid={downloadCidCompaniesCsv}
          pcc={isPCCEnabledClient}
          isActiveProject={project.active}
          isMobile={isMobile}
          hasClientPortalMessages={hasClientPortalMessages}
          onSelectAll={onSelectAll}
          onUnselectAll={onUnselectAll}
          isProjectCidEnabled={isProjectCidEnabled}
        />
      )}
      <AlphaTable table={table} />
    </AlphaTableWrapper>
  );
};

const AlphaTableWrapper = styled.div`
  background: ${(props) => props.background};
  display: grid;
  tbody tr:hover {
    background-color: ${(props) => props.hoverRowColor};
  }
  .row-selected {
    background-color: ${(props) => props.selectedRowColor};
  }
  .row-hidden {
    background-color: ${(props) => props.hiddenRowColor};
  }
`;
