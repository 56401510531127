import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";
import { ChevronDown, ChevronUp, Close } from "@alphasights/alphadesign-icons";
import {
  Icon,
  useThemeTokens,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Typography,
} from "@alphasights/alphadesign-components";
import { TranscriptSearchBarWithAccess } from "components/SearchBar";
import { SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { Variant, withAccessControl } from "components/AccessControl/AccessControl";
import { View } from "models/Header";
import { noResultsContent, searchWithinTranscript } from "content/AlphaNow";
import { useTranscriptActionBarStyles } from "./TranscriptActionBar.styles";
import { useAlphaNowQueryContext } from "pages/AlphaNowPage/components/AlphaNowQueryContext";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import TranscriptSearchButton from "./TranscriptSearchButton/TranscriptSearchButton";
const ButtonWithAccess = withAccessControl(IconButton);

const TranscriptActionBar = ({
  isTranscriptDataReady,
  hasKeywords,
  viewMode,
  setViewMode,
  searchQuery,
  setSearchQuery,
  transcriptActions,
  contentTypePermission,
  detailsCollapsed,
  setDetailsCollapsed,
  isQuestionViewEnabled,
  showTranscript,
  comprehensiveSummaryViewEnabled,
  keywordSearchProps: {
    totalKeywordMatches,
    selectedMentionIndex,
    setSelectedMentionIndex,
    selectedComprehensiveSummaryIndex,
    setSelectedComprehensiveSummaryIndex,
  },
}) => {
  const isTranscriptView = viewMode === View.Transcript;
  const isComprehensiveSummaryView = viewMode === View.Summary;
  const isKeywordFilterEnabled = isTranscriptView || isComprehensiveSummaryView;

  const hasKeywordMatch = totalKeywordMatches > 0;
  const hasMultipleKeywordMatches = totalKeywordMatches > 1;

  let selectedSearchTermIndex, setSelectedSearchTermIndex;

  if (isComprehensiveSummaryView) {
    selectedSearchTermIndex = selectedComprehensiveSummaryIndex;
    setSelectedSearchTermIndex = setSelectedComprehensiveSummaryIndex;
  } else {
    selectedSearchTermIndex = selectedMentionIndex;
    setSelectedSearchTermIndex = setSelectedMentionIndex;
  }

  const [toggleValue, setToggleValue] = useState(View.Transcript);
  const [anchorEl, setAnchorEl] = useState();

  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();
  // TODO [CON-2281][CON-2282][CON-2283] add tests once buttons are added back
  const { isMobile, isUltraWide } = useCheckScreen();
  const { updateQuery } = useAlphaNowQueryContext();

  const {
    transcriptActionBarContainer: transcriptActionBarContainerStyles,
    chevronContainer: chevronContainerStyles,
  } = useTranscriptActionBarStyles({ isMobile });
  // TODO [CON-2281][CON-2282][CON-2283] temporarily hide transcript actions

  const transcriptSearchAnchorElement = document.querySelector('[data-testid="icon-button-outline"]');
  const transcriptSearchButtonDisplayed =
    comprehensiveSummaryViewEnabled && isQuestionViewEnabled && detailsCollapsed && !isUltraWide;

  const displayKeywordFilter =
    isKeywordFilterEnabled && isTranscriptDataReady && hasKeywords && totalKeywordMatches !== null;

  const displayPopOverSearchBar = (hasKeywords && viewMode !== View.Question) || viewMode === View.Mentions;

  const loadKeywords = async (typedTerm) => {
    return [
      {
        label: typedTerm,
        value: typedTerm,
      },
    ];
  };

  const onOptionSelect = async (searchQuery) => {
    const query = searchQuery ?? [];
    setSearchQuery(query);
  };

  const findNextMatch = () => {
    const isLastKeywordSelected = selectedSearchTermIndex === totalKeywordMatches - 1;
    const nextIndex = isLastKeywordSelected ? 0 : selectedSearchTermIndex + 1;
    setSelectedSearchTermIndex(nextIndex);
  };

  const findPreviousMatch = () => {
    const isFirstKeywordSelected = selectedSearchTermIndex === 0;
    const previousIndex = isFirstKeywordSelected ? totalKeywordMatches - 1 : selectedSearchTermIndex - 1;
    setSelectedSearchTermIndex(previousIndex);
  };

  const onTranscriptViewSelect = () => {
    setViewMode(View.Transcript);
    updateQuery({ fragmentIds: [] });
  };

  useEffect(() => {
    let newViewMode;
    switch (viewMode) {
      case View.Mentions:
        newViewMode = View.Mentions;
        break;
      case View.Question:
        newViewMode = View.Question;
        break;
      case View.Summary:
        newViewMode = View.Summary;
        break;
      default:
        newViewMode = View.Transcript;
    }
    setToggleValue(newViewMode);
  }, [viewMode]);

  useEffect(() => {
    if (totalKeywordMatches !== null && totalKeywordMatches < selectedSearchTermIndex) {
      setSelectedSearchTermIndex(0);
    }
  }, [totalKeywordMatches]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (displayPopOverSearchBar) {
      setAnchorEl(transcriptSearchAnchorElement);
    } else {
      setAnchorEl(null);
    }
  }, [displayPopOverSearchBar, viewMode, transcriptSearchAnchorElement]);

  const TranscriptSearchBar = () => (
    <x.div display="flex" flex="1 0 0">
      <x.span flex="1 0 0" overflow="hidden">
        <TranscriptSearchBarWithAccess
          accessControl={{ allowedPermissions: [contentTypePermission] }}
          loadOptions={loadKeywords}
          onChange={onOptionSelect}
          placeholder={searchWithinTranscript}
          showSeparator={isTranscriptView && hasKeywords}
          value={searchQuery}
        />
      </x.span>
      {displayKeywordFilter && (
        <Typography component="span" color={text.secondary} variant="body-em" display="flex" alignItems="center">
          {hasMultipleKeywordMatches && (
            <x.div display="inherit" pl={inner.base03} gap={inner.base03}>
              <Icon size="medium" onClick={findNextMatch}>
                <ChevronDown />
              </Icon>
              <Icon size="medium" onClick={findPreviousMatch}>
                <ChevronUp />
              </Icon>
            </x.div>
          )}

          {hasKeywordMatch ? (
            <x.div color={text.secondary} pl={inner.base03}>
              <x.span color={text.danger}>{selectedSearchTermIndex + 1}</x.span>
              <x.span>/{totalKeywordMatches}</x.span>
            </x.div>
          ) : (
            <x.span color={text.danger} pl={inner.base04}>
              {noResultsContent}
            </x.span>
          )}
        </Typography>
      )}
    </x.div>
  );

  return (
    <x.div {...transcriptActionBarContainerStyles}>
      <x.div flex row my={inner.base06} spaceX={inner.base04} justifyContent="space-between">
        {transcriptSearchButtonDisplayed ? (
          <>
            <TranscriptSearchButton
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
              popOverBody={
                <x.span display="flex" alignItems="center" w="350px">
                  <TranscriptSearchBar />
                  <Button variant="icon" mx={inner.base03} onClick={() => setAnchorEl(null)}>
                    <Close />
                  </Button>
                </x.span>
              }
            />
          </>
        ) : (
          <TranscriptSearchBar />
        )}
        <ToggleButtonGroup
          value={toggleValue}
          allowUnselection={false}
          wrapperProps={{
            flex: 1,
            minWidth: "fit-content",
          }}
        >
          {isQuestionViewEnabled && (
            <ToggleButton value={View.Question} onClick={() => setViewMode(View.Question)}>
              Questions
            </ToggleButton>
          )}
          {comprehensiveSummaryViewEnabled && (
            <ToggleButton value={View.Summary} onClick={() => setViewMode(View.Summary)}>
              Summary
            </ToggleButton>
          )}
          <ToggleButton value={View.Mentions} onClick={() => setViewMode(View.Mentions)}>
            Mentions
          </ToggleButton>

          <ToggleButton value={View.Transcript} onClick={onTranscriptViewSelect}>
            Transcript
          </ToggleButton>
        </ToggleButtonGroup>
        {detailsCollapsed && <x.div display="flex">{transcriptActions}</x.div>}
      </x.div>
      {showTranscript && (
        <x.div {...chevronContainerStyles}>
          <ButtonWithAccess
            accessControl={{
              allowedPermissions: [contentTypePermission],
              variant: Variant.Opaque,
            }}
            color={text.secondary}
            onClick={() => setDetailsCollapsed(!detailsCollapsed)}
            variant="basic"
          >
            {detailsCollapsed ? <ChevronDown data-testid="chevron-down" /> : <ChevronUp data-testid="chevron-up" />}
          </ButtonWithAccess>
        </x.div>
      )}
    </x.div>
  );
};

TranscriptActionBar.defaultProps = {
  isTranscriptDataReady: false,
  hasKeywords: false,
  searchQuery: [],
  selectedSearchTermIndex: 0,
  totalKeywordMatches: 0,
};

TranscriptActionBar.propTypes = {
  isTranscriptDataReady: PropTypes.bool,
  hasKeywords: PropTypes.bool,
  searchQuery: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.oneOf(Object.values(SEARCH_SUGGESTION_TYPES)),
    })
  ),
  setSearchQuery: PropTypes.func.isRequired,
  selectedSearchTermIndex: PropTypes.number,
  totalKeywordMatches: PropTypes.number,
  transcriptActions: PropTypes.node,
};

export default TranscriptActionBar;
