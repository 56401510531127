import React, { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, Tabs, TabItem } from "@alphasights/alphadesign-components";
import { ConditionalTooltip, DotLabel } from "pages/AlphaNowPage/primers/components";
import CompetitivePerformanceKPCsTab from "./CompetitivePerformanceKPCsTab";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import { useCustomerPrimersStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state/store";
import { getTruncatedTextAndTooltip } from "pages/AlphaNowPage/primers/utils/utils";

type CompetitivePerformanceKPCsTabsProps = {
  onClick: (index: number) => void;
  selectedChartSegment: number | null;
};

const CompetitivePerformanceKPCsTabs = ({ onClick, selectedChartSegment }: CompetitivePerformanceKPCsTabsProps) => {
  const { spacing, color } = useThemeTokens();
  const keyPurchasingCriteria = useCustomerPrimersStore(({ keyPurchasingCriteria }) => keyPurchasingCriteria);
  const { vendors } = keyPurchasingCriteria;
  const { getChartColors } = useGetChartColors();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const colors = getChartColors(null);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    setSelectedTab(selectedChartSegment ?? 0);
  }, [selectedChartSegment]);

  return (
    <x.div pt={spacing.inner.base04}>
      <Tabs
        data-testid="customerPrimerKPCsTabs"
        colorVariant="primary"
        index={selectedTab || 0}
        onChange={(index) => {
          handleTabChange(index);
          onClick(index);
        }}
      >
        {vendors.map(({ companyName }, i) => {
          const [companyNameTruncated, tooltip] = getTruncatedTextAndTooltip(companyName, 30);

          return (
            <TabItem
              key={companyName}
              label={
                <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                  <DotLabel
                    dotColor={colors[i]}
                    label={companyNameTruncated as string}
                    tableEntry
                    labelColor={i === selectedTab ? color.text.strong._ : color.text.secondary}
                  />
                </ConditionalTooltip>
              }
            />
          );
        })}
      </Tabs>

      {vendors.length > 0 && <CompetitivePerformanceKPCsTab vendor={vendors[selectedTab ?? 0]} />}
    </x.div>
  );
};

export default CompetitivePerformanceKPCsTabs;
