import React, { useEffect, useState, FC } from "react";
import { x } from "@xstyled/styled-components";
import { ChevronLeft, ChevronRight } from "@alphasights/alphadesign-icons";
import { ButtonWrapper, CarouselItemWrapper, StyledButton } from "./styled";
import useCarouseIncrement from "./useCarouseIncrement";

const DataTestIds = Object.freeze({
  nextButton: "next-button",
  prevButton: "prev-button",
  carouselItem: "carousel-item",
});

type CarouselProps = {
  children: React.ReactNode[];
  numberOfItemsDisplayed: number;

  itemInView?: number;
};

type CarouselButtonProps = {
  variant: "next" | "previous";
  onClick: () => void;
};

const CarouselButton: FC<CarouselButtonProps> = ({ variant, onClick }) => {
  const isNextButton = variant === "next";

  return (
    <>
      <ButtonWrapper isNextButton={isNextButton}>
        <StyledButton
          onClick={onClick}
          data-testid={isNextButton ? DataTestIds.nextButton : DataTestIds.prevButton}
          isNextButton={isNextButton}
          variant="outline"
        >
          {isNextButton ? <ChevronRight /> : <ChevronLeft />}
        </StyledButton>
      </ButtonWrapper>
    </>
  );
};

const Carousel: FC<CarouselProps> = ({ children, numberOfItemsDisplayed, itemInView }) => {
  const [slideToMoveTo, setSlideToMoveTo] = useState<number | null>(null);

  const {
    setNextSlide,
    setPrevSlide,
    cumulativeIncrement,
    isFirstSlide,
    isFinalSlide,
    activeSlideIndex,
  } = useCarouseIncrement({
    children,
    numberOfItemsDisplayed,
  });
  //display a blurred proportion of the next and/or previous item in carousel
  const totalItemsDisplayed = numberOfItemsDisplayed + 0.5;

  const percentageCarouselItemDisplayed = 100 / totalItemsDisplayed;

  useEffect(() => {
    if (itemInView !== undefined) {
      const desiredSlideIndex = Math.ceil(itemInView / numberOfItemsDisplayed) - (itemInView % 2);
      if (desiredSlideIndex > activeSlideIndex) {
        setSlideToMoveTo(desiredSlideIndex);
        setNextSlide();
      } else if (desiredSlideIndex < activeSlideIndex) {
        setSlideToMoveTo(desiredSlideIndex);
        setPrevSlide();
      }
    }
  }, [itemInView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (slideToMoveTo !== null) {
      if (slideToMoveTo === activeSlideIndex) {
        setSlideToMoveTo(null);
      } else {
        if (slideToMoveTo! > activeSlideIndex) {
          setNextSlide();
        } else {
          setPrevSlide();
        }
      }
    }
  }, [activeSlideIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div position="relative" overflow="hidden" data-testid={`carousel-slide-${activeSlideIndex}`} w="100%">
      <x.div
        display="flex"
        transition="transform 0.5s ease-in-out"
        transform={`translateX(-${cumulativeIncrement * percentageCarouselItemDisplayed}%)`}
        alignItems="center"
      >
        {children.map((child: React.ReactNode, index: number) => (
          <CarouselItemWrapper
            key={index}
            percentageCarouselItemDisplayed={percentageCarouselItemDisplayed}
            data-testid={DataTestIds.carouselItem}
          >
            {child}
          </CarouselItemWrapper>
        ))}
      </x.div>
      {!isFirstSlide && <CarouselButton variant="previous" onClick={setPrevSlide} />}
      {!isFinalSlide && <CarouselButton variant="next" onClick={setNextSlide} />}
    </x.div>
  );
};

export { Carousel as default, DataTestIds };
export type { CarouselProps };
