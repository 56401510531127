import React from "react";
import { Info } from "@alphasights/alphadesign-icons";
import { CustomAccordion } from "@alphasights/client-portal-shared";
import { Button, Tile, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import useWatchlist, { DEFAULT_WATCHLIST_NAME, WatchlistDataProps } from "hooks/useWatchlist";
import {
  WatchlistHeaderContainer,
  WatchlistTitleContainer,
  NoWatchlistContainer,
  NoWatchlistDescriptionContainer,
  StyledTypography,
} from "pages/AlphaNowPage/components/LandingPage/WatchlistSection/WatchlistSection.styled";
import {
  WATCHLIST_TITLE,
  NUM_WATCHLIST_TRIGGERS_DISPLAYED,
  MANAGE_WATCHLIST_BUTTON,
  NO_WATCHLIST_DESCRIPTION,
} from "pages/AlphaNowPage/components/LandingPage/WatchlistSection/constants";
import useModal from "hooks/useModal";
import WatchlistModal from "components/WatchlistModal";
import WatchlistItem from "pages/AlphaNowPage/components/LandingPage/WatchlistItem";
import { getOrderedTriggers } from "pages/AlphaNowPage/components/LandingPage/WatchlistSection/utils";

const DATA_TEST_IDS = {
  WatchlistSection: "watchlist-section",
  DefaultWatchlistSection: "default-watchlist-section",
};

const WatchlistHeader = ({ watchlistName, watchlistId }: { watchlistName: string; watchlistId?: string }) => {
  const { color } = useThemeTokens();
  const { isVisible, onOpen, onClose } = useModal();
  const { availableWatchlists } = useWatchlist();

  return (
    <WatchlistHeaderContainer>
      <WatchlistTitleContainer>
        <Typography variant="body-small" color={color.text.secondary}>
          {WATCHLIST_TITLE}
        </Typography>
        <Typography variant="body-em">{watchlistName}</Typography>
      </WatchlistTitleContainer>
      <Button variant="outline" size="small" onClick={onOpen}>
        {MANAGE_WATCHLIST_BUTTON}
      </Button>
      {isVisible && (
        <WatchlistModal availableWatchlists={availableWatchlists} onClose={onClose} selectedWatchlistId={watchlistId} />
      )}
    </WatchlistHeaderContainer>
  );
};

const WatchlistSection = () => {
  const { availableWatchlists } = useWatchlist();

  if (availableWatchlists.length === 0) {
    return (
      <NoWatchlistContainer data-testId={DATA_TEST_IDS.DefaultWatchlistSection}>
        <WatchlistHeader watchlistName={DEFAULT_WATCHLIST_NAME} />
        <NoWatchlistDescriptionContainer>
          <Tile variant="icon" size="small" icon={<Info />} />
          <StyledTypography>{NO_WATCHLIST_DESCRIPTION}</StyledTypography>
        </NoWatchlistDescriptionContainer>
      </NoWatchlistContainer>
    );
  }

  return (
    <div data-testId={DATA_TEST_IDS.WatchlistSection}>
      {(availableWatchlists as WatchlistDataProps[]).map(({ name, triggers, id }) => (
        <CustomAccordion
          key={id}
          disableShowMore={triggers.length <= NUM_WATCHLIST_TRIGGERS_DISPLAYED}
          titleContent={<WatchlistHeader watchlistName={name} watchlistId={id} />}
        >
          {getOrderedTriggers(triggers).map((trigger) => (
            <WatchlistItem trigger={trigger} />
          ))}
        </CustomAccordion>
      ))}
    </div>
  );
};

export { WatchlistSection as default, DATA_TEST_IDS };
