import React, { useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAccessControl } from "hooks/useAccessControl";
import { InteractionsPage } from "components/InteractionsPage";
import ThirdPartyPage from "components/ThirdPartyPage";
import { UnauthorizedPage } from "components/UnauthorizedPage";
import { DismissClientRequest } from "components/DismissClientRequest";
import { MessengerPage } from "pages/MessengerPage/MessengerPage";
import { CUSTOMER_KNOWLEDGE, useProjectBadgeContext } from "providers/BadgeProvider";
import { useCurrentUser, useIsAuthenticated, useMyProjects } from "@alphasights/portal-auth-react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { MobileComplianceResourcesPage } from "views/MobileComplianceResoucesPage";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useNewNavigation } from "@alphasights/client-portal-shared";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";

export const ProjectRoutes = (props) => {
  const {
    project: { enablePortalWorkstreams, workstreams },
    location: { pathname },
  } = props;

  const workstreamId =
    enablePortalWorkstreams && /\/workstream\/(?<workstreamId>\w+)\//.exec(pathname)?.groups?.workstreamId;

  const isSurveyWorkstream = useMemo(() => {
    const workstream = workstreams?.find((w) => w.id === workstreamId);
    return enablePortalWorkstreams && workstream?.workstreamType === "survey" && workstream?.clientSurveys?.length > 0;
  }, [enablePortalWorkstreams, workstreamId, workstreams]);

  const propsWithWorkstream = {
    ...props,
    enablePortalWorkstreams,
    isSurveyWorkstream,
    workstreamId,
  };
  return (
    <>
      <DeprecatedRouteRedirects {...propsWithWorkstream} />
      <OtherProjectRoutes {...propsWithWorkstream} />
      <SignedInRoutes {...propsWithWorkstream} />
      <OpenRoutes {...propsWithWorkstream} />
      <MyAlphasightsRoute {...propsWithWorkstream} />
    </>
  );
};

export const DeprecatedRouteRedirects = (allProps) => {
  const { path, project, isSurveyWorkstream, enablePortalWorkstreams } = allProps;
  const { defaultView } = useCurrentProjectContext();
  const userDefaultView = isSurveyWorkstream ? "survey-view" : defaultView;

  const currentUser = useCurrentUser();
  const allProjects = useMyProjects();
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const canAccessMessagesView = canAccessNewMessagesView({ currentUser, allProjects }, project, hasProjectsPermission);

  return (
    <>
      <RedirectReplacing from={`${path}/services`} replaceOld={"services"} withNew={"experts"} />
      <RedirectReplacing
        from={`${path}/experts/calendar-view`}
        replaceOld={"experts/calendar-view"}
        withNew={"calendar-view"}
      />
      <RedirectReplacing from={`${path}/industry-view`} replaceOld={"industry-view"} withNew={"experts"} />
      <RedirectReplacing from={`${path}/advisors/:subPath?`} replaceOld={"advisors"} withNew={"experts"} />
      <RedirectReplacing
        exact
        from={`${path}/experts`}
        replaceOld={"/experts"}
        withNew={`/experts/${userDefaultView || "table-view"}`}
      />
      {enablePortalWorkstreams && (
        <RedirectReplacing
          exact
          from={`${path}/workstream/:workstreamId/experts/:expertId([a-zA-Z0-9]{6})?`}
          replaceOld={"/experts"}
          withNew={`/experts/${userDefaultView || "table-view"}`}
        />
      )}
      {/* InteractionId without a view specified may be passed */}
      <RedirectReplacing
        from={`${path}/experts/:expertId([a-zA-Z0-9]{6})`}
        replaceOld={"/experts"}
        withNew={`/experts/${userDefaultView || "table-view"}`}
      />
      {/* Redirect the url of the old message thread to a new messenger */}
      {canAccessMessagesView ? (
        <RedirectReplacing
          exact
          from={`${path}/messenger/:advisor([a-zA-Z0-9]{6})?`}
          replaceOld={/\/messenger\/([a-zA-Z0-9]{6})/}
          withNew={"/experts/messages-view"}
          replaceOldSearch={"thread"}
          withNewSearch={"selectedThread"}
        />
      ) : (
        <RedirectReplacing
          exact
          from={`${path}/messenger/:advisor([a-zA-Z0-9]{6})?`}
          replaceOld={"/messenger"}
          withNew={"/experts/messages-view"}
        />
      )}
    </>
  );
};

const canAccessNewMessagesView = ({ currentUser, allProjects }, project, hasProjectsPermission) => {
  const isLoggedIn = currentUser;
  const userBelongsToProject = !!allProjects?.find((p) => p.token === project.token);
  return project.active && project.enablePortalMessages && isLoggedIn && userBelongsToProject && hasProjectsPermission;
};

export const OpenRoutes = React.memo((allProps) => {
  const { project, path, isSurveyWorkstream, enablePortalWorkstreams, computedMatch } = allProps;

  const currentUser = useCurrentUser();
  const allProjects = useMyProjects();

  const { hasProjectBadge } = useProjectBadgeContext();
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const canAccessMessagesView = canAccessNewMessagesView({ currentUser, allProjects }, project, hasProjectsPermission);

  const customerViewEnabled = currentUser?.portalCustomerKnowledgeEnabled || hasProjectBadge(CUSTOMER_KNOWLEDGE);

  const projectViews = [
    !isSurveyWorkstream && "table-view",
    !isSurveyWorkstream && "list-view",
    isSurveyWorkstream && "survey-view",
    canAccessMessagesView && "messages-view",
    customerViewEnabled && "customer-view",
  ]
    .filter((view) => view)
    .join("|");

  const disabledViews = ["list-view", "table-view", "survey-view", "messages-view", "customer-view", "invalid"]
    .filter((view) => !projectViews.includes(view))
    .join("|");

  // when navigating via projects dropdown, sometimes the `project` would not match the project in the URL
  // so here we check that before using the project's workstreams
  const projectMatchesRoute = computedMatch?.params?.token === project?.token;
  return (
    <>
      {enablePortalWorkstreams && projectMatchesRoute && (
        <>
          <RedirectReplacing
            from={`${path}/experts/:currentView(${projectViews})/:advisor?`}
            replaceOld={"/experts"}
            withNew={`/workstream/${project.workstreams[0].id}/experts`}
          />
          <Route
            path={`${path}/workstream/:workstreamId/experts/:currentView(${projectViews})/:advisor?`}
            render={(props) => {
              return <InteractionsPage {...props} {...allProps} />;
            }}
          />
          <Route
            path={`${path}/workstream/:workstreamId/experts/:currentView(${disabledViews})/:advisor?`}
            render={({ match: { params } }) => {
              const root = params?.workstreamId
                ? `/${params.token}/workstream/${params.workstreamId}/experts`
                : `/${params.token}/experts`;
              return <Redirect to={root} />;
            }}
          />
        </>
      )}
      {!enablePortalWorkstreams && (
        <>
          <Route
            path={`${path}/experts/:currentView(${projectViews})/:advisor?`}
            render={(props) => {
              return <InteractionsPage {...props} {...allProps} />;
            }}
          />
          <Route
            path={`${path}/workstream/**`}
            render={(props) => {
              return <Redirect to={`/${props.match.params.token}/experts`} />;
            }}
          />
        </>
      )}
    </>
  );
});

const MyAlphasightsRoute = (allProps) => {
  const newNavigationEnabled = useNewNavigation();

  if (!newNavigationEnabled) return null;

  return (
    <SignedInRoute
      otherProps={{
        ...allProps,
        currentView: "my-alphasights-view",
      }}
      viewEnabled
      path={`/${myAlphaSightsBaseUrl}/projects/:token`}
    />
  );
};

export const SignedInRoutes = (allProps) => {
  const { project, path } = allProps;
  const { enableComparisonView } = useExpertCompareContext();
  const currentUser = useCurrentUser();
  const allProjects = useMyProjects();

  const enableProjectAlphaGpt = project.projectAlphaGPTEnabled;
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const canAccessMessagesView = canAccessNewMessagesView({ currentUser, allProjects }, project, hasProjectsPermission);

  return (
    <>
      <MobileRoutes path={path} />
      <SignedInRoute
        otherProps={allProps}
        viewEnabled
        path={`${path}/experts/:currentView(deliverables-view)/:advisor?`}
      />
      <SignedInRoute
        otherProps={allProps}
        viewEnabled={enableComparisonView}
        path={`${path}/experts/:currentView(comparison-view)/:advisor?`}
        pathWorkstream={`${path}/workstream/:workstreamId/experts/:currentView(comparison-view)/:advisor?`}
      />
      <SignedInRoute
        otherProps={allProps}
        viewEnabled={enableProjectAlphaGpt}
        path={`${path}/experts/:currentView(alphagpt-view)`}
      />
      <SignedInRoute
        otherProps={allProps}
        viewEnabled={project.active}
        path={`${path}/:currentView(calendar-view)/:advisor?`}
        pathWorkstream={`${path}/workstream/:workstreamId/:currentView(calendar-view)/:advisor?`}
      />
      {!canAccessMessagesView && (
        <SignedInRoute
          otherProps={allProps}
          viewEnabled={project.active && project.enablePortalMessages}
          path={`${path}/experts/:currentView(messages-view)/:advisor?`}
        />
      )}
      <SignedInRoute
        otherProps={allProps}
        viewEnabled={project.active && project.enablePortalMessages}
        path={`${path}/messages/:expertId?`}
        Destination={MessengerPage}
      />
      <SignedInRoute
        otherProps={allProps}
        viewEnabled={project.active && project.synthesisEnabled}
        path={`${path}/:currentView(synthesis)`}
      />
    </>
  );
};

export const MobileRoutes = ({ path }) => {
  const { isMobile } = useCheckScreen();
  const invalidMobileViews = ["calendar-view", "table-view", "customer-view", "survey-view"];
  return isMobile ? (
    <>
      <Route
        path={`${path}/:currentView(compliance-resources|synthesis)`}
        render={() => {
          return <MobileComplianceResourcesPage />;
        }}
      />
      <Route
        path={`${path}/experts/:currentView(${invalidMobileViews.join("|")})/:advisor?`}
        render={({ match: { params } }) => {
          return <Redirect to={`/${params.token}/experts/list-view`} />;
        }}
      />
    </>
  ) : null;
};

export const OtherProjectRoutes = (allProps) => {
  const { project, path } = allProps;
  const { token } = project;

  return (
    <>
      <Route path={`${path}/third-party`} render={(props) => <ThirdPartyPage {...props} project={project} />} />
      <Route path={`${path}/unauthorized`} component={UnauthorizedPage} token={token} />
      <Route path={`${path}/client-requests/:id`} component={DismissClientRequest} />
    </>
  );
};

const RedirectReplacing = ({
  from,
  replaceOld,
  withNew,
  exact = false,
  replaceOldSearch = null,
  withNewSearch = null,
}) => {
  return (
    <Route
      exact={exact}
      path={from}
      render={({ location }) => {
        const newPath = {
          ...location,
          search:
            replaceOldSearch && withNewSearch
              ? location.search.replace(replaceOldSearch, withNewSearch)
              : location.search,
          pathname: location.pathname.replace(replaceOld, withNew),
        };
        return <Redirect to={newPath} />;
      }}
    />
  );
};

const SignedInRoute = ({ viewEnabled, path, pathWorkstream, otherProps, Destination = InteractionsPage }) => {
  const { project } = otherProps;
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const allProjects = useMyProjects();
  const isLoggedIn = useIsAuthenticated();
  const userBelongsToProject = !!allProjects?.find((p) => p.token === project.token);

  const renderFn = (props) => {
    if (!hasProjectsPermission) {
      return null;
    }

    const { location } = props;

    if (!isLoggedIn) {
      const searchParams = new URLSearchParams({
        next: `${location.pathname}${location.search || ""}`,
      });
      return <Redirect to={`/sign-in?${searchParams}`} />;
    }

    if (!viewEnabled || !userBelongsToProject) {
      const expertsView = {
        ...location,
        pathname: `/${props.match.params.token}/experts`,
      };

      return <Redirect to={expertsView} />;
    }

    return <Destination {...props} {...otherProps} />;
  };

  return (
    <>
      <Route path={path} render={renderFn} />
      {pathWorkstream && <Route path={pathWorkstream} render={renderFn} />}
    </>
  );
};
