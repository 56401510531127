import React, { useMemo, useRef } from "react";
import { x } from "@xstyled/styled-components";
import { Popover, PopoverProps } from "@alphasights/alphadesign-components";

import { STYLE_CONFIG, SearchSizeVariant } from "components/Search/consts";
import { SearchOption, OptionalOptionProps, OptionsPopoverFooterProps } from "components/Search/types";
import { fromPx, toPx } from "pages/AlphaNowPage/utils";
import Footer from "./Footer";

import "./index.css";

const DataTestIds = {
  Popover: "advanced-search-popover",
};

export interface OptionsPopoverProps extends PopoverProps {
  onClose: () => void;
  options: SearchOption[];
  onSelect: () => void;
  onOptionHighlight: (index: number) => void;
  searchContainerWidth: number;
  components: Record<"Option", Function>;
  allowBooleanExpressions: boolean;

  size?: SearchSizeVariant;
  isOpen?: boolean;
  anchorEl?: HTMLElement;
  highlightedOptionIndex?: number;
  optionProps?: OptionalOptionProps;
  footerProps?: OptionsPopoverFooterProps;
}

const OptionsPopover = ({
  onClose,
  options,
  onSelect,
  onOptionHighlight,
  searchContainerWidth,
  anchorEl,
  allowBooleanExpressions,
  components: { Option },
  highlightedOptionIndex = -1,
  isOpen = false,
  optionProps = {},
  footerProps,
  size = SearchSizeVariant.Medium,
  ...props
}: OptionsPopoverProps) => {
  const { current: styles } = useRef(STYLE_CONFIG[size]);
  const maxHeight = useMemo(() => toPx(fromPx(styles.height) * 6), [styles]);

  return (
    <Popover
      {...props}
      data-testid={DataTestIds.Popover}
      className={DataTestIds.Popover}
      anchorEl={anchorEl}
      open={isOpen}
      closeOnClickOutside
      onClose={onClose}
      onFloatingData={() => ({ w: toPx(searchContainerWidth) })}
    >
      <x.div maxH={maxHeight} overflowY="auto" pl="0">
        {/* Limiting the number of results to 50 to avoid slowness - see CON-3159 */}
        {options.slice(0, 50).map((option, index) => (
          <Option
            key={index}
            size={size}
            data={option}
            highlight={index === highlightedOptionIndex}
            onSelect={onSelect}
            onHighlight={() => onOptionHighlight(index)}
            {...optionProps}
          />
        ))}
      </x.div>
      <Footer {...footerProps!} size={size} allowBooleanExpressions={allowBooleanExpressions} />
    </Popover>
  );
};

export { OptionsPopover as default, DataTestIds };
