export const fromWorkstream = (objsWithWorkstreamId, workstreamId) => {
  return workstreamId ? objsWithWorkstreamId.filter((i) => i.workstreamId === workstreamId) : objsWithWorkstreamId;
};

export const getWorkstream = (project, workstreamId) => {
  return project?.workstreams?.find((w) => w.id === workstreamId);
};

export const filterSurveysWithInteractions = (allInteractionsForProject, clientSurveys) => {
  const allInteractions = allInteractionsForProject;
  const referencedSurveyIds = allInteractions.map((int) => int.surveyResponse?.clientSurveyId);
  const referencedSurveys = clientSurveys.filter((cs) => referencedSurveyIds.includes(cs.id));
  return referencedSurveys.length ? referencedSurveys : [clientSurveys[0]];
};

export const hasOnlySurveyWorkstreams = (project) => {
  return project?.workstreams?.length > 0 && project.workstreams.every((w) => w.workstreamType === "survey");
};
