import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import styled, { x } from "@xstyled/styled-components";
import {
  Alert,
  Avatar,
  Button,
  Collapsible,
  Icon,
  IconButton,
  Link,
  Typography,
  useAlphaToast,
} from "@alphasights/alphadesign-components";
import {
  Attachment as AttachmentIcon,
  ChevronDown,
  ChevronUp,
  Download,
  Expert,
  Reply,
} from "@alphasights/alphadesign-icons";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { UnreadDot } from "../MessengerThreadCard/MessengerThreadCard";
import {
  useMessageHeaderStyles,
  useMessengerAdvisorThreadCardStyles,
  useMessengerAttachmentStyles,
  useMessengerMessageStyles,
  useMessengerQuestionCardStyles,
  useMessengerReplyFieldStyles,
} from "./MessengerAdvisorThreadCard.styles";
import {
  AttachmentResponse,
  ComplianceReviewStatus,
  MessageResponse,
  ParticipantRole,
  ResponseStatusType,
  ThreadResponse,
} from "types";
import { useMessengerContext } from "providers/MessengerProvider";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { TextBox } from "components/TextBox";
import { messageAttachmentService } from "services/messageAttachment";
import { useNotifications } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { sanitizeMessage } from "pages/MessengerPage/utils";
import { MessengerSuggestion } from "../MessengerSuggestion/MessengerSuggestion";

const REPLY_SIZE_LIMIT = 300;

const MessengerApprovalStatus = ({ status }: { status?: ComplianceReviewStatus }) => {
  if (
    !status ||
    status === ComplianceReviewStatus.DO_NOT_REVIEW ||
    status === ComplianceReviewStatus.NOT_REQUIRED ||
    status === ComplianceReviewStatus.APPROVED
  )
    return <></>;

  if (status === ComplianceReviewStatus.SUGGESTION_ACCEPTED) {
    return (
      <>
        {" "}
        <Typography color="assistive" display="inline-block">
          (edited)
        </Typography>
      </>
    );
  }

  const colorMap = {
    [ComplianceReviewStatus.PENDING]: "processing",
    [ComplianceReviewStatus.REJECTED]: "processing",
    [ComplianceReviewStatus.REJECTED_WITH_SUGGESTION]: "processing",
    [ComplianceReviewStatus.SUGGESTION_PENDING]: "processing",
    [ComplianceReviewStatus.SUGGESTION_DECLINED]: "danger",
  };

  const textMap = {
    [ComplianceReviewStatus.PENDING]: "Awaiting Approval",
    [ComplianceReviewStatus.REJECTED]: "Awaiting Approval",
    [ComplianceReviewStatus.REJECTED_WITH_SUGGESTION]: "Awaiting Approval",
    [ComplianceReviewStatus.SUGGESTION_PENDING]: "Awaiting Approval",
    [ComplianceReviewStatus.SUGGESTION_DECLINED]: "Suggestion Declined",
  };

  return (
    <>
      •{" "}
      <Typography color={colorMap[status]} display="inline-block">
        {textMap[status]}
      </Typography>
    </>
  );
};

export const MessengerQuestionCard = ({ question }: { question: MessageResponse }) => {
  const { card } = useMessengerQuestionCardStyles();
  const { sanitizedContent } = useMemo(() => sanitizeMessage(question), [question]);

  return (
    <x.div data-testid="question-card" {...card}>
      <Typography variant="body-em">
        Question <MessengerApprovalStatus status={question.complianceReviewStatus} />
      </Typography>
      <Typography
        data-testid={`question-content-${question.id}`}
        dangerouslySetInnerHTML={{ __html: question.originalContentOfSuggestion ?? sanitizedContent }}
      />

      {question.complianceReviewStatus === ComplianceReviewStatus.SUGGESTION_DECLINED && (
        <Alert size="small" width="100%">
          Suggested edits were declined. The Work Request has not been sent to expert(s)
        </Alert>
      )}

      {(question.complianceReviewStatus === ComplianceReviewStatus.REJECTED_WITH_SUGGESTION ||
        question.complianceReviewStatus === ComplianceReviewStatus.SUGGESTION_PENDING) && (
        <Alert size="small" width="100%" variant="warning">
          AlphaSights has reviewed your question and has provided suggestions to ensure your message is compliant.
          Please accept this suggestion in order for your message to be sent to the expert.
        </Alert>
      )}

      {(question.complianceReviewStatus === ComplianceReviewStatus.SUGGESTION_PENDING ||
        question.complianceReviewStatus === ComplianceReviewStatus.REJECTED_WITH_SUGGESTION) && (
        <MessengerSuggestion content={sanitizedContent} />
      )}
    </x.div>
  );
};

export const MessengerAdvisorThreadCard = ({
  thread,
  isFirstThread,
  hideStatus,
}: {
  thread: ThreadResponse;
  isFirstThread: boolean;
  hideStatus?: boolean;
}) => {
  const { card, repliesContainer, repliesButton } = useMessengerAdvisorThreadCardStyles();

  const [isExpanded, setIsExpanded] = useState(isFirstThread);

  const replies = thread.messages.slice(2);

  return (
    <>
      {thread.messages.length > 1 ? (
        <x.div data-testid="advisor-thread-card" data-messenger-card-thread-id={thread.id} {...card}>
          <MessengerMessage message={thread.messages[1]} isLastMessage={replies.length === 0} threadId={thread.id} />
          {replies.length > 0 && (
            <Button
              marginRight="auto"
              variant="ghost"
              size="small"
              onClick={() => setIsExpanded(!isExpanded)}
              startIcon={isExpanded ? <ChevronUp /> : <ChevronDown />}
              {...repliesButton}
            >
              {isExpanded && `Hide ${replies.length > 1 ? "replies" : "reply"}`}
              {!isExpanded && `View ${replies.length} ${replies.length > 1 ? "replies" : "reply"}`}
            </Button>
          )}
          {replies.length > 0 && isExpanded && (
            <Collapsible open>
              <x.div {...repliesContainer}>
                {replies.map((message, index) => (
                  <MessengerMessage
                    key={message.id}
                    message={message}
                    isLastMessage={index === replies.length - 1}
                    threadId={thread.id}
                  />
                ))}
              </x.div>
            </Collapsible>
          )}
        </x.div>
      ) : (
        <MessagedExperts thread={thread} hideStatus={hideStatus} />
      )}
    </>
  );
};

const MessagedExperts = ({ thread, hideStatus }: { thread: ThreadResponse; hideStatus?: boolean }) => {
  const { card } = useMessengerAdvisorThreadCardStyles();
  const { container } = useMessengerMessageStyles();
  const { experts } = useMessengerContext();

  const expert = useMemo(() => experts.find((e) => e.id === thread.advisor.id), [experts, thread.advisor.id]);

  return (
    <x.div {...card}>
      <x.div {...container}>
        <MessageHeader
          id={thread.advisor.id}
          name={thread.advisor.name}
          relevantCompany={expert?.relevantCompany}
          relevantPosition={expert?.relevantPosition}
          status={hideStatus ? undefined : thread.status}
          avatarColor="base02"
          doubledBlinded={expert?.interaction.doubleBlinded ?? false}
        />
      </x.div>
    </x.div>
  );
};

const MessengerMessage = ({
  message,
  isLastMessage,
  threadId,
}: {
  message: MessageResponse;
  isLastMessage: boolean;
  threadId: string;
}) => {
  const { experts } = useMessengerContext();
  const { container, rightInfo } = useMessengerMessageStyles();
  const isAdvisor = message.sender.role === ParticipantRole.Advisor;

  const replyRef = useRef<HTMLTextAreaElement>(null);
  const [showReply, setShowReply] = useState(false);

  const [isExpanded, setExpanded] = useState(false);
  const { isMobile } = useCheckScreen();

  const { sanitizedContent, originalContent, isContentSanitized } = useMemo(() => sanitizeMessage(message), [message]);

  const messageContent = isExpanded ? originalContent : sanitizedContent;

  const focusReply = useCallback(() => {
    if (!replyRef.current) return;
    replyRef.current.focus();
  }, []);

  useEffect(() => focusReply(), [focusReply, showReply]);

  const onToggleExpandedMode = () => setExpanded(!isExpanded);
  const clickReply = () => {
    setShowReply(true);
    focusReply();
  };

  const cancelReply = () => {
    setShowReply(false);
  };

  const expert = useMemo(() => experts.find((e) => e.id === message.sender.id), [experts, message.sender.id]);

  const isClient = message.sender.role === "client";

  const avatarColors = useMemo(() => ["base", "base03", "base04", "base05"], []);
  const avatarColor = useMemo(() => avatarColors[stringToIntInRange(message.sender.id, 3)], [
    avatarColors,
    message.sender.id,
  ]);

  return (
    <>
      <x.div data-testid="thread-message" {...container}>
        {!message.isRead && !isClient && <UnreadDot marginRight={0} />}
        <MessageHeader
          id={message.sender.id}
          name={message.sender.name}
          relevantCompany={expert?.relevantCompany}
          relevantPosition={expert?.relevantPosition}
          avatarColor={avatarColor}
          doubledBlinded={expert?.interaction.doubleBlinded ?? false}
        ></MessageHeader>
        <x.div {...rightInfo}>
          {isLastMessage && isAdvisor && (
            <Button
              marginRight="auto"
              variant="ghost"
              size="small"
              startIcon={<Reply />}
              onClick={clickReply}
              data-testid="reply-message-button"
            >
              Reply
            </Button>
          )}
          <Typography variant="body-small" color="secondary">
            <FormattedDateTime prefix={undefined} date={message.createdAt} format="E d, h:mmaaa" />
          </Typography>
        </x.div>
      </x.div>
      <Typography
        data-testid={`message-content-${message.id}`}
        style={{
          filter: message.obfuscated ? "blur(2.5px)" : "none",
          userSelect: message.obfuscated ? "none" : "auto",
        }}
        dangerouslySetInnerHTML={{ __html: messageContent }}
      />
      {message.attachments.length > 0 && <AttachmentList attachments={message.attachments} />}
      {isContentSanitized && (
        <Link onClick={() => onToggleExpandedMode()}>
          <Typography variant={isMobile ? "body-large-em" : "body-em"}>See {isExpanded ? "Less" : "More"}</Typography>
        </Link>
      )}
      {showReply && <MessengerReplyField ref={replyRef} closeReply={cancelReply} threadId={threadId} />}
    </>
  );
};

const MessageHeader = ({
  id,
  name,
  relevantPosition,
  relevantCompany,
  status,
  avatarColor,
  doubledBlinded,
}: {
  id: string;
  name?: string;
  relevantPosition: string;
  relevantCompany: string;
  status?: ResponseStatusType;
  avatarColor?: any;
  doubledBlinded: boolean;
}) => {
  const { onSelectExpert } = useMessengerContext();
  const { senderName, statusLabel } = useMessageHeaderStyles({ status });

  return (
    <>
      {doubledBlinded ? (
        <Avatar color={avatarColor}>
          <Expert />
        </Avatar>
      ) : (
        <Avatar text={name ?? "Expert"} color={avatarColor} />
      )}
      <x.div>
        <x.div>
          <Typography variant="body-em" component="span" onClick={() => onSelectExpert(id)} {...senderName}>
            {name}
            {status && ` • `}
          </Typography>
          {status && (
            <Typography component="span" {...statusLabel}>
              {status}
            </Typography>
          )}
        </x.div>
        <Typography variant="body-small" color="secondary">
          {relevantCompany} - {relevantPosition}
        </Typography>
      </x.div>
    </>
  );
};

const AttachmentList = ({ attachments }: { attachments: AttachmentResponse[] }) => {
  const { attachmentsListContainer } = useMessengerAttachmentStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const showCollapseButton = attachments.length > 2;

  return (
    <>
      {(isExpanded || !showCollapseButton) && (
        <x.div {...attachmentsListContainer}>
          {attachments.map((attachment: AttachmentResponse) => (
            <Attachment attachment={attachment} key={`attachment-${attachment.id}`} />
          ))}
        </x.div>
      )}
      {showCollapseButton && (
        <div>
          <Button
            variant="ghost"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
            startIcon={<AttachmentIcon />}
          >
            {isExpanded ? `Hide ${attachments.length} attachments` : `Show ${attachments.length} attachments`}
          </Button>
        </div>
      )}
    </>
  );
};

const Attachment = ({ attachment }: { attachment: AttachmentResponse }) => {
  const { projectToken } = useMessengerContext();
  const { attachmentContainer } = useMessengerAttachmentStyles();
  const { showErrorBanner, showSuccessBanner } = useNotifications();

  const onClickDownload = useCallback(() => {
    messageAttachmentService
      .downloadMessageAttachment(projectToken, attachment)
      .then(() => showSuccessBanner("Attachment download successful"))
      .catch(() =>
        showErrorBanner("The attachment could not be downloaded. Please try again or contact your project lead")
      );
  }, [attachment, projectToken, showSuccessBanner, showErrorBanner]);

  return (
    <x.div {...attachmentContainer} data-testid="attachment-card">
      <Icon>
        <AttachmentIcon />
      </Icon>
      <Typography color="strong">{attachment.filename}</Typography>
      <Typography variant="body-small" color="assistive">
        {attachment.type} • {attachment.fileSize}
      </Typography>
      <IconButtonWithAccess
        accessControl={{ allowedPermissions: ["follow-up-messages"] }}
        variant="basic"
        onClick={onClickDownload}
        color="secondary"
      >
        <Download />
      </IconButtonWithAccess>
    </x.div>
  );
};

const IconButtonWithAccess = withAccessControl(IconButton);

const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string | undefined) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      textAreaRef.style.height = (scrollHeight < 48 ? 48 : scrollHeight) + "px";
    }
  }, [textAreaRef, value]);
};

type MessengerReplyFieldProps = {
  closeReply: () => void;
  threadId: string;
};

const MessengerReplyField = React.forwardRef<HTMLTextAreaElement, MessengerReplyFieldProps>(
  ({ closeReply, threadId }, ref) => {
    const { onSubmitReply, onReplyCreated } = useMessengerContext();
    const { toast } = useAlphaToast();
    const { container, buttonsContainer } = useMessengerReplyFieldStyles();

    const [value, setValue] = useState<string | undefined>("");
    const [isLoading, setIsLoading] = useState(false);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => textAreaRef.current as HTMLTextAreaElement);
    useAutosizeTextArea(textAreaRef.current, value);

    const onClickSend = useCallback(() => {
      setIsLoading(true);
      onSubmitReply(threadId, {
        content: value,
      })
        .then((message) => {
          toast.success({ message: "Reply sent!" });
          onReplyCreated(threadId, message);
          closeReply();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [onSubmitReply, onReplyCreated, toast, closeReply, threadId, value]);

    const isReplyValid = value != null && value.trim().length > 0 && value.trim().length <= REPLY_SIZE_LIMIT;

    return (
      <x.div data-testid="advisor-thread-reply-form" {...container}>
        <TextBox
          ref={textAreaRef}
          name="reply"
          onChange={setValue}
          required
          resizable={false}
          maxLength={REPLY_SIZE_LIMIT as any}
          inputHeight="60px"
          inputMinHeight="60px"
          placeholder="Begin typing your message here..."
        />
        {value && value.length > REPLY_SIZE_LIMIT && (
          <StyledAlert variant="warning">You have exceeded the character limit for this response.</StyledAlert>
        )}
        <x.div {...buttonsContainer}>
          <Button variant="ghost" size="small" onClick={closeReply}>
            Cancel
          </Button>
          <ButtonWithAccess
            data-testid="send-reply-button"
            accessControl={{ allowedPermissions: ["follow_up_messages"] }}
            onClick={onClickSend}
            disabled={!isReplyValid}
            variant="secondary"
            size="small"
            loading={isLoading}
          >
            Send
          </ButtonWithAccess>
        </x.div>
      </x.div>
    );
  }
);

function stringToIntInRange(str: string, max: number) {
  const hashCodeValue = str.split("").reduce((acc, char) => {
    return acc + char.charCodeAt(0);
  }, 0);
  const range = max + 1;
  const result = ((hashCodeValue % range) + range) % range;
  return result;
}

const StyledAlert = styled(Alert)`
  width: 100%;
`;

const ButtonWithAccess = withAccessControl(Button);
