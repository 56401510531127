import React, { useEffect, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { Icon, IconButton, TabItem, Tabs, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import ReactHtmlParser from "react-html-parser";
import CopyLinkButton from "components/CopyLinkButton";
import BookmarkButton from "components/BookmarkButton";
import DownloadButton from "pages/AlphaNowPage/components/AlphaNowTranscript/DownloadButton";
import { ICON_BUTTON_DEVICE_PROPS } from "pages/AlphaNowPage/components/AlphaNowContent/AlphaNowContentActions";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { AlphaNowProductType, AlphaNowSRMProductType, Market } from "@alphasights/client-portal-shared";
import { ChevronUp, Purchased } from "@alphasights/alphadesign-icons";
import { formatDate } from "utils/dates";
import useContentApi from "components/BookmarkButton/useContentApi";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { Spacer, WatchlistQuickAddButton } from "pages/AlphaNowPage/components";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import {
  CommissionModal,
  CommissionButton as CommissionPrimerButton,
} from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";
import useModal from "hooks/useModal";
import { usePrimersStore } from "../../state/store";
import MarketPill from "pages/AlphaNowPage/components/MarketPill";

type PrimersHeaderActionBarPropTypes = {
  companyIds: number[];
  currentTabIndex?: number;
  handleNavigationClick: (index: number) => void;
  showNavigationOnly?: boolean;
  tabs: string[];
  productType: AlphaNowSRMProductType;
  market: Market;
};

const PrimersHeaderActionBar = ({
  companyIds,
  currentTabIndex,
  handleNavigationClick,
  showNavigationOnly = false,
  tabs,
  productType,
  market,
}: PrimersHeaderActionBarPropTypes) => {
  const {
    spacing: { inner },
    color: { icon, background, text },
  } = useThemeTokens();
  const { hasUserBadge } = useUserBadgeContext();
  const { isVisible, onOpen, onClose } = useModal();
  const {
    primerTitle,
    lastUpdatedAt,
    contentId,
    isAccessible,
    downloadFileName,
    purchaseProperties,
  } = usePrimersStore();
  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const { isMobile } = useCheckScreen();

  // TODO [RD1-51]: Remove Watchlist Badge
  const isWatchlistQuickAddButtonVisible = !isMobile && hasUserBadge(Badge.watchlist);

  useEffect(() => {
    setIsTitleVisible(isAccessible);
  }, [isAccessible]);

  const iconButtonProps = isMobile ? ICON_BUTTON_DEVICE_PROPS.mobile : ICON_BUTTON_DEVICE_PROPS.other;

  const { isBookmarked, onToggleBookmark } = useContentApi(contentId, AlphaNowProductType.marketPrimer);

  const containerRef = useRef<HTMLInputElement>(null);

  // TODO [RD1-133]: Remove Markets Badge
  const hasMarketsBadge = hasUserBadge(Badge.markets);
  const displayMarkets = !isMobile && hasMarketsBadge && !!market;
  enum ProductTitle {
    COMPANY_PRIMER = "Company Primer",
    MARKET_PRIMER = "Market Primer",
    CUSTOMER_PRIMER = "Customer Primer",
  }

  return (
    <x.div>
      {!showNavigationOnly && (
        <x.div
          data-testid="primer-header-bar"
          display="flex"
          justifyContent="space-between"
          boxShadow={!isAccessible && `0 5px 5px 0 rgba(34, 34, 34, 0.2)`}
          ref={containerRef}
        >
          {isTitleVisible ? (
            <x.div
              display="flex"
              gap={inner.base02}
              p={`${inner.base06} ${inner.base06} ${inner.base02}`}
              alignItems="center"
            >
              <Typography variant="body-small-em">
                <span className="mentions-container">
                  <>
                    {ReactHtmlParser(primerTitle)}
                    {` | ${ProductTitle[productType]}`}
                  </>
                </span>
              </Typography>
              <Typography color={text.secondary} variant="body-small">{`Last updated ${formatDate(
                lastUpdatedAt,
                "d MMM yyyy"
              )} `}</Typography>
              {displayMarkets && (
                <Typography component="span" color={text.secondary} display="flex" alignItems="center">
                  <Spacer />
                  <MarketPill market={market} containerRef={containerRef} />
                </Typography>
              )}
              <Spacer />
              <>
                <x.span px={inner.base}>
                  <Icon color="success">
                    <Purchased />
                  </Icon>
                </x.span>
                <Typography color={text.success} component="span">
                  {purchaseProperties.purchaseStatus}
                </Typography>
              </>
            </x.div>
          ) : (
            <Tabs
              index={currentTabIndex}
              onChange={handleNavigationClick}
              w="100%"
              style={{ paddingTop: `${isAccessible ? inner.base06 : inner.base02}`, paddingLeft: `${inner.base06}` }}
            >
              {tabs.map((tab) => (
                <TabItem key={tab} label={tab} />
              ))}
            </Tabs>
          )}
          {isAccessible && (
            <x.div
              p={`${inner.base06} ${inner.base06} ${inner.base02}`}
              data-testid="customer-primer-header-buttons-container"
              display="flex"
              gap={inner.base02}
              alignItems="center"
              borderBottom={!isTitleVisible && `solid 1px ${background.disabled}`}
            >
              {isWatchlistQuickAddButtonVisible && (
                <WatchlistQuickAddButton
                  cdsAlphaCompanyIds={companyIds}
                  productType={productType}
                  contentId={contentId}
                />
              )}
              <CopyLinkButton
                contentId={contentId}
                contentType={AlphaNowProductType.customerPrimer}
                {...iconButtonProps}
              />

              <BookmarkButton
                permissions={[MANAGE_CONTENT_PERMISSION]}
                isBookmarked={isBookmarked}
                onToggleBookmark={onToggleBookmark}
                {...iconButtonProps}
              />
              <DownloadButton
                contentId={contentId}
                contentType={AlphaNowProductType.customerPrimer}
                downloadFileName={downloadFileName}
                {...iconButtonProps}
              />
              <CommissionPrimerButton onClick={onOpen} variant="secondary" />
              <IconButton
                onClick={() => setIsTitleVisible((prevState) => !prevState)}
                variant="basic"
                size="medium"
                color={icon.secondary}
              >
                <ChevronUp data-testid="hide-title-button" />
              </IconButton>
            </x.div>
          )}
        </x.div>
      )}
      {(showNavigationOnly || isTitleVisible) && (
        <Tabs p={`0 ${inner.base06}`} index={currentTabIndex} onChange={handleNavigationClick}>
          {tabs.map((tab) => (
            <TabItem key={tab} label={tab} />
          ))}
        </Tabs>
      )}
      {isVisible && <CommissionModal productType={productType} onClose={onClose} />}
    </x.div>
  );
};

export default PrimersHeaderActionBar;
