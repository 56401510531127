import Notification from "rc-notification";
import { ErrorBanner, SuccessBanner, InfoBanner } from "./components/alphaui";
import React from "react";
import { SuccessNotification } from "components/Notification";
import { debounce } from "lodash";

let notification = null;
Notification.newInstance(
  {
    style: {
      top: 10,
      left: "50%",
      position: "fixed",
      width: 500,
      marginLeft: -250,
      zIndex: 200,
    },
    maxCount: 4,
  },
  (n) => {
    notification = n;
  }
);

let notificationBottom = null;
Notification.newInstance(
  {
    style: {
      bottom: 10,
      left: "50%",
      position: "fixed",
      width: 500,
      marginLeft: -250,
      zIndex: 200,
    },
    maxCount: 4,
  },
  (n) => {
    notificationBottom = n;
  }
);

const showErrorMessage = debounce((message) => window.toast.error({ message }), 5000, {
  leading: true,
  trailing: false,
});

const showSuccessMessage = (message) => window.toast.success({ message });

export const showErrorBanner = (message, { position } = { position: "top" }) => {
  if (window.toast) {
    showErrorMessage(message);
  } else {
    const key = new Date().getTime().toString();
    notify(key, position, <ErrorBanner onClose={() => notification.removeNotice(key)}>{message}</ErrorBanner>);
  }
};

export const showSuccessBanner = (message, { position, useAlphaUI } = { position: "top", useAlphaUI: true }) => {
  if (window.toast) {
    showSuccessMessage(message);
  } else {
    const key = new Date().getTime().toString();
    const BannerComponent = useAlphaUI ? SuccessBanner : SuccessNotification;
    notify(key, position, <BannerComponent onClose={() => notification.removeNotice(key)}>{message}</BannerComponent>);
  }
};

export const showInfoBanner = (message, { position, body } = { position: "top" }, icon = "check") => {
  const key = new Date().getTime().toString();
  notify(
    key,
    position,
    <InfoBanner slim={true} icon={icon} onClose={() => notification.removeNotice(key)}>
      {message || body}
    </InfoBanner>
  );
};

const notify = (key, position, content) => {
  const target = position === "top" ? notification : notificationBottom;
  target.notice({
    key,
    content,
    closable: false,
    duration: 4,
    style: { margin: 10 },
  });
};
