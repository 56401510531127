import React, { useEffect, useState } from "react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Typography, Link } from "@alphasights/alphadesign-components";
import { DIAL_IN_TAB, CALL_ME_TAB, WEB_CONNECT_TAB, ZOOM_APP_TAB, CallAvailabilityStatus } from "./constants";
import { DialIn } from "./DialIn.js";
import { RequestCallBack } from "./RequestCallBack.js";
import { WebConnect } from "./WebConnect.js";
import { accessCodeForHuman } from "components/CallMe";
import { fetch } from "hooks/useApi";
import { OpenExternalLink } from "./OpenExternalLink.js";

const CallMeTabItem = ({ label, onClick, isSelected }) => {
  return (
    <div className="aui-flex aui-flex-col aui-cursor-pointer" onClick={onClick}>
      <Typography className="aui-px-4 aui-pb-2" variant="body-em">
        {label}
      </Typography>
      {isSelected && (
        <div
          className="aui-button-gradient aui-width-full"
          style={{
            height: "4px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        />
      )}
    </div>
  );
};

// Initially a Tabs component was created for CallMe, as ADS Tabs have rendering problems in the modal and do not allow changing the current index
const CallMeTabs = ({ provider, selectedTabIndex, setSelectedTabIndex }) => {
  return (
    <div
      className="aui-text-body aui-font-semibold aui-text-grey-5 aui-flex aui-flex-row aui-border-grey-2 aui-border-b aui-mt-1"
      data-testid="join-method-tabs"
    >
      {provider && provider === "zoom" && (
        <CallMeTabItem
          label="Zoom App"
          onClick={() => setSelectedTabIndex(ZOOM_APP_TAB)}
          isSelected={selectedTabIndex === ZOOM_APP_TAB}
        />
      )}
      {provider && ["default", "twilio", "zoom", "263", "zipdx", "quanshi"].includes(provider) && (
        <CallMeTabItem
          label="Dial-in"
          onClick={() => setSelectedTabIndex(DIAL_IN_TAB)}
          isSelected={selectedTabIndex === DIAL_IN_TAB}
        />
      )}
      {provider && ["default", "twilio", "263", "zipdx", "quanshi", "zoom"].includes(provider) && (
        <CallMeTabItem
          label="Call Me"
          onClick={() => setSelectedTabIndex(CALL_ME_TAB)}
          isSelected={selectedTabIndex === CALL_ME_TAB}
        />
      )}
      {provider && ["default", "twilio", "263", "zipdx", "teams"].includes(provider) && (
        <CallMeTabItem
          label="Web Connect"
          onClick={() => setSelectedTabIndex(WEB_CONNECT_TAB)}
          isSelected={selectedTabIndex === WEB_CONNECT_TAB}
        />
      )}
    </div>
  );
};

export const CallMePanel = ({
  mainNumber,
  accessCode,
  otherNumbers,
  projectToken,
  interactionId,
  newspeakProvider,
  isClosed,
  initialTab,
  callMeUrl,
}) => {
  const getInitialTab = () => {
    if (initialTab && initialTab >= 0 && initialTab <= 3) {
      return initialTab;
    } else {
      if (newspeakProvider && newspeakProvider === "zoom") {
        return ZOOM_APP_TAB;
      }
      if (newspeakProvider && newspeakProvider === "teams") {
        return WEB_CONNECT_TAB;
      }
      return DIAL_IN_TAB;
    }
  };

  const { isMobile } = useCheckScreen();
  const [selectedTabIndex, setSelectedTabIndex] = useState(getInitialTab());

  // states to control the display of contents according to tab selection,
  // thus avoiding rendering components and keeping their states (especially for web connect)
  const [isTabDialInOpen, setIsTabDialInOpen] = useState(false);
  const [isTabRequestCallBackOpen, setIsTabRequestCallBackOpen] = useState(false);
  const [isTabWebConnectOpen, setIsTabWebConnectOpen] = useState(false);
  const [isTabZoomAppOpen, setIsTabZoomAppOpen] = useState(false);

  const [isWebConnectionActive, setIsWebConnectionActive] = useState(false);

  const [callAvailabilityStatus, setCallAvailabilityStatus] = useState();

  const [projectLead, setProjectLead] = useState();

  const interactionAccessCode = accessCodeForHuman(accessCode, newspeakProvider);

  const [clientAccessCode, setClientAccessCode] = useState(interactionAccessCode);

  const [clientDialNumbers, setClientDialNumbers] = useState([]);

  useEffect(() => {
    if (otherNumbers && otherNumbers.length > 0) {
      setClientDialNumbers(otherNumbers);
    } else {
      const requestDialNumbers = async () => {
        try {
          const response = await fetch({
            url: `/api/internal/projects/${projectToken}/calls/${interactionId}/dial-numbers`,
            method: "GET",
            skipAlert: true,
          });

          const json = await response.json();
          setClientDialNumbers(json);
        } catch (error) {
          console.warn(error);
        }
      };
      requestDialNumbers();
    }
  }, [otherNumbers, projectToken, interactionId]);

  useEffect(() => {
    const requestProjectData = async () => {
      try {
        const response = await fetch({
          url: `/api/projects/${projectToken}/lead`,
          method: "GET",
          skipAlert: true,
        });

        const json = await response.json();
        setProjectLead(json);
      } catch (error) {
        console.warn(error);
      }
    };
    requestProjectData();
  }, [projectToken]);

  useEffect(() => {
    if (!isClosed) {
      const requestCallAvailability = async () => {
        try {
          setCallAvailabilityStatus(null);

          const response = await fetch({
            url: `/api/internal/projects/${projectToken}/calls/${interactionId}/call-availability`,
            method: "GET",
            skipAlert: true,
          });

          if (response.status === 404) {
            setCallAvailabilityStatus({
              availability: CallAvailabilityStatus.NOT_SCHEDULED,
            });
          } else {
            const json = await response.json();
            setCallAvailabilityStatus(json);
            if (!interactionAccessCode || interactionAccessCode === "") {
              if (json.clientAccessCode) {
                setClientAccessCode(`${json.clientAccessCode}#`);
              }
            }
          }
        } catch (error) {
          console.warn(error);
          setCallAvailabilityStatus({
            availability: CallAvailabilityStatus.FETCH_ERROR,
          });
        }
      };

      requestCallAvailability();
    }
  }, [isClosed, projectToken, interactionId, interactionAccessCode]);

  useEffect(() => {
    switch (selectedTabIndex) {
      case DIAL_IN_TAB:
        setIsTabDialInOpen(true);
        setIsTabRequestCallBackOpen(false);
        setIsTabWebConnectOpen(false);
        setIsTabZoomAppOpen(false);
        break;

      case CALL_ME_TAB:
        setIsTabDialInOpen(false);
        setIsTabRequestCallBackOpen(true);
        setIsTabWebConnectOpen(false);
        setIsTabZoomAppOpen(false);
        break;

      case WEB_CONNECT_TAB:
        setIsTabDialInOpen(false);
        setIsTabRequestCallBackOpen(false);
        setIsTabWebConnectOpen(true);
        setIsTabZoomAppOpen(false);
        break;

      case ZOOM_APP_TAB:
        setIsTabDialInOpen(false);
        setIsTabRequestCallBackOpen(false);
        setIsTabWebConnectOpen(false);
        setIsTabZoomAppOpen(true);
        break;

      default:
        break;
    }
  }, [selectedTabIndex]);

  const getZoomAppText = () => (
    <>
      <div className="aui-text-grey-4 aui-font-regular aui-text-body aui-mt-4">
        Click on "Join Call" to join your call via the Zoom app.
      </div>
      <div className="aui-text-grey-4 aui-font-regular aui-text-body aui-mt-4">
        By joining this meeting, you agree to{" "}
        <Link href={"https://explore.zoom.us/en/eula-terms-of-service/"}>Zoom’s Terms of Service</Link>.
      </div>
    </>
  );

  const getGenericExternalLinkText = () => (
    <>
      <div className="aui-text-grey-4 aui-font-regular aui-text-body aui-mt-4">
        Join your scheduled conference by clicking "Join Call" and enable microphone access if prompted.
      </div>
    </>
  );

  return (
    <>
      <div
        style={
          !isMobile
            ? {
                width: "511px",
                height:
                  !callAvailabilityStatus || callAvailabilityStatus.availability === CallAvailabilityStatus.AVAILABLE
                    ? "279px"
                    : "331px",
              }
            : {}
        }
        className={`${isMobile ? "aui-w-screen aui-px-4" : ""}`}
      >
        <CallMeTabs
          provider={newspeakProvider}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        <div className="aui-mt-4">
          <OpenExternalLink
            isOpen={isTabZoomAppOpen}
            callAvailabilityStatus={callAvailabilityStatus}
            projectLead={projectLead}
            joinUrl={callMeUrl}
            textToShow={getZoomAppText()}
          />

          <DialIn
            isOpen={isTabDialInOpen}
            mainNumber={mainNumber}
            otherNumbers={clientDialNumbers}
            interactionId={interactionId}
            accessCode={clientAccessCode}
            callAvailabilityStatus={callAvailabilityStatus}
            projectLead={projectLead}
          />

          <RequestCallBack
            isOpen={isTabRequestCallBackOpen}
            projectToken={projectToken}
            interactionId={interactionId}
            callAvailabilityStatus={callAvailabilityStatus}
            projectLead={projectLead}
            accessCode={clientAccessCode}
            isWebConnectionActive={isWebConnectionActive}
          />
          <WebConnect
            isOpen={isTabWebConnectOpen && newspeakProvider && ["default", "twilio"].includes(newspeakProvider)}
            interactionId={interactionId}
            projectToken={projectToken}
            mainNumber={mainNumber}
            accessCode={clientAccessCode}
            callAvailabilityStatus={callAvailabilityStatus}
            projectLead={projectLead}
            isClosed={isClosed}
            setIsWebConnectionActive={setIsWebConnectionActive}
            setSelectedTabIndex={setSelectedTabIndex}
          />
          <OpenExternalLink
            isOpen={isTabWebConnectOpen && newspeakProvider && ["263", "zipdx", "teams"].includes(newspeakProvider)}
            callAvailabilityStatus={callAvailabilityStatus}
            projectLead={projectLead}
            joinUrl={callMeUrl}
            textToShow={getGenericExternalLinkText()}
          />
        </div>
      </div>
    </>
  );
};
