import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useRecordingPlayerStyles = (variant) => {
  const {
    color: { icon, border, text, background },
    spacing: { inner },
  } = useThemeTokens();

  const headerStyles = {
    display: "flex",
    ...(variant === "new-flyout" && {
      alignItems: "center",
      justifyContent: "space-between",
    }),
    ...(variant === "calendar-popup" && {
      flexDirection: "column",
    }),
  };

  const titleStyles = {
    display: "flex",
    alignItems: "flex-start",
    ...(variant === "new-flyout" && {
      gap: inner.base02,
    }),
  };

  const iconStyles = {
    width: inner.base05,
    height: inner.base05,
    minWidth: inner.base05,
    minHeight: inner.base05,
    ...(variant === "new-flyout" && {
      color: icon.secondary,
    }),
  };

  const controlStyles = {
    display: "flex",
    alignItems: "center",
    gap: inner.base06,
    ...(variant === "new-flyout" && {
      gap: inner.base02,
    }),
    ...(variant === "calendar-popup" && {
      ml: inner.base05,
      pl: inner.base04,
      mt: inner.base02,
      justifyContent: "center",
    }),
  };

  const progressBarStyles = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
    ...(variant === "calendar-popup" && {
      ml: inner.base05,
      pl: inner.base04,
    }),
    ...(variant === "new-flyout" && {
      ml: inner.base08,
    }),
  };

  const durationsStyles = {
    display: "flex",
    justifyContent: "space-between",
  };

  const wrapperStyles = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
    ...(variant === "calendar-popup" && {
      mt: inner.base04,
    }),
  };

  const lightGreyTextStyles = {
    color: text.secondary,
  };

  const darkGreyTextStyles = {
    color: text.strong._,
  };

  const controlButtonStyles = {
    w: inner.base05,
    minW: inner.base05,
    h: inner.base05,
    minH: inner.base05,
    ...(variant === "new-flyout" && {
      w: inner.base08,
      h: inner.base08,
      borderWidth: 1,
      borderRadius: inner.base,
      borderColor: {
        _: border.neutral.default,
        hover: border.selected,
      },
      color: {
        _: icon.secondary,
        hover: icon.secondary,
      },
    }),
  };

  const playSpeedButtonStyles = {
    ...controlButtonStyles,
    ...(variant === "new-flyout" && {
      borderColor: {
        _: border.neutral.default,
        hover: border.selected,
        focus: border.selected,
      },
    }),
  };

  const descriptionStyles = {
    ...(variant === "calendar-popup" && {
      pl: inner.base04,
    }),
  };

  const popoverStyles = {
    p: 0,
  };

  const playbackSpeedOptionsStyles = {
    gap: inner.base,
    display: "flex",
    flexDirection: "column",
    p: inner.base02,
  };

  const playbackSpeedStyles = {
    px: inner.base04,
    justifyContent: "space-between",
    color: text.strong._,
    backgroundColor: {
      hover: background.surface.page.hover,
    },
    minW: "110px",
  };

  const selectedPlaybackSpeedStyles = {
    backgroundColor: {
      _: background.surface.page.hover,
      hover: background.surface.page.hover,
    },
  };

  return {
    headerStyles,
    titleStyles,
    iconStyles,
    controlStyles,
    progressBarStyles,
    durationsStyles,
    lightGreyTextStyles,
    darkGreyTextStyles,
    controlButtonStyles,
    playSpeedButtonStyles,
    wrapperStyles,
    descriptionStyles,
    popoverStyles,
    playbackSpeedStyles,
    playbackSpeedOptionsStyles,
    selectedPlaybackSpeedStyles,
  };
};
