import React from "react";
import { PrimaryButton, Icon } from "../alphaui";

const CentralizedBox = ({ children, className }) => {
  const classes = `aui-flex aui-justify-center ${className || ""}`;

  return <div className={`aui-flex ${classes || ""}`}>{children}</div>;
};

const InformationSection = ({ children, className }) => (
  <div className={`aui-w-10/12 md:aui-w-3/5 lg:aui-w-1/3 ${className}`}>
    <div className="aui-border-solid aui-border-1 aui-border-t-0 aui-w-8 aui-text-dark-1 aui-mt-5" />
    {children}
  </div>
);

const ContactDetail = ({ icon, children }) => (
  <div className="aui-flex aui-items-center aui-mb-1">
    <Icon className="aui-text-grey-4" icon={icon} />
    <span className="aui-text-dark-1 aui-ml-4 aui-leading-relaxed">{children}</span>
  </div>
);

const Paragraph = ({ children }) => <p className="aui-font-light aui-text-sm aui-leading-snug">{children}</p>;

const LargeText = ({ children }) => (
  <div className="aui-font-light aui-text-dark-1" style={{ fontSize: "155px" }}>
    {children}
  </div>
);

const UnauthorizedBlock = () => (
  <CentralizedBox>
    <div className="aui-flex aui-justify-around aui-mx-8 aui-h-40 aui-w-64">
      <LargeText>403</LargeText>
    </div>
  </CentralizedBox>
);

export const UnauthorizedPage = () => (
  <>
    <CentralizedBox className="aui-flex-col aui-mt-8">
      <UnauthorizedBlock />

      <h1 className="aui-text-primary-2 aui-mt-12 aui-mb-5 aui-font-bold aui-mx-4 md:aui-mx-40 lg:aui-mx-0 aui-text-lg lg:aui-text-xl aui-text-center">
        Sorry, you are not allowed to access this resource.
      </h1>

      <CentralizedBox className="aui-flex-wrap md:aui-mx-20 lg:aui-ml-34 lg:aui-mr-32 lg:aui-justify-evenly">
        <InformationSection className="aui-mb-6">
          <Paragraph>AlphaSights connects the world's top professionals with the world's best knowledge.</Paragraph>
          <PrimaryButton onClick={() => window.open("https://www.alphasights.com", "_self")}>Learn More</PrimaryButton>
        </InformationSection>
        <InformationSection className="aui-ml-5 aui-mb-10">
          <Paragraph>
            Can’t find what you’re looking for? <br />
            You can contact us using any of the following methods and we will do our best to get the information you are
            looking for.
          </Paragraph>

          <ContactDetail icon="phone">+44 20 7399 9750</ContactDetail>
          <ContactDetail icon="email">client.service@alphasights.com</ContactDetail>
        </InformationSection>
      </CentralizedBox>
    </CentralizedBox>
  </>
);
