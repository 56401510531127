import { ProjectCreationRequest } from "models/ProjectLauncher";
import { fetch } from "../hooks/useApi";

export const projectLauncherService = {
  async launchProject(project: ProjectCreationRequest): Promise<{ token: string }> {
    return fetch({
      url: "/api/launched-projects",
      method: "POST",
      handleForbidden: true,
      skipAlert: true,
      body: JSON.stringify(project),
    }).then((res) => res.json());
  },
};
